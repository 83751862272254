import React, {useState,useRef } from "react";
import DynamicDropdown from "../../../../shared/components/DynamicDropdown";
import Dropdown from "../../../../shared/components/Dropdown";
import { toast } from "react-toastify";
import { masterScreenList, suggestionMasterScreen, sendToReopen } from "./constant";
import MasterScreenDashboard from "./MasterScreenDashboard";
import { configStore } from "../../../../../redux/ConfigStore";
import {
  getSuggestionSpecialScheme,
  deleteSuggestionSchemeName,
  getSuggestonOfficeAgencyDashboard,
  getSuggAgency,
  getSugReopenDetails,
  submitReopenSuggestion,
  adminUploadDocument,
  suggetionOfficerSuggestionById
} from "../../../../../constants/api.service";
import UpdatePopUpScreen from "./UpdatePopUpScreen";
import searchIcon from "../../../../../assets/drishti_images/admin/searchIcon.svg";
import AddPopUpScreen from "./AddPopUpScreen";
import { toaster } from "../../../../../utils/Utils";
import LoaderIndicator from "../../../../shared/loader/LoaderIndicator";
import DataNotFound from "../../../../shared/notFound/DataNotFound";
import { useSelector } from "react-redux";
import SuggestionReOpenDetails from "../suggestionOffice/SuggestionReOpenDetails";

function SuggestionMasterScreen() {
  const [selectedScreen, setSelectedScreen] = useState(null);
  const [updateFormData, setUpdateFormData] = useState(null);
  const [isFormUpdatePopup, setIsFormUpdatePopup] = useState(false);
  const [AddFormData, setAddFormData] = useState(false);
  const [isFormViewPopup, setIsFormViewPopup] = useState(false);
  const [addFormBtn, setAddFormBtn] = useState(true);
  const [masterScreenListData, setMasterScreenListData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showMasterScreenDashboard, setShowMasterScreenDashboard] =
    useState(false);
  const [showMasterScreenDashboardReopen, setShowMasterScreenDashboardReopen] =
    useState(false);
  const [divisionList, setDivisionList] = useState([]);
  const [suggestionData, setSuggestionData] = useState(null);
  const [divisionCode, setDivisionCode] = useState(null);
  const AdminLoginData = useSelector((state) => state.adminLoginState);
  const [suggestionId, setSuggestionId] = useState("");
  const [search, setSearchFunction] = useState("");
  const [agencyData, setAgencyData] = useState([]);
  const [agency, setAgency] = useState("");
  const [clarificationRemark, setClarificationRemark] = useState("");
  const [showData, setShowData] = useState(false);
  const [selectedReopenStatus, setSelectedReopenStatus] = useState(null);
  const [showReopenDivison,setShowReopenDivison] = useState(false);
  const [showReopenAgency,setShowReopenAgency] = useState(false);
  const fileInputRef = useRef(null);
  const [statusCode,setStatusCode] = useState(null)
  const [attachmentName, setAttachmentName] = useState('');
  const [documentData, setDocumentData] = useState(null);
  const [documentLink, setdocumentLink] = useState(null);
  const [showReopenDocumentUpload,setShowReopenDocumentUpload] = useState(false);
  const [orginalAgencyDetails,setOrignalAgencyDetails] = useState(null);
  const [selectedDivisionType, setSelectedDivisionType] = useState("Select Division");
  const [showRemarks,setShowRemarks] = useState(false)
  const [selectedStatus,setSelectedStatus] = useState('')
  const isUploadButtonDisabled =  !attachmentName.documentName;

  const getSuggestionByIdData = (sug_id) => {
    setLoading(true)
    suggetionOfficerSuggestionById(sug_id)
      .then((res) => {
        const { status, data } = res
        if (status === 200 || status === 201) {
          setSuggestionData(data?.data);
          setSelectedStatus(data?.data?.suggestion_details?.status)
          setShowData(true)
          
        }
        else { toaster("warning", res?.data?.message ?? "Something Went Wrong!") }
      })
      .catch((error) => { toaster("error", error?.data?.message ?? "Something Went Wrong") })
      .finally(() => { setLoading(false) })
  }

  //---------------------------------Get Suggestion By Id for Reopen cases------------------------//
  const getSuggestionById = (sug_id) => {
    if (sug_id.trim() === "") {
      toaster("warning", "Suggestion ID cannot be empty");
      setShowData(false);
      setStatusCode(null)
      setShowRemarks(false)
      setClarificationRemark("");
    } else if (!isNaN(sug_id) && !isNaN(parseFloat(sug_id))) {
      setSelectedDivisionType(null);
      setAgency(null);
      setShowData(false)
      let params = {
        comp_code: AdminLoginData?.company?.company_code,
        pa: AdminLoginData?.userData?.PersArea,
        sug_id: sug_id,
      };
      getSugReopenDetails(params)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            setSuggestionData(res?.data?.data);
            setStatusCode(res?.data?.data?.suggestion_details?.status_code)
            setShowRemarks(true)
            setOrignalAgencyDetails({
              id: res?.data?.data?.suggestion_details?.agency,
              label: res?.data?.data?.suggestion_details?.agency_name,
            })
            getAgency(res?.data?.data?.suggestion_details?.division_id);
            setDivisionCode(res?.data?.data?.suggestion_details?.division_id);
            setShowData(true);
            toaster("success", res?.data?.message ?? "Data Fetch Successfully");
          } else {
            setClarificationRemark("");
            setShowData(false);
            setShowRemarks(false)
            setStatusCode(null)

            toaster("warning", res?.data?.message ?? "Something Went Wrong!");
          }
        })
        .catch((error) => {
          toaster("warning", error?.data?.message ?? "Something Went Wrong");
          setShowData(false);
          setStatusCode(null)
          setShowRemarks(false)
        });
    } else {
      toaster("warning", "Sugg ID entered contains Alpha numerical value");
      setShowData(false);
      setStatusCode(null)
      setShowRemarks(false)
    }
  };

  // ---------------------Get Agency Details Based of Division ID------------ //
  const getAgency = (divisionId) => {
    const params = { division: divisionId };
    getSuggAgency(params)
      .then((res) => {
        if (res?.status === 200) {
          const agency_list = res?.data?.data?.agency_list.map((agency) => ({
            id: agency?.agency_code,
            label: agency?.agency_name,
          }));
          setAgencyData(agency_list);
        } else {
          setAgencyData([]);
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      });
  };

  //--------------Get Suggestion Office API-------------------------
  const getSuggestionOfficeAgencyDashBoardList = () => {
    setLoading(true);
    let params = {
      comp_code: AdminLoginData?.company?.company_code,
      pa: AdminLoginData?.userData?.PersArea,
    };
    getSuggestonOfficeAgencyDashboard(params)
      .then((res) => {
        const { status, data } = res;
        if (status === 200 || status === 201) {
          setDivisionList(data?.data ? [...data.data] : []);
        } else {
          toaster("warning", res?.data?.message ?? "Something Went Wrong!");
        }
      })
      .catch((error) => {
        toaster("warning", error?.data?.message ?? "Something Went Wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSlected = (data) => {
    if (data.key == suggestionMasterScreen.SchemeList) {
      setAddFormBtn(true);
      getSpecialSchemeListDetails();
      setShowMasterScreenDashboard(true);
      setSearchFunction("");
      setShowData(false)
      setStatusCode(null)
      setShowReopenAgency(false)
      setShowReopenDivison(false)
      setShowReopenDocumentUpload(false)
      setShowRemarks(false)
      setSelectedReopenStatus("")
      setClarificationRemark("");
      setDivisionCode(null);
      setShowMasterScreenDashboardReopen(false);
    } else if (data.key == "Reopen") {
      getSuggestionOfficeAgencyDashBoardList();
      setAddFormBtn(false);
      setShowMasterScreenDashboard(false);
      setShowMasterScreenDashboardReopen(true);
    }
    setSelectedScreen(data);
  };

  const getSpecialSchemeListDetails = async () => {
    setLoading(true);
    const param = {
      pa: configStore.getState().adminLoginState?.userData?.PersArea,
      comp_code: configStore.getState().adminLoginState?.company?.company_code,
    };
    await getSuggestionSpecialScheme(param)
      .then((res) => {
        if (res.status === 200) {
          setMasterScreenListData(res?.data?.data);
        } else {
          setMasterScreenListData([]);
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoading(false);
      });
    };
    
    const onAction = (listData, type) => {
      if (type === "update") {
        setIsFormUpdatePopup(true);
        setUpdateFormData(listData);
      } else if (type === "delete") {
        deleteForm(listData);
    } else if (type === "view") {
      setIsFormViewPopup(true);
      setUpdateFormData(listData);
    }
  };

  const addForm = () => {
    setAddFormData(true);
  };
  
  const deleteForm = async (data) => {
    setLoading(true);
    let body = {
      scheme_name: data?.scheme_name,
      start_date: data?.start_date,
      end_date: data?.end_date,
      pa: configStore.getState().adminLoginState?.userData?.PersArea,
      comp_code: configStore.getState().adminLoginState?.company?.company_code,
    };
    await deleteSuggestionSchemeName(body, data.id)
    .then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        toaster("success", res?.data?.message ? res?.data?.message : "");
          getSpecialSchemeListDetails();
        } else {
          res?.status != 404 &&
            toaster("warning", res?.data?.message ? res?.data?.message : "");
        }
      })
      .catch((error) => {
        error?.status != 404 &&
        toaster("warning", error?.data?.message ? error?.data?.message : "");
      })
      .finally(() => {
        setLoading(false);
      });
    };
    
    const searchFilterFunction = (e) => {
      const searchValue = e.target.value;
      setSearchFunction(searchValue);
    };
    
    const suggestionReopen = () => {
      setLoading(true);
      let req_body={}
    const sug_id = suggestionData?.suggestion_details?.id
    if(statusCode != 4 || statusCode != 5){
    req_body = {
      comp_code: AdminLoginData?.company?.company_code,
      pa: AdminLoginData?.userData?.PersArea,
      action: "Re-Open",
      comment: clarificationRemark,
      agency: orginalAgencyDetails?.id,
      agency_name: orginalAgencyDetails?.label,
    }}
    if(selectedReopenStatus?.id == 0){
      if(search === "" || !divisionCode || !agency || clarificationRemark === ""){
        toaster("warning", "All fields are Required");
        return
      }else{
        req_body = {
          comp_code: AdminLoginData?.company?.company_code,
          pa: AdminLoginData?.userData?.PersArea,
          action: "Re-Open",
          comment: clarificationRemark,
          agency: agency?.id,
          agency_name: agency?.label,
          send_to_status:selectedReopenStatus?.id,
        };

      }
    }
    if(selectedReopenStatus?.id == 1){
      if(search === "" || !divisionCode || clarificationRemark === ""){
        toaster("warning", "All fields are Required");
        return
      }else{
        req_body = {
          comp_code: AdminLoginData?.company?.company_code,
          pa: AdminLoginData?.userData?.PersArea,
          action: "Re-Open",
          comment: clarificationRemark,
          agency: agency?.id,
          agency_name: agency?.label,
          send_to_status:selectedReopenStatus?.id,
        };
      }
    }
    if(selectedReopenStatus?.id == 2){
      
      if(search === "" || !documentData || clarificationRemark === ""){
        toaster("warning", "All fields are Required");
        return
      }else{
          req_body = {
            comp_code: AdminLoginData?.company?.company_code,
            pa: AdminLoginData?.userData?.PersArea,
            action: "Re-Open",
            comment: clarificationRemark,
            agency: orginalAgencyDetails?.id,
            agency_name: orginalAgencyDetails?.label,
            suggestion_path:documentData,
            send_to_status:selectedReopenStatus?.id,
          };
      }
    }
      submitReopenSuggestion(search, req_body)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            setClarificationRemark("");
            setShowData(false)
            setStatusCode(null)
            setShowReopenAgency(false)
            setShowReopenDivison(false)
            setShowReopenDocumentUpload(false)
            setShowRemarks(false)
            setSelectedReopenStatus("")
            getSuggestionByIdData(sug_id)
            toaster("success", res?.data?.message);
            
          } else {
            toaster("warning", res?.data?.message);
          }
        })
        .catch((error) => {
          toaster("warning", error?.data?.message);
        })
        .finally(() => {
          setLoading(false);
        });
  };

  const handleDivision = (data) => {
    setSelectedDivisionType(data?.division_name);
    setDivisionCode(data?.division_code);
    setSuggestionId(suggestionId);
    setAgency(null);
    getAgency(data?.division_code);
    setShowReopenAgency(true)
  };

  const handleAgency = (data) => {
    setAgency(data);
  };
  const dismisUpdateForm = () => {
    getSpecialSchemeListDetails();
    setIsFormUpdatePopup(false);
  };

  const closeUpdatePopUp = () => {
    getSpecialSchemeListDetails();
    setIsFormUpdatePopup(false);
  };

  const dismisAddForm = () => {
    getSpecialSchemeListDetails();
    setAddFormData(false);
  };

  const closePopup = () => {
    setShowData(false);
  };

  const closeAddPopUp = () => {
    getSpecialSchemeListDetails();
    setAddFormData(false);
  };

  const fileSelectHandler = (event) => {
    if (event?.target?.files && event?.target?.files[0]) {
        let typeOfImage = event?.target?.files[0]?.type;
        let ImageType = typeOfImage.split('/');
        setAttachmentName({
            documentName: event?.target?.files[0]?.name,
            documentType: ImageType[1] || ''
        });
        
        let maxAllowedSize = 2097152;
        if (event?.target?.files[0]?.size <= maxAllowedSize) {
            let reader = new FileReader();
            reader.readAsDataURL(event?.target?.files[0]);
            reader.onload = (e) => {
                setdocumentLink({ documentLink: e.target.result });
            };
        } else {
            toast.warn('Please upload image less than 2MB', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            });
            setdocumentLink({ documentLink: '' });
        }
    } else {
        setAttachmentName({ documentName: '', documentType: '' });
        setdocumentLink({ documentLink: '' });
    }
  };

  const handleUpload = () => {
    UploadDocumentDetail(
      documentLink?.documentLink,
      attachmentName?.documentType,
      attachmentName?.documentName
    );
  };

  const UploadDocumentDetail = async (
    attachment,
    attachmentType,
    attachmentName
  ) => {
    setLoading(true);
    let body = {
      emp_id: configStore.getState().adminLoginState?.userData?.PersArea,
      attachment: attachment,
      attachment_name: attachmentName,
      attachment_type: attachmentType,
    };
    await adminUploadDocument(body)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          const document = {
            name: res?.data?.data?.attachment_name,
            extension: attachmentType,
            attachmentURL: res?.data?.data?.attachment_presigned_url,
            attachment: res?.data?.data?.attachment_path,
            path: res?.data?.data?.attachment_path,
            document_category: "Re-Open",
            role: "Suggestion_office",
            type: null
          };

          setDocumentData([document]);
          toaster("success", res?.data?.message ? res?.data?.message : "File uploaded successfuly");
        } else {
          res?.status != 404 &&
            toaster("warning", res?.data?.message ? res?.data?.message : "");
        }
      })
      .catch((error) => {
        error?.status != 404 &&
          toaster("warning", error?.data?.message ? error?.data?.message : "");
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const handleSendToReopen = (val) =>{
    setShowReopenDivison(false);
    setShowReopenDocumentUpload(false);
    setShowReopenAgency(false);
    setSelectedDivisionType('Select Division');
    setAgency(null);
    setDocumentData(null);
    setdocumentLink(null);

    if(val.id == 0){
      setShowReopenDivison(true);
    }
    if(val.id == 1){
      setShowReopenDivison(true);
    }
    if(val.id == 2){
      setShowReopenDocumentUpload(true);
    }
    setSelectedReopenStatus(val);
  }

  return (
    <div>
      <LoaderIndicator loading={loading} />
      <div className="px-0 pt-0">
        <div className="justify-content-between align-item-center mt-1">
          <div className="route-common-heading ml-3">MASTER SCREEN</div>
          <div className="justify-content-end align-item-center">
            <div className="ml-2 dropdown-Text">
              <Dropdown
                data={masterScreenList}
                selectedValue={(data) => {
                  handleSlected(data);
                }}
                value={selectedScreen?.value ?? "Select Master Screen Type"}
                render={"value"}
                arrow={"true"}
                height={"dependOnContent"}
              />
            </div>
            {addFormBtn ? (
              <div>
                <input
                  type="button"
                  value="ADD FORM"
                  className={`${
                    selectedScreen ? "validateBtn" : "validateBtnDisabled"
                  }`}
                  onClick={() => addForm()}
                  disabled={!selectedScreen}
                />
              </div>
            ) : null}
          </div>
        </div>

        <div className="row mt-2">
          {showMasterScreenDashboard ? (
            <>
              <div className="profile-inner-card w-100">
                <MasterScreenDashboard
                  tabName={selectedScreen}
                  masterScreenListData={masterScreenListData}
                  onAction={onAction}
                />
              </div>
            </>
          ) : null}

          {showMasterScreenDashboardReopen ? (
            <div
              style={{ position: "relative" }}
              className="align-items-center w-100 gap-10px filter-p5"
            >
              <div className="d-flex flex-wrap align-items-center mx-2">
                <div className="flex-direction-column ml-3">
                  <div className="p-sub-heading ml-2 required">
                    Suggestion ID
                  </div>
                  <div style={{position: "relative"}} className="d-flex align-items-center">
                    <input
                      className="inpt input-date w-100 w-md-auto"
                      type="text"
                      placeholder="Sugg No..."
                      onChange={(e) => searchFilterFunction(e)}
                      value={search}
                    />
                    <div
                      type="button"
                      className="serchIconSize-master-screen"
                      onClick={() => getSuggestionById(search)}
                    >
                      <img
                        alt="radioOn"
                        className="filterIcon"
                        src={searchIcon}
                      />
                    </div>
                  </div>
                </div>
                {(statusCode == 4 || statusCode == 5) && (
                  <>
                    <div className="flex-direction-column ml-3">
                      <div className="p-sub-heading ml-2 required">
                        {"Whom to send"}
                      </div>
                      <DynamicDropdown
                        data={sendToReopen}
                        render="title"
                        value={selectedReopenStatus?.title ? selectedReopenStatus?.title : "Select Value"}
                        selectedValue={(data) =>
                          handleSendToReopen(data)
                        }
                        arrow={"true"}
                        height={"dependOnContent"}
                        width={"w-auto"}
                        boxWidth="w-auto"
                        placeholder={"Select Value"}
                        
                      />
                    </div>

                    
                    {
                      showReopenDivison && (
                        <div className="flex-direction-column ml-3">
                          <div className="p-sub-heading ml-2 required">
                            {"Select Division"}
                          </div>
                          <DynamicDropdown
                            data={divisionList}
                            render="division_name"
                            value={selectedDivisionType}
                            selectedValue={(data) => handleDivision(data)}
                            arrow={"true"}
                            height={"dependOnContent"}
                            width={"w-auto"}
                            boxWidth="w-auto"
                          />
                        </div>
                      )
                    }

                    {
                      showReopenAgency && (
                        <div className="flex-direction-column ml-3">
                          <div className="p-sub-heading ml-2 required">
                            {"Select Agency"}
                          </div>
                          <DynamicDropdown
                            data={agencyData}
                            render="label"
                            value={agency?.label ? agency?.label : "Select Agency"}
                            selectedValue={(data) => handleAgency(data)}
                            arrow={"true"}
                            height={"dependOnContent"}
                            width={"w-auto"}
                            boxWidth="w-atuo"
                          />
                        </div>
                      )
                    }
                    {
                      showReopenDocumentUpload && (
                        <div className="flex-direction-column ml-3">
                          <div className='p-sub-heading'>Upload Document</div>
                            <div className="scroll-webkit-x">
                              <input
                                className='form-control p-placeholder mr-10'
                                type="file"
                                accept=".png,.jpg,.jpeg,.pdf"
                                ref={fileInputRef}
                                style={{ height: '43px' }}
                                placeholder={'Select Document'}
                                onChange={(e) => fileSelectHandler(e)}
                             />
                              <input
                                type="button"
                                value='UPLOAD'
                                className={`${!isUploadButtonDisabled ? 'validateBtn' : 'validateBtnDisabled'}`}
                                onClick={handleUpload}
                                disabled={isUploadButtonDisabled}
                              />
                            </div>
                        </div>
                      )
                    }

                    

                  </>
                )}
                {showRemarks ? (<><div className="flex-direction-column ml-3">
                      <div className="p-sub-heading ml-2 required">
                        {"Remarks"}
                      </div>
                      <div className="d-flex flex-column w-100">
                        <div className="row w-100 row-gap-10px">
                          <div className="flex-direction-column">
                            <textarea
                              className="form-control required esep-placeholder-editable px-3"
                              type="text"
                              onChange={(e) => {
                                setClarificationRemark(e.target.value);
                              }}
                              value={clarificationRemark}
                              placeholder={"Remarks"}
                              maxLength={1024}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex-direction-column ml-3 mt-10px">
                        <input
                          type="button"
                          value="Submit"
                          className={` ${"validateBtn"}`}
                          onClick={() => suggestionReopen()}
                        />
                    </div></>) : null}
              </div>
            </div>
          ) : null}
        </div>
        {showData ? (
          <>
            <SuggestionReOpenDetails
              title={"Suggestion Details"}
              backAction={closePopup}
              selectedRecordId={suggestionData?.suggestion_details?.id}
              showComment={false}
              showAwardType={false}
              selectedStatus ={selectedStatus}
            />
          </>
        ) : null}

        {isFormUpdatePopup && (
          <UpdatePopUpScreen
            updateFormData={updateFormData}
            filteredValue={selectedScreen}
            dismisUpdateForm={dismisUpdateForm}
            closeUpdatePopUp={closeUpdatePopUp}
          />
        )}

        {AddFormData && (
          <AddPopUpScreen
            dismisUpdateForm={dismisAddForm}
            closeUpdatePopUp={closeAddPopUp}
          />
        )}

        {isFormViewPopup && (
          <div className="modal popup-box">
            <div className={"popupCard-box p-0 w-50"}>
              <div className="modal-header">
                <h4 className="modal_title black">
                  {"Notice Board Attachment"}
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={(e) => {
                    setIsFormViewPopup(false);
                  }}
                >
                  &times;
                </button>
              </div>
              {updateFormData.image_path ? (
                <div className="modal-body m-15px scroll-auto">
                  <span className="flex-direction-column">
                    <span className="d-flex flex-direction-column px-10px pb-10px">
                      <>
                        <img
                          src={
                            updateFormData?.image_path
                              ? updateFormData?.image_path
                              : ""
                          }
                          alt=""
                          className="upload_bills_modal_body"
                        />
                      </>
                    </span>
                  </span>
                </div>
              ) : (
                <div>
                  <DataNotFound />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SuggestionMasterScreen;
