import React, { Fragment, useEffect, useState, useRef } from "react";
import moment from "moment";
import Dropdown from "../../../../shared/components/Dropdown";
import { toast } from "react-toastify";
import { suggestionMasterScreen } from "./constant";
import {
  noticeboardUploadDocument,
  addSuggestionSchemeName,
} from "../../../../../constants/api.service";
import { configStore } from "../../../../../redux/ConfigStore";
import { toaster } from "../../../../../utils/Utils";
import LoaderIndicator from "../../../../shared/loader/LoaderIndicator";
import { minImageHeight,minImageWidth,maxImageHeight,maxImageWidth } from './constant';

function AddPopUpScreen({
  dismisUpdateForm,
  closeUpdatePopUp,
}) {
  const [schemeName, setSchemeName] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [documentData, setDocumentData] = useState(null);
  const fileInputRef = useRef(null);
  const [attachmentName, setAttachmentName] = useState("");
  const [documentLink, setdocumentLink] = useState(null);
  const [showDocument, setShowDocument] = useState(false);
  const [loading, setloading] = useState(false);
  const today = new Date().toISOString().split("T")[0];
  const [validateBtn,setValidateBtn] = useState(false);

  const validateForm = () => {
    if(!schemeName || !startDate || !endDate){
        toaster("warning", 'Please fill all the mandatory fields');
        return;
    }
    addSchemeName();
  };

  const handleSchemeName = (e) => {
    setSchemeName(e);
  };

  const handleStartDate = (e) => {
    setStartDate(e);
    setEndDate('')
  };

  const handleEndDate = (e) => {
    setEndDate(e);
  };

  const handleUpload = () => {
    UploadDocumentDetail(
      documentLink?.documentLink,
      attachmentName?.documentType,
      attachmentName?.documentName
    );
    setAttachmentName("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const UploadDocumentDetail = async (
    attachment,
    attachmentType,
    attachmentName
  ) => {
    setloading(true);
    let body = {
      emp_id: configStore.getState().adminLoginState?.userData?.PersArea,
      attachment: attachment,
      attachment_name: attachmentName,
      attachment_type: attachmentType,
    };
    await noticeboardUploadDocument(body)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          const document = {
            // type: viewDocumentTypeFilter?.title,
            name: res?.data?.data?.attachment_name,
            extension: attachmentType,
            attachmentURL: res?.data?.data?.attachment_presigned_url,
            attachment: res?.data?.data?.attachment_path,
            path: res?.data?.data?.attachment_path,
            document_category: "Scheme name",
          };
          // setdocumentData([...documentData, document]);
          setDocumentData(document);
          setShowDocument(true);
        } else {
          res?.status != 404 &&
            toaster("warning", res?.data?.message ? res?.data?.message : "");
        }
      })
      .catch((error) => {
        console.log("error in S3 delete documents-->", error);
        error?.status != 404 &&
          toaster("warning", error?.data?.message ? error?.data?.message : "");
      })
      .finally(() => {
        setloading(false);
      });
  };

  const fileSelectHandler = (event) => {
    handleImageUpload(event);
    if (event?.target?.files && event?.target?.files[0]) {
      let typeOfImage = event?.target?.files[0]?.type;
      let ImageType = typeOfImage.split("/");
      setAttachmentName({
        documentName: event?.target?.files[0]?.name,
        documentType: ImageType[1] || "",
      });

      let maxAllowedSize = 2097152; // 2MB size
      if (event?.target?.files[0]?.size <= maxAllowedSize) {
        let reader = new FileReader();
        reader.readAsDataURL(event?.target?.files[0]);
        reader.onload = (e) => {
          setdocumentLink({ documentLink: e.target.result });
        };
      } else {
        toast.warn("Please upload image less than 2MB", {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
        setdocumentLink({ documentLink: "" });
      }
    } else {
      setAttachmentName({ documentName: "", documentType: "" });
      setdocumentLink({ documentLink: "" });
    }
  };

  const validateImage = (file) => {
    const img = new Image();
    const objectUrl = URL.createObjectURL(file);
    img.onload = function () {
      // Validate image dimensions
      if (
        img.width >= minImageWidth && img.width <= maxImageWidth &&
        img.height >= minImageHeight && img.height <= maxImageHeight
      ){
        setValidateBtn(true);
      } else {
        toaster("warning",`Please upload an image with dimensions between ${minImageWidth}x${minImageHeight} and ${maxImageWidth}x${maxImageHeight} pixels.`);
        setValidateBtn(false);
      }
      URL.revokeObjectURL(objectUrl);
    };

    img.src = objectUrl;
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      validateImage(file);
    }
  };

  const addSchemeName = async () => {
    setloading(true);
    let body = {
      scheme_name: schemeName,
      start_date: startDate,
      end_date: endDate,
      image_path: documentData?.attachment ?? '',
      pa: configStore.getState().adminLoginState?.userData?.PersArea,
      comp_code: configStore.getState().adminLoginState?.company?.company_code,
    };
    await addSuggestionSchemeName(body)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          toaster("success", res?.data?.message ? res?.data?.message : "");
          closeUpdatePopUp();
        } else {
          res?.status != 404 &&
            toaster("warning", res?.data?.message ? res?.data?.message : "");
            closeUpdatePopUp();
        }
      })
      .catch((error) => {
        console.log("error ", error);
        error?.status != 404 &&
          toaster("warning", error?.data?.message ? error?.data?.message : "");
        closeUpdatePopUp();
      })
      .finally(() => {
        setloading(false);
      });
  };

  return (
    <div className="modal popup-box" id="addTodoModal">
      <LoaderIndicator loading={loading} />
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content toDoModalMedium bg-light">
          <div className="modal-header edit-cardtitle">
            <h4 className="modal_title">Add Scheme</h4>
            <button type="button" className="close" onClick={dismisUpdateForm}>
              &times;
            </button>
          </div>
          <div style={{ height: "80%" }}>
            <form
              className="todoModalBody w-100"
              style={{ height: "100%" }}
            >
              <div className="p-1 w-100">
                <div>
                  <div className="p-sub-heading ml-3 required">
                    {suggestionMasterScreen.SchemeName}
                  </div>
                  <div className="p-placeholder-text mb-2 w-50rem">
                    <input
                      className="form-control p-placeholder p-placeholder-admin px-2"
                      type="text"
                      onChange={(e) => handleSchemeName(e.target.value)}
                      value={schemeName}
                      placeholder={"Enter Scheme Name"}
                      // maxLength={50}
                    />
                  </div>

                  <div className="scroll-webkit-x" style={{ width: "90%" }}>
                    <span className="mr-20px w-45-percent">
                      <div className="p-sub-heading ml-3 required">
                        {suggestionMasterScreen.StartDate}
                      </div>
                      <div className="p-placeholder-text mb-2">
                        <input
                          className="form-control p-placeholder"
                          type="date"
                          value={startDate || ""}
                          style={{ height: "38px" }}
                          onChange={(e) => handleStartDate(e.target.value)}
                          min={today}
                        />
                      </div>
                    </span>

                    <span className="w-45-percent">
                      <div className="p-sub-heading ml-3 required">
                        {suggestionMasterScreen.EndDate}
                      </div>
                      <div className="p-placeholder-text mb-2">
                        <input
                          className="form-control p-placeholder"
                          type="date"
                          value={endDate || ""}
                          style={{ height: "38px" }}
                          onChange={(e) => handleEndDate(e.target.value)}
                          min={startDate}
                          disabled={!startDate}
                        />
                      </div>
                    </span>
                  </div>
                  <div>
                    <div className="p-sub-heading">Upload Document</div>
                    <div
                      className="scroll-webkit-x"
                      style={{ width: "80%", marginBottom: "5px" }}
                    >
                      <div className="p-placeholder-text">
                        <input
                          className="form-control p-placeholder"
                          type="file"
                          accept=".png,.jpg,.jpeg,.pdf"
                          ref={fileInputRef}
                          style={{ height: "43px" }}
                          placeholder={"Select Document"}
                          onChange={(e) => fileSelectHandler(e)}
                        />
                        {/* {errors.attachmentName && <span className="p-sub-heading ml-3 errorBlock">{errors.attachmentName}</span>} */}
                        <div>
                          <span className="noteTextMsg">
                            {
                              `You can upload Attachment upto 2MB size only.(pdf/png/jpg/jpeg)`
                            }
                          </span>
                        </div>
                      </div>
                      <div className="col-12 col-md-3 mb-4 d-flex  align-items-center">
                        <input
                          type="button"
                          value="UPLOAD"
                          className={`${validateBtn ? 'validateBtn' : 'validateBtnDisabled'}`}
                          onClick={handleUpload}
                          disabled={!validateBtn}
                        />
                      </div>
                    </div>
                    <div>
                      <span className="noteTextMsg">
                        {
                          `Note: Upload an image with a minimum width of ${minImageWidth}px and height of ${minImageHeight}px, or a maximum width of ${maxImageWidth}px and height of ${maxImageHeight}px`
                        }
                      </span>
                    </div>
                  </div>

                  {showDocument && (
                    <div className="sugg-container2">
                      <img src={documentData.attachmentURL} alt="Uploaded Image" className="sugg-container-Image"/>
                    </div>
                  )}
                </div>
              </div>
            </form>
            <div className="modal-footer btnBox justify-content-end">
              <div className="justify-content-around">
                <div className="justify-content-start">
                  <input
                    type="button"
                    value="CANCEL"
                    className="popup-back-btn popup-back-text"
                    onClick={(e) => dismisUpdateForm()}
                  />
                </div>
                <div className="justify-content-start">
                  <input
                    type="button"
                    value="ADD"
                    className="popup-confirm-btn"
                    onClick={(e) => validateForm()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddPopUpScreen;
