import { configStore } from "../../../redux/ConfigStore";

export function NavImageList() {
    let EmployeeList = []
    let ManagerList = []
    let chroList = []
    let chroOfficeMember = []
    let rpoBHRList = []
    let timeOfficeList = []
    let itsdList = []
    let mdmTeamList = []
    let coiList = []
    let esepList = []
    let spocGems = []
    let oprAdmin = []
    let hrRegSpoc = []
    let localConveyanceList = []
    let suggestionspoc = []
    let clearanceAdmin = []

    const isEmployeeLoggedIn = configStore?.getState()?.loginState?.isLoggedIn
    const isManager = configStore?.getState()?.keycloakState?.keycloakData?.is_employee_reporting
    const isAdminLoggedIn = configStore?.getState()?.adminLoginState?.isAdminLoggedIn
    const adminRole = configStore?.getState()?.adminLoginState?.adminRoles?.length != 0 ? configStore.getState().adminLoginState.adminRoles : []
    const companyCode = configStore.getState().loginState?.company?.company_code
    const suggestionApplicable = configStore.getState().loginState?.isSUGG

    EmployeeList = [
        { id: 1, src_active: require('../../../assets/drishti_images/sidebar/menue-active.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/menu-inactive.svg'), title: 'Overview', description: 'Overview', path: '/home' },
        { id: 2, src_active: require('../../../assets/drishti_images/sidebar/leave-active.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/leave-inactive.svg'), title: 'Leaves', description: 'Leave', path: '/leave' },
        { id: 3, src_active: require('../../../assets/drishti_images/sidebar/performance-active.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/performance-inactive.svg'), title: 'CFM', description: 'performance', path: '/performance' },
        { id: 4, src_active: require('../../../assets/drishti_images/sidebar/attendance-active.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/attendance-inactive.svg'), title: 'Time', description: 'Attendance', path: '/attendance' },
        { id: 5, src_active: require('../../../assets/drishti_images/sidebar/activeGEMsIcon.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/inactiveGEMsIcon.svg'), title: 'GEMS', description: 'Gems', path: '/gems' },
        { id: 6, src_active: require('../../../assets/drishti_images/sidebar/suggestionInactive.png'), src_inactive: require('../../../assets/drishti_images/sidebar/suggestionActive.png'), title: 'Suggestion Scheme', description: 'Suggestion Scheme', path: '/suggestionScheme' },
    ]
    ManagerList = [
        { id: 1, src_active: require('../../../assets/drishti_images/sidebar/menue-active.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/menu-inactive.svg'), title: 'Overview', description: 'Menue', path: '/home' },
        { id: 2, src_active: require('../../../assets/drishti_images/sidebar/teams-active.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/teams-inactive.svg'), title: 'Team', description: 'Team', path: '/teams' },
        { id: 3, src_active: require('../../../assets/drishti_images/sidebar/leave-active.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/leave-inactive.svg'), title: 'Leaves', description: 'Leave', path: '/leave' },
        { id: 4, src_active: require('../../../assets/drishti_images/sidebar/attendance-active.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/attendance-inactive.svg'), title: 'Time', description: 'Attendance', path: '/attendance' },
        { id: 5, src_active: require('../../../assets/drishti_images/sidebar/performance-active.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/performance-inactive.svg'), title: 'CFM', description: 'performance', path: '/performance' },
        { id: 6, src_active: require('../../../assets/drishti_images/sidebar/activeGEMsIcon.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/inactiveGEMsIcon.svg'), title: 'GEMS', description: 'Gems', path: '/gems' },
        { id: 7, src_active: require('../../../assets/drishti_images/sidebar/suggestionInactive.png'), src_inactive: require('../../../assets/drishti_images/sidebar/suggestionActive.png'), title: 'Suggestion Scheme', description: 'Suggestion Scheme', path: '/suggestionScheme' },
    ]
    chroList = [
        { id: 1, src_active: require('../../../assets/drishti_images/sidebar/menue-active.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/menu-inactive.svg'), title: 'Dashboard', description: 'Menue', path: '/admin/home' },
        { id: 2, src_active: require('../../../assets/drishti_images/sidebar/admin-notification-active.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/admin-notification-inactive.svg'), title: 'Notifications', description: 'adminNotificationScreen', path: '/admin/notification/home' },
        { id: 3, src_active: require('../../../assets/drishti_images/sidebar/admin-transfer-active.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/admin-transfer-inactive.svg'), title: 'Transfer', description: 'Leave', path: '/admin/transfer' },
        { id: 4, src_active: require('../../../assets/drishti_images/sidebar/leave-active.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/leave-inactive.svg'), title: 'Leaves', description: 'Leave', path: '/admin/leave' },
        { id: 5, src_active: require('../../../assets/drishti_images/sidebar/admin-todo-active.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/admin-todo-inactive.svg'), title: 'To Do List', description: 'Attendance', path: '/admin/todo' },
        { id: 6, src_active: require('../../../assets/drishti_images/sidebar/developer-active.png'), src_inactive: require('../../../assets/drishti_images/sidebar/developer-inactive.png'), title: 'Developer Tools', description: 'DevAdminMain', path: '/admin/developerTools' },
        { id: 7, src_active: require('../../../assets/drishti_images/sidebar/enableDisable-active.png'), src_inactive: require('../../../assets/drishti_images/sidebar/enableDisable-inactive.png'), title: 'Enable/Disable', description: 'EnableDisableMain', path: '/admin/screenEnableDisable' },
        { id: 8, src_active: require('../../../assets/drishti_images/sidebar/eseparationEnable.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/eseparationDisable.svg'), title: 'e-Separation', description: 'eseparation', path: '/admin/eseparationHome' },
        { id: 9, src_active: require('../../../assets/drishti_images/sidebar/activeGEMsIcon.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/inactiveGEMsIcon.svg'), title: 'GEMS', description: 'Gems', path: '/admin/gems' },
    ]
    chroOfficeMember = [
        { id: 1, src_active: require('../../../assets/drishti_images/sidebar/menue-active.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/menu-inactive.svg'), title: 'Dashboard', description: 'Menue', path: '/admin/home' },
        { id: 2, src_active: require('../../../assets/drishti_images/sidebar/admin-notification-active.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/admin-notification-inactive.svg'), title: 'Notifications', description: 'Notification', path: '/admin/notification/home' },
        { id: 3, src_active: require('../../../assets/drishti_images/sidebar/leave-active.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/leave-inactive.svg'), title: 'Leaves', description: 'Leave', path: '/admin/leave' },
        { id: 4, src_active: require('../../../assets/drishti_images/sidebar/admin-todo-active.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/admin-todo-inactive.svg'), title: 'To Do List', description: 'Attendance', path: '/admin/todo' },
        { id: 5, src_active: require('../../../assets/drishti_images/sidebar/eseparationEnable.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/eseparationDisable.svg'), title: 'e-Separation', description: 'eseparation', path: '/admin/eseparationHome' },
        { id: 6, src_active: require('../../../assets/drishti_images/sidebar/activeGEMsIcon.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/inactiveGEMsIcon.svg'), title: 'GEMS', description: 'Gems', path: '/admin/gems' },
    ]
    rpoBHRList = [
        { id: 1, src_active: require('../../../assets/drishti_images/sidebar/menue-active.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/menu-inactive.svg'), title: 'Dashboard', description: 'Menue', path: '/admin/home' },
        { id: 2, src_active: require('../../../assets/drishti_images/sidebar/admin-transfer-active.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/admin-transfer-inactive.svg'), title: 'Transfer', description: 'Leave', path: '/admin/transfer' },
    ]
    itsdList = [
        { id: 1, src_active: require('../../../assets/drishti_images/sidebar/menue-active.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/menu-inactive.svg'), title: 'Dashboard', description: 'Menue', path: '/admin/home' },
        { id: 2, src_active: require('../../../assets/drishti_images/sidebar/leave-active.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/leave-inactive.svg'), title: 'Leaves', description: 'Leave', path: '/admin/leave' },
    ]
    timeOfficeList = [
        { id: 1, src_active: require('../../../assets/drishti_images/sidebar/menue-active.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/menu-inactive.svg'), title: 'Dashboard', description: 'Menue', path: '/admin/home' },
        { id: 2, src_active: require('../../../assets/drishti_images/sidebar/leave-active.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/leave-inactive.svg'), title: 'Leaves', description: 'Leave', path: '/admin/leave' },
        { id: 3, src_active: require('../../../assets/drishti_images/sidebar/activeOT.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/inactiveOT.svg'), title: 'OT', description: 'OT', path: '/admin/overtime' },
    ]
    mdmTeamList = [
        { id: 1, src_active: require('../../../assets/drishti_images/sidebar/menue-active.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/menu-inactive.svg'), title: 'Dashboard', description: 'Menue', path: '/admin/home' },
    ]
    coiList = [
        { id: 1, src_active: require('../../../assets/drishti_images/sidebar/menue-active.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/menu-inactive.svg'), title: 'Dashboard', description: 'Menue', path: '/admin/home' },
        { id: 2, src_active: require('../../../assets/drishti_images/sidebar/coi-inactive.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/coi-active.svg'), title: 'COI', description: 'COI', path: '/admin/coi' },
    ]
    esepList = [
        { id: 1, src_active: require('../../../assets/drishti_images/sidebar/menue-active.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/menu-inactive.svg'), title: 'Dashboard', description: 'Menue', path: '/admin/home' },
        { id: 2, src_active: require('../../../assets/drishti_images/sidebar/admin-exit-active.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/admin-exit-inactive.svg'), title: 'Exit Process', description: 'performance', path: '/admin/exit' },
        { id: 3, src_active: require('../../../assets/drishti_images/sidebar/eseparationEnable.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/eseparationDisable.svg'), title: 'e-Separation', description: 'eseparation', path: '/admin/eseparationHome' },
    ]
    spocGems = [
        { id: 1, src_active: require('../../../assets/drishti_images/sidebar/menue-active.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/menu-inactive.svg'), title: 'Dashboard', description: 'Menue', path: '/admin/home' },
        { id: 2, src_active: require('../../../assets/drishti_images/sidebar/activeGEMsIcon.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/inactiveGEMsIcon.svg'), title: 'GEMS', description: 'Gems', path: '/admin/gems' },
    ]
    oprAdmin = [
        { id: 1, src_active: require('../../../assets/drishti_images/sidebar/menue-active.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/menu-inactive.svg'), title: 'Dashboard', description: 'Menue', path: '/admin/home' },
        { id: 2, src_active: require('../../../assets/drishti_images/sidebar/admin-notification-active.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/admin-notification-inactive.svg'), title: 'Notifications', description: 'adminNotificationScreen', path: '/admin/notification/home' },
        { id: 3, src_active: require('../../../assets/drishti_images/sidebar/registration-active.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/registration-inactive.svg'), title: 'Registration', description: 'RegistrationScreen', path: '/admin/registrationinfo' }
    ]
    hrRegSpoc = [
        { id: 1, src_active: require('../../../assets/drishti_images/sidebar/menue-active.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/menu-inactive.svg'), title: 'Dashboard', description: 'Menue', path: '/admin/home' },
        { id: 2, src_active: require('../../../assets/drishti_images/sidebar/admin-notification-active.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/admin-notification-inactive.svg'), title: 'Notifications', description: 'adminNotificationScreen', path: '/admin/notification/home' },
        { id: 3, src_active: require('../../../assets/drishti_images/sidebar/registration-active.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/registration-inactive.svg'), title: 'Registration', description: 'RegistrationScreen', path: '/admin/registrationinfo' }
    ]
    localConveyanceList = [
        { id: 1, src_active: require('../../../assets/drishti_images/sidebar/menue-active.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/menu-inactive.svg'), title: 'Dashboard', description: 'Menue', path: '/admin/home' },
        { id: 2, src_active: require('../../../assets/drishti_images/sidebar/LocalConveyance.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/LocalConveyanceInactive.svg'), title: 'Local Conveyance', description: 'Local Conveyance', path: '/admin/localConveyance' },
    ]
    suggestionspoc = [
        { id: 1, src_active: require('../../../assets/drishti_images/sidebar/menue-active.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/menu-inactive.svg'), title: 'Dashboard', description: 'Menue', path: '/admin/home' },
        { id: 2, src_active: require('../../../assets/drishti_images/sidebar/suggestionInactive.png'), src_inactive: require('../../../assets/drishti_images/sidebar/suggestionActive.png'), title: 'Suggestion Scheme', description: 'adminSuggestionScheme', path: '/admin/suggestionScheme' },
    ]
    clearanceAdmin = [
        { id: 1, src_active: require('../../../assets/drishti_images/sidebar/menue-active.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/menu-inactive.svg'), title: 'Dashboard', description: 'Menue', path: '/admin/home' },
        { id: 2, src_active: require('../../../assets/drishti_images/sidebar/ClearanceActiveIcon.svg'), src_inactive: require('../../../assets/drishti_images/sidebar/ClearanceInActiveIcon.svg'), title: 'Clearance', description: 'Clearance', path: '/admin/clearance' },
    ]

    //----------------Condition added by hiding specific feature in the sidebar--------------------
    const filteredEmployeeList = EmployeeList.filter(item => {
        if (item.title === 'Suggestion Scheme') {
            // return companyCode === '0100' || companyCode === '0550';
            return suggestionApplicable;
        }
        return true;
    });

    const filteredManagerList = ManagerList.filter(item => {
        if (item.title === 'Suggestion Scheme') {
            // return companyCode === '0100' || companyCode === '0550';
            return suggestionApplicable;
        }
        return true;
    });
    //----------------------------------------------------------------------------------------------
    if (isEmployeeLoggedIn) {
        if (isManager) {
            return filteredManagerList
        }
        else {
            return filteredEmployeeList
        }
    }

    else {
        if (isAdminLoggedIn) {
            let adminRoles = []
            // Below for loop is for White collar roles
            for (var i = 0; i < adminRole.length; i++) {
                console.log("adminRole. i : ", i);
                switch (adminRole[i]) {
                    case "chro-office-lead":
                        adminRoles = adminRoles.concat(chroList)
                        break;
                    case "chro-office-member":
                        adminRoles = adminRoles.concat(chroOfficeMember)
                        break;
                    case "rpo-bhr":
                        adminRoles = adminRoles.concat(rpoBHRList)
                        break;
                    case 'coi-admin':
                        adminRoles = adminRoles.concat(coiList)
                        break;
                    case "itsd-lead":
                        adminRoles = adminRoles.concat(itsdList)
                        break;
                    case 'itsd-member':
                        adminRoles = adminRoles.concat(itsdList)
                        break;
                    case 'time-office-lead':
                        adminRoles = adminRoles.concat(timeOfficeList)
                        break;
                    case 'time-office-member':
                        adminRoles = adminRoles.concat(timeOfficeList)
                        break;
                    case 'esep-team':
                        adminRoles = adminRoles.concat(esepList)
                        break;
                    case 'spoc-gems':
                        adminRoles = adminRoles.concat(spocGems)
                        break;
                    case 'mdm-team':
                        adminRoles = adminRoles.concat(mdmTeamList)
                        break;
                    case 'local_conveyance':
                        adminRoles = adminRoles.concat(localConveyanceList)
                        break;
                    case 'clearance-team':
                        adminRoles = adminRoles.concat(clearanceAdmin)
                        break;
                    case 'suggestion_spoc':
                        adminRoles = adminRoles.concat(suggestionspoc)
                        break;
                }
            }
            // Below for loop is for Blue collar roles (Operatives)
            for (var i = 0; i < adminRole.length; i++) {
                switch (adminRole[i]) {
                    case "opr-admin":
                        adminRoles = adminRoles.concat(oprAdmin)
                        break;
                    case "hr-reg-spoc":
                        adminRoles = adminRoles.concat(hrRegSpoc)
                        break;
                    case "hr-notification-plant-spoc":
                        break;
                    case "hr-notification-corp-admin":
                        break;
                }
            }
            return adminRoles
        }
    }
}