import React, { useState, useEffect } from 'react';
import { suggestionDetails } from '../../../adminScreens/clearance/constants';
import './suggestion.css';
import AddSuggestion from './AddSuggestion';
import suggester_slider_1 from '../../../../../assets/drishti_images/suggester_slider_1.png'
import suggester_slider_2 from '../../../../../assets/drishti_images/suggester_slider_2.png'
import suggester_slider_3 from '../../../../../assets/drishti_images/suggester_slider_3.png'
import DataNotFound from '../../../../shared/notFound/DataNotFound';
import { deleteS3Documents, getInstruction, getSuggestionCount, getSuggestionNoteBoradImage } from '../../../../../constants/api.service';
import { toaster } from '../../../../../utils/Utils';
import { configStore } from "../../../../../redux/ConfigStore";
import { toast } from 'react-toastify';
import ComingSoon from '../../../../shared/notFound/ComingSoon';
import { useSelector } from 'react-redux';
import AliceCarousel from 'react-alice-carousel';
import ServiceUnavailable from '../../../../shared/ServiceUnavailable';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';


const Card = ({ status, count, onClick, status_codes }) => (
    <div className="card-inner-sugg m-2 d-flex flex-column justify-content-start align-items-center"
        style={{ width: '185px', height: '150px', cursor: 'pointer', textAlign: 'center' }}
        onClick={() => onClick({ title: status, id: status_codes })}>
        <div className='d-flex flex-column justify-content-start align-items-center mt-4'>
            <span className='fw-bold h1 position-relative'>{count}</span>
            <span className="fw-bold">{status}</span>
        </div>
    </div>
);

const SuggestionLandingScreen = ({ onCardClick, setTab,isSuggestorScreen,isSuggestorScreenReason,isSuggestorAddForm}) => {
    const [ApplySuggestion, setSuggestion] = useState(false);
    const [targetData, setTargetData] = useState([]);
    const [instructionData, setInstructionData] = useState([]);
    const [noticeData, setNoticeData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [suggestionCount, setSuggestionCount] = useState([]);
    const [slider,setSlider] = useState([]);
    const [noticeBoardImage,setNoticeBoardImage] = useState([]);

    const loginData = useSelector(state => state.loginState);
    let instructionFlag = false;

    const getSuggestionCountData = async () => {
        setLoading(true);
        try {
            const res = await getSuggestionCount();
            if (res.status === 200) {
                const data = res.data.data;
                const suggestionCardDetails = data.map(item => ({
                    status_name: item.status_name,
                    count: item.count,
                    status_codes: item.status_codes
                }));
                setSuggestionCount(suggestionCardDetails);
            } else {
                toast.warn(res.message, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                });
            }
        } catch (error) {
            toast.warn(error.message, {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            });
        } finally {
            setLoading(false);
        }
    };

    const getInstructionData = () => {
        setLoading(true);
        getInstruction()
            .then(res => {
                if (res.status === 200) {
                    setInstructionData(res?.data?.data?.instructions)
                } else { setInstructionData([]) }
            })
            .catch(errors => {
                toast.warn(errors, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                });
            })
            .finally(() => { setLoading(false); });
    };

    const getNoticeBoardDetails = () => {
        setLoading(true);
        const param = {
            pa: configStore.getState().loginState?.userData?.PersArea,
            comp_code: configStore.getState().loginState?.company?.company_code
        };
        let endpoint = "?comp_code="+param.comp_code+"&pa="+param.pa+"&notice_type=Campaign";
        getSuggestionNoteBoradImage(endpoint)
            .then(res => {
                if (res.status === 200) {
                    const sliderData = res?.data?.data.map((data)=>{
                        return {
                            ...data,
                            image_path : data.image_link
                        }
                    })
                    setNoticeBoardImage(sliderData);
                } else { 
                    setNoticeBoardImage([]);
                }
            })
            .catch(errors => {
                toast.warn(errors, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                });
            })
            .finally(() => { setLoading(false); });
    };

    const getCircularSliderDetails = () => {
        setLoading(true);
        const param = {
            pa: configStore.getState().loginState?.userData?.PersArea,
            comp_code: configStore.getState().loginState?.company?.company_code
        };
        let endpoint = "?comp_code="+param.comp_code+"&pa="+param.pa+"&notice_type=Circular";
        getSuggestionNoteBoradImage(endpoint)
            .then(res => {
                if (res.status === 200) {
                    const sliderData = res?.data?.data.map((data)=>{
                        return {
                            ...data,
                            image_path : data.image_link
                        }
                    })
                    setSlider(sliderData);
                } else {
                    setSlider([]);
                }
            })
            .catch(errors => {
                toast.warn(errors, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                });
            })
            .finally(() => { setLoading(false); });
    };


    useEffect(() => {
        getSuggestionCountData();
        getInstructionData();
        getCircularSliderDetails();
        getNoticeBoardDetails()
    }, []);

    const createSuggestion = () => {
        setSuggestion(true);
    };
    const deleteS3Document = async (pathdoc) => {
        setLoading(true)
        let params = {
            emp_id: loginData?.userData?.Perno,
            path: pathdoc
        }
        await deleteS3Documents(params)
            .then((res) => {
                if (res?.status === 200 || res?.status === 201) {
                    console.log("document Deleted Successfully")
                }
                else { res?.status != 404 && toaster("warning", res?.data?.message ? res?.data?.message : "") }
            })
            .catch((error) => {
                console.log("error in S3 delete documents-->", error)
                error?.status != 404 && toaster("warning", error?.data?.message ? error?.data?.message : "")
            })
            .finally(() => { setLoading(false) })
    }

    const handleBack = (documents, responseData) => {
        if (responseData === null) {
            documents.map((doc) => {
                deleteS3Document(doc?.path)
            })
        }
        setSuggestion(false);
    };
    return (
        <>
            {isSuggestorScreen ? (
                <div className="col-md-12 h-100vh justify-content-center align-item-center">
                    <ServiceUnavailable reason={isSuggestorScreenReason} />
                </div>
            ) : (
                ApplySuggestion ?
                    <AddSuggestion
                        handleBack={handleBack}
                        setTab={setTab}
                    />
                    :
                    <div className='suggestion-gridcontainer'>
                        <LoaderIndicator loading={loading} />
                        {/* --------------------Instruction card-------------------------------- */}
                        <div className="card-inner-sugg flex-direction-column pointer instruction">
                            <div className='d-flex flex-direction-row justify-content-center align-content-center h-60-percent'>
                                {slider.length !== 0 ? (
                                    <div className=''>
                                        <AliceCarousel autoPlay autoPlayInterval="2000" infinite={true}>
                                            {slider.map((listValue, index) => {
                                                return (
                                                    <div key={index}>
                                                        <div className='d-flex flex-direction-row justify-content-center align-item-center'>
                                                            <img src={listValue?.image_path} role="presentation" className='border_radius_12px suggestion-image-styles' />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </AliceCarousel>
                                    </div>
                                ) : (
                                    <div className='d-flex justify-content-center align-content-center'>
                                        <DataNotFound/>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* --------------------Status card-------------------------------- */}
                        <div className="d-flex card-inner-sugg flex-direction-column pointer card-style status">
                            <div className='d-flex row justify-content-between w-100'>
                                <div className='document-innercard-content-title'>{'Suggestion Status'}</div>
                                        <div>
                                        {!isSuggestorAddForm &&(  
                                            <input type='button'
                                                value='ADD SUGGESTION'
                                                className='mr-2 validateBtn'
                                                onClick={createSuggestion}
                                            />)}
                                        </div>
                            </div>

                            <div className='flex-direction-row scroll-x overflow-y-hidden'>
                                {suggestionCount.map((card, index) => (
                                    <div key={index}>
                                        <Card
                                            status={card?.status_name}
                                            onClick={onCardClick}
                                            count={card?.count}
                                            status_codes={card?.status_codes}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* --------------------Instruction Dashboard card-------------------------------- */}
                            <div className="card-inner-sugg pointer flex-direction-column card-style target">
                                <span className='document-innercard-content-title'>{'Instruction'}</span>
                                {instructionData?.length > 0 ? (
                                    <div className='scroll-y h-21vh ml-2 mt-2'>
                                        {instructionData?.map((point, index) => {
                                            let instructionFlag = false;
                                            return (
                                                <div key={index}>
                                                    <div className='row mr-4 mt-2'>
                                                        {point.line.split(" ").map((text) => (
                                                            <span>
                                                                {text !== "<b>" && text !== "</b>" && !instructionFlag && (
                                                                    <h2 className='noteTextMsg mr-4px'>
                                                                        {text}
                                                                    </h2>
                                                                )}
                                                                {text === "<b>" && (instructionFlag = true)}
                                                                {text !== "<b>" && text !== "</b>" && instructionFlag && (
                                                                    <span className='highlight-sm-Text mr-4px mt-3px'>
                                                                        {text}
                                                                    </span>
                                                                )}
                                                                {text === "</b>" && (instructionFlag = false)}
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <div className='d-flex justify-content-center align-content-center'>
                                        <DataNotFound />
                                    </div>
                                )}
                            </div>

                        {/* --------------------Notice Board card-------------------------------- */}
                        <div className="card-inner-sugg pointer flex-direction-column card-style notice">
                            <span className='document-innercard-content-title'>{'Notice Board'}</span>
                                <div className='justify-content-center'>
                                    { noticeBoardImage.length > 0 ? (
                                            <AliceCarousel autoPlay autoPlayInterval="2000" infinite={true}>
                                                {noticeBoardImage.map((listValue, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <div className='d-flex flex-direction-row justify-content-center align-item-center'>
                                                                <img src={listValue?.image_path} role="presentation" className='border_radius_12px suggestion-image-styles' />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                }
                                            </AliceCarousel>
                                        ) : (
                                            <div className='d-flex justify-content-center align-content-center'>
                                                <DataNotFound />
                                            </div>
                                        )
                                    }
                                    
                                </div>
                        </div>

                    </div >
            )}
        </>
    );
}
export default SuggestionLandingScreen;