import React, { useState, useEffect } from 'react'
import DataNotFound from '../../../../shared/notFound/DataNotFound'
import DynamicDropdown from '../../../../shared/components/DynamicDropdown';
import Table from './Table';
import CommonButton from '../../../../shared/button/GradientButton';
import SuperAdminCommonPopup from './SuperAdminCommonPopup';
import SuperAdminDetailPopup from './SuperAdminDetailPopup';
import leftArrow from '../../../../../assets/drishti_images/left-arrow.svg';
import { getDivisionalAgencyDashboard, getDivCordinatorSuggestionList, getMasterLists } from '../../../../../constants/api.service';
import { statusCodes, superAdminTableField } from '../../../employeeScreens/suggestionScheme/constants';
import './SuperAdmin.css';
import searchIcon from '../../../../../assets/drishti_images/admin/searchIcon.svg';
import { toaster } from '../../../../../utils/Utils';
import { getStatusMasterListsAdmin, getMasterAdminLists, getSuperAdminSuggestionList, getSuperAdminSuggestionOfficeDashboard, getSuggestionOfficeDivision } from '../../../../../constants/api.service';
import XLSX from 'sheetjs-style';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import CommonCard from '../../../employeeScreens/suggestionScheme/commonComponent/CommonCard';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import { Pagination } from "react-bootstrap";


function SuperAdminHome() {
  
  const [agency, setAgency] = useState('')
  const [ searchSuggestionOffice, setSearchSuggestionOffice ] = useState("");
  const [agencyList, setAgencyList] = useState([])

  const [filteredAgencyList, setFilteredAgencyList] = useState([])
  
  const [divisionList, setdivisionList] = useState([])  
  const [selectedSchemeType, setSelectedSchemeType] = useState({ })

  const [ statusList, setstatusList ] = useState({id: 1, status_name: 'Pending at Suggestion Office', status_code: 2});
  const [loading, setLoading] = useState(false)

  const [selectedAgency, setSelectedAgency] = useState({})
  const [selectedStatus, setSelectedStatus] = useState({id: 4, status_code: 3, status_name: "Pending at Super Admin"})
  const [selectedSuggestion, setSelectedSuggestion] = useState([])
  const [selectedRecord, setSelectedRecord] = useState('')
  const [totalPages, setTotalPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [tableData, setTableData] = useState([])    // ...dummyData
  const [suggestionTypeList, setSuggestionTypeList] = useState([])
  const [ selectedSuggestionType, setSelectedSuggestionType ] = useState(null);
  const [selectedAgencyId, setSelectedAgencyId] = useState(null)
  const [searchSuggText, setSearchSuggText] = useState('');
  const AdminLoginData = useSelector(state => state.adminLoginState);


  const searchSuggestionOfficeHandler = (e) => {
    setSearchSuggestionOffice(e.target.value)
    if (e.target.value === '') { 
      setFilteredAgencyList([...agencyList]) 
    }
    else {
      let newDataDump = [...agencyList];
      const filteredData = newDataDump.filter((value) => (value.suggestion_office_name.toLowerCase()).includes(e.target.value.toLowerCase()));
      setFilteredAgencyList(filteredData);
    }
  }

  const searchFilterFunction = (e) => {
    setAgency(e.target.value)
  }
  const [selectedPopupAction, setSelectedPopupAction] = useState('')
  const [rejectionRemark, setRejectionRemark] = useState('')
  const [sendBackRemark, setSendBackRemark] = useState('')
  const [openCommonPopup, setOpenCommonPopup] = useState(false)
  const [fromDate, setFormDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [radioValue, setRadioValue] = useState('highValue')
  const [selectedRow, setSelectedRow] = useState(null);
  const [ singleSelected, setSingleSelected ] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const [divisionalPaginatedList, setDivisionalPaginatedList] = useState([])
  const openSelectedAgency = (data, sugStateCode, SchemeType, searchText, currentPageNo = currentPage) => {
    setLoading(true)
    let params = {
      sug_status_code: sugStateCode !== null ? sugStateCode : null,
      scheme_type_code: SchemeType !== null ? SchemeType : null,
      search: searchText !== null ? searchText : null,
      page_number: currentPageNo !== null ? currentPageNo : currentPage
    }
    getDivCordinatorSuggestionList(params, data)
      .then((res) => {
        const { status, data } = res
        if (status === 200 || status === 201) {
          setDivisionalPaginatedList(data?.data ? data.data?.paginated_data : [])
          setTotalPages(data?.data ? data.data?.total_pages : null)
        }
        else {
           toaster("warning", res?.data?.message ?? "Something Went Wrong!") 
           setDivisionalPaginatedList([])
          }
      })
      .catch((error) => { toaster("error", error?.data?.message ?? "Something Went Wrong") })
      .finally(() => {
        setLoading(false)
      })
  }


  const handleInputSubmit = (event) => {
    event.preventDefault();
    const pageNum = parseInt(inputValue, 10);

    // Validate the input value
    if (pageNum > 0 && pageNum <= totalPages) {
      setCurrentPage(pageNum);
      paginationCall(pageNum);
      setInputValue(''); // Clear input after submission
    } else {
      toaster("warning", `Enter Valid Number 1 to ${totalPages}`)
    }
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const paginationCall = (currentPageNo) => {
    openSelectedAgency(selectedAgencyId, selectedStatus?.status_code, selectedSchemeType?.scheme_type_code, searchSuggText, currentPageNo)
    setCurrentPage(currentPageNo)
  }
  const getSuggestionOfficeAgencyList = () => {

    setSelectedSuggestion([]);

    setLoading(true)
    let params = {
      comp_code: AdminLoginData?.company?.company_code,
      pa: AdminLoginData?.userData?.PersArea
    }
    getSuperAdminSuggestionOfficeDashboard(params)
      .then((res) => {
        const { status, data } = res
        if (status === 200 || status === 201) {
          setFilteredAgencyList(data?.data ? data?.data : [])
          setAgencyList(data?.data ? data.data : [])
        }
        else { toaster("warning", res?.data?.message ?? "Something Went Wrong!") }
      })
      .catch((error) => { toaster("warning", error?.data?.message ?? "Something Went Wrong") })
      .finally(() => {
        setLoading(false)
      })

  }

  const getDivisionMaster = (id) => {
    setLoading(true)
    getSuggestionOfficeDivision(id)
      .then((res) => {
        const { status, data } = res
        if (status === 200 || status === 201) {
          setdivisionList(data?.data ? data?.data : [])
        }
        else { toaster("warning", res?.data?.message ?? "Something Went Wrong!") }
      })
      .catch((error) => { toaster("warning", error?.data?.message ?? "Something Went Wrong") })
      .finally(() => {
        setLoading(false)
      })
  }

  const getSchemeTypeList = () => {
    setLoading(true)

    getMasterAdminLists("scheme_type")
      .then((res) => {
        const { status, data } = res
        if (status === 200 || status === 201) {
          setSuggestionTypeList(data?.data ? data?.data?.scheme_type_list : [])
        }
        else { toaster("warning", res?.data?.message ?? "Something Went Wrong!") }
      })
      .catch((error) => { toaster("warning", error?.data?.message ?? "Something Went Wrong") })
      .finally(() => {
        setLoading(false)
      })
  }

  const getStatusList = () => {
    setLoading(true)
    getStatusMasterListsAdmin("status")
      .then((res) => {
        const { status, data } = res
        if (status === 200 || status === 201) {
          setstatusList(data?.data ? data?.data?.status_list : [])
        }
        else { toaster("warning", res?.data?.message ?? "Something Went Wrong!") }
      })
      .catch((error) => { toaster("warning", error?.data?.message ?? "Something Went Wrong") })
      .finally(() => {
        setLoading(false)
      })
  }

  const getSuperAdminSuggestion = () => {
    
    if(selectedAgency?.sug_office) {
      setLoading(true)

      let param = {
        sug_office_code: selectedAgency?.sug_office,
        sug_division_code: selectedSchemeType.division_code !== null ? selectedSchemeType.division_code : '',
        sug_status_code: selectedStatus.status_code !== null ? selectedStatus.status_code : '',
        search: agency,
        scheme_type_code: selectedSuggestionType?.scheme_type_code ?? '',
        from_date: fromDate,
        to_date: toDate,
        page_number: currentPage ? currentPage : 1,
      }
  
      getSuperAdminSuggestionList(param)
        .then( (res) => {
      
          const { status, data } = res;
  
          if(status === 200) {
            setTableData(data.data.paginated_data);
            setTotalPages(data.data.total_pages ? data.data.total_pages : null);
            // setCurrentPage(data.data.page_number);
          }
          else {
            setTableData([])
            setTotalPages(1);
  
            toast.warn(data.message, {
              position: 'bottom-center',
              hideProgressBar: true,
              className: "toastWarn"
            })
          }
  
        } )
        .catch( error => {
          toast.warn(error.message, {
            position: 'bottom-center',
            hideProgressBar: true,
            className: "toastWarn"
          })
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  useEffect( () => {
    getSuperAdminSuggestion();
  }, [currentPage] )


  useEffect(() => {
    getStatusList();
    getSchemeTypeList();
    getSuggestionOfficeAgencyList();
  }, [])

  const handlePageCount = (action) => {
    setSelectedSuggestion([])
    if (action === "decrement" && currentPage > 1) {
        setCurrentPage(currentPage - 1);
        paginationCall(currentPage - 1)

    } else if (action === "increment" && currentPage < totalPages) {
        setCurrentPage(currentPage + 1);
        paginationCall(currentPage + 1)
    }
  };

  const backAction = () => {
    setSelectedAgency('');
    setFilteredAgencyList(agencyList);
    setAgency('')
    setSelectedSuggestion([])
    setTotalPages(1)
    setCurrentPage(1)
    setRadioValue('highValue')
  }

  const callSelectedPopupAction = (actionType) => {
    setSelectedPopupAction(actionType)
    setOpenCommonPopup(!openCommonPopup)
  }

  const handleAgencyFilter = (data) => { 
    setSelectedAgency(data) 
    getDivisionMaster(data?.sug_office);
  }

  const handleStatusFilter = (data) => { setSelectedStatus(data) }
  const handleSchemeTypeFilter = (data) => { setSelectedSchemeType(data) }
  const handleSuggestionTypeFilter = (data) => { setSelectedSuggestionType(data) }

  const handleFromDate = (data) => {
    setFormDate(data);
  }
  const handleToDate = (data) => {
    setToDate(data);
  }
 
  const handleSearch = () => {
    getSuperAdminSuggestion();
  }

  useEffect( () => {
    if(Object.keys(selectedAgency).length) getSuperAdminSuggestion();
  }, [selectedAgency, selectedSchemeType, selectedStatus, selectedSuggestionType] )

  const superAdminSuggestionCard = (agency) => {
    setSelectedAgency(agency);
    getDivisionMaster(agency?.sug_office);
  } 

  const toDay= new Date().toISOString().substring(0, 10);
  return (
    <div className=''> 
      <LoaderIndicator loading={loading} />
      {/* ------------------------------------------------------------------- Landing Screen -------------------------------------------------------- */}
      {
        <div className=''>
          {/* ------------------------------------------------------------------- Listing Screen -------------------------------------------------------- */}
          <div className='row align-item-center background-filter gap-10'>
              <div className='d-flex flex-direction-column w-100 '>  
                <div className='d-flex flex-direction-row justify-content-between mt-2' >
                  <div className='d-flex flex-direction-row input-group-radio radio-button'>
                    { Object.keys(selectedAgency).length !== 0  &&  <div>
                      <span className='pointer align-item-center add-btn-text' onClick={backAction}>
                        <img alt="left arrow" src={leftArrow} className="left-arrow" />{'Back'}</span>
                    </div>}
                  </div>
                  
                    <div className='row justify-content-between mt-10px'>
                      {/* <div className='popup-title'>{'Select Suggestion Office'}</div> */}
                      { Object.keys(selectedAgency).length === 0 && <div className='row'>
                        <div className='row ml-15px'>
                          <div className='p-placeholder-text mr-3'>
                            <input
                              className="form-control p-placeholder px-2 p-placeholder-admin"
                              type="text"
                              placeholder='Search Suggestion Office'
                              onChange={(e) => { searchSuggestionOfficeHandler(e) }}
                              value={searchSuggestionOffice}
                            />
                          </div>
                        </div>
                      </div>
                      }
                    </div>
                
                </div>
                
                <div className='d-flex flex-direction-column' >
                { Object.keys(selectedAgency).length !== 0 && ( radioValue !== 'enquiry' ?
                  
                  <div className='d-flex flex-wrap align-items-center gap-10px filter-p5'>
                    <div className='d-flex flex-wrap align-items-center mx-2'>

                      <div className='flex-direction-column ml-3 mb-1'>
                        <div className='p-sub-heading'>{"Suggestion Office"}</div>
                        <DynamicDropdown
                          data={agencyList}
                          render="suggestion_office_name"
                          value={selectedAgency?.suggestion_office_name}
                          selectedValue={(data) => handleAgencyFilter(data)}
                          arrow={'true'}
                          height={'dependOnContent'}
                          width={"w-auto"}
                          boxWidth="w-auto"
                        />
                      </div>
                      <div className='flex-direction-column ml-3 mb-1'>
                        <div className='p-sub-heading'>{"Select Division"}</div>
                        <DynamicDropdown
                          data={divisionList}
                          render="division_name"
                          value={selectedSchemeType.division_name}
                          selectedValue={(data) => handleSchemeTypeFilter(data)}
                          arrow={'true'}
                          height={'dependOnContent'}
                          width={"w-auto"}
                          boxWidth="w-auto"
                        />
                      </div>
                      <div className='flex-direction-column ml-3 mb-1'>
                        <div className='p-sub-heading'>{"Status"}</div>
                        <DynamicDropdown
                          data={statusList}
                          render="status_name"
                          value={selectedStatus.status_name === undefined ? selectedStatus : selectedStatus.status_name}
                          selectedValue={(data) => handleStatusFilter(data)}
                          arrow={'true'}
                          height={'dependOnContent'}
                          width={"w-auto"}
                          boxWidth="w-auto"
                        />
                      </div>
                      <div className='flex-direction-column ml-3 mb-1'>
                          <div className='p-sub-heading'>{"Type of suggestion"}</div>
                          <DynamicDropdown
                            data={suggestionTypeList}
                            render="scheme_type"
                            value={selectedSuggestionType?.scheme_type}
                            selectedValue={(data) => handleSuggestionTypeFilter(data)}
                            arrow={'true'}
                            height={'dependOnContent'}
                            width={"w-auto"}
                            boxWidth="w-auto"
                          />
                      </div>
                      <div className='flex-direction-column ml-3 mb-1 p-r'>
                      <div className="p-sub-heading">Search</div>
                          <div className='d-flex align-item-center'>
                            <input
                              className="inpt input-date w-100 w-md-auto"
                              type="text"
                              placeholder='Title/Sugg No...'
                              onChange={(e) => searchFilterFunction(e)}
                              value={agency}
                            />
                            <div type='button' className='serchIconSize-evaluator-super-admin' onClick={() => { getSuperAdminSuggestion() } } >
                              <img alt="radioOn" className='filterIcon' src={searchIcon} />
                            </div>
                          </div>
                      </div>
                      <div className='flex-direction-column ml-3 mb-1'>
                        <div className='p-sub-heading'>{"From"}</div>
                        <input
                          className='inpt input-date w-100 w-md-auto'
                          type='date'
                          max={toDay}  //min props using for hiding the date current to prev date 
                          placeholder='Select Start Date'
                          value={fromDate}
                          onChange={(e) => handleFromDate(e.target.value)}
                        />
                      </div>
                      <div className='flex-direction-column ml-3 mb-1'>
                        <div className='p-sub-heading'>{"To"}</div>
                        <input
                          className='inpt input-date w-100 w-md-auto'
                          type='date'
                          max={toDay}  //min props using for hiding the date current to prev date 
                          placeholder='Select To Date'
                          value={toDate}
                          onChange={(e) => handleToDate(e.target.value)}
                        />
                      </div>
                      <div className='flex-direction-column ml-3 mb-1 pt-super-admin'>
                        <CommonButton
                          label="SEARCH"
                          onClick={() => handleSearch()}
                          isGradientBtn={true}
                          className={'px-20px w-100 w-md-auto'}
                        />
                      </div>

                    </div>
                  </div>

                  : <></>
                ) 
                }
                </div>
              </div>
          </div>
          { Object.keys(selectedAgency).length === 0 ? 
            <div className='row mt-10px gap-20 h-90'>
              { filteredAgencyList.length > 0 ?
                filteredAgencyList.map((value) =>
                  <div className='w-32 h-28vh pointer'>
                    <CommonCard onClick={() => superAdminSuggestionCard(value) } >
                      <div className='flex-direction-column h-100 justify-content-center w-97 align-items-center gap-10'>
                        <div className='suggestion-card-content'>{value.count}</div>
                        <div className='document-innercard-content-title'>{value.suggestion_office_name}</div>
                      </div>
                    </CommonCard>
                  </div>
                )
                : <div className='justify-content-center align-items-center w-100'>
                  <DataNotFound />
                </div>
              }
            </div>
           : 
            <>
            {
              tableData?.length > 0 ?
                <div>
                  <Table
                    hasAction
                    radioValue = {radioValue} 
                    columns={superAdminTableField[0]}
                    data={tableData}
                    agency_name={selectedAgency?.suggestion_office_name}
                    onSelectSuggestion={(data) => { setSelectedSuggestion(data) }}
                    selectedSuggestion={selectedSuggestion}
                    setCurrentPage={setCurrentPage}
                    totalPages={totalPages}
                    currentPage={currentPage}
                    pageSize={totalPages}
                    statusList
                    selectedStatus
                    onAction={(data) => { 
                      setSelectedRecord(data.id);
                    }}
                    onSendBackAction={ (row) => {
                      setSingleSelected(true);
                      setSelectedRow(row); setOpenCommonPopup(true); setSelectedPopupAction("SEND BACK")
                    } }
                    readOnly={ selectedStatus.status_code === statusCodes['Pending at Super Admin'] ? false : true  }
                    renderData={ () => getSuperAdminSuggestion() }
                    minHeight="35vh"
                    
                  />
                </div>
                :
                <div className='justify-content-center align-items-center w-100 h-54vh'>
                  <DataNotFound />
                </div>
            }
            
            {tableData?.length > 0 &&
                  <div className='row align-item-center justify-content-between Evaluator-background-highlighter gap-10 bottom-css'>
                    <div className='ptb-0_375 plr-0_75'>{selectedSuggestion.length + '/' + tableData.length + ' records selected.'}</div>
                    <div style={{ flex: 1, display: 'flex', justifyContent: 'center'}}>
                      <Pagination>
                        {currentPage != 1 &&
                          <>
                            <Pagination.Prev
                              onClick={() => { handlePageCount("decrement") }} />
                            <Pagination.Item disabled onClick={() => setCurrentPage(1)}>{1}</Pagination.Item>
                            <Pagination.Ellipsis disabled />
                          </>
                        }
                        <Pagination.Item active>{currentPage}</Pagination.Item>
                        {currentPage != totalPages &&
                          <>
                            <Pagination.Ellipsis disabled />
                            <Pagination.Item disabled onClick={() => setCurrentPage(totalPages)} >{totalPages}</Pagination.Item>
                            <Pagination.Next
                              onClick={() => { handlePageCount("increment") }} />
                          </>
                        }
                      </Pagination>
                      </div>
                      {totalPages >= 2 && (
                  <div className='d-flex align-items-center'>
                       <input
                         className="inpt input-date w-100 w-md-auto"
                         type="text"
                         placeholder='Page No...'
                         onChange={handleInputChange}
                         value={inputValue}
                       />
                       <div type='button' className='serchIconSize-evaluator' onClick={(event) => handleInputSubmit(event)}>
                         <img alt="radioOn" className='filterIcon' src={searchIcon} />
                       </div>
                </div>)}
                { selectedSuggestion.length > 0 && (<div style={{ display: 'flex', gap: '7px', minWidth: '350px', justifyContent: 'flex-end' }}>

{
  <div className='row gap-7px'>
    { !selectedSuggestion.some(ele => ele.is_divisional_coordinator_send_back) &&     
      <CommonButton
        label="SEND BACK"
        onClick={() => { callSelectedPopupAction("Send_back") }}
        isGradientBtn={true}
        className={'px-20px'}
      />
    }
    <CommonButton
      label="REJECT"
      onClick={() => { callSelectedPopupAction("Reject") }}
      isGradientBtn={true}
      className={'px-20px'}
    />
    <CommonButton
      label="APPROVE"
      onClick={() => { callSelectedPopupAction("Approved") }}
      isGradientBtn={true}
      className={'px-20px'}
    />
  </div>
}
</div>)}                  </div>
                }
            {openCommonPopup &&
                <SuperAdminCommonPopup
                  data={singleSelected ? [selectedRow] : selectedSuggestion}
                  title={selectedPopupAction}
                  action={'finalAction'}
                  agency_name={ selectedAgency?.suggestion_office_name }
                  submitApproved={ () => {
                    setOpenCommonPopup(false)
                    setSelectedSuggestion([])
                    getSuperAdminSuggestion();
                  }}
                  submitSendBack={(remarks) => { 
                    setSendBackRemark(remarks); 
                    setOpenCommonPopup(false);
                    getSuperAdminSuggestion();
                    setSelectedSuggestion([])
                    setSingleSelected(false);
                  }}
                  submitRejection={(remarks) => {setRejectionRemark(remarks); setOpenCommonPopup(false)}}
                  onDismiss={() => { 
                    setOpenCommonPopup(false) 
                    getSuperAdminSuggestion();
                    setSelectedSuggestion([])
                  }}
                />
            }
            </>
           }
        </div>
      }
      {selectedRecord !== '' && <SuperAdminDetailPopup title={'View Details'} agency_name={ selectedAgency?.suggestion_office_name } readOnly={selectedStatus.status_code === statusCodes['Pending at Super Admin'] ? false : true } backAction={() => { getSuperAdminSuggestion(); setSelectedRecord('')}} selectedRecordId={selectedRecord} data={{}} />}
    </div>
  )
}

export default SuperAdminHome