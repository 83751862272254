import React, { Component } from 'react';
import './DeclarationPopup.css'
import { Link } from 'react-router-dom';

class DeclarationPopup extends Component {
    render() {
        return (
            <div className="modal popup-box">
                <div className="declaration-box declaration_popup p-0" style={{ width: '30%' }}>                    
                    <div className="modal-body">
                        <div className='flex-direction-column justify-content-center'>
                            <div className="popup_text  text-center">
                                <Link to={{ pathname: '/coi' }}>
                                    <img className='declaration-image' src={this.props.image_url} alt='declaration_image'></img>
                                </Link>
                                {/* <a className='link-text' href={this.props.image_hyperlink} target='_blank'>
                                    <img className='declaration-image' src={this.props.image_url} alt='declaration_image'></img>
                                </a> */}
                                    <button type="button" className="close popup-close" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        );
    }
}
export default DeclarationPopup;