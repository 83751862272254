import React, { useContext, useEffect, useState } from 'react'
import ClearanceTable from './ClearanceTable'
import { constantVals, employeeDetailsFields, requestTableCol } from './constants'
import DataNotFound from '../../../shared/notFound/DataNotFound'
import DateFilter from './DateFilter'
import DynamicDropdown from '../../../shared/components/DynamicDropdown'
import searchIcon from '../../../../assets/drishti_images/admin/searchIcon.svg';
import { addDays, subtractDays } from '../../managerScreens/approval/commonUtils'
import { Pagination } from 'react-bootstrap'
import { toaster } from '../../../../utils/Utils'
import ClearanceRequestDashboard from './ClearanceRequestDashboard'
import ClearanceDetailsPopup from './ClearanceDetailsPopup'
import { RoleContext } from './Contexts'
import { getClearanceRequests, revokeClearance } from '../../../../constants/api.service'
import useDidMountEffect from '../../../../customHooks/ComponentDidUpdateFunction'
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'
import { configStore } from '../../../../redux/ConfigStore'

function ClearanceRequests(props) {
  const filterMaster = useContext(RoleContext)
  const [tableData, setTableData] = useState([])
  const [fromDate, setFromDate] = useState("")
  const [toDate, setToDate] = useState("")
  const [loading, setLoading] = useState(false)
  const [allAgencyView, setAllAgencyView] = useState(false)
  const [totalPages, setTotalPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [filterVal, setFilterVal] = useState(filterMaster?.releaseStatusList?.[0])
  const [viewFilter, setViewFilter] = useState(filterMaster?.cleRequestViewFilter?.[0])
  const [empId, setEmpId] = useState("")
  const [selectedEmployee, setSelectedEmployee] = useState([])

  useEffect(() => {
    setCurrentPage(1)
    setTotalPages(1)
    if (filterVal && viewFilter) { fetchClearanceRequests(filterVal, viewFilter, 1) }
  }, [filterVal, viewFilter, fromDate, toDate])

  useDidMountEffect(() => {
    setViewFilter(filterMaster.cleRequestViewFilter[0])
    setFilterVal(filterMaster.releaseStatusList[0])
  }, [filterMaster])

  const fetchClearanceRequests = async (status, agency, page) => {
    setLoading(true)
    let params = {
      comp_code: configStore.getState()?.adminLoginState?.company?.company_code,
      agency_config_id: agency?.value,
      from_date: fromDate,
      to_date: toDate,
      page_number: page,
      emp_id: empId,
      release_status_id: status?.value
    }
    await getClearanceRequests(params, props)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          const { paginated_data, total_pages } = res.data.data
          setTableData(paginated_data)
          setTotalPages(total_pages)
        }
        else {
          setTableData([])
          setTotalPages(0)
          res?.status != 404 && toaster("warning", res.data.message ? res.data.message : "Something went wrong")
        }
      })
      .catch((error) => {
        console.log("error in get clearance request API", error)
        setTableData([])
        setTotalPages(0)
        error?.status != 404 && toaster("warning", error?.data?.message ? error.data.message : "Something went wrong")
      })
      .finally(() => { setLoading(false) })
  }
  const searchFilterFunction = () => {
    setCurrentPage(1)
    setTotalPages(1)
    fetchClearanceRequests(filterVal, viewFilter, 1)
  }
  const handlePageCount = (action) => {
    if (action === "decrement" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
      fetchClearanceRequests(filterVal, viewFilter, currentPage - 1)
    } else if (action === "increment" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      fetchClearanceRequests(filterVal, viewFilter, currentPage + 1)
    }
  };
  const handleViewAction = (index) => {
    setAllAgencyView(false)
    setSelectedEmployee(tableData[index])
  }
  const handleSecondaryAction = (index) => {
    setAllAgencyView(true)
    setSelectedEmployee(tableData[index])
  }
  const handleViewFilter = (data) => {
    setViewFilter(data)
  }
  const backAction = () => {
    setSelectedEmployee([])
    setCurrentPage(1)
    setTotalPages(1)
    fetchClearanceRequests(filterVal, viewFilter, 1)
  }
  const secondaryTableAction = () => {
    setLoading(true)
    revokeClearance(selectedEmployee["clearance_id"], props).then((res) => {
      if (res.status === 200 || res.status === 201) {
        toaster("success", res.data.message)
        backAction()
      }
      else {
        toaster("warning", res.data.message ? res.data.message : "")
      }
    }).catch((error) => {
      toaster("warning", error?.data?.message ? error.data.message : "")
    }).finally(() => { setLoading(false) })
  } // for revoke cases

  return (

    selectedEmployee.length != 0 && allAgencyView ?
      <ClearanceRequestDashboard
        selectedEmployee={selectedEmployee}
        backAction={backAction}
        primaryFields={employeeDetailsFields}
        backBtnTitle="Clearance Requests"
        screenType={constantVals.ClearanceRequests}
        showNetAmount={viewFilter.title == "F & F Settlement"}
        isSecondaryAction={filterVal.title == "Pending for Revoke" && viewFilter.title == "H2R SPOC"}
        secondaryBtnTitle="REVOKE ALL"
        secondaryBtnAction={() => secondaryTableAction()}
      />
      :
      <>
        <div className='flex-direction-column'>
          <LoaderIndicator loading={loading} />
          <div className='row align-item-center justify-content-between background-highlighter'>
            <div className='row'>
              <div className='row ml-15px'>
                <div className='p-placeholder-text mr-3'>
                  <input
                    className="form-control p-placeholder px-2 p-placeholder-admin"
                    type="text"
                    placeholder='Enter Employee ID'
                    onChange={(e) => setEmpId(e.target.value)}
                    value={empId}
                  />
                </div>
                <div type='button' className='serchIconSize' onClick={() => searchFilterFunction()}
                ><div className='mr-4'><img alt="radioOn" className='filterIcon' src={searchIcon} ></img></div>
                </div>
              </div>
            </div>
            <div className='row'>
              {
                viewFilter &&
                <div className='flex-direction-row gap-10px align-item-center'>
                  <div className='p-sub-heading ml-2'>{"Select Agency View"}</div>
                  <div className='d-flex align-item-center'></div>
                  <DynamicDropdown
                    data={filterMaster.cleRequestViewFilter}
                    render="title"
                    value={viewFilter.title}
                    selectedValue={(data) => handleViewFilter(data)}
                    arrow={'true'}
                    height={'NotificationHeight'}
                    width={"w-14rem"}
                  />
                </div>
              }
              <div className='flex-direction-row gap-10px align-item-center'>
                <div className='p-sub-heading ml-2'>{"Clearance Status"}</div>
                <div className='d-flex align-item-center'></div>
                <DynamicDropdown
                  data={viewFilter?.title == 'F & F Settlement' ? filterMaster.releaseStatusList  : filterMaster.releaseStatusList.filter(item=> item?.title != 'Hold') }
                  render="title"
                  value={filterVal?.title ?? ""}
                  selectedValue={(data) => setFilterVal(data)}
                  arrow={'true'}
                  height={'NotificationHeight'}
                />
              </div>
              <DateFilter title="From Date" action={(val) => setFromDate(val)} value={fromDate} minDate={subtractDays(new Date(), filterMaster.dateRange.minDay)} maxDate={addDays(new Date(), 60)} />
              <DateFilter title="To Date" action={(val) => setToDate(val)} value={toDate} minDate={subtractDays(new Date(), filterMaster.dateRange.maxDays)} maxDate={addDays(new Date(), 60)} />
            </div>
          </div>
          {
            tableData && tableData.length > 0 ?
              <div className='flex-direction-column scroll-auto h-57vh mt-10px'>
                <ClearanceTable
                  data={tableData}
                  columns={requestTableCol}
                  handleAction={handleViewAction}
                  hasAction={filterVal.title == "Pending" || filterVal.title == "Completed" || filterVal.title == "Hold" || (filterVal.title == "Pending for Revoke" && viewFilter.title == "H2R SPOC")}
                  hasPrimaryAction={true}
                  hasSecondaryAction={viewFilter?.is_applicable_agency_view}
                  handleSecondaryAction={handleSecondaryAction}
                  currentPage={currentPage}
                  screenType={"Clearance Request"}
                  pageSize={10}
                />
              </div>
              :
              <div className='mt-5 h-90-percent'>
                <DataNotFound />
              </div>
          }
          {tableData.length > 0 &&
            <div className="col-md-12 w-100 d-flex justify-content-center paginator">
              <Pagination>
                {currentPage != 1 &&
                  <>
                    <Pagination.Prev
                      onClick={() => { handlePageCount("decrement") }} />
                    <Pagination.Item disabled onClick={() => setCurrentPage(1)}>{1}</Pagination.Item>
                    <Pagination.Ellipsis disabled />
                  </>
                }
                <Pagination.Item active>{currentPage}</Pagination.Item>
                {currentPage != totalPages &&
                  <>
                    <Pagination.Ellipsis disabled />
                    <Pagination.Item disabled onClick={() => setCurrentPage(totalPages)} >{totalPages}</Pagination.Item>
                    <Pagination.Next
                      onClick={() => { handlePageCount("increment") }} />
                  </>
                }
              </Pagination>
            </div>
          }
        </div>
        {
          selectedEmployee.length != 0 &&
          <ClearanceDetailsPopup
            onDismiss={backAction}
            screenType={constantVals.ClearanceRequests}
            title={selectedEmployee?.["agency_name"] ? selectedEmployee["agency_name"] : 'Clearance Details'}
            commentOnly={!(filterVal.value == 0 || filterVal.value == 4) || viewFilter.value == ""}
            selectedEmployee={selectedEmployee}
            clearanceId={selectedEmployee["clearance_id"]}
            agencyConfigId={selectedEmployee["agency_config"]}
            agencyName={selectedEmployee["agency_name"]}
          />
        }
      </>

  )
}

export default ClearanceRequests