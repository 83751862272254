export const suggestionReportFieldVisiblity = {
    // S010:{showDivision:true,showToDate:false,showFromDate:false,showSuggStatus:false},
    // D001:{showDivision:true,showToDate:false,showFromDate:false,showSuggStatus:false},
    // S011:{showDivision:false,showToDate:false,showFromDate:false,showSuggStatus:false},
    // S012:{showDivision:false,showToDate:false,showFromDate:false,showSuggStatus:false},
    // S003:{showDivision:false,showToDate:false,showFromDate:false,showSuggStatus:false},
    D001:{showDivision:true,showToDate:false,showFromDate:false,showSuggStatus:false},
    D002:{showDivision:true,showToDate:true,showFromDate:true,showSuggStatus:true},
    D003:{showDivision:true,showToDate:true,showFromDate:true,showSuggStatus:false},
    E004:{showDivision:false,showToDate:true,showFromDate:true,showSuggStatus:false},
    D004:{showDivision:false,showToDate:true,showFromDate:true,showSuggStatus:false},
    E003:{showDivision:false,showToDate:true,showFromDate:true,showSuggStatus:false},
    D005:{showDivision:true,showToDate:true,showFromDate:true,showSuggStatus:false},
    D006:{showDivision:true,showToDate:false,showFromDate:false,showSuggStatus:false},
    D008:{showDivision:false,showToDate:true,showFromDate:true,showSuggStatus:false},
    E002:{showDivision:false,showToDate:true,showFromDate:true,showSuggStatus:false},
    E005:{showDivision:false,showToDate:true,showFromDate:true,showSuggStatus:false},
    E006:{showDivision:false,showToDate:true,showFromDate:true,showSuggStatus:false},
    SU01:{showDivision:false,showToDate:true,showFromDate:true,showSuggStatus:false},
    SU02:{showDivision:false,showToDate:true,showFromDate:true,showSuggStatus:false},
    D011:{showDivision:false,showToDate:true,showFromDate:true,showSuggStatus:false},
    D012:{showDivision:false,showToDate:true,showFromDate:true,showSuggStatus:false},
    D010:{showDivision:true,showToDate:true,showFromDate:true,showSuggStatus:false},
}
export const employeeTypeList = [
    {name: 'Permanent Manpower', value: 'Permanent Manpower'},
    {name: 'Permanent Blue Collar Manpower', value: 'Permanent Blue Collar Manpower'},
    {name: 'Permanent and Flexible Manpower', value: 'Permanent and Flexible Manpower'},
    {name: 'Flexible Manpower', value: 'Flexible Manpower'},
]
export const monthData = [
    {name: 'JANUARY', value: '01'},
    {name: 'FEBRUARY', value: '02'},
    {name: 'MARCH', value: '03'},
    {name: 'APRIL', value: '04'},
    {name: 'MAY', value: '05'},
    {name: 'JUNE', value: '06'},
    {name: 'JULY', value: '07'},
    {name: 'AUGUST', value: '08'},
    {name: 'SEPTEMBER', value: '09'},
    {name: 'OCTOBER', value: '10'},
    {name: 'NOVEMBER', value: '11'},
    {name: 'DECEMBER', value: '12'}
];