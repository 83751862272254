import React, { useEffect, useState } from 'react'
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import { historyDataFields, memberDataFields, schemeCode, suggestionDataFieldsforReopen, suggestionDataFieldsConditinalforReOpen,statusCodes } from '../../../employeeScreens/suggestionScheme/constants';
import { DDMMYYYY, toaster } from '../../../../../utils/Utils';
import Table from './Table';
import DataNotFound from '../../../../shared/notFound/DataNotFound';
import { suggetionOfficerSuggestionById} from '../../../../../constants/api.service';
import CommonPreviewComponent from '../../../employeeScreens/suggestionScheme/commonComponent/CommonPreviewComponent';

function SuggestionReOpenDetails(props) {
  const { title, backAction,selectedStatus, selectedRecordId, from_agency, data, suggestionStatus ,showComment = true, showAwardType = true} = props
  const [loading, setLoading] = useState(false)
  const [suggestionSelectedData, setSuggestionSelectedData] = useState(null)

  useEffect(() => {
    getSuggestionByIdData(selectedRecordId)
  }, [])

  const getSuggestionByIdData = (id) => {
    setLoading(true)
    suggetionOfficerSuggestionById(id)
      .then((res) => {
        const { status, data } = res
        if (status === 200 || status === 201) {
          setSuggestionSelectedData(data?.data ? data?.data : null);
          
        }
        else { toaster("warning", res?.data?.message ?? "Something Went Wrong!") }
      })
      .catch((error) => { toaster("error", error?.data?.message ?? "Something Went Wrong") })
      .finally(() => { setLoading(false) })
  }

  const keys = ['title', 'present_status', 'earlier_status', 'proposed_changes', 'changes_implemented', 'benefits', 'comment', 'clarification_to_divc', 'applicable_areas', 'entered_on', 'scheme_name']
  const renderFields = (fields) => (
    fields.length > 0 ? (
      fields.map((field) => {
        const { name, key, type } = field;

        if (key === 'scheme_name') {
          return (suggestionSelectedData?.suggestion_details['scheme_type_code'] === schemeCode.Special || suggestionSelectedData?.suggestion_details['scheme_type_code'] === schemeCode['Special Implemented']) && (
            <div className="col-4" key={key}>
              <div className="text14_bold">{name}</div>
              <div className={keys.includes(key) ? " text14_light mt-1 popup-overflow " : ' text14_light mt-1'}>
                {type === 'date' ? DDMMYYYY(selectedStatus.status_code === statusCodes['Pending at Suggestion Office'] ? suggestionSelectedData?.suggestion_details[key] : suggestionSelectedData?.suggestion_details[key]) ?? '--' : suggestionSelectedData?.suggestion_details[key] ?? '--'}
              </div>
              <div className='mt-10px mb-10px'><hr /></div>
            </div>
          );
        }
        else {
          return (
            <div className="col-4" key={key}>
              <div className="text14_bold">{name}</div>
              <div className={keys.includes(key) ? " text14_light mt-1 popup-overflow " : ' text14_light mt-1'}>
                {type === 'date' ? DDMMYYYY(selectedStatus.status_code === statusCodes['Pending at Suggestion Office'] ? suggestionSelectedData?.suggestion_details[key] : suggestionSelectedData?.suggestion_details[key]) ?? '--' : suggestionSelectedData?.suggestion_details[key] ?? '--'}
              </div>
              <div className='mt-10px mb-10px'><hr /></div>
            </div>
          );
        }
      })
    ) : (
      <DataNotFound />
    )
  );

  return (
    <div className={""}>
      <LoaderIndicator loading={loading} />
      <div
        className={
          "modal-dialog modal-dialog-centered modal-dialog-scrollable w-60rem"
        }
      >
        <div
          className={
            `modal-content bg-white border_radius_12px max-height-100 ` +
            `w-85vw`
          }
        >
          <div className="modal-header mx-2">
            <div className="row">
              <h4 className="popup-title mt-2">{title}</h4>
            </div>
            <div className="solid-border"></div>
          </div>
          <div className="column mt-3 scroll-y pb-20px m-2">
            <div className="d-flex flex-column">
              <div className="row w-100 mb-3 row-gap-10px">
                {suggestionSelectedData?.suggestion_details?.scheme_type_code === schemeCode.Special ||
                  suggestionSelectedData?.suggestion_details?.scheme_type_code === schemeCode.Normal
                  ? renderFields(suggestionDataFieldsforReopen)
                  : renderFields(suggestionDataFieldsConditinalforReOpen)}
              </div>
            </div>
            {
              suggestionSelectedData?.document_details.filter((e) => e.document_category === 'suggestion_details').length > 0 && (
                <>
                  <div className="mb-10px">
                    <hr />
                  </div>
                  <div className="toggle-card-heading ml-10 mb-10">
                    {"Suggestor Documents"}
                  </div>
                  <CommonPreviewComponent
                    data={suggestionSelectedData.document_details.filter((e) => e.document_category === 'suggestion_details')}
                    download={true}
                    from={'suggestionOffice'}
                  />
                </>
              )}
            <div className="mb-10px">
              <hr />
            </div>
            <div className="toggle-card-heading ml-10 mb-10">
              {"History Details"}
            </div>
            {suggestionSelectedData?.history_details.length > 0 ? (
              <>
                <div className="d-flex flex-column ml-4">
                  <div className="row w-100 mb-3 row-gap-10px justify-content-center">
                    <Table
                      isSrNoShow={true}
                      details={suggestionSelectedData}
                      data={suggestionSelectedData.history_details}
                      columns={historyDataFields}
                      multiSelect={false}
                      isActionShow={false}
                      className={"w-80vw max-height-33vh"}
                      from={'historyTable'}
                    />
                  </div>
                </div>
              </>
            ) : (
              <DataNotFound />
            )}

            <div className="mt-10px mb-10px">
              <hr />
            </div>
            <div className="toggle-card-heading ml-10 mb-10">
              {"Member Details"}
            </div>
            {suggestionSelectedData?.member_details.length > 0 ? (
              <>
                <div className="d-flex flex-column">
                  <div className="row w-100 mb-3 row-gap-10px justify-content-center">
                    <Table
                      isSrNoShow={true}
                      isActionShow={false}
                      data={suggestionSelectedData.member_details}
                      columns={memberDataFields}
                      multiSelect={false}
                      className={"w-75vw max-height-33vh"}
                      from={'memberTable'}
                      isCertificateEnable={true}
                      sugDetailsList={suggestionSelectedData.suggestion_details}
                    />
                  </div>
                </div>
                <div className="mt-10px mb-10px">
                  <hr />
                </div>
              </>
            ) : (
              <DataNotFound />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}


export default SuggestionReOpenDetails