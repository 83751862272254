import React, { Component } from 'react'
import './LoginScreen.css'
import { RiArrowDropDownLine } from 'react-icons/ri'
import LoginMainHead from './LoginMainHead'
import WarnIcon from '../../../../assets/drishti_images/login/WarnIcon.svg'
import Cookies from 'universal-cookie'
import * as Config from '../../../../constants/Config';
import { fetchApiCall, fetchApiCallWithService, sessionOut } from '../../../../constants/Service';
import { connect } from 'react-redux';
import * as userActions from './LoginAction';
import * as KeycloakActions from './KeyCloakAction';
import * as serviceActionToken from './ServiceTokenAction';
import * as ActivePopupAction from "../landingScreen/ActivePopupAction";
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import loginHead from '../../../../assets/drishti_images/login/tml_logo.png'
import { Carousel } from 'react-bootstrap';
import slider_image_default from '../../../../assets/drishti_images/login/drishti_login_default_img.png'
import { toast } from 'react-toastify';
import ResetPassword from './ResetPassword'
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'
import ScrollTOTop from '../login/ScrollTOTop';
import { Transition } from 'react-transition-group';
import { Link } from 'react-router-dom';
import { decrypt, encrypt, validateNumber } from '../../../../utils/Utils'
import { configStore } from "../../../../redux/ConfigStore";
import { toggleLoginStatus } from './LoginAction'
import { isAdmin } from '../../adminScreens/login/LoginAction'
import {suggestionTabApplicable} from "../../../../constants/api.service";
const cookies = new Cookies();
const CryptoJS = require("crypto-js");
const default_text = 'Engage and connect with new UI & Omnichannel experience'
const default_company = { id: 1, company_code: '0100', company_sht_txt: 'TML', company_lng_txt: 'Tata Motors Limited', kong_path: '/cv' }
const headers = {
    'Content-Type': 'application/json',
}

class NewLoginScreen extends Component {
    constructor(props) {
        super(props)
        this.state = {
            visited: cookies.get('visited'),
            access_token: '',
            sliderImgData: [],
            companyList: [],
            companyName: 'Select',
            companyCode: '',
            companyPath: '',
            loading: false,
            showPass: false,
            empIDWarn: false,
            isWhiteCollar: true,
            rememberMe: false,
            requestPath: '',
            token: '',
            showExitForm: false,
            PassWarn: false,
            companyWarn: false,
            disableEmpWarn: false,
            disableWarnMsg: '',
            emp_id: '',
            password: '',
            selectedCompanyValue: {},
            isEmployeeReporting: false,
            resetPassword: false,
            companyErrorMgs: '',
            idWarnMsg: '',
            loginwith: 'IDPASS',
            getAnApp: false
        }
    }

    componentDidMount() {
        // console.log("configStore.getState():",configStore.getState())
        if (this.props.loginData.isLoggedIn == true) {
            // Reset the all state
            // window.localStorage.clear();
            configStore.dispatch(toggleLoginStatus(false));
            configStore.dispatch(isAdmin(false));
            // this.getCookieValue()
            // this.getServiceAccountToken()
        }
        this.getServiceAccountToken()
        this.getCookieValue()
        this.checkValidEsepLink()
        // else if(this.props.loginData.isLoggedIn == false) {
        //     this.getServiceAccountToken()
        //     this.getCookieValue()
        //     this.checkValidEsepLink()
        // }
    }

    getMomentsPresent = (emp_id) => {
        const headers = {
            "Content-Type": "application/json",
        };
        let body = {
            emp_id: emp_id
        };
        localStorage.removeItem('is_popup_notification')
        fetchApiCall(Config.momentThatMatterPresent, body, headers, true, "")
            .then((res) => {
                if (res.data.status_code === "200") {
                    localStorage.setItem('is_popup_notification', JSON.stringify(res.data.data.is_popup_notification));
                    this.setState({
                        is_event: res.data.data.is_event,
                        is_popup_notification: res.data.data.is_popup_notification,
                        notification_type: res.data.data.notification_type,
                        loading: false,
                    });

                } else {
                    this.setState({
                        loading: false
                    });
                }
            })
            .catch((err) => {
                console.log('err--->', err)
                this.setState({
                    loading: false,
                });
            });

    }

    getCookieValue = () => {
        const comp_default_value = default_company

        const isRemember = cookies.get('rememberMe') != undefined
        if (isRemember) {
            this.setState({ emp_id: cookies.get('emp_id') })
            let tempCompanyPath = cookies.get('company_path')
            this.setState({ selectedCompanyValue: cookies.get('company') },
                () => this.setState({
                    companyName: this.state.selectedCompanyValue.company_sht_txt,
                    companyCode: this.state.selectedCompanyValue.company_code,
                    companyPath: tempCompanyPath,
                    rememberMe: true
                }))
            this.props.actions.setCompany(cookies.get('company'))
            this.decryptPass()
        }
        else {
            this.props.actions.setCompany(comp_default_value)
        }
    }

    decryptPass = () => {
        let password = cookies.get('pass')

        if (password !== undefined) {
            let skey = "edp@$3#drishti"
            let salt = "dRishtI";
            this.setState({ password: decrypt(password, skey, salt) })
        }
    }

    checkValidEsepLink = () => {
        if (this.props.location.initialpath != undefined) {
            let pathName = this.props.location.initialpath.pathname
            var myParam = this.props.location.initialpath.search.split('=')[1]
            var key = Config.ESEP_VERIFY_KEY

            if (pathName === '/exitform' && myParam == key) {
                this.setState({ requestPath: 'link' })
            }
            else {
                this.setState({ requestPath: 'login' })
            }
        }
        else {
            this.setState({ requestPath: 'login' })
        }
    }

    getServiceAccountToken = () => {
        this.setState({ loading: true })
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.getServiceAccountToken, {}, headers, true, 'service')
            .then(res => {
                if (res.data.status_code === '200') {
                    this.props.serviceActionToken.getServiceToken(res.data.access_token)
                    this.setState({ access_token: res.data.access_token, loading: false })
                    this.getCompanyNames()
                    this.getSliderImage()
                }
                else {
                    this.setState({ loading: false })
                }
            })
            .catch(err => {
                this.setState({ loading: false })
                console.log('err--->', err)
            })
    }

    getCompanyNames = () => {
        this.setState({ loading: true })
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.getCompanyNames, {}, headers, true, 'service')
            .then(res => {
                if (res.data.status_code === '200') {
                    this.setState({ companyList: res.data.company_list ? res.data.company_list : [], loading: false })
                }
                else {
                    this.setState({ loading: false })
                }
            })
            .catch(err => {
                this.setState({ loading: false })
                console.log('err--->', err)
            })
    }

    getSliderImage = () => {
        this.setState({ loading: true })
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
        const comp_path = '/cv'       //slider images common for all companies
        fetchApiCall(comp_path + Config.getSliderImages, {}, headers, true, 'service')
            .then(res => {
                if (res.data.status_code === '200') {
                    this.setState({
                        sliderImgData: res.data.data,
                        loading: false
                    })
                }
                else {
                    this.setState({ loading: false })
                }
            })
            .catch(err => {
                this.setState({ loading: false })
                console.log('err--->', err)
            })
    }

    validateWhiteCollar = (emp_id, password) => {
        if (emp_id.length == 0) {
            console.log("emp_idError");
        }
        else if (password.length == 0) {
            console.log("passwordError");
        }
        else {
            this.whiteUserLogin(emp_id)
        }
    }

    setCompanyName(e, listValue) {
        this.setState({
            companyName: listValue.company_sht_txt,
            companyCode: listValue.company_code,
            selectedCompanyValue: listValue,
            companyWarn: false,
            companyPath: listValue.kong_path,
        })
        this.props.actions.setCompany(listValue)
    }

    handelPassword = () => {
        this.setState({ showPass: !this.state.showPass })
    }

    login = event => {
        this.setState({ loading: true })
        event.preventDefault();
        const password = this.state.password
        let skey = "edp@$3#drishti"
        let salt = "dRishtI";
        var encryptPass = encrypt(password, skey, salt)

        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
        const { companyCode } = this.state
        let body = {
            password: this.state.isWhiteCollar ? encryptPass : this.state.otp,
            empId: this.state.emp_id,
            comp_code: companyCode,
            platform: "web-portal"
        }
        fetchApiCall(Config.getEmployeeToken, body, headers, true, 'login')
            .then(res => {
                if (res.data.status_code === '200') {
                    this.props.tokenActions.getToken(res.data)
                    this.setState({ token: res.data.access_token, loading: false })
                    this.rememberMe(encryptPass)
                    this.props.actions.setCompany(this.state.selectedCompanyValue)

                    this.setState({ isEmployeeReporting: res.data.is_employee_reporting })
                    if (this.state.isWhiteCollar) {
                        this.validateWhiteCollar(this.state.emp_id, this.state.password);
                    } else {
                        this.validateWhiteCollar(this.state.emp_id, this.state.otp);
                    }
                    this.getUserDetails(this.state.emp_id)
                    this.getCurrentRole(this.state.emp_id)
                    this.getOTRole(this.state.emp_id)
                    this.getProfile(this.state.emp_id)
                    this.getMomentsPresent(this.state.emp_id)
                    this.getExitFormDetails()

                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })

                }
                else {
                    if (res.data.istatus_code === '605') {
                        this.setState({ empIDWarn: true, disableWarnMsg: '', loading: false, idWarnMsg: res.data.message })
                    }
                    else if (res.data.istatus_code === '606') {
                        this.setState({ PassWarn: true, loading: false })
                    }
                    else if (res.data.istatus_code === '607') {
                        this.setState({ companyWarn: true, loading: false, companyErrorMgs: res.data.message })
                    }
                    else if (res.data.status_code === '404') {
                        this.setState({ disableEmpWarn: true, idWarnMsg: '', loading: false, disableWarnMsg: res.data.message })
                    }
                    else {
                        this.setState({ loading: false })
                    }
                }
            })
            .catch(err => {
                this.setState({ loading: false })
                console.log('err--->', err)
            })
    }

    getProfile = (emp_id) => {
        this.setState({ loading: true })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: emp_id,
        }
        fetchApiCall(Config.getProfilePhotoDetails, body, headers, true, '')
            .then(res => {
                if (res.data.status_code === '200') {
                    this.setState({ loading: false })
                    this.props.actions.getProfileImage(res.data.data);
                } else {
                    this.setState({ loading: false })
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                console.log('err--->', err)
            })
    }

    getCurrentRole = (emp_id) => {
        this.setState({ loading: true })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: emp_id,
        }
        fetchApiCall(Config.getcurrent_role, body, headers, true, '')
            .then(res => {
                if (res.data.status_code === '200' || res.data.status_code === '500') {
                    this.setState({ loading: false })
                    this.props.actions.getUjrData(res.data);
                } else {
                    this.setState({ loading: false })
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                console.log('err--->', err)
            })
    }

    getOTRole = (emp_id) => {
        this.setState({ loading: true })
        const reqBody = {
            emp_id: emp_id,
        }

        const headers = {
            "Content-Type": "application/json",
        };
        fetchApiCallWithService(Config.getOTRole, reqBody, headers, false, "accessToken", "POST", "login", "")
            .then((res) => {
                if (res.status === '200' || res.status === 200) {
                    this.setState({ loading: false })
                    this.props.actions.getOTData(res?.data?.data);
                } else {
                    this.setState({ loading: false })
                    this.props.actions.getOTData([]);
                }
            })
            .catch((err) => {
                this.setState({ loading: false })
                this.props.actions.getOTData([]);
            });
    }

    getCoiDetails = (emp_id) => {
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: emp_id,
        }
        fetchApiCall(Config.isCOIEnbale, body, headers, false, '')
            .then(res => {
                if (res.data) {
                    this.props.actions.isCOIPopupLogin(res.data)
                }
            }).catch(err => {
                console.log('isCOIEnbale err--->', err)
            })
    }

    //-------------------------Get Employee Exit Data APIs------------------------------
    getExitFormDetails = () => {
        this.setState({
            loading: true,
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        const { companyCode, emp_id } = this.state
        let body = {
            emp_id: emp_id ? emp_id : '',
            comp_code: companyCode ? companyCode : '',
        }
        fetchApiCall(Config.getExitFormDetails, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                    })
                    this.props.actions.esepStatusData(res.data.data);
                } else {
                    this.setState({
                        loading: false,
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                });
                console.log('err--->', err)
            })
    }

    getUserDetails = (emp_id) => {
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: emp_id,
        }
        fetchApiCall(Config.getSuccessFactorUserDetails, body, headers, true, '')
            .then(res => {
                if (res.data.status_code === '200') {
                    this.props.actions.userSFMDetails(res.data);
                }
                else {
                    this.setState({
                        loading: false
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                console.log('err--->', err)
            })
    }

    rememberMe(encryptPass) {
        if (this.state.rememberMe) {
            cookies.set('rememberMe', true)
            cookies.set('pass', encryptPass)
            cookies.set('emp_id', this.state.emp_id)
            if (this.state.companyName === 'TML') {
                cookies.set('company', default_company)
                cookies.set('company_path', '/cv')
            }
            else {
                cookies.set('company', this.state.selectedCompanyValue)
                cookies.set('company_path', this.state.selectedCompanyValue.kong_path)
            }
        }
    }

    whiteUserLogin = (emp_id) => {
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.getUserAndManagerDetail, { "empId": emp_id }, headers, true)
            .then(res => {
                if (res.data.status_code === '200') {
                    if (res.data.user_details[0]) {
                        this.props.actions.authUser(res.data.user_details[0]);
                    }
                    if (res.data.manager_details[0]) {
                        this.props.actions.authManager(res.data.manager_details[0]);
                    }
                    this.isSuggApp();
                    this.props.actions.toggleLoginStatus(true);
                    this.props.actions.isFieldlevelEmployee(res.data.is_field_level_employee);
                    this.getServiceAvailability()
                    this.isEmployeeBHR()
                    this.employeeApplicableForExitForm()
                    this.choicePayNaEmployee(emp_id)
                    this.getCoiDetails(this.state.emp_id)
                    this.setState({
                        loading: false
                    })
                } else {
                    this.setState({
                        loading: false
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                console.log('err--->', err)
            })
    }

    getServiceAvailability() {
        const headers = {
            "Content-Type": "application/json",
        };
        let request = {};
        fetchApiCall(Config.getServiceAvailabilty, request, headers, false, "")
            .then((res) => {
                sessionOut(this.props, res);
                if (res.data.status_code === "200") {
                    this.setState({ serviceData: res.data.services_status });
                    this.props.serviceAction.getServiceAvailabilityData(res.data.services_status);
                    this.props.actions.setServiceEnableDisable(res.data.services_status);
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    }

    employeeRoleBasedAccess = () => {
        const headers = {
            'Content-Type': 'application/json',
        }
        const body = { emp_id: this.state.emp_id }
        fetchApiCall(Config.employeeRoleBasedAccess, body, headers, false, '')
            .then(res => {
                if (res.data.status_code === '200') {
                    this.props.actions.isApplicableForIDT(res.data.idt_access)
                }
                else {
                    this.setState({ loading: false })
                    console.log('empRoleBased Error')
                }
            }).catch(err => {
                console.log('empRoleBased Error 2', err)
                cookies.set('rememberMe', false)
                this.setState({
                    companyWarn: true, loading: false,
                    companyErrorMgs: 'Invalid company'
                })
            }).then(() => this.isEmployeeBHR())
    }

    //to check employee applicable for BHR (Transfer module toggle)
    isEmployeeBHR = () => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.state.emp_id,
        }
        fetchApiCall(Config.is_employee_bhr, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    if (res.data.is_bhr === 'True') {
                        this.props.actions.isApplicableForBHR(true)
                    }
                    else {
                        this.props.actions.isApplicableForBHR(false)
                    }
                    this.setState({
                        loading: false,
                    });
                    this.getEmpModules();
                } else {
                    this.setState({ loading: false })
                }

            }).catch(err => {
                this.setState({
                    loading: false
                })
                console.log('bhr check API error', err);
            })
    }

    isSuggApp = () =>{
        this.setState({
            loading: true
        });
        const param = {
            pa: configStore.getState().loginState?.userData?.PersArea,
            comp_code: configStore.getState().loginState?.company?.company_code
        };
        let endpoint =
        '?pa=' + param.pa + '&comp_code=' + param.comp_code;
        suggestionTabApplicable(endpoint)
          .then((res) => {
            if (res?.status == 200 || res?.status == 201) {
                if(res?.data?.data?.is_employee_applicable_for_suggestion_tab){
                    this.props.actions.isApplicableForSuggestionScheme(true)
                }
                else{
                    this.props.actions.isApplicableForSuggestionScheme(false)
                }
                this.setState({ loading: false })
            }else{
                this.props.actions.isApplicableForSuggestionScheme(false)
                this.setState({ loading: false })
            }
          })
          .catch((errors) => {
            this.props.actions.isApplicableForSuggestionScheme(false)
            this.setState({ loading: false })
            console.log("error", errors);
          })
    }

    getEmpModules = () => {
        let body = {
            is_bhr: configStore.getState()?.loginState?.isBHR,
            is_manager: configStore.getState()?.keycloakState?.keycloakData?.is_employee_reporting,
            employee_type: configStore.getState()?.adminLoginState?.isAdminLoggedIn ? "admin" : "employee",
            comp_code: configStore.getState()?.loginState?.company?.company_code
        }
        let method = "GET"

        this.setState({
            loading: false
        });
        fetchApiCall(Config.getEmpModules, body, headers, true, '', method)
            .then(res => {
                sessionOut(this.props, res);
                this.props.actions.setEmpModules(res.data.data.modules)
                this.setState({
                    loading: false,
                });
            }).catch(err => {
                this.setState({
                    loading: false
                })
                console.error('Get emp modules API error', err.response);
            })
    }

    employeeApplicableForExitForm() {
        const headers = {
            'Content-Type': 'application/json',
        }
        const body = {
            emp_id: this.state.emp_id,
            navigated_from: this.state.requestPath
        }
        fetchApiCall(Config.employeeApplicableForExitForm, body, headers, false, '')
            .then(res => {
                if (res.data.status_code === '200') {
                    if (res.data.employee_applicable === 'True' && res.data.employee_status === 'Show') {
                        this.setState({ showExitForm: true })
                        this.props.actions.isApplicableForExitForm(true)
                        this.props.actions.esepEmployee(res.data)
                    }
                    else {
                        this.props.actions.isApplicableForExitForm(false)
                    }
                    this.setState({ loading: false },
                        () => this.handleClick()
                    )
                }
                else {
                    this.setState({ loading: false },
                        () => this.handleClick()
                    )
                }
            }).catch(err => {
                console.log('err--->', err)
                this.setState({ loading: false },
                    () => this.handleClick())
            })
    }

    choicePayNaEmployee = (emp_id) => {
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: emp_id ? emp_id : '',
            comp_code: this.state.companyCode ? this.state.companyCode : '',
        }
        fetchApiCall(Config.choicePayNaEmployee, body, headers, true, '')
            .then(res => {
                if (res.data.status_code === '200' || res.data.status_code == 200) {
                    this.props.actions.isApplicableForChoicePay(res.data.data);
                } else {
                    this.setState({ loading: false })
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                console.log('choicePayNaEmployee not woking', err)
            })
    }

    handleClick = () => {
        cookies.set('visited', true)
        if (this.state.showExitForm && this.state.requestPath === 'link') {
            this.props.history.push('/exitform');
        }
        else {
            this.props.history.push('/home')
        }
    }

    onDismiss = () => {
        this.setState({ resetPassword: false })
    }

    resetPasswordOpen = () => {
        this.setState({ resetPassword: true })
    }

    validateLogin = (event) => {
        event.preventDefault()
        if (this.state.companyName === 'Select') {
            this.setState({
                companyWarn: true,
                companyErrorMgs: 'Please Select Company'
            })
        }
        else if (this.state.emp_id.length > 8) {
            this.setState({
                empIDWarn: true,
                idWarnMsg: `Please Check Your's ID, Keep Less than 8`
            })
        }
        else {
            this.login(event)
        }
    }

    getCoiDetails = (emp_id) => {
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: emp_id,
        }
        fetchApiCall(Config.isCOIEnbale, body, headers, false, '')
            .then(res => {
                if (res.data) {
                    this.props.actions.isCOIPopupLogin(res.data)
                }
            }).catch(err => {
                console.log('err--->', err)
            })
    }

    render() {
        return (
            <div>
                <LoaderIndicator loading={this.state.loading} />
                <div className='sec-col d-flex row rowRevert'>
                    <div className='column-1 col-lg-6 col-xl-6 col-md-6' id="down">
                        <div className='round-border d-flex align-items-center justify-content-center margin30px'>
                            <div className='main-box round-border mt-5'>
                                <div>
                                    <>
                                        <LoginMainHead
                                            loginHead={'DRISHTI 2.0'}
                                            loginSubhead={this.state.visited ? 'Welcome Back' : 'Welcomes You !!'}
                                            loginSubPara={'Drishti makes it easy for everyone to find and manage everything at one place'}
                                        />
                                        <form className='form-group login-form'>
                                            <label className='login-form-label card-content-title1 mt-3 ml-2'>Company</label>
                                            <div className='dropdown'>
                                                <button
                                                    className='btn inpt dropDown d-flex justify-content-between'
                                                    type='button' id="dropdownMenuButton"
                                                    data-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    {this.state.companyName}
                                                    <RiArrowDropDownLine size={20} color={'rgb(185 188 197)'} />
                                                </button>
                                                <div className='dropdown-menu' aria-labelledby="dropdownMenuButton">
                                                    {this.state.companyList.map((listValue, i) => {
                                                        return (
                                                            <label key={i} className='dropdown-item' onClick={(e) => this.setCompanyName(e, listValue)}>{listValue.company_sht_txt}</label>
                                                        );
                                                    })}
                                                </div>
                                                {this.state.companyWarn ? <>
                                                    <img src={WarnIcon} alt='' className='ml-1' /><label className='ml-2 PassWarning'>{this.state.companyErrorMgs}</label></> : null}
                                            </div>
                                            <div className=''>
                                                <div>
                                                    <label className='login-form-label card-content-title1 mt-3 ml-2'>Employee Id</label>
                                                    <div>
                                                        <input
                                                            type='text'
                                                            maxLength={8}
                                                            onChange={(e) => this.setState({ emp_id: e.target.value ? validateNumber(e.target.value) : '', empIDWarn: false })}
                                                            className='inpt'
                                                            placeholder='Ex. 57680'
                                                            value={this.state.emp_id}
                                                        ></input>
                                                        {this.state.empIDWarn || this.state.disableEmpWarn ?
                                                            <span className='d-flex'>
                                                                <img src={WarnIcon} alt='' className='ml-1 align-items-start' />
                                                                <div className='ml-2 PassWarning'>{this.state.idWarnMsg || this.state.disableWarnMsg}</div>
                                                            </span> : null}

                                                    </div>
                                                </div>
                                                <label className='login-form-label card-content-title1 mt-3 ml-2'>Password</label>
                                                <div className='d-flex justify-content-between'>
                                                    <input
                                                        type={this.state.showPass ? "text" : "password"}
                                                        className={this.state.PassWarn ? 'invalid' : 'inpt'}
                                                        onChange={(e) => this.setState({ password: e.target.value, PassWarn: false })}
                                                        placeholder='Enter Your Password'
                                                        value={this.state.password}
                                                    />
                                                    {this.state.password != '' & this.state.password !== undefined ?
                                                        <span
                                                            className='showPassBtn'
                                                            onClick={this.handelPassword}
                                                        > {this.state.showPass ? 'hide' : 'show'}</span>
                                                        :
                                                        <span className='showDisabledPassBtn'>show</span>
                                                    }
                                                </div>
                                                {this.state.PassWarn ? <>
                                                    <img src={WarnIcon} alt='' className='ml-1' /><label className='ml-2 PassWarning'>The password entered is incorrect</label></> : null}
                                                <div className='justify-content-between d-flex align-item-center mt-3'>
                                                    <div className='d-flex ml-3'>
                                                        <label className="checkBoxData pl-3">
                                                            <input type="checkbox" className='hidecheckBox'
                                                                checked={this.state.rememberMe}
                                                                onChange={e => this.setState({ rememberMe: !this.state.rememberMe })}
                                                            />
                                                            <span className="markk"></span>
                                                        </label>
                                                        <div className='rememberMe align-item-center'>
                                                            Remember Me
                                                        </div>
                                                    </div>
                                                    <div className='resetPassText mr-2'
                                                        onClick={this.resetPasswordOpen}
                                                    >
                                                        Reset password
                                                    </div>
                                                </div>
                                                <button
                                                    type="submit"
                                                    className='btn btn-primary w-100 mt-3 login-btn py-2'
                                                    value="LOGIN"
                                                    disabled={(this.state.companyName === 'Select' || this.state.emp_id === '' || this.state.password === '') ? true : false}
                                                    onClick={this.validateLogin}
                                                >
                                                    LOGIN
                                                </button>

                                            </div>
                                        </form>
                                    </>
                                    <Link to={'appinfo'} >
                                        <div className='justify-content-end text-align-center text20-bold m-5px pointer getAppText'
                                        >Get The App </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='column-2 col-lg-6 col-xl-6 col-md-6'>
                        <div className='row mt-4 ml-5'>
                            <img src={loginHead} alt='' className='login-img-head' />
                        </div>
                        {this.state.sliderImgData.length != 0 ?
                            <div className='my__carousel'>
                                <Carousel
                                    nextIcon={false}
                                    prevIcon={false}
                                    indicators={true}
                                    controls={false}
                                    pause={false}
                                >
                                    {this.state.sliderImgData.map((list, index) =>
                                        <Carousel.Item
                                            key={index}
                                            activeindex={index - 1}
                                            interval={3000}
                                        >
                                            <div className='row mt-1 mr-4 align-item-center-noflex justify-content-center-noflex '>
                                                <img src={list.image_link} alt='' className='login-3d-img' />
                                            </div>
                                            <div>
                                                <div className='row ml-4'>
                                                    <div className='login-stay-touch'>{list.image_title}</div>
                                                    {/* <div className='login-stay'>{list.image_title.split(' ')[0]}</div>
                                                <div className='login-stay-touch'>{list.image_title.split(' ')[1]}</div> */}
                                                </div>
                                                <div className='login-stay-touch-sub mt-2 ml-1'>
                                                    {list.image_hyperlink}
                                                </div>
                                            </div>
                                        </Carousel.Item>
                                    )}
                                </Carousel>
                                <div className='scroll-down-login'>
                                    <a className='scroll-down-key' href="#down">LOGIN</a>
                                </div>
                            </div>
                            :
                            <div className='my__carousel'>
                                <Carousel
                                    nextIcon={false}
                                    prevIcon={false}
                                    indicators={true}
                                    controls={false}
                                    pause={false}
                                >
                                    <Carousel.Item
                                        activeindex={1}
                                        interval={3000}
                                    >
                                        <div className='row mt-4 ml-5 pl-5'>
                                            <img src={slider_image_default} alt='' className='login-3d-img' />
                                        </div>
                                        <div className='row mt-1 ml-4'>
                                            <span className='login-stay-touch'>New experience,</span>
                                            <span className='login-stay-touch'>new beginnings!</span>
                                        </div>
                                        <div className='login-stay-touch-sub mt-2 ml-1'>
                                            {default_text}
                                        </div>
                                    </Carousel.Item>
                                </Carousel>
                                <div className='scroll-down-login'>
                                    <a className='scroll-down-key' href="#down">LOGIN</a>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {this.state.resetPassword &&
                    <ResetPassword
                        onDismiss={this.onDismiss}
                    />
                }
            </div>
        )
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        keycloakData: state.keycloakState,
        serviceData: state.activePopupState
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(userActions, dispatch),
        tokenActions: bindActionCreators(KeycloakActions, dispatch),
        serviceActionToken: bindActionCreators(serviceActionToken, dispatch),
        serviceAction: bindActionCreators(ActivePopupAction, dispatch),
    };
}

export default withRouter(connect(mapStatesToProps, mapDispatchToProps)(NewLoginScreen));



