import * as type from '../../../../redux/action/ActionType';

const initialState = {
    userData: {},
    managerData: {},
    exitFormData: [],
    cfmUserDetails: [],
    isExitFormApplicable: false,
    isFieldLevelEmployee: '',
    isLoggedIn: false,
    isBHR: false,
    isSUGG: false,
    isIDT: false,
    company: {},
    companyPath: '',
    profileImage: '',
    ujrData: [],
    OTData: [],
    serviceEnableDisableData: [],
    isExitApplicable: false,
    isApplicableForChoicePay: false,
    empModules: [],
    coiLoginPopup : []
}

export const login = (state = initialState, action) => {
    switch (action.type) {
        case type.USER_LOGIN_SUCCESS:
            return {
                ...state,
                userData: action.userData,
            }
        case type.MANAGER_LOGIN_SUCCESS:
            return {
                ...state,
                managerData: action.managerData
            }
        case type.GET_EMPLOYEE_PROFILE_DETAILS:
            return {
                ...state,
                profileImage: action.payload
            }
        case type.GET_UJR_DATA:
            return {
                ...state,
                ujrData: action.payload
            }
        case type.GET_OT_DATA:
            return {
                ...state,
                OTData: action.payload
            }
        case type.GET_ESEP_STATUS:
            return {
                ...state,
                isExitApplicable: action.payload
            }
        case type.SET_COMPANY:
            return {
                ...state,
                company: action.payload
            }
        case type.FIELD_LEVEL_EMP_SUCCESS:
            return {
                ...state,
                isFieldLevelEmployee: action.isFieldLevelEmployee
            }
        case type.EXIT_FORM_APPLICABLE:
            return {
                ...state,
                isExitFormApplicable: action.payload
            }
        case type.EXIT_FORM_DATA:
            return {
                ...state,
                exitFormData: action.payload
            }
        case type.EMPLOYEE_APPLICABLE_FOR_CHOICE_PAY:
            return {
                ...state,
                isApplicableForChoicePay: action.payload
            };
        case type.BHR_APPLICABLE:
            return {
                ...state,
                isBHR: action.payload
            }
        case type.SUGG_APPLICABLE:
            return {
                ...state,
                isSUGG: action.payload
            }
        case type.IDT_APPLICABLE:
            return {
                ...state,
                isIDT: action.payload
            }
        case type.TOGGLE_LOGIN_STATUS:
            return {
                ...state,
                isLoggedIn: action.payload
            }
        case type.GET_SUCCESS_FACTOR_USER_DETAILS:
            return {
                ...state,
                cfmUserDetails: action.payload
            }
        case type.SET_SERVICE_ENABLE_DISABLE:
            return {
                ...state,
                serviceEnableDisableData: action.payload
            }
        case type.EMP_MODULES:
            return {
                ...state,
                empModules: action.payload
            }
        case type.EMPTY_LOGIN_STATE:
            return {
                ...state,
                managerData: {},
                exitFormData: [],
                cfmUserDetails: [],
                isExitFormApplicable: false,
                isFieldLevelEmployee: '',
                isLoggedIn: false,
                isBHR: false,
                isSUGG: false,
                isIDT: false,
                company: {},
                profileImage: '',
                ujrData: [],
                serviceEnableDisableData: [],
                isExitApplicable: false,
                isApplicableForChoicePay: false,
                empModules: [],
                OTData:[],
                coiLoginPopup: []
            }
        case type.COI_POPUP_LOGIN:
            return{
                ...state,
                coiLoginPopup : action.payload
            }
        default:
            return state;
    }
}