import React, { useEffect, useState } from 'react'
import DataNotFound from '../../../../shared/notFound/DataNotFound'
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator'
import { clarification, suggestionDetails, SuggestionDocument, SuggestionMemberDetails } from '../../suggestionScheme/constants';
import './suggestion.css'
import SuggesterPreviewCompoment from './SuggesterPreviewCompoment';
import { DDMMYYYY } from '../../../../../utils/Utils';
import ViewTable from './ViewTable';
import { getMemberDetails, getS3Document, getSuggestionLogs } from '../../../../../constants/api.service';
import { toast } from 'react-toastify';
import ServiceUnavailable from '../../../../shared/ServiceUnavailable';

const SuggesterDeatilPopup = (props) => {
    const { selectedItem, showCheckbox, showAction, itemsPerPage, selectedOption, onDismiss } = props
    const [loading, setLoading] = useState(false);
    const [documentView, setDocumentView] = useState(false);
    const [SuggestionDetailList, setSuggestionDetails] = useState(null);
    const [is_service_Unavailable, setIs_Service_Unavailable] = useState(false);
    const [landing_Service_reason, setLanding_Service_reason] = useState(null);
    const handleSecondaryAction = (index) => {
        setDocumentView(true);
    };

    useEffect(() => {
        getSuggestionDetails(selectedItem);
    }, [])

    const getSuggestionDetails = async (selectedItem) => {
        setLoading(true);
        try {
            const res = await getSuggestionLogs(selectedItem.id);
            if (res.status === 200) {
                setSuggestionDetails(res.data.data);
            } else {
                toast.warn(res.message, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                });

            }
        } catch (error) {
            toast.warn(error.message, {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            });
        } finally {
            setLoading(false);
        }
    };


    const fields = [
    { title: 'Suggestion ID', value: SuggestionDetailList?.suggestion_details?.id ?? '----'},
    { title: 'Title', value: SuggestionDetailList?.suggestion_details?.title ?? '----' },
    { title: 'Category', value: SuggestionDetailList?.suggestion_details?.category_name ?? '----' },
    { title: 'Scheme Type', value: SuggestionDetailList?.suggestion_details?.scheme_type_name ?? '----' },
    { title: 'Agency', value: SuggestionDetailList?.suggestion_details?.agency_name ?? '----' },
    { title: 'Division', value: SuggestionDetailList?.suggestion_details?.division_name ?? '----' },
    { title: 'Suggestion Submission Date', value: DDMMYYYY(SuggestionDetailList?.suggestion_details?.entered_on) ?? '----'},
    { title: 'Annual Saving in ₹', value: SuggestionDetailList?.suggestion_details?.annual_saving ?? '----'},
];

const schemeType = SuggestionDetailList?.suggestion_details?.scheme_type_name;
if (schemeType === 'SPECIAL' || schemeType === 'SPECIAL IMPLEMENTED') {
    fields.push({ title: 'Scheme Name', value: SuggestionDetailList?.suggestion_details?.scheme_name ?? '----' });}

if (schemeType === 'IMPLEMENTED' || schemeType === 'SPECIAL IMPLEMENTED') {
    fields.push(
        { title: 'Implemented Date', value: DDMMYYYY(SuggestionDetailList?.suggestion_details?.implemented_date) },
        { title: 'Idea Bank Reference', value: SuggestionDetailList?.suggestion_details?.idea_bank_reference ?? '----' });}
    return (
        <div className={"modal popup-box "}>
            <LoaderIndicator loading={loading} />

            <div className={"modal-dialog modal-dialog-centered modal-dialog-scrollable w-60rem"}>
                <div className={`modal-content bg-white border_radius_12px max-height-80 ` + `w-60vw`}>
                    <div className="modal-header mx-2">
                        <div className='row'>
                            <h4 className="popup-title mt-2">Suggestion Details</h4>
                        </div>
                        <button type="button" className="close pt-4" data-dismiss="modal" onClick={() => { onDismiss() }}>&times;</button>
                        <div className='solid-border'></div>
                    </div>

                    <div className='scroll-y mx-2 mt-2'>
                        {selectedItem && (
                            <>
                                <div className='d-flex flex-column mt-1'>
                                    <div className='row w-100  mb-3'>
                                        {fields.map((field, index) => (
                                            <div className="col-4 mt-2" key={index}>
                                                <div className="text14_bold">{field.title}:</div>
                                                <div className="text14_light mt-1 popup-overflow">{field.value}</div>
                                                <div className='mt-1'><hr /></div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className='mt-1'><hr /></div>
                                {/* Suggestion Details */}
                                <div className='d-flex flex-column mt-2'>
                                    <div className='w-100 mb-3'>
                                        {(SuggestionDetailList?.suggestion_details?.scheme_type_name === 'NORMAL' ||
                                            SuggestionDetailList?.suggestion_details?.scheme_type_name === 'SPECIAL') && (
                                                <>
                                                    <div className="col-12">
                                                        <div className="text14_bold">Present Status:</div>
                                                        <div className="text14_light mt-1 popup-overflow" >{SuggestionDetailList?.suggestion_details?.present_status ?? '----'}</div>
                                                        <div className='mt-1'><hr /></div>
                                                    </div>
                                                    <div className="col-12 mt-3">
                                                        <div className="text14_bold">Proposed Changes:</div>
                                                        <div className="text14_light mt-1 popup-overflow" >{SuggestionDetailList?.suggestion_details?.proposed_changes ?? '----'}</div>
                                                        <div className='mt-1'><hr /></div>
                                                    </div>
                                                </>
                                            )}
                                        {(SuggestionDetailList?.suggestion_details?.scheme_type_name === 'IMPLEMENTED' ||
                                            SuggestionDetailList?.suggestion_details?.scheme_type_name === 'SPECIAL IMPLEMENTED') && (
                                                <>
                                                    <div className="col-12">
                                                        <div className="text14_bold">Earlier Status:</div>
                                                        <div className="text14_light mt-1 popup-overflow" >{SuggestionDetailList?.suggestion_details?.earlier_status ?? '----'}</div>
                                                        <div className='mt-1'><hr /></div>
                                                    </div>
                                                    <div className="col-12 mt-3">
                                                        <div className="text14_bold">Implemented Changes:</div>
                                                        <div className="text14_light mt-1 popup-overflow" >{SuggestionDetailList?.suggestion_details?.changes_implemented ?? '----'}</div>
                                                        <div className='mt-1'><hr /></div>
                                                    </div>
                                                </>
                                            )}
                                        <div className="col-12 mt-3">
                                            <div className="text14_bold">Benefits:</div>
                                            <div className="text14_light mt-1 popup-overflow" >{SuggestionDetailList?.suggestion_details?.benefits ?? '----'}</div>
                                            <div className='mt-1'><hr /></div>
                                        </div>
                                    </div>
                                    
                                    <div className='w-100  mb-3'>
                                        <div className="col-12 mt-1">
                                            <div className="text14_bold">Other areas where this suggestion is applicable:</div>
                                            <div className="text14_light mt-1 popup-overflow" >{SuggestionDetailList?.suggestion_details?.applicable_areas ?? '----'}</div>
                                            <div className='mt-1'><hr /></div>
                                        </div>
                                    </div>
                                    <div className='mt-1'><hr /></div>
                                </div>

                                {/* Member Details */}
                                <div className='toggle-card-heading mt-10px ml-10 mb-10'>Member Details</div>
                                <div className='d-flex flex-column'>
                                    {!SuggestionDetailList?.member_details || SuggestionDetailList?.member_details.length === 0 ? (
                                        <DataNotFound />
                                    ) : (
                                        <div className='ml-3 mr-4'>
                                            <ViewTable isCertificateEnable={true} data={SuggestionDetailList?.member_details} columns={SuggestionMemberDetails} sugDetailsList={SuggestionDetailList?.suggestion_details} />
                                        </div>
                                    )}
                                </div>
                                <div className='mt-1'><hr /></div>

                                {/* Document To Upload */}
                                <div className='toggle-card-heading mt-10px ml-10 mb-10'>Documents Uploaded</div>
                                <div className='d-flex flex-column'>
                                    {!SuggestionDetailList?.document_details || SuggestionDetailList?.document_details.length === 0 ? (
                                        <DataNotFound />
                                    ) : (
                                        <div className='ml-3 mr-4'>
                                            <SuggesterPreviewCompoment data={SuggestionDetailList?.document_details.filter((e)=> e.document_category === 'suggestion_details')} download={true} viewDelete={false} />
                                        </div>
                                    )}
                                </div>
                                <div className='mt-1'><hr /></div>
                            </>
                        )}
                    </div>
                    <div className="popup-button-background justify-content-end align-item-center pr-3 p-10px">
                        <input type='button' value={'CLOSE'} onClick={onDismiss} className='validateBtn' />
                    </div>
                </div>
            </div>
        </div>
    );
};


export default SuggesterDeatilPopup