import React, { useState } from 'react';
import './Regularize.css';
import Dropdown from '../../../../shared/components/Dropdown';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import DynamicDropdown from '../../../../shared/components/DynamicDropdown';
import { CardColumns } from 'react-bootstrap';
import { fetchApiCallWithService, sessionOut } from '../../../../../constants/Service';
import * as Config from '../../../../../constants/Config';
import { useSelector } from 'react-redux';

const Regularize = ({ onCancel, setIsRegularizeDetail, validateEmpIdAndOTDate, loading, regularizeTab }) => {
    const [employeeId, setEmployeeId] = useState('');
    const [cc, setCC] = useState('');
    const [otDate, setOtDate] = useState('');
    const [dayType, setDayType] = useState('');
    const [quotaType, setQuotaType] = useState('');
    const [otHrs, setOtHrs] = useState('00:30');
    const [error, setError] = useState(false);
    const [Hrs, setHrs] = useState('');
    const [Min, setMin] = useState('');
    const [showCostCenter, setShowCostCenter] = useState(false);
    const [isEmployeeIdValid, setIsEmployeeIdValid] = useState(false);
    const [isCCValid, setIsCCValid] = useState(false);
    const [isEmpDetails, setIsEmpDetails] = useState({});
    const adminLoginState = useSelector(state => state.adminLoginState);
    const loginState = useSelector(state => state.loginState);

    const isValidInput = (value) => {
        if (value.trim() === '') return true;
        const parts = value.split(':');
        if (parts.length !== 2) return false;
        const hours = parseInt(parts[0], 10);
        const minutes = parseInt(parts[1], 10);
        if (isNaN(hours) || hours < 0) return false;
        if (isNaN(minutes) || minutes < 0 || minutes > 59 || parts[1].length !== 2) return false;
        if (hours === 0 && minutes < 30) return false;
        return true;
    };

    const props = {
        adminLoginState: adminLoginState,
        loginState: loginState
    }

    const isNumeric = (value) => {
        return /^\d*$/.test(value); // Regex to check if the value is a number (including empty string)
    };
    const isValidHour = (value) => isNumeric(value) && (value === '' || (parseInt(value, 10) >= 0 && parseInt(value, 10) <= 23));
    const isValidMinute = (value) => isNumeric(value) && (value === '' || (parseInt(value, 10) >= 0 && parseInt(value, 10) <= 59));
    const DayType = [
        { quotaType: "01", dayType: 'W', value: 'Working day' },
        { quotaType: "01", dayType: 'O', value: 'Week - off' },
        { quotaType: "01", dayType: 'H', value: 'Holiday' },
    ]

    const QuotaType = [
        { key: '01', value: '01 - Overtime' },
        { key: '02', value: '02 - Weekly off booking' },
        { key: '03', value: '03 - Holiday Booking' },
    ];

    const headers = {
        "Content-Type": "application/json",
    };

    const handleOTHoursChange = (newHour, newMinute) => {
        const newValue = `${newHour}:${newMinute}`;
        if (!isValidInput(newValue)) {
            setError(true);
        } else {
            setError(false);
        }
        setOtHrs(newValue);
    };

    const handleUpdate = () => {
        console.log("employeeId, otDate, dayType.key, quotaType.key, otHrs", employeeId, otDate, "W", "01", otHrs)
        validateEmpIdAndOTDate(employeeId, cc, otDate, "W", "01", otHrs);
    };

    const handleEmployeeIdChange = (event) => {
        const value = event.target.value;
        if (/^\d*$/.test(value) && value.length <= 8) {
            setEmployeeId(value);
            if (value.length >= 6 && value.length <= 8) {
                let reqBody = { 'emp_id': value }
                fetchApiCallWithService(Config.checkIsEmployeeValid, reqBody, headers, false, "accessToken", "POST", "login", "")
                    .then((res) => {
                        sessionOut(props, res);
                        if (res.status == "200" || res.status == 200) {
                            console.log("res", res)
                            setIsEmpDetails(res.data)
                            setCC(res?.data?.data?.cost_center)
                            setIsEmployeeIdValid(true)
                            setIsCCValid(/^\d*$/.test(value));
                            setShowCostCenter(true)
                        }
                        else {
                            setIsEmpDetails({})
                            setIsEmployeeIdValid(false);
                            setCC('')
                            setShowCostCenter(false)
                        }
                    })
                    .catch((err) => {
                        setIsEmpDetails({})
                        setIsEmployeeIdValid(false);
                        setCC('')
                        setShowCostCenter(false)
                    });
            }
            else {
                setIsEmpDetails({})
                setIsEmployeeIdValid(false);
                setCC('')
                setShowCostCenter(false)
            }
            setIsEmployeeIdValid(false);
        }
    };
    const handleCCChange = (event) => {
        const value = event.target.value;
        if (/^\d*$/.test(value)) {
            setCC(value);
            setIsCCValid(/^\d*$/.test(value));
        }
    };

    const setingQuotaType = (data) => {
        if (data.key == '01') {
            setDayType("")
        }
        if (data.key == '02') {
            setDayType({ key: 'O', value: 'Week - off' })
        }
        if (data.key == '03') {
            setDayType({ key: 'H', value: 'Holiday' })
        }
        setQuotaType(data)
    }

    // const isFormValid = otDate && employeeId && otHrs && quotaType && cc ? quotaType.key == '01' ? dayType != '' ? true : false : true : false && isEmployeeIdValid;
    const isFormValid = employeeId && otDate && otHrs && isEmployeeIdValid && cc;
    // const isFormValid = otDate && employeeId && !error && dayType && isEmployeeIdValid;


    return (
        <div className="modal popup-box">
            <div className="declaration-box p-0 edit-popup" style={{ width: '30%' }}>
                <LoaderIndicator loading={loading} />
                <div className="modal-header">
                    <h4 className="modal_title black">Regularize OT</h4>
                    <button type="button" className="close" data-dismiss="modal" onClick={onCancel}>&times;</button>
                </div>
                <div className="modal-body" style={{ height: '400px', overflowY: 'auto' }}>
                    <div className="popup-content pl-3 pr-3">
                        <div className="form-group">
                            <label className="form-label ml-2">Employee Id</label>
                            <input
                                type="text"
                                id="employeeId"
                                className="form-control p-placeholder px-2 p-placeholder-admin"
                                value={employeeId}
                                onChange={handleEmployeeIdChange}
                            />
                            {!isEmployeeIdValid && employeeId && (
                                <div style={{ color: 'red', fontSize: '14px' }}>
                                    Employee ID must be at least 6 digits long.
                                </div>
                            )}
                            {isEmpDetails &&
                                <div style={{ color: '#096dd9' }} className='bold-font ml-2 mt-5px'>{isEmpDetails?.data?.emp_name}</div>
                            }
                        </div>
                    </div>
                    {/* {employeeId.length>=6 && <div className="popup-content pl-3 pr-3"> */}
                        {showCostCenter && <div className="popup-content pl-3 pr-3">
                        <div className="form-group">
                            <label className="form-label ml-2">Assigned Cost Center</label>
                            <input
                                type="text"
                                id="cc"
                                className="form-control p-placeholder px-2 p-placeholder-admin"
                                value={cc}
                                onChange={handleCCChange}
                            />
                            {!isCCValid && cc && (
                                <div style={{ color: 'red', fontSize: '14px' }}>
                                    Cost Center must be digit.
                                </div>
                            )}
                        </div>
                    </div>}
                    <div className="popup-content pl-3 pr-3">
                        <div className="form-group">
                            <label className="form-label ml-2">OT Date</label>
                            <input
                                className="form-control p-placeholder px-2 p-placeholder-admin"
                                type="date"
                                placeholder="Select the date"
                                onChange={(e) => setOtDate(e.target.value)}
                                value={otDate}
                                onKeyDown={(e) => e.preventDefault()}
                                max={new Date(new Date().setDate(new Date().getDate() - 2)).toISOString().split('T')[0]}
                                min={loginState?.OTData?.date_config}
                                // min={"2024-07-01"}
                            />
                        </div>
                    </div>
                    {/* <div className="form-group pl-3 pr-3">
                        <label className="form-label ml-2">Quota Type</label>
                        <Dropdown
                            data={QuotaType}
                            selectedValue={(data) => setingQuotaType(data)}
                            value={quotaType ? quotaType.value : "Select Quota Type"}
                            render="value"
                            height="NotificationHeight"
                        />
                    </div>   
                    */}
                    {/* <div className="form-group pl-3 pr-3">
                        <label className="form-label ml-2">Day Type</label>
                        <Dropdown
                            data={DayType}
                            selectedValue={(data) => setDayType(data)}
                            value={dayType ? dayType.value : "Select Day Type"}
                            render="value"
                            height="NotificationHeight"
                        />
                    </div>
                    */}
                    <div className="form-group pl-3 pr-3">
                        <div className="form-group">
                            <div className="main-atribute">OT Hours (HH:MM)</div>
                            <div className='d-flex align-items-center'>
                                <div style={{ position: 'relative' }}>
                                    <input
                                        type="text"
                                        value={otHrs.split(':')[0]}
                                        onChange={({ target: { value } }) => {
                                            if (isValidHour(value)) {
                                                setHrs(value);
                                                handleOTHoursChange(value, Min || otHrs.split(':')[1]);
                                            }
                                        }}
                                        maxLength="2"
                                        className={'editTime'}
                                        placeholder="HH"
                                    />

                                </div>
                                <span className='mx-1'>:</span>
                                <div style={{ position: 'relative' }}>
                                    <input
                                        type="text"
                                        value={otHrs.split(':')[1]}
                                        onChange={({ target: { value } }) => {
                                            if (isValidMinute(value)) {
                                                setMin(value);
                                                handleOTHoursChange(Hrs || otHrs.split(':')[0], value)
                                            }
                                        }}
                                        maxLength="2"
                                        className={'editTime'}
                                        placeholder="MM"
                                    />

                                </div>
                            </div>
                            {error && (
                                <div style={{ color: 'red', fontSize: '14px' }}>
                                    Invalid input
                                </div>
                            )}
                        </div>
                    </div>

                </div>
                <div className="modal-footer">
                    <input type="button" value="Cancel" className="cancelBtn" onClick={onCancel} />
                    <input
                        type="button"
                        value="Validate"
                        className={isFormValid ? 'ml-2 validateBtn' : 'ml-2 validateBtnDisabled'}
                        onClick={handleUpdate}
                        disabled={!isFormValid}
                    />
                </div>
            </div>
        </div>


    );
};

export default Regularize;
