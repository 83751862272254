import React, { Component } from 'react';
import { connect } from 'react-redux';
import NodataApproval from '../../../../assets/drishti_images/nodatafound/todolist-nodata.svg';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import CommonButton from '../../../shared/button/GradientButton';
import ReactExport from "react-data-export";
import moment from 'moment';
import * as Config from '../../../../constants/Config';
import { fetchApiCallWithService, sessionOut } from '../../../../constants/Service';
import { toaster } from '../../../../utils/Utils';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const CurrentYearNote = 'Report generated for Leave Quota entitlement for current Calendar Year'
class ReporteeLeaveQuota extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            leaveApproval: [],
        }
    }

    downloadYearlyReport (){
        this.setState({
            loading: true,
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData?.userData?.Perno ? this.props.loginData?.userData?.Perno : '',
            comp_code: this.props.loginData?.userData?.CompCode ? this.props.loginData?.userData?.CompCode : '',
        }
        if( this.props.loginData?.userData?.Imailid){

            fetchApiCallWithService(Config.ExportReporteeAvailedLeavesData, body, headers, false, "accessToken", "POST", "login", "")
                .then(res => {
                    this.setState({loading: false})
                    // console.log('downloadYearlyReport res----->', res);
                    sessionOut(this.props, res);
                    if (res?.data?.status_code == 200) {
                        this.setState({
                            loading: false,
                        });
                        toaster("success",res?.data?.message)
                    } else {
                        this.setState({
                            loading: false,
                        });
                        toaster("error", res?.data?.message ? res?.data?.message : "Something went wrong")
                    }
                }).catch(err => {
                    console.log('downloadYearlyReport err-->', err);
                    this.setState({
                        loading: false,
                    });
                })
        }else{
            toaster("warning", "Email ID is not Present Please Update the EmailID.")
        }
    }
    render() {
        const { loading } = this.state;
        const { leaveApprovalList } = this.props;
        return (
            <div>
                <LoaderIndicator loading={loading} />
                <div className='h-72vh scroll-auto pl-4 pr-4'>
                    <div className='d-flex flex-direction-row justify-content-between'>
                        <div className='d-flex flex-direction-row'>
                            <div className='purple-color performance-timeline-text-circle ml-1 mt-3'>{'Note:'}</div>
                            <div className='welcomomepopupdescription ml-1'>
                                {CurrentYearNote}
                            </div>
                        </div>
                        <div className='row'>
                            {leaveApprovalList.length > 0 ? (
                                <>
                                <ExcelFile filename="Reportee Leave Quota Reports"
                                    element={
                                        <div className='d-flex justify-content-end p-2'>
                                            <CommonButton
                                                label="DOWNLOAD SUMMARY REPORT"
                                                isGradientBtn={true}
                                            />
                                        </div>
                                    }>
                                    <ExcelSheet
                                        data={leaveApprovalList}
                                        name="Leave Quota Report"
                                    >
                                        <ExcelColumn label="EMPLOYEE ID" value="employee_id" />
                                        <ExcelColumn label="EMPLOYEE NAME" value="employee_name" />
                                        <ExcelColumn
                                            label="DATE OF JOINING"
                                            value={row => moment(row.doj).format('DD-MM-YYYY')}
                                        />
                                        <ExcelColumn label="PL QUOTA" value="pl_quota" />
                                        <ExcelColumn label="PL AVAILED" value="pl_quota_availed" />
                                        <ExcelColumn label="SL+CL QUOTA" value="sl_and_cl_quota" />
                                        <ExcelColumn label="SL+CL AVAILED" value="sl_and_cl_quota_availed" />
                                        <ExcelColumn label="CH QUOTA" value="ch_quota" />
                                        <ExcelColumn label="CH AVAILED" value="ch_quota_availed" />
                                        {/* <ExcelColumn label="CL QUOTA" value="cl_quota" /> */}
                                        {/* <ExcelColumn label="CL AVAILED" value="cl_quota_availed" /> */}
                                        {/* <ExcelColumn label="SL QUOTA" value="sl_quota" /> */}
                                        {/* <ExcelColumn label="SL AVAILED" value="sl_quota_availed" /> */}
                                    </ExcelSheet>
                                </ExcelFile>
                                    <div className='p-2' style={{ padding: '0.4rem' }}>
                                        <input
                                            type='button'
                                            value='EXPORT ANNUAL REPORT'
                                            className='btn buttonStyle ml-2'
                                            style={{ padding: '0.4rem' }}
                                            onClick={() => this.downloadYearlyReport()}
                                        />
                                    </div>
                                </>) : null
                            }
                            {/* {leaveApprovalList.length > 0 &&
                                <div className='p-2' style={{ padding: '0.4rem' }}>
                                    <input type='button'
                                        value='EXPORT YEARLY REPORT'
                                        className='btn buttonStyle ml-2'
                                        style={{ padding: "0.4rem" }}
                                        onClick={() => { this.downloadYearlyReport() }}
                                    />
                                </div>
                            } */}
                        </div>
                    </div>

                    {leaveApprovalList.length != 0 ?
                        <table className="table">
                            <thead className=''>
                                <tr>
                                    <th className='table-header' scope="col"><div className='thead pr-0'>{'Sr.No'}</div></th>
                                    <th className='table-header' scope="col"><div className='thead'>{'Employee Name'}</div></th>
                                    <th className='table-header' scope="col"><div className='thead'>{'Employee ID'}</div></th>
                                    <th className='table-header' scope="col"><div className='thead' >{'DOJ'}</div></th>
                                    {/* <th className='table-header' scope="col"><div className='thead' >{'CL Quota '}</div></th> */}
                                    <th className='table-header' scope="col"><div className='thead'>{'PL Quota'}</div></th>
                                    <th className='table-header' scope="col"><div className='thead'>{'PL Availed'}</div></th>
                                    <th className='table-header' scope="col"><div className='thead' >{'SL+CL Quota'}</div></th>
                                    <th className='table-header' scope="col"><div className='thead' >{'SL+CL Availed'}</div></th>
                                    <th className='table-header' scope="col"><div className='thead' >{'CH Quota'}</div></th>
                                    {/* <th className='table-header' scope="col"><div className='thead' >{'Choice Hoilday Quota'}</div></th> */}
                                    <th className='table-header' scope="col"><div className='thead' >{'CH Availed'}</div></th>
                                    {/* <th className='table-header' scope="col"><div className='thead' >{'Choice Hoilday Availed'}</div></th> */}
                                    {/* <th className='table-header' scope="col"><div className='thead'>{'CL Availed'} </div></th> */}
                                    {/* <th className='table-header' scope="col"><div className='thead'>{'SL Quota'}</div></th> */}
                                    {/* <th className='table-header' scope="col"><div className='thead'>{'SL Availed'}</div></th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {leaveApprovalList.map((listValue, index) => {
                                    return (
                                        <tr key={index}>
                                            <td><div className="td table-data-font">{index + 1}</div></td>
                                            <td><div className="td table-data-font" >{(listValue.employee_name ? listValue.employee_name : '-')}</div></td>
                                            <td><div className="td table-data-font">{(listValue.employee_id ? listValue.employee_id : '-')}</div></td>
                                            <td> <div className="td table-data-font">{(listValue.doj ? moment(listValue.doj).format("DD-MM-YYYY") : '-')}</div> </td>
                                            {/* <td><div className="td table-data-font" >{(listValue.cl_quota ? listValue.cl_quota : '-')}</div></td> */}
                                            <td><div className="td table-data-font">{(listValue.pl_quota ? listValue.pl_quota : '-')}</div></td>
                                            <td><div className="td table-data-font">{(listValue.pl_quota_availed ? listValue.pl_quota_availed : '-')}</div></td>
                                            <td><div className="td table-data-font" >{(listValue.sl_and_cl_quota ? listValue.sl_and_cl_quota : '-')}</div></td>
                                            <td><div className="td table-data-font" >{(listValue.sl_and_cl_quota_availed ? listValue.sl_and_cl_quota_availed : '-')}</div></td>
                                            <td><div className="td table-data-font" >{(listValue.ch_quota ? listValue.ch_quota : '-')}</div></td>
                                            <td><div className="td table-data-font" >{(listValue.ch_quota_availed ? listValue.ch_quota_availed : '-')}</div></td>
                                            {/* <td><div className="td table-data-font" >{(listValue.cl_quota_availed ? listValue.cl_quota_availed : '-')}</div></td> */}
                                            {/* <td><div className="td table-data-font">{(listValue.sl_quota ? listValue.sl_quota : '-')}</div></td> */}
                                            {/* <td><div className="td table-data-font">{(listValue.sl_quota_availed ? listValue.sl_quota_availed : '-')}</div></td> */}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        :
                        <div className='row'>
                            <div className='col-md-12 d-flex align-items-center flex-column' style={{ top: '10px' }}>
                                <div>
                                    <img src={NodataApproval} alt="Nodata" style={{ "height": "6.25rem" }} />
                                </div>
                                <div>
                                    <label className='card-content-title grey-color'>{'You have taken care of all the approvals!'}</label>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}


const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState
    }
}

export default connect(mapStatesToProps)(ReporteeLeaveQuota);