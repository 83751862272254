import React, { Component } from 'react';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'
// import radioOff from '../../../../../assets/drishti_images/radioOff.png';
import radioOff from '../../../../assets/drishti_images/radioOff.png'
import radioOn from '../../../../assets/drishti_images/radioOn.png'
import Dropdown from '../../../shared/components/Dropdown'
import DataNotFound from '../../../shared/notFound/DataNotFound'
import moment from 'moment';
import approve from '../../../../assets/drishti_images/manager/landing/approve.png';
import reject from '../../../../assets/drishti_images/manager/landing/reject.svg';
import { fetchApiCall, operativeSessionOut, sessionOut } from '../../../../constants/Service';
import * as Config from '../../../../constants/Config';
import { toast } from 'react-toastify';
import ConfirmPopup from '../ConfirmPopup'
import reject_button from '../../../../assets/drishti_images/manager/approval/reject_button.svg'
import approve_button from '../../../../assets/drishti_images/manager/approval/approve_button.svg'
import exportBtn from '../../../../assets/images/icons8-excel-50.png';
import filterIconActive from '../../../../assets/drishti_images/admin/filterIconActive.svg';
import filterIconInactive from '../../../../assets/drishti_images/admin/filterIconInactive.svg';
import searchIcon from '../../../../assets/drishti_images/admin/searchIcon.svg';
import DateFilter from '../../adminScreens/clearance/DateFilter';
import { exportToExcel, toaster } from '../../../../utils/Utils';
import { OverlayTrigger, Pagination, Tooltip } from 'react-bootstrap';

const requestFailed = 'Unable to contact server\n\tPlease retry after sometime';
class TraineeLeaveApproval extends Component {
    constructor(props) {
        super(props);
        const today = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD
        const previousDate = new Date();
        previousDate.setDate(previousDate.getDate() - 1);
        const fromDate = previousDate.toISOString().split('T')[0]; // Format: YYYY-MM-DD
        this.state = {
            show: false,
            isLeaveApprovalActive: true,
            loading: false,
            leaveApprovalList: [],
            actionCode: '',
            isApprove: false,
            isReject: false,
            selectedData: [],
            selectAllCheckBox: false,
            showApproveReject: false,
            leaveRequestStatus: [],
            leaveRequestTypes: [],
            leaveRequestEmpId: [],
            tempHideShowArray: [],
            leaveCatCode: 'All',
            leaveStatusCode: '',
            leaveNameCode: 'All',
            leaveCatValue: 'All Leave Type',
            leaveStatusValue: '',
            leaveNameValue: 'All Employees',
            checked:0,
            listType: '',
            radioFilters: [
                { id: 1, title: 'Leave Approval', },
                 { id: 2, title: 'Delete Leave Approval', },
                 { id: 3, title: 'Report', }
                ],
            deleteLeaveApprovalList:[],
            Approve_leave_status_code:[0],
            delete_leave_status_code:[3,6],
            isFilter: false,
            fromDate: fromDate,
            toDate:today,
            empID:'',  
            searchPlaceholder : "Search",
            page: 1,
            count:[],
            export:false,
            approverleaveRequestStatus:[],
            deleteleaveRequestStatus:[]
     
        }
    }
    componentDidMount() {
        this.getLeaveRequestMasterData()
    }
    
    componentDidUpdate(prevProps) {
        if (this.props.isTraineeLeaveApproveActive && this.props.isTraineeLeaveApproveActive !== prevProps.isTraineeLeaveApproveActive) {
            this.onPresentType(0, 'Leave Approval');
        }
    }

    getCurrentStatusName(statusCode) {
        var tempStatusName = this.state.leaveRequestStatus.find(status => status.code == statusCode);
        if (tempStatusName) {
            this.setState({
                leaveStatusCode: tempStatusName.code,
                leaveStatusValue: tempStatusName.value
            }, () =>{
                this.getTraineeLeaveListingData(this.props.loginData.userData.Perno, this.state.leaveCatCode, this.state.leaveStatusCode, this.state.empID, 'emp_id',this.state.page)
            }
            )
        } else {
            return null
        }
    }
    getLeaveRequestMasterData() {
        this.setState({ loading: true })
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.trl_getLeaveRequestMasterData, {
            pa: this.props.loginData?.userData?.PersArea ? this.props.loginData.userData.PersArea:  "",
            psa: this.props.loginData?.userData?.PSubarea ? this.props.loginData.userData.PSubarea : "",
            emp_id: this.props.loginData?.userData?.Perno ? this.props.loginData.userData.Perno : '',
            eg: this.props.loginData?.userData?.Egroup ? this.props.loginData.userData.Egroup : '',
            esg: this.props.loginData?.userData?.Esubgroup ? this.props.loginData.userData.Esubgroup : '',
        }, headers, false, '')
            .then(res => {
                operativeSessionOut(this.props, res,"");
                if (res?.data?.status_code == 200) {
                    this.setState({
                        leaveRequestTypes: res?.data?.data?.leave_category_values_supervisor ? res?.data?.data?.leave_category_values_supervisor:[],
                        leaveRequestStatus: res?.data?.data?.leave_status ? res?.data?.data?.leave_status:[],
                        leaveRequestEmpId: res?.data?.data?.emp_id ? res?.data?.data?.emp_id : [],
                        approverleaveRequestStatus:res?.data?.data?.leave_approval_status ? res?.data?.data?.leave_approval_status:[],
                        deleteleaveRequestStatus:res?.data?.data?.delete_leave_approval_status ? res?.data?.data?.delete_leave_approval_status :[],
                        loading: false
                    });
                    let tempLeaveTypeData = [];
                    if (this.props.loginData.userData.Gender == 'M') {
                        tempLeaveTypeData = this.state.leaveRequestTypes.filter(item => item.code !== "D0");
                    } 
                    else if (this.props.loginData.userData.Gender == 'F') {
                        tempLeaveTypeData = this.state.leaveRequestTypes.filter(item => item.code !== "D3");
                    }
                    this.setState({
                        leaveRequestTypes: tempLeaveTypeData
                    });
                    let temp = {
                        value: 'All',
                        code: 'All'
                    }
                    let tempEmpID = {
                        value: 'All Employees',
                        code: 'All'
                    }
                    this.state.leaveRequestTypes.unshift(temp);
                    this.state.leaveRequestStatus.unshift(temp);
                    this.state.deleteleaveRequestStatus.unshift(temp);
                    this.state.approverleaveRequestStatus.unshift(temp);
                    this.getCurrentStatusName(0)

                    if (this.state.leaveRequestEmpId.length != 0) {
                        this.state.leaveRequestEmpId.unshift(tempEmpID)
                    } 
                    else {
                        this.state.leaveRequestEmpId.unshift({ value: 'No data found', code: '' })
                    }
                }
                else { this.setState({ loading: false }) }

            }).catch(err => {
                this.setState({ loading: false })
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }
    getTraineeLeaveListingData(employeeId, leaveData, statusData, empIdData, type,currentPage) {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        
        let body = {
            // emp_id: employeeId,
            // approval_type: this.state.checked == 0 ? 'Leave Request': this.state.checked==1 ? 'Delete Leave Request' :'Leave Request',
            leave_category_code: leaveData,
            leave_status_code: statusData,
            page_number:currentPage,
        }

        if (this.state.checked ==  0||this.state.checked ==  1) body['approver_emp_id'] = this.props.loginData.userData.Perno
        if (this.state.checked ==  0 || this.state.checked==1) body['approval_type'] = this.state.checked == 0 ? 'Leave Request': this.state.checked==1 ? 'Delete Leave Request' :'Leave Request'
        if (this.state.checked ==  0 || this.state.checked==1) body['emp_id'] = empIdData == '' ? '' : empIdData
        if (this.state.checked ==  2) body['from_date'] = this.state.fromDate
        if (this.state.checked ==  2) body['to_date'] = this.state.toDate
        if (this.state.checked ==  2) body['supervisor_emp_id'] = this.props.loginData.userData.Perno
        if (this.state.checked ==  2) body['is_export'] = this.state.export
        if (this.state.checked ==  2) body['emp_id'] =  empIdData == '' ? '' : empIdData

        let apiType = this.state.checked==2 ? Config.trl_exportLeaveRequestsApproval : Config.trl_getLeaveApproval_v1;
        fetchApiCall(apiType, body, headers, false, '')
            .then(res => {
                // sessionOut(this.props, res);
                operativeSessionOut(this.props, res,"");
                if (res?.data?.status_code == 200) {
                    if (body.is_export == true && res.status == "200") {
                        toaster("success", res?.data?.message,()=>{
                        })
                    }
                    let totalPage = Array.from({ length: res?.data?.data?.total_pages }, (_, i) => i + 1)
                    this.setState({
                        leaveApprovalList: res?.data?.data?.leave_requests_approval ? res?.data?.data?.leave_requests_approval : [],
                        loading: false,
                        count: totalPage ? totalPage : []
                    });
                    const tempApprovalList = this.state.leaveApprovalList.map(tempValue => ({ ...tempValue, isSelected: false, tempSelect: false }))
                    this.setState({
                        leaveApprovalList: tempApprovalList,
                        tempHideShowArray: [],
                        export:false
                    });
                } else {
                    this.setState({
                        loading: false,
                        count:[],
                        leaveApprovalList:[],
                        export:false
                    });
                    toaster("warning", res?.data?.message)
                }
            }).catch(err => {
                console.log('err', err);
                this.setState({
                    loading: false
                })
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }

    approveRequest = (action) => {
        this.setState({
            loading: true
        });
        let temp = this.state.tempHideShowArray;
        const ids = [];
        let result = temp.filter(item => ids.includes(item.leave_request_id) ? false : ids.push(item.leave_request_id));
        const req = {
            emp_id: this.props.loginData.userData.Perno,
            data: result,
            action: action,
            approval_type: this.state.checked == 0 ? 'Leave Request' : 'Delete Leave Request',
        }
        const headers = {
            'Content-Type': 'application/json',
        }

        let apiType = this.state.checked == 0 ? Config.trl_approveLeaveRequest : Config.trl_deleteLeaveApproval;
        fetchApiCall(apiType, req, headers, false, '')
            .then(res => {
                // sessionOut(this.props, res);
                operativeSessionOut(this.props, res,"");
                if (res?.data?.status_code === '200') {
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.setState({
                        loading: false,
                        tempHideShowArray: [],
                        selectAllCheckBox: false,
                        selectAllCheckBoxDelete: false,
                        isApprove: false,
                        isReject: false,

                    });
                    this.state.checked == 0 ? this.getCurrentStatusName(0) : this.getCurrentStatusName(5)
                }
                else {
                    toast.error(res?.data?.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                    this.setState({
                        popupVisibility: false,
                        loading: false,
                    });
                }
            }).catch(err => {
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
                this.setState({
                    loading: false
                });
            })
    }

    changeCatDropdown = (listValue) => {
        this.setState({
            leaveCatCode: listValue.code,
            leaveCatValue: listValue.value,
            empID:'',
        }, () => {
            this.getTraineeLeaveListingData(this.props.loginData.userData.Perno, this.state.leaveCatCode, this.state.leaveStatusCode,this.state.empID, 'leave_type',this.state.page)
        }
        )
    }

    changeStatusDropdown = (listValue) => {
        this.setState({
            leaveStatusCode: listValue.code,
            leaveStatusValue: listValue.value,
            empID:'',
        }, () => {
            this.getTraineeLeaveListingData(this.props.loginData.userData.Perno, this.state.leaveCatCode, this.state.leaveStatusCode, this.state.empID, 'leave_status',this.state.page)
        }
        )
    }

    // changeEmpDropdown = (listValue) => {
    //     this.setState({
    //         leaveNameCode: listValue.code,
    //         leaveNameValue: listValue.value
    //     }, () => {
    //         this.getTraineeLeaveListingData(this.props.loginData.userData.Perno, this.state.leaveCatCode, this.state.leaveStatusCode, this.state.leaveNameCode, 'emp_id')
    //     }
    //     )
    // }

    toggleAction = (type, data) => {
        if (data) {
            this.setState({ selectedData: data, actionValue: type })
            // this.selectItem(data)
            if (!data?.isSelected) {
                this.selectItem(data);
            }
            if (type == 'approve') {
                this.setState({ actionCode: '0', isApprove: true })
            }
            else {
                this.setState({ actionCode: '1', isReject: true })
            }
            let temp = [{
                leave_request_id: data.leave_request_id,
                requestor_emp_id: data.requestor_emp_id
            }]
            this.setState({ tempHideShowArray: temp })
        }
        else {
            if (type == 'approve') {
                this.setState({ actionCode: '0', isApprove: true, actionValue: type })
            }
            else {
                this.setState({ actionCode: '1', isReject: true, actionValue: type })
            }
        }
    }

    selectItem = data => {
        data.isSelected = !data.isSelected;
        const index = this.state.leaveApprovalList.findIndex(
            item => item.leave_request_id === data.leave_request_id
        );

        this.state.leaveApprovalList[index] = data;

        if (data.isSelected) {
            let temp = {
                leave_request_id: data.leave_request_id,
                requestor_emp_id: data.requestor_emp_id
            }
            this.setState({
                showApproveReject: true
            })
            this.state.tempHideShowArray.push(temp)
        } 
        else {
            this.state.tempHideShowArray.splice(this.state.tempHideShowArray.findIndex(item => item.leave_request_id === data.leave_request_id), 1)
            if (this.state.tempHideShowArray.length === 0) {
                this.setState({
                    tempHideShowArray: [],
                    selectAllCheckBox: false,
                    showApproveReject: false
                })
            }
        }

        var tempList = [];
        tempList = this.state.leaveApprovalList.filter((leave) => leave.leave_status_code === 0)

        if (tempList.length === this.state.tempHideShowArray.length) {
            this.setState({
                selectAllCheckBox: true,
            })
        } 
        else {
            this.setState({
                selectAllCheckBox: false,
            })
        }
        this.setState({
            leaveApprovalList: this.state.leaveApprovalList,
        });
    }

    selectAllCheckBox = () => {
        this.setState({
            tempHideShowArray: [],
            selectAllCheckBox: !this.state.selectAllCheckBox,
        });
        if (!this.state.selectAllCheckBox) {
            this.state.leaveApprovalList.forEach((data) => {
                if (data.leave_status_code == 0||data.leave_status_code == 5) {
                    data.isSelected = true;
                    this.setState({
                        showApproveReject: true,
                    })

                    let temp = {
                        leave_request_id: data.leave_request_id,
                        requestor_emp_id: data.requestor_emp_id
                    }
                    this.state.tempHideShowArray.push(temp)
                    let tempselect = this.state.tempHideShowArray;
                    const ids = [];
                    let result = tempselect.filter(item => ids.includes(item.leave_request_id) ? false : ids.push(item.leave_request_id));
                    this.setState({ tempHideShowArray: result })
                }
            })
        } else {
            this.state.leaveApprovalList.forEach((data) => {
                data.isSelected = false;
                this.setState({
                    showApproveReject: false
                })
            })
            while (this.state.tempHideShowArray.length) {
                this.state.tempHideShowArray.pop();
            }
        }
    }   

    onPresentType = (key, type) => {
        const today = new Date();
        const previousDate = new Date(today);
        previousDate.setDate(today.getDate() - 1); 
        this.setState({
            leaveStatusValue:'',
            leaveNameValue:'All Employees',
            leaveCatValue:'All Leave Type',
            selectAllCheckBox:false,
            isFilter:false,
            leaveCatCode: 'All',
            fromDate: previousDate.toISOString().split('T')[0],
            toDate: today.toISOString().split('T')[0],
            empID:'',
            tempHideShowArray:[],
            page:1
        })
        if(type=='Leave Approval'){
            this.setState({
                checked: key,
                tempHideShowArray: [],
                leaveNameCode: 'All'
            })
            this.getCurrentStatusName(0)
        }
        if(type=='Delete Leave Approval'){
            this.setState({
                checked: key,
                tempHideShowArray: [],
                leaveNameCode: 'All'
            })
                this.getCurrentStatusName(5)
        }        
        if(type=='Report'){
            this.setState({
                checked: key,
                tempHideShowArray: [],
                leaveNameCode:''
            })
                this.getCurrentStatusName('All')
        }
    }

      onClickFilter = () => {
        this.setState({
            isFilter: !this.state.isFilter,
        })
    }
    searchFilterFunction=(e)=>{

        const { empID } = this.state;
        if (empID.length > 0 && empID.length < 6) {
            toaster("warning", 'Employee ID must be between 6 and 8 digits');
        } else{
            this.setState({page:1},()=>this.getTraineeLeaveListingData(this.props.loginData.userData.Perno, this.state.leaveCatCode, this.state.leaveStatusCode, this.state.empID, 'emp_id',this.state.page))
        }
    }

    downloadReport = async() =>{
    this.setState({export:true},()=>{
        this.getTraineeLeaveListingData(this.props.loginData.userData.Perno, this.state.leaveCatCode, this.state.leaveStatusCode,this.state.empID, 'emp_id',this.state.page)
    })
    }

    handlePageCount = (type) => {
        let pageCountInc = this.state.page + 1
        let pageCountDec = this.state.page - 1
        if (this.props.isTraineeLeaveApproveActive) {
            if (type === "increment") {
                if (pageCountInc <= this.state.count[this.state.count.length - 1]) {
                    this.setState({ page: pageCountInc }, () => this.getTraineeLeaveListingData(this.props.loginData.userData.Perno, this.state.leaveCatCode, this.state.leaveStatusCode, this.state.empID, 'emp_id',this.state.page)
                )
                }
            }
            else if (type === "decrement") {
                if (this.state.page > 1) { this.setState({ page: pageCountDec }, () => this.getTraineeLeaveListingData(this.props.loginData.userData.Perno, this.state.leaveCatCode, this.state.leaveStatusCode,this.state.empID, 'emp_id',this.state.page)
                ) }
            }
        }
    }

    selectedPage = (pageCount) => {
        if (this.props.isTraineeLeaveApproveActive) {
            this.setState({ page: pageCount }, () => this.getTraineeLeaveListingData(this.props.loginData.userData.Perno, this.state.leaveCatCode, this.state.leaveStatusCode, this.state.empID, 'emp_id',this.state.page))
        } 
    }
     DynamicTooltip = value => (
        <Tooltip {...value}>
          <div className="tool-tip-font">{value}</div>
        </Tooltip>
      );
       handleEmpChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,8}$/.test(value)) {
            this.setState({ empID: value })
        }
      }
    render() {
        const { isApprove, isReject, leaveApprovalList, leaveStatusCode } = this.state
        const today = new Date().toISOString().split('T')[0];
        return (
            <div>
                <LoaderIndicator loading={this.state.loading} />
                <div className='row mt-12px '>
                    <div className='row justify-content-between align-item-center px-3 col-md-12 mb-1'>
                    <div className='d-flex row justify-content-start align-item-center'>
                        <div className={this.state.isFilter && this.state.checked == 2 ?' ml-4 bold-font':'status-dashboard ml-4'}>{this.state.isFilter && this.state.checked == 2 ? 'Report' :'Status :'}</div>
                        {
                            !this.state.isFilter && 
                            <>
                                        {this.state.radioFilters.length != 0 && this.state.radioFilters.map((list, key) => {
                                            return (
                                                <div key={key} className='mx-3'>
                                                    {this.state.checked === key  ?
                                                        <div onClick={() => this.onPresentType(key, list.title)}>
                                                            <img alt="radioOn" className='radio' src={radioOn} />
                                                            <label className="radioTxt" >{list.title}</label>
                                                        </div>
                                                        :
                                                        <div onClick={() => this.onPresentType(key, list.title)}>
                                                            <img alt="radioOff" className='radio' src={radioOff} />
                                                            <label className="radioTxt">{list.title}</label>
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        })
                                        }
                            </>
                        }
                        </div>
                        <div className='d-flex row'>
                            {this.state.checked == 2 && this.state.isFilter &&
                                <>
                                    {/* <div className='status-dashboard mr-5 align-content-center mb-5px'>{'Report :'}</div> */}
                                    <div>
                                        <DateFilter
                                            title="From Date"
                                            action={(val) => {
                                                if (new Date(val) > new Date(this.state.toDate)) {
                                                    const messg='From Date cannot be greater than To Date. Please adjust To Date.';
                                                    toast.error(messg, {
                                                        position: "bottom-center",
                                                        hideProgressBar: true,
                                                        className: 'toastError'
                                                    })
                                                } else {
                                                    this.setState({ fromDate: val, empID: '' }, () => {
                                                        this.getTraineeLeaveListingData(this.props.loginData.userData.Perno, this.state.leaveCatCode, this.state.leaveStatusCode, this.state.empID, 'emp_id',this.state.page)
                                                    });
                                                }
                                            }
                                            }
                                            value={this.state.fromDate}
                                            // maxDate={today} 
                                        />
                                    </div>
                                    <div className='mr-2'>
                                        <DateFilter
                                            title="To Date"
                                            action={(val) => {
                                                this.setState({ toDate: val,empID:''}, () => {
                                                    this.getTraineeLeaveListingData(this.props.loginData.userData.Perno, this.state.leaveCatCode, this.state.leaveStatusCode,this.state.empID, 'emp_id',this.state.page)
                                                })
                                            }}
                                            value={this.state.toDate}
                                            minDate={this.state.fromDate}
                                            // maxDate={today} 
                                        />
                                    </div>
                                </>
                            }
                            {/* <div>
                                <Dropdown
                                    data={this.state.leaveRequestEmpId}
                                    selectedValue={(data) => this.changeEmpDropdown(data)}
                                    value={this.state.leaveNameValue}
                                    render={'value'}
                                    arrow={'true'}
                                    height={'height'}
                                />
                            </div> */}
                            <div className='row'>
                                <div className='p-placeholder-text mb-1 d-flex ml-2'>
                                    <input
                                        className="form-control p-placeholder px-2 p-placeholder-admin"
                                        type="text"
                                        placeholder='Enter Emp ID'
                                        onChange={(e) => this.handleEmpChange(e)}
                                        value={this.state.empID}
                                        maxLength={25}
                                    />
                                    <img alt="radioOn" className='empolye_search_input filterIcon' src={searchIcon} onClick={(e) => this.searchFilterFunction(e)} />
                                </div>
                            </div>
                            {/* <div className='ml-2'></div> */}
                            <Dropdown
                                data={this.state.leaveRequestTypes}
                                selectedValue={(data) => this.changeCatDropdown(data)}
                                value={this.state.leaveCatValue}
                                render={'value'}
                                arrow={'true'}
                                height={'height'}
                            />
                            <div className='ml-2 w-17rem'>
                                <Dropdown
                                    data={this.state.checked==0 ? this.state.approverleaveRequestStatus:this.state.checked==1 ? this.state.deleteleaveRequestStatus:this.state.leaveRequestStatus}
                                    selectedValue={(data) => this.changeStatusDropdown(data)}
                                    value={this.state.leaveStatusValue}
                                    render={'value'}
                                    arrow={'true'}
                                    height={'height'}
                                />
                            </div>
                            {this.state.checked == 2 &&
                                <>
                                    <div className='mr-2 ml-1 align-content-center'>
                                        <input
                                            type="button"
                                            className='ml-2  validateBtn'
                                            value={this.state.isFilter ? 'BACK' : "FILTER"}
                                            onClick={(e) => this.onClickFilter(e)}
                                            style={{ height: '75%', cursor: 'pointer', marginBottom: '5px' }}
                                        />
                                    </div>
                                    <OverlayTrigger placement="top" overlay={this.DynamicTooltip('Export Details')}>
                                        <div className=" ml-1" style={{ marginTop: '5px' }}>
                                            <img src={exportBtn} onClick={this.downloadReport} style={{ height: '28px', width: '28px' }} />
                                        </div>
                                    </OverlayTrigger>
                                </>
                            }
                        </div>
                    </div>
                    {leaveApprovalList.length != 0 ?
                        <>
                            <div className='col-md-12 flex-direction-column scroller-managerApproval'>
                                <div className='row scroll-y w-100'>
                                    <table className="table gemsAdmin">
                                        <thead className='w-100'>
                                            <tr className='w-100'>
                                                {this.state.checked!==2 &&
                                                    <th scope="col">
                                                    <div className='thead white-space-no-wrap'>
                                                        {(this.state.checked == 0 && leaveStatusCode == 0 ) || (this.state.checked == 1 && leaveStatusCode == 5) ?
                                                            <label
                                                            // className="checkBoxData"
                                                            >
                                                                <input type="checkbox"
                                                                    // className='hidecheckBox'
                                                                    onChange={() => this.selectAllCheckBox()}
                                                                    // checked={this.state.selectAllCheckBox}
                                                                    checked={leaveApprovalList.length == this.state.tempHideShowArray.length}
                                                                    disabled={this.state.leaveApprovalList == [] || this.state.leaveApprovalList == '' ? true : false}
                                                                />
                                                                {/* <span className="markk"></span> */}
                                                            </label> :
                                                            <label
                                                            // className="checkBoxData"
                                                            >
                                                                <input type="checkbox"
                                                                    // className='hidecheckBox'
                                                                    disabled={true}
                                                                    checked={false}
                                                                />
                                                                {/* <span className="markk"></span> */}
                                                            </label>
                                                        }
                                                    </div>
                                                </th>}
                                                <th scope="col"><div className='thead white-space-no-wrap'>Employee ID</div></th>
                                                <th scope="col"><div className='thead white-space-no-wrap'>Employee Name</div></th>
                                                <th scope="col"><div className='thead white-space-no-wrap'>Leave Category</div></th>
                                                <th scope="col"><div className='thead white-space-no-wrap'>Leave Type</div></th>
                                                <th scope="col"><div className='thead white-space-no-wrap'>From</div></th>
                                                <th scope="col"><div className='thead white-space-no-wrap'>To</div></th>
                                                <th scope="col"><div className='thead white-space-no-wrap'>Status</div></th>
                                                <th scope="col"><div className='thead white-space-no-wrap'>Reason</div></th>
                                                {this.state.checked==2 && <th scope="col"><div className='thead white-space-no-wrap'>Approver ID</div></th>}
                                                {this.state.checked==2 && <th scope="col"><div className='thead white-space-no-wrap'>Approver Name</div></th>}
                                                {this.state.checked!==2 && <th scope="col"><div className='thead white-space-no-wrap'>Action</div></th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {leaveApprovalList.map((listValue, index) => {
                                                return (
                                                    <tr key={index} >
                                                        {this.state.checked!==2 &&
                                                            <td><div>
                                                            {
                                                                ((this.state.checked == 0 && leaveStatusCode==0) || (this.state.checked == 1 && leaveStatusCode==5))
                                                                ?
                                                                <label
                                                                // className="checkBoxData"
                                                                >
                                                                    <input type="checkbox"
                                                                        // className='hidecheckBox'
                                                                        onChange={() => this.selectItem(listValue)}
                                                                        checked={listValue.isSelected} />
                                                                    {/* <span class="markk"></span> */}
                                                                </label>:
                                                                <label
                                                                // className="checkBoxData"
                                                                >
                                                                    <input type="checkbox"
                                                                        // className='hidecheckBox'
                                                                        disabled={true}
                                                                    />
                                                                    {/* <span className="markk"></span> */}
                                                                </label> 
                                                            }
                                                        </div>
                                                        </td>}
                                                        <td><div className="td" style={{ width: 20 }}>{listValue.requestor_emp_id}</div></td>
                                                        <td><div className="td">{listValue.requestor_name ? listValue.requestor_name : ''}</div></td>
                                                        <td><div className="td">{listValue.leave_category ? listValue.leave_category : ''}</div></td>
                                                        <td> <div className="td d-flex">{listValue.leave_type ? listValue.leave_type : ''}</div> </td>
                                                        <td><div className="td" >{moment(listValue.from_date).format("DD-MM-YYYY")}</div></td>
                                                        <td><div className="td" >{moment(listValue.to_date).format("DD-MM-YYYY")}</div></td>
                                                        <td><div className="td" style={{ color: listValue?.leave_status_color}}>{listValue.leave_status ? listValue.leave_status : ''}</div></td>
                                                        <td><div className="td" style={{ maxWidth: '8rem', wordWrap: 'break-word', whiteSpace: 'normal' }}>{listValue.reason}</div></td>
                                                        {this.state.checked==2 && <td><div className="td">{listValue?.approver_emp_id}</div></td>}
                                                        {this.state.checked==2 && <td><div className="td" style={{ maxWidth: '7rem', wordWrap: 'break-word', whiteSpace: 'normal' }}>{listValue?.approver_emp_name?listValue.approver_emp_name:'---' }</div></td>}
                                                       {this.state.checked!==2 && 
                                                       <>
                                                       {(this.state.tempHideShowArray.length<=1) &&  (this.state.checked == 0 && leaveStatusCode == 0 ) || (this.state.checked == 1 && leaveStatusCode == 5)?
                                                            <td className='d-flex'>
                                                                <div onClick={() => this.toggleAction('approve', listValue)}>
                                                                    <img alt='approve' src={approve} style={{ width: '25px' }} className='mx-2' />
                                                                </div>
                                                                <div onClick={() => this.toggleAction('reject', listValue)}>
                                                                    <img alt="reject" src={reject} style={{ width: '25px' }} />
                                                                </div>
                                                            </td> :
                                                            <td className='d-flex'>
                                                                <div>{'-'}</div>
                                                            </td>
                                                        }
                                                        </>
                                                    }
                                                    </tr>
                                                )
                                            }
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className='col-md-12 flex-direction-column approveBottom occasion-bg-color  mb-3'>
                                {this.state.showApproveReject && this.state.tempHideShowArray.length > 1 ?
                                    <div className='row justify-content-between p-2'>
                                        <div>
                                            <span>
                                                {this.state.tempHideShowArray.length}
                                                {' '} of {''}
                                                {this.state.leaveApprovalList.length}
                                                {''} Selected
                                            </span>
                                        </div>
                                        <div className='row'>
                                            <div className='mx-2 pointer' onClick={() => this.toggleAction('reject')}>
                                                <img alt="reject" src={reject_button} />
                                            </div>
                                            <div className='pointer' onClick={() => this.toggleAction('approve')}>
                                                <img alt="approve" src={approve_button} />
                                            </div>
                                        </div>
                                    </div> : null}
                            </div>
                        </>
                        :
                        <div className='col-md-12 justify-content-center align-item-center h-60vh'>
                            <DataNotFound />
                        </div>
                    }
                    {/* // -----------------Pagination Model----------------------------// */}
                    {
                        <>
                            {this.state.leaveApprovalList?.length !== 0 &&  this.state.tempHideShowArray.length < 2 ?
                                <div className="col-md-12 w-100 d-flex justify-content-center">
                                        <Pagination>
                                            <Pagination.Prev
                                                onClick={() => this.handlePageCount("decrement")} />
                                            {this.state.count.length > 1 &&
                                                <Pagination.Item onClick={() => this.selectedPage(1)}>{1}</Pagination.Item>}<Pagination.Ellipsis />
                                            <Pagination.Item active>{this.state.page}</Pagination.Item><Pagination.Ellipsis />
                                            {this.state.count.length > 1 &&
                                                <Pagination.Item onClick={() => this.selectedPage(this.state.count[this.state.count.length - 1])}>{this.state.count[this.state.count.length - 1]}</Pagination.Item>}
                                            <Pagination.Next
                                                onClick={() => this.handlePageCount("increment")} />
                                        </Pagination>
                                </div> : null}
                        </>
                    }
                </div>
                {isReject &&
                    <ConfirmPopup
                        title={'Confirmation'}
                        action={this.state.actionValue}
                        content={'Are you sure to reject this leave?'}
                        rejectData={() => this.approveRequest(this.state.actionCode)}
                        onDismiss={() => {
                            const updatedLeaveApprovalList = this.state.leaveApprovalList?.map(item => {
                                if (item?.isSelected) {
                                    item.isSelected = false;
                                }
                                return item;
                            });
                            // this.setState({ isReject: false })
                            // this.selectItem(this.state.selectedData)
                            this.setState({ isReject: false,leaveApprovalList: updatedLeaveApprovalList,tempHideShowArray:[] })
                        }}
                    />
                }
                {isApprove &&
                    <ConfirmPopup
                        title={'Confirmation'}
                        action={this.state.actionValue}
                        content={'Are you sure to approve this leave?'}
                        approveData={() => this.approveRequest(this.state.actionCode)}
                        onDismiss={() => {
                            const updatedLeaveApprovalList = this.state.leaveApprovalList?.map(item => {
                                if (item?.isSelected) {
                                    item.isSelected = false;
                                }
                                return item;
                            });
                            // this.setState({ isApprove: false})
                            // this.selectItem(this.state.selectedData)
                            this.setState({ isApprove: false,leaveApprovalList: updatedLeaveApprovalList,tempHideShowArray:[] })
                        }}
                    />
                }

            </div>                          
        );
    }
}

export default TraineeLeaveApproval                 