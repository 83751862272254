import { fetchApiCallWithService, fetchApiCallWithServiceForReports } from './Service'
import { plantUpdateTypes, plantUpdates, logout, adminPlants, getClaimComponents, getClaimAttachments, getClearanceMaster, getClearanceRequest, getClearanceAgencyDetails, addClearanceRemark, clearanceAttachment, updateClearance, getClearanceStartRequests, postStartClearanceAction, employeeClearanceStatus, agencyWiseClearanceReport, downloadAgencyWise, nudgeForClearance, fAndFSettlementReport, isClearanceApplicable, getAgentmaster, getAgentConfig, getAgencyMaster, getAgencyConfig, getEsgGrpMapping, getClearancePAList, getClearancePSAList, getGradeGrpList, getAgencyList, Agentmaster, AgentConfig, AgencyMaster,AgencyMasterV1, AgencyConfig, EsgGrpMapping, getAgencySPOC, checkAgencyAgentConfig, checkAgencyConfig, clearanceRevoke, employeeExithandbook,getEmployeeAssetsDetails,suggestionSchemeBaseUrl, DuplicateAgentConfig, agencyWiseClearanceReportV2, clearanceReportMonths, fAndFSettlementReportV2, downloadEmpExitForm, getRelivingLetterDetail, getServiceCertDetail,exportMasterReport } from './Config'
import { commonHeadersAcceptJson, commonHeadersAcceptPDF } from '../../src/utils/Utils'
import { getApprovedConveyanceList, getClaimSummary, getClaimSummaryAttachmentFiles } from '../constants/Config'

export const getPlantUpdate = async (param, propsValue) => {
    const params = param
    return fetchApiCallWithService(plantUpdates, {}, commonHeadersAcceptJson, false, "admin", "GET", "admin", "", params)
};

export const deletePlantUpdate = (param, propsValue) => {
    const params = param
    return fetchApiCallWithService(plantUpdates + Object.values(params), {}, commonHeadersAcceptJson, false, "admin", "DELETE", "admin", "", param)
};

export const postPlantUpdate = (param, propsValue, body, plant_type, plant) => {
    const params = param
    return fetchApiCallWithService(plantUpdates, body, commonHeadersAcceptJson, false, "admin", "POST", "admin", "", params)
};

export const getPlantUpdateTypes = (param, propsValue) => {
    const params = param
    return fetchApiCallWithService(plantUpdateTypes, {}, commonHeadersAcceptJson, false, "admin", "GET", "admin", "", params)
};

export const getAdminPlants = (param, propsValue) => {
    return fetchApiCallWithService(`${adminPlants}`, {}, commonHeadersAcceptJson, false, "admin", "GET", 'admin', "", param)
};

export const getConveyanceList = (param, propsValue, status) => {
    return fetchApiCallWithService(`${getApprovedConveyanceList}claim_approval/${status}`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', "", param)
};

export const getExpenseList = (param, propsValue) => {
    return fetchApiCallWithService(`${getApprovedConveyanceList}claim_components`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', "", param)
};

export const getManagerList = (param) => {
    return fetchApiCallWithService(`${getApprovedConveyanceList}get_dem_reporting_details/`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', "", param)
};

export const getClaimHistoryList = (param, propsValue, status) => {
    return fetchApiCallWithService(`${getApprovedConveyanceList}claim_history/${status}`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', "", param)
};

export const getAttachmentList = (param, propsValue) => {
    return fetchApiCallWithService(`${getApprovedConveyanceList}claim_component_attachments`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', "", param)
};

export const patchClaimApproval = (body, propsValue, claim_id) => {
    return fetchApiCallWithService(`${getApprovedConveyanceList}claim_approval/test/${claim_id}`, body, commonHeadersAcceptJson, true, "accessToken", "PATCH", 'login', "", {})
};

export const checkapprovalstatus = (param, propsValue) => {
    return fetchApiCallWithService(`${getApprovedConveyanceList}does_claim_exist`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', "", param)
};

export const admincheckapprovalstatus = (param, propsValue) => {
    return fetchApiCallWithService(`${getApprovedConveyanceList}does_claim_exist`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', "", param)
};

export const getLocalConveyanceAllApiList = (param, endPoint, propsValue) => {
    return fetchApiCallWithService({ getApprovedConveyanceList } + { endPoint }, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', "", param)
};

export const getClaimSummaryAdmin = (param, propsValue) => {
    return fetchApiCallWithService(getClaimSummary, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', "", param)
};

export const getClaimSummaryAttachment = (param, propsValue) => {
    return fetchApiCallWithService(getClaimSummaryAttachmentFiles, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', "", param)
};

export const getComponentDetailsAdmin = (param, propsValue) => {
    return fetchApiCallWithService(getClaimComponents, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', "", param)
}
export const getClaimAttachmentsAdmin = (param, propsValue) => {
    return fetchApiCallWithService(getClaimAttachments, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', "", param)
}

export const getConveyanceListAdmin = (param, propsValue, status) => {
    return fetchApiCallWithService(`${getApprovedConveyanceList}claim_admin/${status}`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', "", param)
};

export const updateClaimAdmin = (body, propsValue, claimId) => {
    return fetchApiCallWithService(`${getApprovedConveyanceList}claim_admin/test/${claimId}`, body, commonHeadersAcceptJson, true, "admin", "PATCH", 'admin', "", {})
};

//----BDC-----------------
export const getClaimBDC = (param, propsValue, status) => {
    return fetchApiCallWithService(`${getApprovedConveyanceList}bdc_history`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', "", param)
};
export const getClaimBDCGenerate = (param, propsValue, status) => {
    return fetchApiCallWithService(`${getApprovedConveyanceList}claim_bdc`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', "", param)
};

export const claimHistoryCommonApiFun = (param, propsValue, endPoint, body) => {
    return fetchApiCallWithService(`${getApprovedConveyanceList}${endPoint}`, body, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', "", param)
};

export const claimHistoryDelete = (body, endPoint, param) => {
    return fetchApiCallWithService(`${getApprovedConveyanceList}${endPoint}`, body, commonHeadersAcceptJson, true, "accessToken", "DELETE", 'login', "", param)
};

export const claimSubmit = (body, endPoint, propsValue) => {
    return fetchApiCallWithService(`${getApprovedConveyanceList}${endPoint}`, body, commonHeadersAcceptJson, true, "accessToken", "POST", "login", propsValue, {})
}

export const doesClaimExistsApi = (param, propsValue) => {
    return fetchApiCallWithService(`${getApprovedConveyanceList}does_claim_exist`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", "login", propsValue, param)
}
export const updateAttachment = (body, method, propsValue, endPoint) => {
    return fetchApiCallWithService(`${getApprovedConveyanceList}${endPoint}`, body, commonHeadersAcceptJson, true, "accessToken", method, "login", propsValue, {})
}
export const getLocalConveyananceVisibleStatus = (propsValue) => {
    return fetchApiCallWithService(`${getApprovedConveyanceList}is_local_conveyance_applicable/`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", '', propsValue, '')
}
// export const fetchCityDetails = () => {
//     return fetchApiCallWithService(`${getApprovedConveyanceList}/city`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', "", param)
// };

// Admin Apis for Clearance 
export const getClearanceRequests = (params, propsValue) => {
    return fetchApiCallWithService(`${getClearanceRequest}`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', propsValue, params)
}
export const getClearanceRequestMaster = (params, propsValue) => {
    return fetchApiCallWithService(`${getClearanceMaster}`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', propsValue, params)
}
export const getAgencyWiseClearance = (propsValue, endPoint, params) => {
    return fetchApiCallWithService(`${getClearanceAgencyDetails}/${endPoint}`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', propsValue, params)
}

export const getAdminEmployeeAssetsDetail = (propsValue, params) => {
    return fetchApiCallWithService(`${getEmployeeAssetsDetails}/`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', propsValue, params)
}
export const getEmployeeAssetsDetail = (propsValue, params) => {
    return fetchApiCallWithService(`${getEmployeeAssetsDetails}/`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', propsValue, params)
}

export const getAgencyWiseClearanceEmployee = (propsValue, endPoint, params) => {
    return fetchApiCallWithService(`${getClearanceAgencyDetails}/${endPoint}`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', propsValue, params)
}
export const postClearanceComment = (propsValue, endPoint, body) => {
    return fetchApiCallWithService(`${addClearanceRemark}/${endPoint}`, body, commonHeadersAcceptJson, true, "admin", "POST", 'admin', propsValue, '')
}
export const addClearanceAttachment = (propsValue, body) => {
    return fetchApiCallWithService(`${clearanceAttachment}/`, body, commonHeadersAcceptJson, true, "admin", "POST", 'admin', propsValue, '')
}
export const deleteClearanceAttachment = (propsValue, body) => {
    return fetchApiCallWithService(`${clearanceAttachment}/`, body, commonHeadersAcceptJson, true, "admin", "DELETE", 'admin', propsValue, '')
}
export const updateClearanceStatus = (propsValue, endPoint, body) => {
    return fetchApiCallWithService(`${updateClearance}/${endPoint}`, body, commonHeadersAcceptJson, true, "admin", "PUT", 'admin', propsValue, '')
}
export const getStartClearanceRequests = (propsValue, params) => {
    return fetchApiCallWithService(`${getClearanceStartRequests}`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', propsValue, params)
}
export const startClearance = (propsValue, body) => {
    return fetchApiCallWithService(`${postStartClearanceAction}/`, body, commonHeadersAcceptJson, true, "admin", "POST", 'admin', propsValue, '')
}
export const fetchEmployeeClearance = (propsValue, params) => {
    return fetchApiCallWithService(`${employeeClearanceStatus}`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', propsValue, params)
}
export const postClearanceReminderEmployee = (propsValue, params) => {
    return fetchApiCallWithService(`${nudgeForClearance}`, {}, commonHeadersAcceptJson, true, "accessToken", "POST", 'login', propsValue, params)
}
export const getAgencyWiseClearanceReport = (params, propsValue) => {
    return fetchApiCallWithService(`${agencyWiseClearanceReportV2}`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', propsValue, params)
}
export const downloadAgencyWiseReport = (propsValue, clearanceId) => {
    return fetchApiCallWithService(`${downloadAgencyWise}${clearanceId}/report/employee`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', propsValue, {}, "blob")
}
export const getFAndFSettlementClearanceReport = (params, propsValue) => {
    return fetchApiCallWithService(`${fAndFSettlementReportV2}`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', propsValue, params)
}
export const getClearanceIsApplicable = (propsValue) => {
    return fetchApiCallWithService(`${isClearanceApplicable}`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', propsValue, {})
}
export const revokeClearance = (endPoint, propsValue) => {
    return fetchApiCallWithService(`${clearanceRevoke}/${endPoint}`, {}, commonHeadersAcceptJson, true, "admin", "POST", 'admin', propsValue, '')
}
// master APIs for clearance module
export const getSPOCMasterList = (params, propsValue) => {
    return fetchApiCallWithService(`${Agentmaster}`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', propsValue, params)
}
export const addSPOCMaster = (body, propsValue) => {
    return fetchApiCallWithService(`${Agentmaster}`, body, commonHeadersAcceptJson, true, "admin", "POST", 'admin', propsValue, '')
}
export const updateSPOCMaster = (body, id, propsValue) => {
    return fetchApiCallWithService(`${Agentmaster}${id}`, body, commonHeadersAcceptJson, true, "admin", "PUT", 'admin', propsValue, '')
}
export const deleteSPOCMaster = (endPoint, propsValue) => {
    return fetchApiCallWithService(`${Agentmaster}${endPoint}`, {}, commonHeadersAcceptJson, true, "admin", "DELETE", 'admin', propsValue, '')
}
export const getSPOCConfigList = (params, propsValue) => {
    return fetchApiCallWithService(`${AgentConfig}`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', propsValue, params)
}
export const addSPOCConfig = (body, propsValue) => {
    return fetchApiCallWithService(`${AgentConfig}`, body, commonHeadersAcceptJson, true, "admin", "POST", 'admin', propsValue, '')
}
export const updateSPOCConfigApi = (body,id,propsValue) => {
    return fetchApiCallWithService(`${AgentConfig}${id}`, body, commonHeadersAcceptJson, true, "admin", "PUT", 'admin', propsValue, '')
}
export const duplicateSPOCConfigApi = (body,propsValue) => {
    return fetchApiCallWithService(`${DuplicateAgentConfig}`, body, commonHeadersAcceptJson, true, "admin", "POST", 'admin', propsValue, '')
}
export const deleteSPOCConfig = (endPoint,propsValue) => {
    return fetchApiCallWithService(`${AgentConfig}${endPoint}`, {}, commonHeadersAcceptJson, true, "admin", "DELETE", 'admin', propsValue, '')
}
export const getAgencyMasterList = (params, propsValue) => {
    return fetchApiCallWithService(`${AgencyMaster}`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', propsValue, params)
}
export const addAgencyMaster = (body, propsValue) => {
    return fetchApiCallWithService(`${AgencyMaster}`, body, commonHeadersAcceptJson, true, "admin", "POST", 'admin', propsValue, '')
}
export const updateAgencyMaster = (body, endPoint, propsValue) => {
    return fetchApiCallWithService(`${AgencyMaster}${endPoint}`, body, commonHeadersAcceptJson, true, "admin", "PUT", 'admin', propsValue, '')
}
export const deleteAgencyMaster = (endPoint, propsValue) => {
    return fetchApiCallWithService(`${AgencyMaster}${endPoint}`, {}, commonHeadersAcceptJson, true, "admin", "DELETE", 'admin', propsValue, '')
}
export const getAgencyConfigList = (params, propsValue) => {
    return fetchApiCallWithService(`${AgencyConfig}`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', propsValue, params)
}
export const addAgencyConfig = (body, propsValue) => {
    return fetchApiCallWithService(`${AgencyConfig}`, body, commonHeadersAcceptJson, true, "admin", "POST", 'admin', propsValue, '')
}
export const deleteAgencyConfig = (endPoint, propsValue) => {
    return fetchApiCallWithService(`${AgencyConfig}${endPoint}`, {}, commonHeadersAcceptJson, true, "admin", "DELETE", 'admin', propsValue, '')
}
export const getEsgGroupList = (params, propsValue) => {
    return fetchApiCallWithService(`${EsgGrpMapping}`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', propsValue, params)
}
export const addEsgGroup = (body, propsValue) => {
    return fetchApiCallWithService(`${EsgGrpMapping}`, body, commonHeadersAcceptJson, true, "admin", "POST", 'admin', propsValue, '')
}
export const deleteEsgGroup = (endPoint, propsValue) => {
    return fetchApiCallWithService(`${EsgGrpMapping}${endPoint}`, {}, commonHeadersAcceptJson, true, "admin", "DELETE", 'admin', propsValue, '')
}
export const getPAFilter = (propsValue) => {
    return fetchApiCallWithService(`${getClearancePAList}`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', propsValue, '')
}
export const getPSAFilter = (params, propsValue) => {
    return fetchApiCallWithService(`${getClearancePSAList}`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', propsValue, params)
}
export const getGradeGroupFilter = (params, propsValue) => {
    return fetchApiCallWithService(`${getGradeGrpList}`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', propsValue, params)
}
export const getAgencyFilter = (propsValue) => {
    return fetchApiCallWithService(`${getAgencyList}`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', propsValue, '')
}
export const getAgencySPOCConfigList = (params, propsValue) => {
    return fetchApiCallWithService(`${getAgencySPOC}`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', propsValue, params)
}
export const checkAgencyAgentMapping = (params, propsValue) => {
    return fetchApiCallWithService(`${checkAgencyAgentConfig}`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', propsValue, params)
}
export const checkAgencyMapping = (params, propsValue) => {
    return fetchApiCallWithService(`${checkAgencyConfig}`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', propsValue, params)
}
export const downloadExitHandbook = (propsValue) => {
    return fetchApiCallWithService(`${employeeExithandbook}`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', propsValue, '')
}
export const getClearanceReportMonth = (propsValue) => {
    return fetchApiCallWithService(`${clearanceReportMonths}`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', propsValue, '')
}
export const exportMasterMaintenceReport = () => {
    return fetchApiCallWithService(`${exportMasterReport}`, {}, commonHeadersAcceptJson, true, "admin", "POST", 'admin', "", '')
}


// Suugestion Scheme

export const getSuggestionCount = () => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}suggestion_count`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', '', {})
}

export const getMemberDetails = (params) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}edp_employee_details`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', '', params)
}

export const getMasterStatus = () => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}master/status`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', '', {})
}
// export const getSuggestionList = (customHost) => {
//     return fetchApiCall(`${SuggestionList}`, {}, commonHeadersAcceptJson, true, "", "GET", customHost)
// }
export const getSuggestionList = (param) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}suggestion_list_details`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', '', param)
}

export const getClarificationList = (params) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}suggestion_list_details`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', '', params)
}

export const AddSuggestions = (payload) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}suggestion_request`, payload, commonHeadersAcceptJson, true, "accessToken", "POST", "login", '', {})
}
export const EditSuggestions = (SuggestionId, payload) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}suggestion-update/${SuggestionId}`, payload, commonHeadersAcceptJson, true, "accessToken", "PUT", "login", '', {})
}


export const getSuggestionLogs = (employeeID) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}suggestion_by_id/${employeeID}`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', '', {})
}

export const getSchemeType = () => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}master/scheme_type`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', '', {})
}
export const getCategory = () => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}master/category`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', '', {})
}
export const get_division = (param) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}master/division`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', '', param)
}
export const get_agency = (params) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}master/agency`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', '', params)
}
export const getschemeList = (param) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}master/scheme`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', '', param)
}

export const getDocumentDetail = () => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}document_details`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', '', {})
}

export const uploadDocument = (body) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}suggestion_document`, body, commonHeadersAcceptJson, true, "accessToken", "POST", "login", '', {})
}

export const deleteS3Documents = (body) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}suggestion_document`, body, commonHeadersAcceptJson, true, "accessToken", "DELETE", 'login', '', {})
}
export const deleteDocuments = (id) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}delete_document_details/${id}`, {}, commonHeadersAcceptJson, true, "accessToken", "DELETE", 'login', '', {})
}
export const getS3Document = (param) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}document_url`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', '', param,'blob')
}
export const getS3DocumentView = (param) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}document_url`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', '', param)
}
export const getInstruction = () => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}suggestion_scheme_instructions`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', '', {})
}

// Super Admin Suggestion
export const getSuperAdminSuggestionList = (param) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}super_admin_list`, {}, commonHeadersAcceptJson, true, "admin", "GET", "admin", '', param )
}

export const getSuperAdminSuggestionById = (id) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}suggestion_by_id/${id}`, {}, commonHeadersAcceptJson, true, "admin", "GET", "admin", '', {})
}

export const patchSuperAdminUpdate = (data) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}super_admin_update`, data, commonHeadersAcceptJson, true, "admin", "PATCH", "admin", '', {} )
}


///-------------------Evaluator------------------/////////
export const isApplicableForSuggestion = (params, propsValue) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}is_applicable_for_suggestion/`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", "login", propsValue, params)
}
export const getEvaluatorSuggestionList = (params, id, propsValue) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}evaluator_suggestion_list/${id}`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", "login", propsValue, params)
}
export const getEvaluatorAgencyDashboard = (params, propsValue) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}evaluator_agency_dashboard`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", "login", propsValue, params)
}
export const getMasterLists = (type, propsValue) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}master/${type}`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", "login", propsValue, "")
}
export const suggestionDocAttachment = (body, method, propsValue, endPoint) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}${endPoint}`, body, commonHeadersAcceptJson, true, "accessToken", method, "login", propsValue, {})
}
export const suggestionById = (id, propsValue) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}suggestion_by_id/${id}`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", "login", propsValue, {})
}
export const getPresignedDocumentUrl = (params, propsValue) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}document_url`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", "login", propsValue, params,'blob')
}
export const updateEvaluatorAction = (body) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}evaluator_update`, body, commonHeadersAcceptJson, true, "accessToken", "PATCH", 'login', "", {})
}
export const getMasterAward = (param) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}master/award`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', '', param)
}
export const getMasterAwardCategory = (param) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}master/award_category`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', '', param)
}
export const getMasterSavingCategory = (param) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}master/saving_category`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', '', param)
}
export const getMasterNatureOfSaving = (param) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}master/nature_of_saving`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', '', param)
}
export const updateSuggestionByEvaluator = (body, endPoint) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}suggestion-update/${endPoint}`, body, commonHeadersAcceptJson, true, "accessToken", "PUT", 'login', '', '')
}
export const deleteSavingDetails = (id, param) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}delete_saving_details/${id}`, {}, commonHeadersAcceptJson, true, "accessToken", "DELETE", 'login','',param)
}
// divisional cordinator
export const getDivisionalAgencyDashboard = (params, propsValue) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}div_coordinator/division`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", "login", propsValue, params)
}

export const getDivCordinatorSuggestionList = (params, id, propsValue) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}div_coordinator/division_suggestion_list/${id}`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", "login", propsValue, params)
}

export const divisionalSuggestionById = (id, propsValue) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}suggestion_by_id/${id}`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", "login", propsValue, {})
}

export const updateDivCordinatorAction = (body) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}div_coordinator/division_co_ordinator_update`, body, commonHeadersAcceptJson, true, "accessToken", "PATCH", 'login', "", {})
}

export const updateSuggestionBydivCordinator = (body, endPoint) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}suggestion-update/${endPoint}`, body, commonHeadersAcceptJson, true, "accessToken", "PUT", 'login', '', '')
}


// -------------Suggestion Office-------------------------------------------
export const getSuggestonOfficeAgencyDashboard = (params) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}suggestion_office`, {}, commonHeadersAcceptJson, true, "admin", "GET", "admin", '', params)
}

export const getSuperAdminSuggestionOfficeDashboard = (params) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}super_admin`, {}, commonHeadersAcceptJson, true, "admin", "GET", "admin", '', params)
}

export const suggetionOfficerSuggestionById = ( id) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}suggestion_by_id/${id}`, {}, commonHeadersAcceptJson, true, "admin", "GET", "admin", '', {})
}

export const getMasterAwardAdmin = (param) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}master/award`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin','',param)
}
export const getMasterAwardCategoryAdmin = (param) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}master/award_category`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin','',param)
}
export const getMasterSavingCategoryAdmin = (param) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}master/saving_category`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin','',param)
}
export const getMasterNatureOfSavingAdmin = (param) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}master/nature_of_saving`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin','',param)
}
export const suggestionDocAttachmentAdmin = (body, method, propsValue, endPoint) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}${endPoint}`, body, commonHeadersAcceptJson, true, "admin", method, "admin", propsValue, {})
}

// export const getSuggestionOfficeList = (id) => {
//     return fetchApiCallWithService(`${suggestionSchemeBaseUrl}suggestion_office_list/${id}`, {}, commonHeadersAcceptJson, true, "admin", "GET", "admin", '', {})}
export const getSuggestionOfficeList = (id, params) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}suggestion_office_list/${id}`, {}, commonHeadersAcceptJson, true, "admin", "GET", "admin", '', params)
}

export const getDivisionalListAdmin = (params, propsValue) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}div_coordinator/division`, {}, commonHeadersAcceptJson, true, "admin", "GET", "admin", propsValue, params)
}
export const getStatusMasterListsAdmin = (type, propsValue) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}master/${type}`, {}, commonHeadersAcceptJson, true, "admin", "GET", "admin", propsValue, "")
}

// export const getEvaluatorSuggestionList = ( id ) => {
//     return fetchApiCallWithService(`${suggestionSchemeBaseUrl}evaluator_suggestion_list/${id}`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", "login", propsValue, {})
// }


export const getSuggestionOfficeDivision = (id) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}master/sug_office_division_master/${id}`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', '', {})
}
export const getSuggestionAwardCategory = () => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}master/award_category`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', '', {})
}

export const getPresignedDocumentUrlAdmin = (params, propsValue) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}document_url`, {}, commonHeadersAcceptJson, true, "admin", "GET", "admin", propsValue, params,'blob')
}

export const updateSuggestionOfficeAction = (body) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}suggestion_office_update`, body, commonHeadersAcceptJson, true, "admin", "PATCH", 'admin', "", {})
}
export const deleteS3DocumentAdmin = (body) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}suggestion_document`, body, commonHeadersAcceptJson, true, "admin", "DELETE", 'admin', '', {})
}
export const approveBySuggestionOffice = (body, endPoint) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}suggestion-update/${endPoint}`, body, commonHeadersAcceptJson, true, "admin", "PUT", 'admin', '', {})
}
export const deleteSavingDocumentsAdmin = (id) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}delete_document_details/${id}`, {}, commonHeadersAcceptJson, true, "admin", "DELETE", 'admin', '', {})
}
export const deleteSavingDetailsAdmin = (id, param) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}delete_saving_details/${id}`, {}, commonHeadersAcceptJson, true, "admin", "DELETE", 'admin','',param)
}

export const getMasterAdminLists = (type, propsValue={}) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}master/${type}`, {}, commonHeadersAcceptJson, true, "admin", "GET", "admin", propsValue, "")}
export const getAgencyMasterListV1 = (params,propsValue) => {
    return fetchApiCallWithService(`${AgencyMasterV1}`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', propsValue, params)
}

export const isApplicableForSuggestionAdmin = (params, propsValue) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}is_applicable_for_sug_office_and_super_admin`, {}, commonHeadersAcceptJson, true, "admin", "GET", "admin", propsValue, params)
}

// ------------------ ADMIN SUGGESTION SCHEME REPORT API's--------------------------------------------------------------------------------------------

export const getAdminschemeList = (param) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}master/scheme`, {}, commonHeadersAcceptJson, true,  "admin", "GET", 'admin', '', param)
}

export const getSchemeListForReports = () => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}report/get_scheme_list_for_reports`, {}, commonHeadersAcceptJson, true,  "admin", "GET", 'admin', '')
}

export const getFinancialYears = () => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}master/financial_years_dropdown`, {}, commonHeadersAcceptJson, true, "admin", "GET", "admin", '',{})
}
export const getManagerFinancialYears = () => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}master/financial_years_dropdown`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", "admin", '',{})
}

export const getReportList = (param) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}is_applicable_for_report`, {}, commonHeadersAcceptJson, true,  "admin", "GET", 'admin', '', param)
}

export const get_admin_division = (param) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}master/division`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', '', param)
}

export const get_Suggestion_generation_report = (param) => {
    return fetchApiCallWithServiceForReports(`${suggestionSchemeBaseUrl}report/suggestion_generation_report`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', '', param)
}
export const get_Suggestion_generation_reports = (param) => {
    return fetchApiCallWithServiceForReports(`${suggestionSchemeBaseUrl}report/suggestion_generation_report`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'admin', '', param)
}

export const get_Suggestion_bdc_report = (param) => {
    return fetchApiCallWithServiceForReports(`${suggestionSchemeBaseUrl}report/suggestion_bdc_report`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', '', param)
}
export const get_suggestion_bdc_report_pdf = (param) => {
    return fetchApiCallWithServiceForReports(`${suggestionSchemeBaseUrl}report/generate_payment_advice_report_pdf`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', '', param,'blob')
}
export const get_suggestion_bdc_report_history_pdf = (param) => {
    return fetchApiCallWithServiceForReports(`${suggestionSchemeBaseUrl}report/payment_advice_report_pdf_history_list`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', '', param)
}
export const get_final_circular_pdf_report = (param) => {
    return fetchApiCallWithServiceForReports(`${suggestionSchemeBaseUrl}report/get_high_value_circular_report_pdf`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', '', param,"blob")
}
export const get_bdc_report_pdf_from_S3 = (param) => {
    return fetchApiCallWithServiceForReports(`${suggestionSchemeBaseUrl}report/get_bdc_report_pdf_from_S3`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', '', param,'blob')
}
export const downloadLOTCertificatePDF = (param) => {
    return fetchApiCallWithServiceForReports(`${suggestionSchemeBaseUrl}get_lot_certificate`, {}, commonHeadersAcceptJson, true,"accessToken",  "GET", 'admin', '', param,'blob')
}
export const downloadLOTCertificatePDFAdmin = (param) => {
    return fetchApiCallWithServiceForReports(`${suggestionSchemeBaseUrl}get_lot_certificate`, {}, commonHeadersAcceptJson, true,'admin',  "GET", 'admin', '', param,'blob')
}
export const suggestion_bdc_calculation_report = (param) => {
    return fetchApiCallWithServiceForReports(`${suggestionSchemeBaseUrl}report/bdc_calculation_report`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', '', param)
}
export const suggestion_bdc_history_report = (param) => {
    return fetchApiCallWithServiceForReports(`${suggestionSchemeBaseUrl}report/bdc_history_details`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', '', param)
}
export const get_agewise_pending_report = (param) => {
    return fetchApiCallWithServiceForReports(`${suggestionSchemeBaseUrl}report/get_agewise_pending_report`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'admin', '', param,'blob')
}
export const get_agewise_pending_report_admin = (param) => {
    return fetchApiCallWithServiceForReports(`${suggestionSchemeBaseUrl}report/get_agewise_pending_report`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', '', param,'blob')
}

export const get_Suggestion_generation_and_acceptance_report = (param) => {
    return fetchApiCallWithServiceForReports(`${suggestionSchemeBaseUrl}report/suggestion_generation_and_acceptance_report`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', '', param,'blob')
}
export const get_SuggOffice_Suggestion_generation_and_acceptance_report = (param) => {
    return fetchApiCallWithServiceForReports(`${suggestionSchemeBaseUrl}report/suggestion_generation_and_acceptance_report`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', '', param,'blob')
}

export const get_Non_participant_report = (param) => {
    return fetchApiCallWithServiceForReports(`${suggestionSchemeBaseUrl}report/non_participant_report`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', '', param,'blob')
}
export const get_Non_participant_reports= (param) => {
    return fetchApiCallWithServiceForReports(`${suggestionSchemeBaseUrl}report/non_participant_report`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'admin', '', param,'blob')
}

export const get_Suggestion_scheme_division_count = (param) => {
    return fetchApiCallWithServiceForReports(`${suggestionSchemeBaseUrl}report/suggestion_scheme_division_count_report`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', '', param)
}

export const division_wise_suggestion_performance_report = (param) => {
    return fetchApiCallWithServiceForReports(`${suggestionSchemeBaseUrl}report/division_wise_suggestion_performance_report`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', '', param)
}

export const suggestionTabApplicable = (endPoint) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}is_employee_applicable_for_suggestion_tab${endPoint}`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', "", {})
}

export const validateIdeaBankReference = (body) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}/is_valid_idea_bank_reference`, body, commonHeadersAcceptJson, true, "accessToken", "POST", "login","", {})
}

export const getSuggestionNoteBoradImage = (endpoint) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}notice_board${endpoint}`,  {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', "", {})
}

export const getReportAwardType = () => {
    return fetchApiCallWithServiceForReports(`${suggestionSchemeBaseUrl}report/get_award_type_for_report`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', '', {})
}

//Suggestion Master Screen
export const getSuggestionSpecialScheme = (param) => {
    return fetchApiCallWithServiceForReports(`${suggestionSchemeBaseUrl}admin_screen/get_scheme_list`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', '', param)
}
export const noticeboardUploadDocument = (body) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}admin_screen/add_notice_board_image_S3`, body, commonHeadersAcceptJson, true, "admin", "POST", 'admin', '', {})
}
export const adminUploadDocument = (body) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}suggestion_document`, body, commonHeadersAcceptJson, true, "admin", "POST", "admin", '', {})
}
export const addSuggestionSchemeName = (body) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}admin_screen/get_scheme_list`, body, commonHeadersAcceptJson, true, "admin", "POST", 'admin', '', {})
}
export const updateSuggestionSchemeName = (body,endpoint) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}admin_screen/get_scheme_list/${endpoint}`, body, commonHeadersAcceptJson, true, "admin", "PUT", 'admin', '', {})
}
export const deleteSuggestionSchemeName = (body,id) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}admin_screen/get_scheme_list/${id}`, body, commonHeadersAcceptJson, true, "admin", "DELETE", 'admin', '', {})
}
export const deleteSchemeImage = (body,id) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}admin_screen/delete_notice_board/${id}`, body, commonHeadersAcceptJson, true, "admin", "DELETE", 'admin', '', {})
}
export const getSuggMasterStatus = () => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}master/status`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', '', {})
}
export const getSuggAgency = (params) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}master/agency`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', '', params)
}
export const getSuggDetailEntryDateReport = (params) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}report/get_sug_detail_entry_date_report?`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', '', params,'blob')
}
export const getSuggDetailStatusReport = (params) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}report/get_sug_detail_status_report?`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', '', params,'blob')
}
export const getSuggEvaluationDetailReport = (params) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}report/get_evaluation_detail_report?`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', '', params,'blob')
}
export const getSuggEvaluationSummaryReport = (params) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}report/get_evaluation_summary_report?`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', '', params,'blob')
}

//-------------------------- Manager Suggestion Scheme Report  --------------------------------------------------------------------------

export const getMangerReportList = (param) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}is_applicable_for_report`,  {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', '', param)
}
export const getMangerSuggEvaluationDetailReport = (params) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}report/get_evaluation_detail_report?`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', '', params,'blob')
}
export const getMangerSuggEvaluationSummaryReport = (params) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}report/get_evaluation_summary_report?`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', '', params,'blob')
}

//-------------------------- Sync Up Report  --------------------------------------------------------------------------
export const getMvDropDown = (param) => {
    return fetchApiCallWithServiceForReports(`${suggestionSchemeBaseUrl}report/get_mv_drop_down`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', '', param)
}

export const refreshMvDetails = (param) => {
    return fetchApiCallWithServiceForReports(`${suggestionSchemeBaseUrl}report/refresh_mv_details`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', '', param)
}
export const getLatestMvDetails = (param) => {
    return fetchApiCallWithServiceForReports(`${suggestionSchemeBaseUrl}report/get_latest_mv_details`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', '', param)
}


export const downloadPDFRelievingLetter = (body, type) => {
    const role = type === "admin" ? "admin" : "accessToken"
    return fetchApiCallWithService(getRelivingLetterDetail, body, commonHeadersAcceptPDF, false, role, "POST", "admin", "", "", 'blob')
}

export const downloadPDFServiceLetter = (body, type) => {
    const role = type === "admin" ? "admin" : "accessToken"
    return fetchApiCallWithService(getServiceCertDetail, body, commonHeadersAcceptPDF, false, role, "POST", "admin", "", "", 'blob')
}//-------------------------- Cost Center Mapping Report  --------------------------------------------------------------------------


export const getCostCenterSummaryReport = (params) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}report/get_cost_center_summary_report?`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', '', params,'blob')
}
export const getCostCenterSummaryReportAdmin = (param) => {
    return fetchApiCallWithServiceForReports(`${suggestionSchemeBaseUrl}report/get_cost_center_summary_report`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', '', param,'blob')
}
export const getCostCenterDetailReport = (params) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}report/get_cost_center_detail_report?`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', '', params,'blob')
}
export const getCostCenterDetailReportAdmin = (param) => {
    return fetchApiCallWithServiceForReports(`${suggestionSchemeBaseUrl}report/get_cost_center_detail_report`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', '', param,'blob')
}



//-------------------------- Suggestion P2 Report  --------------------------------------------------------------------------
export const get_rejection_report = (param) => {
    return fetchApiCallWithServiceForReports(`${suggestionSchemeBaseUrl}report/get_rejection_report`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', '', param,'blob')
}
export const get_monthly_award_greater_than = (param) => {
    return fetchApiCallWithServiceForReports(`${suggestionSchemeBaseUrl}report/get_monthly_award_greater_than`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', '', param,'blob')
}

export const getAdminSuggestionPerformanceReportDivc = (params) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}report/get_sug_performance_report_divc?`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', '', params,'blob')
}

export const getMangerSuggestionPerformanceReportDivc = (params) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}report/get_sug_performance_report_divc?`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', '', params,'blob')
}

export const getStatusWiseSuggReport = (param) => {
    return fetchApiCallWithServiceForReports(`${suggestionSchemeBaseUrl}report/status_wise_sug_generation_report_nos`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', '', param,'blob')
}

export const getStatusWiseSuggReportAdmin = (param) => {
    return fetchApiCallWithServiceForReports(`${suggestionSchemeBaseUrl}report/status_wise_sug_generation_report_nos`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', '', param,'blob')
}
export const getSavingSummaryReport = (param) => {
    return fetchApiCallWithServiceForReports(`${suggestionSchemeBaseUrl}report/get_saving_summary_report`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', '', param,'blob')
}


export const getStatusWiseDetailReport = (params) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}report/get_sug_status_wise_detail_report?`, {}, commonHeadersAcceptJson, true, "accessToken", "GET", 'login', '', params,'blob')
}
export const getStatusWiseDetailReportAdmin = (params) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}report/get_sug_status_wise_detail_report?`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', '', params,'blob')
}

//-------------------------- Reopen API  --------------------------------------------------------------------------
export const getSugReopenDetails = (params) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}get_sug_reopen_details?`, {}, commonHeadersAcceptJson, true, "admin", "GET", 'admin', '', params)
}

export const submitReopenSuggestion = (endPoint, body) => {
    return fetchApiCallWithService(`${suggestionSchemeBaseUrl}submit_reopen_suggestion/${endPoint}`, body, commonHeadersAcceptJson, true, "admin", "PUT", 'admin', "", '')
}