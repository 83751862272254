import React, { Component } from 'react';
import '../profile/ProfileScreen.css';
import '../documents/DocumentStyles.css';
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import overviewButton from '../../../../assets/drishti_images/overview_button.svg';
import { BASE_WEB_URL_DEV } from '../../../../constants/Config';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import moment from 'moment';
import ExitConfirmPopup from './ExitConfirmPopup'

toast.configure();
const requestFailed = 'Unable to contact server\n\tPlease retry after sometime';

class ExitForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            employee_bhr_name: '',
            employeedata: [],
            employee_name: '',
            employee_ticket_no: '',
            grade: '',
            qualification: '',
            function: '',
            subfunction1: '',
            present_designation: '',
            reporting_ticket: '',
            date_of_joining: '',
            date_of_resignation: '',
            present_address: '',
            permanent_address: '',
            present_mobile_no: '',
            future_contact_number: '',
            email_id_personal: '',
            email_id_alternative: '',
            reason_for_leaving: '',
            reason_for_leaving_elaborate: '',
            other_organization_info: '',
            reason_of_joining: '',
            main_role: '',
            aspect_of_organization: '',
            relationship_with_superior: '',
            dissatisfaction_feature: '',
            action_of_organization_to_retain_sevice: '',
            like_to_work_later: '',
            html: '',
            loading: false,
            esep_id: '',
            readOnly: false,
            showConfirm: false,
        }
    }
    componentDidMount() {
        if (this.props.loginData.isExitFormApplicable) {
            this.setState({ esep_id: this.props.loginData.isExitApplicable.separation_request_id ? this.props.loginData.isExitApplicable.separation_request_id : '' })
            this.getSeparationDetail(this.props.loginData.isExitApplicable.separation_request_id)
        }
    }
    // this function to check exit form is on readable format or writable format
    employeeApplicableForExitFormData(empData) {

        let mode = empData.form_mode
        let data = empData.user_details
        if (mode === 'view') {
            this.setState({
                readOnly: true,
                employee_ticket_no: data.employee_ticket_no ? data.employee_ticket_no : '',
                employee_name: data.employee_name ? data.employee_name : '',
                grade: data.grade ? data.grade : '',
                function: data.function ? data.function : '',
                qualification: data.qualifiation ? data.qualifiation : '',
                subfunction1: data.sub_function_1 ? data.sub_function_1 : '',
                present_designation: data.present_designation ? data.present_designation : '',
                reporting_ticket: data.reporting_ticket ? data.reporting_ticket : '',
                date_of_joining: data.date_of_joining ? data.date_of_joining : '',
                present_mobile_no: data.present_mobile_no ? data.present_mobile_no : '',
                date_of_resignation: data.date_of_resignation ? data.date_of_resignation : '',
                present_address: data.present_address ? data.present_address : '',
                permanent_address: data.permanent_address ? data.permanent_address : '',
                future_contact_number: data.future_contact_number ? data.future_contact_number : '',
                email_id_personal: data.email_id_personal ? data.email_id_personal : '',
                email_id_alternative: data.email_id_alternative ? data.email_id_alternative : '',

                reason_for_leaving: data.reason_for_leaving ? data.reason_for_leaving : '',
                reason_for_leaving_elaborate: data.reason_for_leaving_elaborate ? data.reason_for_leaving_elaborate : '',
                other_organization_info: data.other_organization_info ? data.other_organization_info : '',
                reason_of_joining: data.reason_of_joining ? data.reason_of_joining : '',
                main_role: data.main_role ? data.main_role : '',
                aspect_of_organization: data.aspect_of_organization ? data.aspect_of_organization : '',
                relationship_with_superior: data.relationship_with_superior ? data.relationship_with_superior : '',
                dissatisfaction_feature: data.dissatisfaction_feature ? data.dissatisfaction_feature : '',
                action_of_organization_to_retain_sevice: data.action_of_organization_to_retain_sevice ? data.action_of_organization_to_retain_sevice : '',
                like_to_work_later: data.like_to_work_later ? data.like_to_work_later : '',
            })
        }
        else {
            this.setState({
                readOnly: false,
                employee_ticket_no: data.Perno ? data.Perno : '',
                employee_name: data.CompName ? data.CompName : '',
                grade: data.JobtxtShort??'',
                function: data.FunctionText ? data.FunctionText : '',
                subfunction1: data.Subfunction1Tex ? data.Subfunction1Tex : '',
                present_designation: data.Postxt ? data.Postxt : '',
                reporting_ticket: data.Reporting ? data.Reporting : '',
                date_of_joining: data.Doj ? data.Doj : '',
                present_mobile_no: data.PersMobile ? data.PersMobile : '',
                email_id_personal: data.Exmailid ? data.Exmailid : ''
            })
        }

    }

    //this function is called to get all data of employee which is already submitted
    getSeparationDetail = (esepID) => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            employee_seperation_id: esepID,
            emp_id: this.props.loginData.userData.Perno
        }
        fetchApiCall(Config.separationForm, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState(
                        {
                            reason_for_leaving: res.data.reason_for_leaving,
                            loading: false,
                            present_address: res.data.current_address,
                            permanent_address: res.data.permanent_address,
                            employee_bhr_name: res.data.employee_bhr_name,
                            date_of_resignation: res.data.date_of_resignation,
                            qualification: res.data.qualification
                        })
                    this.employeeApplicableForExitFormData(res.data)
                }
                else {
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                    this.setState({ loading: false })
                }
            }).catch(err => {
                this.setState({ loading: false })
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }
    generateExitForm = () => {
        this.setState({ loading: true });
        const req = {
            emp_id: this.props.loginData.userData.Perno,
            employee_seperation_id: this.state.esep_id,
            date_of_resignation: this.state.date_of_resignation,
            employee_ticket_no: this.state.employee_ticket_no,
            employee_name: this.state.employee_name,
            employee_bhr_name: this.state.employee_bhr_name,
            grade: this.state.grade,
            qualification: this.state.qualification,
            function: this.state.function,
            sub_function_1: this.state.subfunction1,
            present_designation: this.state.present_designation,
            reporting_ticket: this.state.reporting_ticket,
            date_of_joining: this.state.date_of_joining,
            present_address: this.state.present_address,
            permanent_address: this.state.permanent_address,
            present_mobile_no: this.state.present_mobile_no,
            future_contact_number: this.state.future_contact_number ? this.state.future_contact_number : this.props.loginData.isExitApplicable.employee_contact_no ? this.props.loginData.isExitApplicable.employee_contact_no : '',
            email_id_personal: this.state.email_id_personal ? this.state.email_id_personal : this.props.loginData.isExitApplicable.employee_personal_email ? this.props.loginData.isExitApplicable.employee_personal_email : '',
            email_id_alternative: this.state.email_id_alternative,
            reason_for_leaving: this.state.reason_for_leaving,
            reason_for_leaving_elaborate: this.state.reason_for_leaving_elaborate,
            other_organization_info: this.state.other_organization_info,
            reason_of_joining: this.state.reason_of_joining,
            main_role: this.state.main_role,
            aspect_of_organization: this.state.aspect_of_organization,
            relationship_with_superior: this.state.relationship_with_superior,
            dissatisfaction_feature: this.state.dissatisfaction_feature,
            action_of_organization_to_retain_sevice: this.state.action_of_organization_to_retain_sevice,
            like_to_work_later: this.state.like_to_work_later,
        }
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.generateEmployeeExitForm, req, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        showConfirm: false
                    })
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    });
                    setTimeout(() => { window.location.reload() }, 2000)

                } else {
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                    this.setState({ loading: false, showConfirm: false });
                }

            }).catch(err => {
                this.setState({ loading: false, showConfirm: false });
                setTimeout(() => {
                    toast.error(requestFailed, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }, 100)
            })
    }
    validate = () => {
        if (this.state.permanent_address === '' ||
            this.state.reason_for_leaving_elaborate === '' || this.state.other_organization_info === '' || this.state.main_role === '' ||
            this.state.aspect_of_organization === '' || this.state.relationship_with_superior === '' || this.state.dissatisfaction_feature === '' || this.state.action_of_organization_to_retain_sevice === '' || this.state.like_to_work_later === '') {
            toast.error('Please fill all required fields', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }

        else { this.setState({ showConfirm: true }) }
    }
    onDismiss = () => {
        this.setState({ showConfirm: false })
    }
    gotoLanding = () => {
        window.location = (BASE_WEB_URL_DEV + 'home');

    }
    toggleOpenDetail = () => {
        this.setState({
            openDetail: !this.state.openDetail
        })
    }

    render() {
        const contactNo = this.props.loginData.isExitApplicable.employee_contact_no
        const emailID = this.props.loginData.isExitApplicable.employee_personal_email
        const actualResignationDate = this.props.loginData.isExitApplicable.actual_resignation_date
        return (
            <div className='p-12px'>
                <LoaderIndicator loading={this.state.loading} />
                <div className='row'>
                    <div className="col-md-12 px-0">
                        <div className="row justify-content-between align-item-center">
                            <div className='row' onClick={this.gotoLanding}>
                                <img src={overviewButton} />
                            </div>
                        </div>
                        <div className='document_card mt-3 p-3 mb-24px col-md-12'>
                            <label className='document-common-heading common-text-color'>Exit Form</label>
                            <hr />
                            <div className={!this.state.readOnly === true ? 'scroll-y h-65vh' : 'scroll-y h-66vh'}>
                                <div className='p-heading mx-2 mt-2 mb-2'>Employee Details</div>
                                <div className='row'>
                                    <div className='col-4 mb-3'>
                                        <div className='p-sub-heading ml-3'>Employee Ticket No.</div>
                                        <div className='p-placeholder-text'>
                                            <input
                                                className="form-control p-placeholder px-3"
                                                type="text"
                                                value={this.state.employee_ticket_no}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-4 mb-3'>
                                        <div className='p-sub-heading ml-3'>Employee Name</div>
                                        <div className='p-placeholder-text'>
                                            <input
                                                className="form-control p-placeholder px-3"
                                                type="text"
                                                value={this.state.employee_name}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-4 mb-3'>
                                        <div className='p-sub-heading ml-3'>Grade/Level</div>
                                        <div className='p-placeholder-text'>
                                            <input
                                                className="form-control p-placeholder px-3"
                                                type="text"
                                                value={this.state.grade}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-4 mb-3'>
                                        <div className='p-sub-heading ml-3'>Function</div>
                                        <div className='p-placeholder-text'>
                                            <input
                                                className="form-control p-placeholder px-3"
                                                type="text"
                                                value={this.state.function}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-4 mb-3'>
                                        <div className='p-sub-heading ml-3'>Sub-Function1</div>
                                        <div className='dropdown'>
                                            <input
                                                className='form-control p-placeholder px-3'
                                                type="text"
                                                value={this.state.subfunction1}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-4 mb-3'>
                                        <div className='p-sub-heading ml-3'>Present Designation</div>
                                        <div className='p-placeholder-text'>
                                            <input
                                                className="form-control p-placeholder px-3"
                                                type="text"
                                                value={this.state.present_designation}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-4 mb-3'>
                                        <div className='p-sub-heading ml-3'>Reporting Manager (Ticket No.)</div>
                                        <div className='p-placeholder-text'>
                                            <input
                                                className="form-control p-placeholder px-3"
                                                type="text"
                                                value={this.state.reporting_ticket}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-4 mb-3'>
                                        <div className='p-sub-heading ml-3'>Date of Joining</div>
                                        <div className='dropdown'>
                                            <input
                                                className='form-control p-placeholder px-3'
                                                type="text"
                                                value={moment(this.state.date_of_joining).format("DD-MM-YYYY")}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-4 mb-3'>
                                        <div className='p-sub-heading ml-3'>Resignation Date</div>
                                        <div className='dropdown'>
                                            <input
                                                className='form-control p-placeholder px-3'
                                                type="text"
                                                value={moment(actualResignationDate).format("DD-MM-YYYY")}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-4 mb-3'>
                                        <div className='p-sub-heading ml-3'>Present Address</div>
                                        <div className='dropdown'>
                                            <input
                                                className='form-control p-placeholder px-3'
                                                type="text"
                                                value={this.state.present_address}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-4 mb-3'>
                                        <div className='p-sub-heading ml-3'>Permanent Address</div>
                                        <div className='dropdown'>
                                            <input
                                                className='form-control p-placeholder px-3'
                                                type="text"
                                                onChange={(e) => this.setState({ permanent_address: e.target.value })}
                                                value={this.state.permanent_address}
                                                readOnly={this.state.readOnly}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-4 mb-3'>
                                        <div className='p-sub-heading ml-3'>Present Mobile No.</div>
                                        <div className='dropdown'>
                                            <input
                                                className='form-control p-placeholder px-3'
                                                type="text"
                                                value={this.state.present_mobile_no}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    <div className='row justify-content-between'>
                                        <div className='p-heading mx-4 mt-2 mb-2'>For Future References</div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-4 mb-3'>
                                            <div className='p-sub-heading ml-3'>Future Contact No</div>
                                            <div className='p-placeholder-text'>
                                                <input
                                                    className="form-control p-placeholder px-3"
                                                    type='number'
                                                    maxLength={13}
                                                    value={contactNo}
                                                    readOnly={true}
                                                />
                                                {/* <input
                                                    className="form-control p-placeholder px-3"
                                                    type='text'
                                                    onChange={(e) => this.setState({ future_contact_number: e.target.value })}
                                                    value={this.state.future_contact_number}
                                                    readOnly={this.state.readOnly}
                                                /> */}
                                            </div>
                                        </div>
                                        <div className='col-4 mb-3'>
                                            <div className='p-sub-heading ml-3'>Email ID (Personal)</div>
                                            <div className='p-placeholder-text'>
                                                <input
                                                    className="form-control p-placeholder px-3"
                                                    type='text'
                                                    maxLength={50}
                                                    value={emailID}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-4 mb-3'>
                                            <div className='p-sub-heading ml-3'>Email ID (Alternate)</div>
                                            <div className='p-placeholder-text'>
                                                <input
                                                    className="form-control p-placeholder px-3"
                                                    type='text'
                                                    onChange={(e) => this.setState({ email_id_alternative: e.target.value })}
                                                    value={this.state.email_id_alternative}
                                                    readOnly={this.state.readOnly}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    <div className='row justify-content-between'>
                                        <div className='p-heading mx-4 mt-2 mb-2'>Employee response on exit interview questionnaire</div>
                                    </div>
                                    <div className='column'>
                                        <div className='mb-3 mx-3'>
                                            <div className='p-sub-heading ml-3'>Reason for Leaving</div>
                                            <div className='row dropdown mt-1'>
                                                <input
                                                    className='form-control p-placeholder px-3'
                                                    type="text"
                                                    value={this.state.reason_for_leaving}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                        <div className='mb-3 mx-3'>
                                            <div className='p-sub-heading ml-3 required'>Please elaborate on above reason</div>
                                            <div className='row p-placeholder-text mt-1'>
                                                <textarea
                                                    className="form-control sub-textarea cfm-subcard"
                                                    placeholder="Enter your response..."
                                                    rows="3"
                                                    id="comment"
                                                    value={this.state.reason_for_leaving_elaborate}
                                                    maxLength={1500}
                                                    onChange={(e) => this.setState({ reason_for_leaving_elaborate: e.target.value })}
                                                    readOnly={this.state.readOnly} >
                                                </textarea>
                                            </div>
                                        </div>
                                        <div className='mb-3 mx-3'>
                                            <div className='p-sub-heading ml-3 required'>Would you like to specify which other Organization you are joining?</div>
                                            <div className='row p-placeholder-text mt-1'>
                                                <textarea
                                                    className="form-control sub-textarea cfm-subcard"
                                                    placeholder="Enter your response..."
                                                    rows="3"
                                                    id="comment"
                                                    maxLength="1500"
                                                    value={this.state.other_organization_info}
                                                    onChange={(e) => this.setState({ other_organization_info: e.target.value })}
                                                    readOnly={this.state.readOnly}>
                                                </textarea>
                                            </div>
                                        </div>
                                        <div className='mb-3 mx-3'>
                                            <div className='p-sub-heading ml-3'>Why did you join this organization ?</div>
                                            <div className='row p-placeholder-text mt-1'>
                                                <textarea
                                                    className="form-control sub-textarea cfm-subcard"
                                                    placeholder="Enter your response..."
                                                    rows="3"
                                                    id="comment"
                                                    maxLength="1500"
                                                    value={this.state.reason_of_joining}
                                                    onChange={(e) => this.setState({ reason_of_joining: e.target.value })}
                                                    readOnly={this.state.readOnly}>
                                                </textarea>
                                            </div>
                                        </div>
                                        <div className='mb-3 mx-3'>
                                            <div className='p-sub-heading ml-3 required'>What were some your key achievements at company?</div>
                                            <div className='row p-placeholder-text mt-1'>
                                                <textarea
                                                    className="form-control sub-textarea cfm-subcard"
                                                    placeholder="Enter your response..."
                                                    rows="3"
                                                    id="comment"
                                                    maxLength="1500"
                                                    value={this.state.main_role}
                                                    onChange={(e) => this.setState({ main_role: e.target.value })}
                                                    readOnly={this.state.readOnly}>
                                                </textarea>
                                            </div>
                                        </div>
                                        <div className='mb-3 mx-3'>
                                            <div className='p-sub-heading ml-3 required'>What aspect of organization do you hold in high regards ?</div>
                                            <div className='row p-placeholder-text mt-1'>
                                                <textarea
                                                    className="form-control sub-textarea cfm-subcard"
                                                    placeholder="Enter your response..."
                                                    rows="3"
                                                    id="comment"
                                                    maxLength="1500"
                                                    value={this.state.aspect_of_organization}
                                                    onChange={(e) => this.setState({ aspect_of_organization: e.target.value })}
                                                    readOnly={this.state.readOnly}>
                                                </textarea>
                                            </div>
                                        </div>
                                        <div className='mb-3 mx-3'>
                                            <div className='p-sub-heading ml-3 required'>How was your relationship with your superior ? Did you receive challenging work and support?</div>
                                            <div className='row p-placeholder-text mt-1'>
                                                <textarea
                                                    className="form-control sub-textarea cfm-subcard"
                                                    placeholder="Enter your response..."
                                                    rows="3"
                                                    id="comment"
                                                    maxLength="1500"
                                                    value={this.state.relationship_with_superior}
                                                    onChange={(e) => this.setState({ relationship_with_superior: e.target.value })}
                                                    readOnly={this.state.readOnly} >
                                                </textarea>
                                            </div>
                                        </div>
                                        <div className='mb-3 mx-3'>
                                            <div className='p-sub-heading ml-3 required'>Please list top 3 reasons which caused you dissatisfaction during your tenure.</div>
                                            <div className='row p-placeholder-text mt-1'>
                                                <textarea
                                                    className="form-control sub-textarea cfm-subcard"
                                                    placeholder="Enter your response..."
                                                    rows="3"
                                                    id="comment"
                                                    maxLength="1500"
                                                    value={this.state.dissatisfaction_feature}
                                                    onChange={(e) => this.setState({ dissatisfaction_feature: e.target.value })}
                                                    readOnly={this.state.readOnly}>
                                                </textarea>
                                            </div>
                                        </div>
                                        <div className='mb-3 mx-3'>
                                            <div className='p-sub-heading ml-3 required'>Was there anything that the organization could have done to retain your services here?</div>
                                            <div className='row p-placeholder-text mt-1'>
                                                <textarea
                                                    className="form-control sub-textarea cfm-subcard"
                                                    placeholder="Enter your response..."
                                                    rows="3"
                                                    id="comment"
                                                    maxLength="1500"
                                                    value={this.state.action_of_organization_to_retain_sevice}
                                                    onChange={(e) => this.setState({ action_of_organization_to_retain_sevice: e.target.value })}
                                                    readOnly={this.state.readOnly}>
                                                </textarea>
                                            </div>
                                        </div>
                                        <div className='mb-3 mx-3'>
                                            <div className='p-sub-heading ml-3 required'>Would you like to work for this organization at a later date and in a different capacity?</div>
                                            <div className='row p-placeholder-text mt-1'>
                                                <textarea
                                                    className="form-control sub-textarea cfm-subcard"
                                                    placeholder="Enter your response..."
                                                    rows="3"
                                                    id="comment"
                                                    maxLength="1500"
                                                    value={this.state.like_to_work_later}
                                                    onChange={(e) => this.setState({ like_to_work_later: e.target.value })}
                                                    readOnly={this.state.readOnly}>
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {!this.state.readOnly &&
                                <div className='row justify-content-end'>
                                    <div className='justify-content-between'>
                                        <div
                                            type='button'
                                            className='cancl-btn cancel-txt'
                                            onClick={() => this.toggleOpenDetail()}
                                        >Cancel</div>
                                        <div
                                            type='button'
                                            className='save-btn save-txt'
                                            onClick={this.validate}
                                        >Save</div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {this.state.showConfirm &&
                    <ExitConfirmPopup onDismiss={this.onDismiss} submit={this.generateExitForm} />}
            </div>
        )
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        keycloakData: state.keycloakState,
    }
}

export default connect(mapStatesToProps)(ExitForm);