import React, { useEffect, useState } from "react";
import DynamicDropdown from "../../../../shared/components/DynamicDropdown";
import { toaster } from "../../../../../utils/Utils";
import { toast } from "react-toastify";
import LoaderIndicator from "../../../../shared/loader/LoaderIndicator";
import { getMvDropDown,refreshMvDetails,getLatestMvDetails } from "../../../../../constants/api.service";



function SyncUpReports() {
  const [loader, setLoader] = useState(false)
  const [report,setReport] = useState([])
  const [updatedDateTime,setUpdatedDateTime]= useState("DD-MM-YYYY HH-MM-SS")
  const [viewCode,setViewCode]=useState("")
  const [selectedReportType, setselectedReportType] = useState("");


  
 
  const handleReportType = (data) => {
    setselectedReportType(data);
    setViewCode(data?.view_code)
    getLatestMvDetailsDateTime(data?.view_code)
  };

  const getMvDetailsDropDown = () => {
    setLoader(true);
    getMvDropDown()
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          const reportList = res?.data?.data.map((report) => ({
            report_name: report?.report_name,
            view_code: report?.view_code,
          }));
          setReport(reportList);
        } else {
          res?.status !== 404 && toaster("warning", res?.data?.message ?? "someThingWentWrong")
          setReport(null);
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const getLatestMvDetailsDateTime = (datetime) => {
    const params= {
      view_code: datetime
    }
    getLatestMvDetails(params)
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          const latestUpdate = res?.data?.data?.refresh_datetime
          setUpdatedDateTime(latestUpdate)
        } else {
          res?.status !== 404 && toaster("warning", res?.data?.message ?? "someThingWentWrong")
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const refreshMvDetailsReport = () => {
    setLoader(true);
    const params = {
      view_code: viewCode
    }
    refreshMvDetails(params)
      .then((res) => {
        if (res.status == 500) {
          toast.success("Report will update in 2 minutes", {
            position: "bottom-center",
            hideProgressBar: true,
            className: "toastSuccess",
          });
          getLatestMvDetailsDateTime(viewCode)
        } else {
          res?.status !== 404 && toaster("warning", res?.data?.message ?? "someThingWentWrong")
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoader(false);
      });
  };

  
  useEffect(() => {
    getMvDetailsDropDown()
  }, []);
  
  return (
    <div>
  <LoaderIndicator loading={loader} />
  <div className="ml-10 h-90">
    <div>
      <div className="gap-20">
        <div className="d-flex flex-direction-column">
          <div className="flex-direction-row flex-wrap justify-content-between align-item-center filter">
            <div className="d-flex flex-wrap flex-direction-row mx-2 align-items-center">
              <div className="flex-direction-column ml-3">
                <div className="p-sub-heading ml-2">
                  {"Select Report Type"}<span style={{color: '#FF4C4C'}}>*</span>
                </div>
                <DynamicDropdown 
                data={report}
                render="report_name"
                value={selectedReportType?.report_name}
                selectedValue={(data) => handleReportType(data)}
                arrow={"true"}
                height={"NotificationHeight"}
                width={"w-20rem"}/>
              </div>
            </div>
            <div className="d-flex flex-direction-column mx-2 align-items-center">
              <span className="form-popup-value-65">Last updated: {updatedDateTime}</span>
              <button 
                className="btn buttonStyle px-50px"
                onClick={refreshMvDetailsReport}
              >
                Refresh
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  );
}

export default SyncUpReports;
