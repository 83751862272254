import { SET_APPLICABLE_SUGGESTION } from "../../../../redux/action/ActionType";

const initialState = {
    isApplicableForSuggestion: {
      is_sug_eval_applicable: false,
      is_sug_div_applicable: false,
      is_sug_office_applicable: false,
      is_sug_super_admin_applicable: false
    },
  };
  
export const applicableSuggestionReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_APPLICABLE_SUGGESTION:
        return {
          ...state,
          isApplicableForSuggestion: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default applicableSuggestionReducer;