import React, { Component } from 'react';
import './Home.css';
import Announcement from '../announcement/Announcement';
import Leave from '../leave/Leave';
import Todolist from '../todoList/Todo';
import Appcenter from '../Appcenter/Appcenter';
import Documents from '../documents/Documents';
import PerformanceTimeLine from '../performanceTimeLine/PerformanceTimeLine';
import { connect } from 'react-redux';
import * as Config from '../../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../../constants/Service';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator'
import * as homeActions from '../ActivePopupAction';
import { checkActivePoup, checkPopupCount } from '../../../../../utils/CheckActivePopup';
import DynamicCustomPopupModal from '../../../../shared/dynamicCustomPopum/DynamicCustomPopup';
import { currentDateYMD } from '../../../../../utils/Utils';
import { bindActionCreators } from 'redux';
import WishesCardPopup from '../../momentsThatMatter/WishesCardPopup';
import { configStore } from "../../../../../redux/ConfigStore";
import Explore from '../../../managerScreens/landing/explore/Explore';
import DeclarationPopup from '../../../managerScreens/landing/DeclarationPopup/DeclarationPopup';
import { isCOIPopupLogin } from '../../login/LoginAction';

class Newhomescreen extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            latestMonthPayslipData: [],
            latestITSheetData: [],
            performanceData: [],
            filter: 'Latest',
            applicationMode: '',
            disabledMessage: '',
            isActive: false,
            message: '',
            note_content: '',
            note_title: '',
            note_type: '',
            image_url: '',
            image_hyperlink: '',
            is_service_Unavailable: false,
            leave_request_service_reason: '',
            is_popup_notification: false,
            notification_type: [],
            employee_first_name: '',
            showDeclaration : false,
            coi_image_url: '',
            coi_image_hyperlink: ''
        }
    }
    componentDidMount() {
        this.getPerformaceTimeline(this.state.filter);
        this.getActivePopupData();
        this.checkService();
        this.setState({ is_popup_notification: JSON.parse(localStorage.getItem('is_popup_notification')) });
        this.getMomentsPresent();
        this.getCoiDetails();
    }

    getMomentsPresent = () => {
        // console.log("getMomentsPresent Present")
        this.setState({ loading: true })
        const headers = {
            "Content-Type": "application/json",
        };
        let body = {
            emp_id: this.props.loginData.userData.Perno
            // emp_id: "00509862"
        };
        // console.log("getMomentsPresentPOP body:", body);
        localStorage.removeItem('is_popup_notification')
        fetchApiCall(Config.momentThatMatterPresent, body, headers, true, "")
            .then((res) => {
                // console.log("getMomentsPresentPOP res:", res.data.data.is_popup_notification);
                // console.log("getMomentsPresentPOP res:", res);
                if (res.data.status_code === "200") {

                    this.setState({
                        notification_type: res.data.data.notification_type,
                        employee_first_name: res.data.data.employee_first_name,
                        is_popup_notification: res.data.data.is_popup_notification,
                        loading: false,
                    });

                } else {
                    this.setState({
                        loading: false
                    });
                }
            })
            .catch((err) => {
                // console.log('getMomentsPresentPOP re')
                this.setState({
                    loading: false,
                });
            });

    }

    hideWishModel = () => {
        localStorage.removeItem('is_popup_notification');
        this.setState({
            is_popup_notification: false,
        })
    }

    getActivePopupData() {
        const headers = {
            'Content-Type': 'application/json',
        }
        let request = { 'current_date': currentDateYMD() }
        fetchApiCall(Config.getActivePopup, request, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.props.homeActions.getActivePopups(res.data.data);
                }
            }).catch(err => {
            }).then(() =>
                this.checkActivePoupDetails()
            )
    }

    checkActivePoupDetails() {
        var activeDetails = checkActivePoup('employee_landing');
        this.setState({
            isActive: activeDetails && checkPopupCount('employee_landing') ? true : false,
            message: activeDetails ? activeDetails : '',
            note_content: activeDetails.note_content ? activeDetails.note_content : '',
            note_title: activeDetails.note_title ? activeDetails.note_title : '',
            note_type: activeDetails.note_type ? activeDetails.note_type : '',
            image_url: activeDetails.image_url ? activeDetails.image_url : '',
            image_hyperlink: activeDetails.image_hyperlink ? activeDetails.image_hyperlink : '',
        }, () => {
            if (this.state.isActive) {
                this.props.homeActions.getActivePopupCount('employee_landing')
            }
        })
    }
    hideModal = () => { this.setState({ isActive: !this.state.isActive }) }

    checkService = () => {
        this.props.serviceData.availableServiceData.forEach((item, i) => {
            if (item.service_type == "leave_request" && item.is_active == false) {
                this.setState({
                    is_service_Unavailable: true,
                    leave_request_service_reason: item.reason
                })
            }
        })
    }

    getPerformaceTimeline = (filter) => {
        this.setState({ loading: true })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno,
            user_id: this.props.loginData.cfmUserDetails.user_details ? this.props.loginData.cfmUserDetails.user_details.user_id : '',
            filter: filter
        }
        fetchApiCall(Config.getPerformaceTimeline, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        performanceData: res.data.timeline_data ? res.data.timeline_data : [],
                        applicationMode: res.data.application_modes,
                        disabledMessage: res.data.message
                    });
                } else {
                    this.setState({
                        loading: false,
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                });
            })
    }

    getCoiDetails = () => {
        const coiActionData = configStore.getState().loginState.coiLoginPopup
        if (coiActionData?.is_coi_popup) {
            this.setState({
                showDeclaration : true,
                coi_image_url : coiActionData.data.image_url,
                coi_image_hyperlink : coiActionData.data.image_hyperlink
            })
        }
    }

    render() {
        const { applicationMode, disabledMessage } = this.state
        const companyName = configStore.getState().loginState.company.company_sht_txt;
        return (
            <div className="">
                <LoaderIndicator loading={this.state.loading} />
                {this.state.showDeclaration && !this.state.notification_type.length ? <DeclarationPopup image_hyperlink = {this.state.coi_image_hyperlink} image_url = {this.state.coi_image_url} onDismiss = {() => {this.setState({showDeclaration: false}); configStore.dispatch(isCOIPopupLogin())}}/> : ''}
                <div className="wrapper-grid-emp pt-12px pr-12px pl-12px">
                    <div className="leavecolumn">
                        <Leave
                            loginData={this.props.loginData}
                            serviceUnavailable={this.state.is_service_Unavailable}
                            unavailableReason={this.state.leave_request_service_reason}
                        />
                    </div>
                    <div className="documentcolumn">
                        <Documents
                            loginData={this.props.loginData}
                        />
                    </div>
                    <div className="performancecolumn">
                        <PerformanceTimeLine
                            data={this.state.performanceData}
                            loading={this.state.loading}
                            applicationMode={applicationMode}
                            disabledMessage={disabledMessage}
                        />
                    </div>
                    <div className="announcementcolumn">
                        <Announcement />
                    </div>
                    <div className="explorecolumn">
                        <Explore
                            loginData={this.props.loginData} />
                    </div>
                    <div className="todolistcolumn">
                        <Todolist
                            loginData={this.props.loginData}
                        />
                    </div>
                    <div className="appcenterhomecolumn">
                        <Appcenter />
                    </div>
                </div>
                {this.state.isActive &&
                    <DynamicCustomPopupModal
                        loading={this.state.isActive}
                        note_title={this.state.note_title}
                        note_content={this.state.note_content}
                        note_type={this.state.note_type}
                        image_url={this.state.image_url}
                        image_hyperlink={this.state.image_hyperlink}
                        message={this.state.message}
                        onDismiss={this.hideModal} />
                }
                {this.state.is_popup_notification &&
                    <WishesCardPopup
                        onDismiss={this.hideWishModel}
                        notification_type={this.state.notification_type}
                        employee_first_name={this.state.employee_first_name}
                    />
                }
            </div>
        )
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        serviceData: state.activePopupState
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        homeActions: bindActionCreators(homeActions, dispatch),
    };
}
export default connect(mapStatesToProps, mapDispatchToProps)(Newhomescreen);

