import moment from "moment";
import { toast } from 'react-toastify';
import XLSX from 'sheetjs-style';
import Resizer from 'react-image-file-resizer';

const CryptoJS = require("crypto-js");
export function currentDate() {
    let fulldate = new Date();
    let year = fulldate.getFullYear();
    let month = (fulldate.getMonth() + 1 < 10) ? '0' + (fulldate.getMonth() + 1) : (fulldate.getMonth() + 1);
    let date = (fulldate.getDate() + 1 < 10) ? '0' + fulldate.getDate() : fulldate.getDate();

    return date + '-' + month + '-' + year;
}
export function currentDateYMD() {
    let fulldate = new Date();
    let year = fulldate.getFullYear();
    let month = (fulldate.getMonth() + 1 < 10) ? '0' + (fulldate.getMonth() + 1) : (fulldate.getMonth() + 1);
    let date = (fulldate.getDate() + 1 < 10) ? '0' + fulldate.getDate() : fulldate.getDate();

    return year + '-' + month + '-' + date;
}
export function toCamelCase(str) {
    return str
        .replace(/\s(.)/g, function ($1) { return $1.toUpperCase(); })
        .replace(/\s/g, ' ')
        .replace(/^(.)/, function ($1) { return $1.toUpperCase(); });
}

export function Month() {
    let monthData = []
    monthData = [
        { value: "All Month", code: "All" },
        { code: "01", value: "January" },
        { code: "02", value: "February" },
        { code: "03", value: "March" },
        { code: "04", value: "April" },
        { code: "05", value: "May" },
        { code: "06", value: "June" },
        { code: "07", value: "July" },
        { code: "08", value: "August" },
        { code: "09", value: "September" },
        { code: "10", value: "October" },
        { code: "11", value: "November" },
        { code: "12", value: "December" }]
    return monthData
}

export function Status() {
    let status = []
    status = [
        { code: "1", value: "Active" },
        { code: "0", value: "Inactive" },
    ]
    return status
}

export function ReasonCode() {
    let reasonCode = []
    reasonCode = [
        { code: "001", value: "New Joinee" },
        { code: "002", value: "Change of Mobile Number" },
        { code: "003", value: "Loss of Mobile" },
    ]
    return reasonCode
}

export function CalenderMonth() {
    let monthData = []
    monthData = [
        { code: "01", value: "January" },
        { code: "02", value: "February" },
        { code: "03", value: "March" },
        { code: "04", value: "April" },
        { code: "05", value: "May" },
        { code: "06", value: "June" },
        { code: "07", value: "July" },
        { code: "08", value: "August" },
        { code: "09", value: "September" },
        { code: "10", value: "October" },
        { code: "11", value: "November" },
        { code: "12", value: "December" }]
    return monthData
}

export function CalenderMonthWithAll() {
    let monthData = []
    monthData = [
        { code: "All", value: "All" },
        { code: "01", value: "January" },
        { code: "02", value: "February" },
        { code: "03", value: "March" },
        { code: "04", value: "April" },
        { code: "05", value: "May" },
        { code: "06", value: "June" },
        { code: "07", value: "July" },
        { code: "08", value: "August" },
        { code: "09", value: "September" },
        { code: "10", value: "October" },
        { code: "11", value: "November" },
        { code: "12", value: "December" }]
    return monthData
}

export function LeaveMonth() {
    let monthData = []
    monthData = [
        { code: "01", value: "Jan" },
        { code: "02", value: "Feb" },
        { code: "03", value: "Mar" },
        // { code: "04", value: "Apr" },
        // { code: "05", value: "May" },
        // { code: "06", value: "June" },
        // { code: "07", value: "July" },
        // { code: "08", value: "Aug" },
        // { code: "09", value: "Sept" },
        // { code: "10", value: "Oct" },
        // { code: "11", value: "Nov" },
        // { code: "12", value: "Dec" }
    ]
    return monthData
}

export const DDMMYYYY = (date) => {
    if (date) { return moment(date).format('DD-MM-YYYY') }
    else { return null }
}

export const DDMMMYY = (date) => {
    if (date) { return moment(date).format("DD MMM YY") }
    else { return null }
}

export function admin_notification_type() {
    let type = []
    type = [
        { code: "announcement", value: "Announcement" },
        { code: "pushNotification", value: "Push Notification" },
        { code: "screenPopUp", value: "Screen Pop-Up" },
        { code: "logInPage", value: "Log-In Page" },
        { code: "leadershipAnnouncement", value: "Leadership Announcement" },
        { code: "tataBuzzNews", value: "Tata Buzz News" },
        { code: "upcomingOccasions", value: "Upcoming Occasions" },
        // { code: "appCenter", value: "App Center" },
        // { code: "tataMotorsSparsh", value: "Tata Motors Sparsh" },
        // { code: "tataMotorsTV", value: "Tata Motors TV" },
    ]
    return type
}

export function admin_blueCollar_notification_type() {
    let type = []
    type = [
        { code: "tataMotorsSparsh", value: "Tata Motors Sparsh" },
        { code: "tataMotorsTV", value: "Tata Motors TV" },
        { code: "plantUpdate", value: "Plant Update" }
    ]
    return type
}

export function dynamic_screen_type() {
    let type = []
    type = [
        { code: "employee_landing", value: "Employee Landing" },
        { code: "manager_landing", value: "Manager Landing" },
        { code: "leave", value: "Leave" },
        { code: "attendance", value: "Attendance" },
        { code: "team", value: "Team" },
        { code: "profile", value: "Profile" },
        { code: "document", value: "Document" },
        { code: "notification", value: "Notification" },
        { code: "todo", value: "To Do" },
        { code: "coi", value: "COI" },
        { code: "exit", value: "Exit Form" },
        { code: "transfer", value: "Ttransfer" },
        { code: "announcements", value: "Announcements" },

    ]
    return type
}

//-------------Eseparation Admin Status Master Data-------------------
export function esep_status() {
    let type = []
    type = [
        { code: "01", value: "Pending" },
        { code: "02", value: "Approved by DEM" },
        { code: "03", value: "Rejected by DEM" },
        { code: "04", value: "Approved by BHR and Update in Progress" },
        { code: "05", value: "Approved by BHR and Error in Posting" },
        { code: "06", value: "Approved by BHR and Updated" },
        { code: "07", value: "Rejected by BHR" },
        { code: "08", value: "Revoked by Admin" },
        { code: "09", value: "Approved by BUHR" },
        { code: "10", value: "Rejected by BUHR" },
    ]
    return type
}

export function esep_noticepaywavier_status() {
    let type = []
    type = [
        { code: "01", value: "Pending" },
        { code: "02", value: "Approved by BHR" },
        { code: "03", value: "Rejected by BHR" },
        { code: "04", value: "Revoked by Admin" },
        { code: "05", value: "Approved by BUHR" },
        { code: "06", value: "Rejected by BUHR" },
    ]
    return type
}

//-----------Admin Exit Form Status-----------------------------
export function exitFormReports_status() {
    let type = []
    type = [
        { code: "01", value: "Pending" },
        { code: "02", value: "Submitted" },
    ]
    return type
}

export function currentTime() {
    var tempDate = new Date()

    let hour = tempDate.getHours();
    let minutes = tempDate.getMinutes();
    let TimeType = '';

    if (hour <= 11) {
        TimeType = 'AM';
    }
    else {
        TimeType = 'PM';
    }

    if (hour > 12) {
        hour = hour - 12;
    }

    if (hour === 0) {
        hour = 12;
    }

    minutes = tempDate.getMinutes();

    if (minutes < 10) {
        minutes = '0' + minutes.toString();
    }

    return hour.toString() + ':' + minutes.toString() + ' ' + TimeType.toString();
}
// transfer module
export function location_change_data() {
    let type = []
    type = [
        { value: 'Location Change' },
        { value: 'No Location Change' },
    ]
    return type
}
export function location_change_data12() {
    let type = []
    type = [
        { value: 'Higher Studies' },
        { value: 'Reasons not specified' },
        { value: 'Locational issues' },
        // { value: 'Lack of Challenge in job' },
        // { value: 'Shifting Abroad' },
        // { value: 'Start own Business' },
    ]
    return type
}
export function transfer_request_type() {
    let type = []
    type = [
        { key: 'pending', value: 'Pending' },
        { key: 'approved', value: 'Approved' },
        { key: 'rejected', value: 'Rejected' },
        { key: 'deleted', value: 'Deleted' }
    ]
    return type
}

export function transfer_request_region() {
    let type = []
    type = [
        { value: 'North' },
        { value: 'East' },
        { value: 'West' },
        { value: 'South' },
        { value: 'IB' },
        { value: 'Plant' },
        { value: 'SHQ' },
        { value: 'Corp' }
    ]
    return type
}
export function time_management_status_data() {
    let type = []
    type = [
        { value: 'Positive' },
        { value: 'Negative' },
    ]
    return type
}
// --------------Admin module--------------------

export function notification_type() {
    let type = []
    type = [
        { value: 'Organization' },
        { value: 'Platform' }
    ]
    return type
}

export function notification_type1() {
    let type = []
    type = [
        { value: 'organization' },
        { value: 'platform' }
    ]
    return type
}

export function notification_typeWith_All() {
    let type = []
    type = [
        { value: 'All' },
        { value: 'Organization' },
        { value: 'Platform' }
    ]
    return type
}

export function cfmData() {
    let type = []
    type = [
        { code: "weekly", value: "Weekly" },
        { code: "lastthreemonthly", value: "Last Three Monthly" },
        { code: "monthly", value: "Monthly" },
        { code: "yesterday", value: "Yesterday" }]
    return type
}

//---------------COI module--------------------------
export function coi_type() {
    let type = []
    type = [
        { code: 'A1', value: 'I have following immediate family members,relatives or person with close personal relationships working in ' },
        { code: 'A2', value: `I have following immediate family members,relatives or person with close personal relationships working in TML's subsidiaries/associate companies.` },
        { code: 'A3', value: `I or my immediate family member or relative or person with close personal relationship had or likely to have a financial interest or any kind of association in a 'Value-Chain partner' or competitor or customer'` }
    ]
    return type
}
export function yesNo_type() {
    let type = []
    type = [
        { value: 'Yes', code: true },
        { value: 'No', code: false },
    ]
    return type
}

export function Newemoji() {
    let Newemoji = []
    Newemoji = [
        { id: 1, src: require('../assets/drishti_images/announcement/portalEmoji/like-1.svg'), description: "like" },
        { id: 2, src: require('../assets/drishti_images/announcement/portalEmoji/clap-1.svg'), description: "applaud" },
        { id: 3, src: require('../assets/drishti_images/announcement/portalEmoji/love-1.svg'), description: "love" },
        { id: 4, src: require('../assets/drishti_images/announcement/portalEmoji/innovative.svg'), description: "innovative" },
        { id: 5, src: require('../assets/drishti_images/announcement/portalEmoji/thoughtfull.svg'), description: "thoughtful" }
    ]
    return Newemoji
}


export function Portalemoji() {
    let Portalemoji = []
    Portalemoji = [
        { id: 1, src: require('../assets/drishti_images/announcement/portalEmoji/like-grey.svg'), description: "like" },
        { id: 2, src: require('../assets/drishti_images/announcement/portalEmoji/clap-grey.svg'), description: "applaud" },
        { id: 3, src: require('../assets/drishti_images/announcement/portalEmoji/love-grey.svg'), description: "love" },
        { id: 4, src: require('../assets/drishti_images/announcement/portalEmoji/thoughtfull-grey.svg'), description: "thoughtful" },
        { id: 5, src: require('../assets/drishti_images/announcement/portalEmoji/innovative-grey.svg'), description: "innovative" }
    ]
    return Portalemoji
}

export function ReactionScreenEmoji() {
    let ReactionScreenEmoji = []
    ReactionScreenEmoji = [
        { id: 1, src: require('../assets/drishti_images/announcement/reactionScreenEmoji/All.svg'), description: "All", count: '120' },
        { id: 2, src: require('../assets/drishti_images/announcement/reactionScreenEmoji/like.svg'), description: "like", count: '30' },
        { id: 3, src: require('../assets/drishti_images/announcement/reactionScreenEmoji/applaud.svg'), description: "applaud", count: '20' },
        { id: 4, src: require('../assets/drishti_images/announcement/reactionScreenEmoji/love.svg'), description: "love", count: '10' },
        { id: 5, src: require('../assets/drishti_images/announcement/reactionScreenEmoji/thoughtfull.svg'), description: "thoughtful", count: '5' },
        { id: 6, src: require('../assets/drishti_images/announcement/reactionScreenEmoji/innovative.svg'), description: "innovative", count: '55' }
    ]
    return ReactionScreenEmoji
}
// --------------------------------Profile-----------------------------------
export function validateName(str) {
    if (!str)
        return;


    return str.replace(/[^a-zA-Z\s]+/g, '');
}
export function validateNumber(str) {
    if (!str)
        return;
    return str.replace(/[^0-9]+/g, '');
}
export function validatePassportnumber(str) {
    if (!str)
        return;

    return str.replace(/[^A-Z0-9]+/g, '');
}

export function validatePANnumber(str) {
    if (!str)
        return;

    return str.replace(/[^A-Z0-9]+/g, '');
}

// ----------------------Leave------------------------
export function leaveColorData() {
    let leaveCodeData = []
    leaveCodeData = [
        {
            type: 'Approved',
            color: '#047857'
        },
        {
            type: 'Rejected',
            color: '#475569'
        },
        {
            type: 'In Progress',
            color: '#F59E0B'
        },
        {
            type: 'Error',
            color: '#30BBC3'
        },
        {
            type: 'Cancel',
            color: '#248AF8'
        },
        {
            type: 'LWOP',
            color: '#BE123C'
        },
        {
            type: 'Weekly Offs',
            color: '#8D4DF1'
        },
        {
            type: 'Public Holiday',
            color: '#6A3399'
        }
    ]
    return leaveCodeData
}
// ----------------------cfm--------------------------------------------------
export function dateFromNow(fromDate) {
    let CreatedDate = new Date(fromDate)
    let today = new Date()
    let requiredDiffrentDays

    const oneDay = 24 * 60 * 60 * 1000;
    const diffDays = Math.round(Math.abs((CreatedDate - today) / oneDay));

    if (diffDays >= 360) {
        requiredDiffrentDays = Math.floor(diffDays / 360) == 1 ? `${Math.floor(diffDays / 365)} year ago` : `${Math.floor(diffDays / 365)} years ago`
    } else if (diffDays >= 30) {
        requiredDiffrentDays = Math.floor(diffDays / 30) == 1 ? `${Math.floor(diffDays / 30)} month ago` : `${Math.floor(diffDays / 30)} months ago`
    } else if (diffDays < 30 && diffDays > 0) {
        requiredDiffrentDays = (diffDays == 1) ? `${diffDays} day ago` : `${diffDays} days ago`
    } else if (diffDays == 0) {
        requiredDiffrentDays = moment(fromDate).startOf('minute').fromNow();
    }
    return requiredDiffrentDays;
}

// ----------------------Moments That Matter--------------------------------------------------//
export function mom_notification_type() {
    let type = []
    type = [
        { value: 'Birthday' },
        { value: 'Work Anniversary' },
        { value: 'Long service awards' }
    ]
    return type
}

export function ReactionEventScreenEmoji() {
    let ReactionEventScreenEmoji = []
    ReactionEventScreenEmoji = [
        { id: 1, src: require('../assets/drishti_images/announcement/reactionScreenEmoji/All.svg'), description: "All", count: '120' },
        { id: 2, src: require('../assets/drishti_images/announcement/reactionScreenEmoji/like.svg'), description: "like", count: '30' },
        { id: 3, src: require('../assets/drishti_images/announcement/reactionScreenEmoji/applaud.svg'), description: "applaud", count: '20' },
        { id: 4, src: require('../assets/drishti_images/announcement/reactionScreenEmoji/love.svg'), description: "love", count: '10' }
    ]
    return ReactionEventScreenEmoji
}

export function decrypt(pass, skey, salt) {
    let bytes = CryptoJS.PBKDF2(skey, salt, { keySize: 48, iterations: 128 });
    let iv = CryptoJS.enc.Hex.parse(bytes.toString().slice(0, 32));
    let key = CryptoJS.enc.Hex.parse(bytes.toString().slice(32, 96));
    let decryptedpassword = CryptoJS.AES.decrypt(pass, key, { iv: iv });
    let finalDecrypt = decryptedpassword.toString(CryptoJS.enc.Utf8)
    return finalDecrypt
}

export function encrypt(pass, skey, salt) {
    let bytes = CryptoJS.PBKDF2(skey, salt, { keySize: 48, iterations: 128 });
    let iv = CryptoJS.enc.Hex.parse(bytes.toString().slice(0, 32));
    let key = CryptoJS.enc.Hex.parse(bytes.toString().slice(32, 96));
    let cipherpassword = CryptoJS.AES.encrypt(pass, key, { iv: iv });
    return cipherpassword.toString()
}

export function toaster(type, message) {
    let settings = {
        position: "bottom-center",
        hideProgressBar: true,
        className: type === "success" ? 'toastSuccess' : type === "warning" ? 'toastWarn' : 'toastError'
    }
    if (type === "toastWarn" || type === "error") {
        toast.error(message, settings)
    } else if (type === "warning") {
        toast.warn(message, settings)
    } else if (type === "success") {
        toast.success(message, settings)
    }
}

export function exportToExcel(fileName, fileExt, fileData, colNames) {
    var wb = XLSX.utils.book_new();
    //Had to create a new workbook and then add the header
    const ws = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, colNames);

    //Starting in the second row to avoid overriding and skipping headers
    XLSX.utils.sheet_add_json(ws, fileData, { origin: 'A2', skipHeader: true });
    XLSX.utils.book_append_sheet(wb, ws, fileName);
    XLSX.writeFile(wb, fileName + fileExt);
}

export function resizes(data, maxWidth, maxHeight, format, quality, rotation, outputform, minWidth, minHeight) {
    return new Promise((resolve, reject) => {
        try {
            Resizer.imageFileResizer(
                data,
                maxWidth,  // ex. 1280
                maxHeight,  //ex. 720
                format, //ex.  'PNG',
                quality, //ex.  100
                rotation, //ex.  0
                (uri) => {
                    resolve(uri);
                },
                outputform, //ex. 'file',
                minWidth,//ex. 128,
                minHeight, //ex. 72
            );
        }
        catch (err) {
            console.log("Resizer err:", err);
            reject(err);
        }
    })
}

export function byteToMB(size) {
    return Math.round(((size / 1024) / 1024) * 100) / 100
}

export const commonHeadersAcceptJson = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
}

export const commonHeadersAcceptPDF = {
    Accept: '*/*',
    'Content-Type': 'application/pdf',
}

export function toggelDiffStatus() {
    let status = []
    status = [
        { key: 'Pending', value: 'Initiated' },
        { key: 'Approved', value: 'Approved' },
        { key: 'Rejected', value: 'Rejected' },
    ]
    return status
}

export function categoryFilter() {
    let categoryFilter = []
    categoryFilter = [
        { key: 'All', value: 'ALL' },
        { key: 'Deputation', value: 'Deputation' },
        { key: 'Stretch', value: 'Stretch' },
    ]
    return categoryFilter
}

export function gemsMaintenanceModule() {
    let gemsMaintenanceModule = []
    gemsMaintenanceModule = [
        { key: 'Area', value: 'Area', endPoint: 'Area' },
        { key: 'Testimonials', value: 'Testimonials', endPoint: 'Testimonial' },
        { key: 'GemsSliderScreen', value: 'Gems Slider', endPoint: 'GemsLandingImage' },
        { key: 'Duration', value: 'Duration', endPoint: 'Duration' },
        { key: 'PIPApplicant', value: 'PIP Applicant', endPoint: 'PIPApplicant' },
        { key: 'DebarApplicant', value: 'Debar Applicant List', endPoint: 'DebarApplicant' },
        { key: 'OtherProjectApplicant', value: 'Other Project Applicant List', endPoint: 'OldApplicant' },
        { key: 'isApplicationRejectScreen', value: 'Rejected Application Reasons', endPoint: 'Reason' },
    ]
    return gemsMaintenanceModule
}
//------For attendance module-------------------
export function attendanceLeaveType() {
    let type = []
    type = [
        { code: "All", value: "All" },
        { code: "Work From Home", value: "Work From Home" },
        { code: "Cert Attd (Away On Duty)", value: "Cert Attd (Away On Duty)" },
        { code: "Certified No Swipe", value: "Certified No Swipe" },
    ]
    return type
}
//--------For Clearance module--------------------
export const clearanceRequestStatus = [
    // { id: 0, title: "All", value: "requests" },
    { id: 1, title: "Pending", value: "pending" },
    { id: 2, title: "Completed", value: "completed" },
    { id: 2, title: "Revoked", value: "revoked" },
]
export const cleRequestViewFilter = [
    { id: 0, title: "All", value: "All" }
]


//------------------CSV To Excel------------------------
export const csvToExcel = (url,contentDisposition,filename)=>{
    if (contentDisposition && contentDisposition.indexOf('filename=') !== -1) {
      const matches = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
      if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, ''); 
      }
  }
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
  toast.success("Your document has been downloaded.", {
      position: "bottom-center",
      hideProgressBar: true,
      className: "toastSuccess",
  });
  }



//-------------------Export All Data----------------------
 export const exportAllData = (exportData, name) => {
    if (exportData) {
      let BdcRecords = exportData;
      let colNames = [
        ...new Set(BdcRecords.flatMap((obj) => Object.keys(obj))),
      ];
      colNames = colNames.map(name => 
        name
          .replace(/_/g, ' ') 
          .replace(/\b\w/g, char => char.toUpperCase()) 
      );
      colNames = [colNames];
      if (BdcRecords.length == 0) {
        toast.warn("No Data found", {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      } else {
        exportToExcel(name, ".xlsx", BdcRecords, colNames);
        toaster("success", "Report downloaded successfully");
      }
    }
  };