import React, { Component } from 'react';
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import Submission from '../paySpace/Submission';
import ChoicePayMain from '../paySpace/choicePay/ChoicePayMain';
import CompensationStructure from '../paySpace/choicePay/CompensationStructure';
import ChoicePayHistory from '../paySpace/choicePay/ChoicePayHistory';
import '../conflictOfInterest/COIStyle.css';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { configStore } from "../../../../redux/ConfigStore";
import './PaySpaceStyle.css';
import Conveyance from './choicePay/Conveyance';
import LocalConveynaceHomeScreen from '../../managerScreens/approval/LocalConveynaceHomeScreen'
import { getLocalConveyananceVisibleStatus } from '../../../../constants/api.service';

const TaxRegime = `Tax Regime`
const ChoicePaySelection = `Choice Pay Selection`
const ChoicePayHistoryText = `Choice Pay History`
const CompensationStructureText = `Compensation Structure`
const ConveyanceText = `Other Details`
const LocalConveyanceText = `Local Conveyance`

class PaySpaceHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isChoicePay: false,
            isTaxRegime: true,
            isHistory: false,
            isCompensation: false,
            isConveyance: false,
            isEmployeeAccessChoicePay: false,
            selectTaxRegime: '',
            choicePayHistory: {},

            isInChoicePaySchedule: false,
            choicePayDataPresent: false,
            isLocalConveynance: false,
            instructionData: [],

            isTaxRegimeVisible: false,
            isChoicepaySelectionVisible: false,
            isChoicepayHistoryVisible: false,
            isStandardStructureVisible: false,
            isChoicepayStructureVisible: false,
            isOtherDetailsVisible: false,
            isLocalConveynanceVisible: false
        }
    }

    componentDidMount() {
        this.getPayspaceVisibility();
        this.setLocalConveyananceVisibility()
    }

    //----------Payspace Tab Visibility API-------------------------
    getPayspaceVisibility = () => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            egroup: this.props.loginData.userData.Egroup ? this.props.loginData.userData.Egroup : '',
            esubgroup: this.props.loginData.userData.Esubgroup ? this.props.loginData.userData.Esubgroup : '',
        }
        fetchApiCall(Config.getPayspaceVisibility, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200 || res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        isTaxRegimeVisible: res.data.data.is_tax_regime_visible ? res.data.data.is_tax_regime_visible : false,
                        isChoicepaySelectionVisible: res.data.data.is_choicepay_selection_visible ? res.data.data.is_choicepay_selection_visible : false,
                        isChoicepayHistoryVisible: res.data.data.is_choicepay_history_visible ? res.data.data.is_choicepay_history_visible : false,
                        isStandardStructureVisible: res.data.data.is_standard_structure_visible ? res.data.data.is_standard_structure_visible : false,
                        isChoicepayStructureVisible: res.data.data.is_choicepay_structure_visible ? res.data.data.is_choicepay_structure_visible : false,
                        isOtherDetailsVisible: res.data.data.is_other_details_visible ? res.data.data.is_other_details_visible : false,
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        isTaxRegimeVisible: false,
                        isChoicepaySelectionVisible: false,
                        isChoicepayHistoryVisible: false,
                        isStandardStructureVisible: false,
                        isChoicepayStructureVisible: false,
                        isOtherDetailsVisible: false,
                    });
                    toast.warn(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }
            }).catch(err => {
                console.log('getPayspaceVisibility err--->', err);
                this.setState({
                    loading: false,
                    isTaxRegimeVisible: false,
                    isChoicepaySelectionVisible: false,
                    isChoicepayHistoryVisible: false,
                    isStandardStructureVisible: false,
                    isChoicepayStructureVisible: false,
                    isOtherDetailsVisible: false,
                });
            })
    }

    //----------Local Conveyance Tab Visibility API-------------------------
    setLocalConveyananceVisibility = () => {
        this.setState({
            loading: true
        })

        getLocalConveyananceVisibleStatus()
            .then(res => {
                if (res.status == 200) {
                    this.setState({
                        isLocalConveynanceVisible: res.data.data.local_conveyance_applicable ? res.data.data.local_conveyance_applicable : false,
                        loading: false
                    })
                }
                else {
                    this.setState({
                        isLocalConveynanceVisible: false,
                        loading: false
                    })
                }
            }).catch(err => {
                console.log('setLocalConveyananceVisibility err--->', err);
                this.setState({
                    isLocalConveynanceVisible: false,
                    loading: false
                });
            })
    }

    //----------Choice Pay Selection Applicable API-------------------------
    employeeAccessForChoicePay = () => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno ? this.props.loginData.userData.Perno : '',
            comp_code: this.props.loginData.userData.CompCode ? this.props.loginData.userData.CompCode : '',
            egroup: this.props.loginData.userData.Egroup ? this.props.loginData.userData.Egroup : '',
            esubgroup: this.props.loginData.userData.Esubgroup ? this.props.loginData.userData.Esubgroup : '',
        }
        fetchApiCall(Config.employeeAccessForChoicePay, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200 || res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        isEmployeeAccessChoicePay: res.data.data.employee_access_for_choice_pay ? res.data.data.employee_access_for_choice_pay : false,
                        selectTaxRegime: res.data.data.select_tax_regime ? res.data.data.select_tax_regime : '',
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        isEmployeeAccessChoicePay: false,
                        selectTaxRegime: ''
                    });
                    toast.warn(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
            })
    }

    //------------Get Choice Pay History API-----------------------
    getChoicePayHistory = () => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno ? this.props.loginData.userData.Perno : '',
            egroup: this.props.loginData.userData.Egroup ? this.props.loginData.userData.Egroup : '',
            esubgroup: this.props.loginData.userData.Esubgroup ? this.props.loginData.userData.Esubgroup : '',
            comp_code: this.props.loginData.userData.CompCode ? this.props.loginData.userData.CompCode : '',
        }
        fetchApiCall(Config.getChoicePayHistory, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200 || res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        choicePayHistory: res.data.data ? res.data.data : {},
                    });
                }
                else {
                    this.setState({
                        loading: false,
                        choicePayHistory: {}
                    });
                    toast.warn(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }
            }).catch(err => {
                console.log("getChoicePayHistory error ", err)
                this.setState({
                    loading: false
                });
            })
    }

    //------------Get Choice Pay Instructions API-----------------------
    getChoicePayInstructions = () => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno ? this.props.loginData.userData.Perno : '',
            comp_code: this.props.loginData.userData.CompCode ? this.props.loginData.userData.CompCode : '',
            esubgup: this.props.loginData.userData.Esubgroup ? this.props.loginData.userData.Esubgroup : '',
        }
        fetchApiCall(Config.getChoicePayInstructions, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200 || res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        instructionData: res.data.data.instructions,
                        choicePayDataPresent: res.data.data.choice_pay_data_present,
                        isInChoicePaySchedule: res.data.data.is_in_choice_pay_schedule,
                    });
                }
                else {
                    this.setState({
                        loading: false,
                        instructionData: [],
                        choicePayDataPresent: false,
                        isInChoicePaySchedule: false,
                    });
                    toast.warn(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }
            }).catch(err => {
                console.log("getChoicePayInstructions error ", err)
                this.setState({
                    loading: false
                });
            })
    }

    togglePaySapce = () => {
        this.setState({ isTaxRegime: true, isChoicePay: false, isHistory: false, isCompensation: false, isConveyance: false, isLocalConveynance: false })
    }

    toggleChoicePay = () => {
        this.setState({
            isTaxRegime: false, isChoicePay: true, isHistory: false, isCompensation: false, isConveyance: false, isLocalConveynance: false
        },
            () => this.getChoicePayInstructions(),
            this.employeeAccessForChoicePay())
    }

    toggleChoiceHistory = () => {
        this.setState({
            isTaxRegime: false, isChoicePay: false, isHistory: true, isCompensation: false, isConveyance: false, isLocalConveynance: false
        }, () => this.getChoicePayHistory())
    }

    toggleCompensation = () => {
        this.setState({ isTaxRegime: false, isChoicePay: false, isHistory: false, isCompensation: true, isConveyance: false, isLocalConveynance: false })
    }

    toggleConveyance = () => {
        this.setState({ isTaxRegime: false, isChoicePay: false, isHistory: false, isCompensation: false, isConveyance: true, isLocalConveynance: false })
    }

    toggleLocalConveyance = () => {
        this.setState({ isTaxRegime: false, isChoicePay: false, isHistory: false, isCompensation: false, isConveyance: false, isLocalConveynance: true })
    }

    render() {
        const companyName = configStore.getState().loginState.company.company_sht_txt
        const { isTaxRegimeVisible, isChoicepaySelectionVisible, isChoicepayHistoryVisible, isStandardStructureVisible, isChoicepayStructureVisible, isOtherDetailsVisible, isLocalConveynanceVisible } = this.state;
        return (
            <div className="row pb-0 pt-12px px-12px">
                <LoaderIndicator loading={this.state.loading} />
                <div className='col-md-12 justify-content-center align-item-center px-0'>
                    <div className="col-md-12 card-new m-rem">
                        <div className='row mt-3 px-3 ss col-md-12'>
                            {isTaxRegimeVisible &&
                                <div className={this.state.isTaxRegime ? "tab-active" : "tab-inactive"} onClick={this.togglePaySapce}>
                                    {TaxRegime}
                                </div>
                            }
                            { isChoicepaySelectionVisible &&
                                <div className={this.state.isChoicePay ? "tab-active" : "tab-inactive"} onClick={this.toggleChoicePay}>
                                    {ChoicePaySelection}
                                </div>}
                            { isChoicepayHistoryVisible &&
                                <div className={this.state.isHistory ? "tab-active" : "tab-inactive"} onClick={this.toggleChoiceHistory}>
                                    {ChoicePayHistoryText}
                                </div>}
                            {companyName !== "TMLBSL" && (isStandardStructureVisible || isChoicepayStructureVisible) &&
                                <div className={this.state.isCompensation ? "tab-active" : "tab-inactive"} onClick={this.toggleCompensation}>
                                    {CompensationStructureText}
                                </div>}
                            {companyName !== "TMLBSL" && isOtherDetailsVisible &&
                                <div className={this.state.isConveyance ? "tab-active" : "tab-inactive"} onClick={this.toggleConveyance}>
                                    {ConveyanceText}
                                </div>}
                            {companyName !== "TMLBSL" && isLocalConveynanceVisible &&
                                <div className={this.state.isLocalConveynance ? "tab-active" : "tab-inactive"} onClick={this.toggleLocalConveyance}>
                                    {LocalConveyanceText}
                                </div>
                            }
                        </div>
                        <div className='mt-3px'><hr /></div>
                        <div className='w-100'>
                            {this.state.isTaxRegime &&
                                <div>
                                    <Submission
                                        getChoicePayInstructions={() => this.getChoicePayInstructions()} />
                                </div>}
                            {this.state.isChoicePay &&
                                <div className='choice-pay-screen-scroll'>
                                    <ChoicePayMain
                                        instructionData={this.state.instructionData}
                                        choicePayDataPresent={this.state.choicePayDataPresent}
                                        isInChoicePaySchedule={this.state.isInChoicePaySchedule}
                                        isEmployeeAccessChoicePay={this.state.isEmployeeAccessChoicePay}
                                        selectTaxRegime={this.state.selectTaxRegime}
                                        getChoicePayInstructions={() => this.getChoicePayInstructions()}
                                    />
                                </div>}
                            {this.state.isHistory &&
                                <div className='choice-pay-screen-scroll'>
                                    <ChoicePayHistory
                                        choicePayHistory={this.state.choicePayHistory} />
                                </div>}
                            {this.state.isCompensation &&
                                <div>
                                    <CompensationStructure
                                        isChoicepayStructureVisible={isChoicepayStructureVisible}
                                        isStandardStructureVisible={isStandardStructureVisible}
                                    />
                                </div>}
                            {this.state.isConveyance &&
                                <div>
                                    <Conveyance />
                                </div>}
                            {this.state.isLocalConveynance &&
                                <div>
                                    <LocalConveynaceHomeScreen />
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
    }
}

export default connect(mapStatesToProps)(PaySpaceHome);