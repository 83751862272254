import React, { Component } from "react";
import { suggestionMasterScreen } from "./constant";
import moment from "moment";
import { connect } from "react-redux";
import view_action from "../../../../../assets/drishti_images/transfer/view_action.svg";
import edit from "../../../../../assets/images/cfm/edit.svg";
import delete_action from "../../../../../assets/drishti_images/transfer/delete_action.svg";
// import default_profile from '../../../../../../assets/drishti_images/profile/default_profile.svg';
import DataNotFound from "../../../../shared/notFound/DataNotFound";

function MasterScreenDashboard({ tabName, masterScreenListData, onAction }) {
  return (
    <>
      <div className="table-container">
        <div className="main-contain">
          <div className="row align-item-center justify-content-start ">
          {
            (masterScreenListData?.length !== 0) ? 
            <>
              <table className="table gemsAdmin">
            <thead className="w-100">
              <tr className="w-100">
                {/* scheme list */}
                {
                  <th width="5%">
                    <div className="thead">{suggestionMasterScreen.Sr}</div>
                  </th>
                }
                {tabName.key == suggestionMasterScreen.SchemeList && (
                  <th width="40%">
                    <div className="thead">
                      {suggestionMasterScreen.SchemeName}
                    </div>
                  </th>
                )}
                {tabName.key == suggestionMasterScreen.SchemeList && (
                  <th width="15%">
                    <div className="thead">
                      {suggestionMasterScreen.StartDate}
                    </div>
                  </th>
                )}
                {tabName.key == suggestionMasterScreen.SchemeList && (
                  <th width="15%">
                    <div className="thead">
                      {suggestionMasterScreen.EndDate}
                    </div>
                  </th>
                )}
                {
                  <th width="15%">
                    <div className="thead">Image View</div>
                  </th>
                }
                {
                  <th width="10%">
                    <div className="thead">Action</div>
                  </th>
                }
              </tr>
            </thead>
            <tbody className="adminScreentbody w-100">
              {
              
                masterScreenListData?.map((listValue, index) => {
                  return (
                    <tr key={index}>
                      <td width="5%">
                        {" "}
                        <div className="td-choice-pay d-flex amount-justify-content-start">
                          {index + 1}
                        </div>{" "}
                      </td>
                      {tabName.key == suggestionMasterScreen.SchemeList && (
                        <td width="40%">
                          <div className="td-choice-pay d-flex amount-justify-content-start">
                            {listValue.scheme_name
                              ? listValue.scheme_name
                              : "-"}
                          </div>
                        </td>
                      )}
                      {tabName.key == suggestionMasterScreen.SchemeList && (
                        <td width="15%">
                          <div className="td-choice-pay d-flex amount-justify-content-start">
                            { listValue.start_date ? moment(listValue.start_date).format('DD-MM-YYYY') : "-" }
                          </div>
                        </td>
                      )}
                      {tabName.key == suggestionMasterScreen.SchemeList && (
                        <td width="15%">
                          <div className="td-choice-pay d-flex amount-justify-content-start">
                            { listValue.end_date ? moment(listValue.end_date).format('DD-MM-YYYY') : "-" }
                          </div>
                        </td>
                      )}
                      <td width="15%">
                        <div onClick={() => onAction(listValue, "view")}>
                          <img
                            alt="view"
                            src={view_action}
                            style={{
                              width: "25px",
                              cursor: "pointer",
                              marginRight: "10px",
                            }}
                          />
                        </div>
                      </td>

                      <td width="10%">
                        <span className="d-flex">
                          {tabName.key == suggestionMasterScreen.SchemeList ? (
                            <div onClick={() => onAction(listValue, "update")}>
                              <img
                                alt="update"
                                src={edit}
                                style={{
                                  width: "25px",
                                  cursor: "pointer",
                                  marginRight: "10px",
                                }}
                              />
                            </div>
                          ) : null}
                          <div onClick={() => onAction(listValue, "delete")}>
                            <img
                              alt="delete"
                              src={delete_action}
                              style={{ width: "25px", cursor: "pointer" }}
                            />
                          </div>
                        </span>
                      </td>
                    </tr>
                  );
                })
              
              }
            </tbody>
              </table>
            </>
            :
            <>
              <div style={{ margin: "10% 20% 30% 40%" }}>
                <DataNotFound />
              </div>
            </>
          }
          </div>
        </div>
      </div>
    </>
  );
}

const mapStatesToProps = (state) => {
  return {
    loginData: state.loginState,
  };
};

export default connect(mapStatesToProps)(MasterScreenDashboard);
