import React, { useState, useEffect } from 'react';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { assetTableCols, clearancePopupDetails, commentField, contactField, uploadNote } from './constants';
import CharacterCounter from '../../../shared/CharacterCounter';
import DataNotFound from '../../../shared/notFound/DataNotFound';
import { AttachmentInput } from './AttachmentInput';
import { addClearanceAttachment, deleteClearanceAttachment, getAgencyWiseClearance, postClearanceComment, updateClearanceStatus, getAdminEmployeeAssetsDetail ,updateClearanceHoldStatusAndRemarks} from '../../../../constants/api.service';
import { constantVals } from './constants.js'
import { DDMMYYYY, DDMMMYY, toaster } from '../../../../utils/Utils.js';
import moment from 'moment';
import DynamicDropdown from "../../../shared/components/DynamicDropdown";
import ConfirmationPopUp from './ConfirmationPopUp.js';
import Success_icon from '../../../../assets/drishti_images/manager/landing/Success_icon.svg';
import { someThingWentWrong } from '../../../../constants/Messages.js';
import ClearanceTable from './ClearanceTable.js';
import ClearancePreviewComponent from '../../employeeScreens/eSeparation/clearance/ClearancePreviewComponent.js';
import axios from 'axios';

function ClearanceDetailsPopup(props) {
    //-------------------------All States------------------------------------
    const { onDismiss, screenType, isRemarkPopUp, title, commentOnly, clearanceId, agencyConfigId, agencyName, showAgencyDetails = false, viewOnly = false, selectedEmployee } = props;
    const [loading, setLoading] = useState(false)
    const [remark, setRemark] = useState('')
    const [comment, setComment] = useState('')
    const [recoveryAmount, setRecoveryAmount] = useState('')
    const [paymentAmount, setPaymentAmount] = useState('')
    const [clearanceDetails, setClearanceDetails] = useState(null)
    const [employeeAssetsDetails, setEmployeeAssetsdetails] = useState([]);
    const [attachments, setAttachments] = useState([])
    const [confirmPopUp, setConfirmPopUp] = useState(false)
    const [payAmountError, setPayAmountError] = useState(null)
    const [recAmountError, setRecAmountError] = useState(null)
    const [commentError, setCommentError] = useState(null)
    const [remarkError, setRemarkError] = useState(null)
    const status = [{'label':'Completed','title':'Completed'}, {'label':'Hold','title':'Hold'}];
    const [selectedStatus, setSelectedStatus] = useState({'label':'Completed','title':'Completed'});
    // const NumberRegex = /^[0-9]*$/;
    const NumberRegex = /^[0-9]*\.?\d{0,2}$/;
    const minRemarkChar = 2

    const handleStatusChange = (data) => {
        const status = data;
        setSelectedStatus(status);
    };

    //---------------------------All Api's and functions---------------------------
    const getClearanceDetails = () => {
        setLoading(true)
        let params = {
            "agency_config_id": agencyConfigId
        }
        getAgencyWiseClearance(props, clearanceId, params)
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    setClearanceDetails(res.data.data)
                    if (agencyName == "IT Asset Clearance" || title == "IT Asset Clearance") {
                        getEmployeeDetails(res.data.data)
                    }
                }
                else {
                    toaster("warning", res?.data?.message)
                    setLoading(false)
                }
            })
            .catch((error) => {
                console.log("error in Get Clearance Agency Details", error);
                // toaster("warning", error?.data?.message ?? someThingWentWrong)
                setLoading(false)
            })
            .finally(() => {
                if (!(title == "IT Asset Clearance" || agencyName == "IT Asset Clearance")) {
                    setLoading(false)
                }
            })
    }


    const getEmployeeDetails = (data) => {
        setLoading(true)
        let params = {
            "emp_id": data.employee_details.Perno
        }
        getAdminEmployeeAssetsDetail(props, params)
            .then((res) => {
                if ((res.status === 200 || res.status === 201) && res?.data?.data != "No Record Found !!") {
                    setEmployeeAssetsdetails(res.data.data)
                }
                else { setEmployeeAssetsdetails([]) }
            })
            .catch((error) => {
                console.log("error in Get Clearance Agency Details", error);
                setEmployeeAssetsdetails([])
                toaster("warning", error?.data?.message ?? someThingWentWrong)
            })
            .finally(() => setLoading(false))
    }
    const postComment = () => {
        if (comment !== "") {
            setLoading(true)
            let req_body = { "remark": comment }
            postClearanceComment(props, clearanceDetails.request[0].id, req_body)
                .then((res) => {
                    if (res.status === 200 || res.status === 201) { toaster("success", res.data.message) }
                    else { toaster("warning", res.data.message) }
                })
                .catch((error) => {
                    console.log("error in post comment api", error);
                    toaster("warning", error.data.message)
                })
                .finally(() => {
                    setLoading(false)
                    onDismiss()
                }
                )
        }
        else {
            toaster("warning", "Comments are required to update clearance")
        }
    }
    const updateClearance = () => {
        setLoading(true)
        let req_body = {
            status: selectedStatus?.title,
            remark: remark,
            recovery_amount: recoveryAmount,
            pay_amount: paymentAmount,
            attachment_path: attachments
        }
        updateClearanceStatus(props, clearanceDetails.request[0].id, req_body)
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    toaster("success", res.data.message)
                    onDismiss()
                }
                else { toaster("warning", res.data.message) }
            })
            .catch((error) => { toaster("warning", error.data.message) })
            .finally(() => { setLoading(false) })
    }
    const addAttachment = (data) => {
        setLoading(true)
        const { employee_details } = clearanceDetails
        let req_body = {
            clearance_id: clearanceId,
            emp_id: employee_details["Perno"],
            agency_name: agencyName,
            attachment: data.attachment,
            attachment_name: data.attachment_name,
            attachment_type: data.attachment_type
        }
        addClearanceAttachment(props, req_body)
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    setAttachments([...attachments,
                    {
                        id: data.id,
                        attachment: res.data.data.attachment_presigned_url,
                        attachment_name: res.data.data.attachment_name,
                        attachment_type: res.data.data.attachment_type,
                        attachment_path: res.data.data.attachment_path
                    }
                    ])
                    toaster("success", res.data.message)
                }
                else { toaster("warning", res?.data?.message ?? "something went wrong!") }
            })
            .catch((error) => { toaster("warning", error?.data?.message ?? someThingWentWrong) })
            .finally(() => { setLoading(false) })
    }
    const deleteAttachment = async (data) => {
        setLoading(true)
        let req_body = { attachment_path: data.attachment_path }
        if (data.attachment_path.length > 0) {
            deleteClearanceAttachment(props, req_body)
                .then(
                    (res) => {
                        if (res.status === 200 || res.status === 201) {
                            const newArr = attachments?.filter(object => { return object.id != data.id; });
                            setAttachments(newArr)
                            toaster("success", res.data.message)
                        }
                        else { toaster("warning", res?.data?.message ?? someThingWentWrong) }
                    }
                )
                .catch((error) => { toaster("warning", error?.data?.message ?? someThingWentWrong) })
                .finally(() => { setLoading(false) })
        }
    }
    const handleBack = async () => {
        let attachmentPathList = { attachment_path: attachments.map((attachment) => { return attachment.attachment_path }) }
        await deleteAttachment(attachmentPathList)
        onDismiss()
    }
    const handleConfirmation = () => {
        setConfirmPopUp(false)
        commentOnly ? postComment() : updateClearance()
    }
    const handleUpdate = () => {
        if (commentOnly) {
            if (comment == "") {
                setCommentError("This field is required")
            }
            else {
                setConfirmPopUp(true)
            }
        }
        else {
            if (selectedStatus?.title !== 'Hold'){
                if (paymentAmount == '') {
                    setPayAmountError("This field is required")
                }
                else if (recoveryAmount == '') {
                    setRecAmountError("This field is required")
                }
                else if (remark == '') {
                    setRemarkError("This field is required")
                }
                else if (remark.length < minRemarkChar) {
                    setRemarkError(`Minimum length for Remarks must be ${minRemarkChar} characters`)
                }
                else {
                    setConfirmPopUp(true)
                }
        }else{
            if (remark == '') {
                setRemarkError("This field is required")
            }
            else if (remark.length < minRemarkChar) {
                setRemarkError(`Minimum length for Remarks must be ${minRemarkChar} characters`)
            }
            else {
                setConfirmPopUp(true)
            }
        }

        }

    }
    const updatePayAmount = (value) => {
        if (value == '' || value.match(NumberRegex)) {
            setPayAmountError(null)
            setPaymentAmount(value)
        }
    }
    const updateRecAmount = (value) => {
        if (value == '' || value.match(NumberRegex)) {
            setRecAmountError(null)
            setRecoveryAmount(value)
        }
    }
    const updateRemark = (value) => {
        setRemark(value)
        if (value.length < minRemarkChar) {
            setRemarkError(`Minimum length for Remarks must be ${minRemarkChar} characters`)
        }
        else if (value !== '') {
            setRemarkError(null)
        }
        setRemark(value)
    }
    useEffect(() => {
        !isRemarkPopUp && getClearanceDetails()
    }, [])

    return (
        <div className={"modal popup-box "}>
            <LoaderIndicator loading={loading} />
            <div className={"modal-dialog modal-dialog-centered modal-dialog-scrollable w-60rem"}>
                <div className={`modal-content bg-white border_radius_12px max-height-80 ` + `w-60vw`}>
                    <div className="modal-header mx-2">
                        <div className='row'>
                            <h4 className="popup-title mt-2">{title}</h4>
                        </div>
                        <button type="button" className="close pt-4" data-dismiss="modal" onClick={() => { handleBack() }}>&times;</button>
                        <div className='solid-border'></div>
                    </div>

                    {
                        clearanceDetails || isRemarkPopUp ?
                            <div className='column mt-3 scroll-y'>
                                {screenType == 'ClearanceInitiate' ?
                                    <div>
                                        <div className='d-flex row'>
                                            {clearancePopupDetails.map((listValue, index) => (
                                                <div key={index} className="col flex-basis-50">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="text14_bold">{listValue.label}</div>
                                                            <div className="text14_light mt-1">{clearanceDetails[listValue.key] ? clearanceDetails[listValue.key] : '-'}</div>
                                                            <div className='mt-1 mb-3'><hr /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div><hr /></div>

                                    </div>
                                    :
                                    <>
                                        {!isRemarkPopUp && <>
                                            <div className='toggle-card-heading ml-10 mb-10'>{constantVals.EmployeeDetails}</div>
                                            <div className='d-flex flex-column'>
                                                <div className='row w-100 justify-content-between mb-3'>
                                                    <div className="col-4">
                                                        <div className="text14_bold">{"Employee ID"}</div>
                                                        <div className="text14_light mt-1">{clearanceDetails?.["employee_details"]?.["Perno"] ?? '--'}</div>
                                                        <div className='mt-1'><hr /></div>
                                                    </div>
                                                    <div className="col-4">
                                                        <div className="text14_bold">{"Employee Name"}</div>
                                                        <div className="text14_light mt-1">{clearanceDetails?.["employee_details"]?.["CompName"] ?? '--'}</div>
                                                        <div className='mt-1'><hr /></div>
                                                    </div>
                                                    <div className="col-4">
                                                        <div className="text14_bold">{"Organization Unit 1"}</div>
                                                        <div className="text14_light mt-1">{clearanceDetails?.["employee_details"]?.["OuLevel1LongTxt"] ?? '--'}</div>
                                                        <div className='mt-1'><hr /></div>
                                                    </div>
                                                </div>
                                                <div className='row w-100 justify-content-between mb-3'>

                                                    <div className="col-4">
                                                        <div className="text14_bold">{"Person Area"}</div>
                                                        <div className="text14_light mt-1">{clearanceDetails?.["employee_details"]?.["PersArea"] ?? '--'}</div>
                                                        <div className='mt-1'><hr /></div>
                                                    </div>
                                                    <div className="col-4">
                                                        <div className="text14_bold">{"Person SubArea"}</div>
                                                        <div className="text14_light mt-1">{clearanceDetails?.["employee_details"]?.["PSubarea"] ?? '--'}</div>
                                                        <div className='mt-1'><hr /></div>
                                                    </div>
                                                    <div className="col-4">
                                                        <div className="text14_bold">{"Level"}</div>
                                                        <div className="text14_light mt-1">{clearanceDetails?.["employee_details"]?.["Esgtxt"] ?? '--'}</div>
                                                        <div className='mt-1'><hr /></div>
                                                    </div>
                                                </div>
                                                <div className='row w-100 justify-content-between mb-3'>
                                                    <div className="col-4">
                                                        <div className="text14_bold">{"Employee Group"}</div>
                                                        <div className="text14_light mt-1">{clearanceDetails?.["employee_details"]?.["Ptext"] ?? '--'}</div>
                                                        <div className='mt-1'><hr /></div>
                                                    </div>
                                                    <div className="col-4">
                                                        <div className="text14_bold">{"Agency Clearance Completion Date"}</div>
                                                        <div className="text14_light mt-1">{DDMMMYY(clearanceDetails?.["request"]?.[0]?.["release_date"]) ?? '--'}</div>
                                                        <div className='mt-1'><hr /></div>
                                                    </div>
                                                    <div className="col-4">
                                                        <div className="text14_bold">{"Updated By"}</div>
                                                        <div className="text14_light mt-1">
                                                            {clearanceDetails?.["request"]?.[0]?.["released_by_name"] ?
                                                                (clearanceDetails?.["request"]?.[0]?.["released_by_name"] + ` (${clearanceDetails?.["request"]?.[0]?.["released_by"] ?? "-- "})`)
                                                                : '--'}
                                                        </div>
                                                        <div className='mt-1'><hr /></div>
                                                    </div>

                                                </div>
                                                <div className='row w-100 mb-3'>
                                                    <div className="col-4">
                                                        <div className="text14_bold">{"Employee Personal Contact"}</div>
                                                        <div className="text14_light mt-1">{selectedEmployee?.["employee_personal_mobile"]?? '--'}</div>
                                                        <div className='mt-1'><hr /></div>
                                                    </div>
                                                    <div className="col-4">
                                                        <div className="text14_bold">{"Employee Personal Email"}</div>
                                                        <div className="text14_light mt-1">{selectedEmployee?.["employee_personal_email"]?? '--'}</div>
                                                        <div className='mt-1'><hr /></div>
                                                    </div>
                                                </div>
                                            </div>

                                            {showAgencyDetails &&
                                                <>
                                                    {/* <div><hr /></div> */}
                                                    <div className='toggle-card-heading mt-10px ml-10 mb-10'>{constantVals.AgencyContactDetails}</div>
                                                    <div className='d-flex flex-column'>
                                                        {
                                                            clearanceDetails?.["request"]?.[0]?.["contact"].length > 0 ? clearanceDetails["request"][0]["contact"].map((contact, index) => (
                                                                <div className='row w-100 justify-content-between mb-3' key={index}>
                                                                    <div className="col-4">
                                                                        <div className="text14_bold">{"SPOC " + (index + 1) + " Name"} :</div>
                                                                        <div className="text14_light mt-1"> {contact["agent"]?.["first_name"] + " " + contact["agent"]?.["last_name"] ?? "--"}</div>
                                                                        <div className='mt-1'><hr /></div>
                                                                    </div>
                                                                    {contactField.map((field, fieldIndex) => (
                                                                        <div className="col-4" key={fieldIndex}>
                                                                            <div className="text14_bold">{field.label.slice(0, 5) + ` ${index + 1} ` + field.label.slice(5)} :</div>
                                                                            <div className="text14_light mt-1 word-wrap w-150px"> {contact["agent"]?.[field.key] ?? "--"}</div>
                                                                            <div className='mt-1'><hr /></div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            )) : (
                                                                <div className='h-90-percent w-100'>
                                                                    <DataNotFound />
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                    <div className='mt-1'><hr /></div>
                                                </>
                                            }
                                        </>
                                        }
                                        {
                                            (title == "IT Asset Clearance" || agencyName == "IT Asset Clearance") &&
                                            <>
                                                {employeeAssetsDetails.length > 0 ?
                                                    <div>
                                                        <div className='toggle-card-heading ml-3 mt-10px mb-10'>{constantVals.AssetsDetails}</div>
                                                        <ClearanceTable
                                                            data={employeeAssetsDetails}
                                                            columns={assetTableCols}
                                                            hasAction={false}
                                                        />
                                                        <div><hr /></div>
                                                    </div>
                                                    :
                                                    <div>
                                                        <div className='toggle-card-heading ml-3 mt-10px mb-10'>{constantVals.AssetsDetails}</div>
                                                        <DataNotFound />
                                                        <div><hr /></div>
                                                    </div>
                                                }
                                            </>
                                        }
                                        {
                                            clearanceDetails?.presigned_urls && clearanceDetails?.presigned_urls.length > 0 &&
                                            <>
                                                <ClearancePreviewComponent data={clearanceDetails.presigned_urls} />
                                                <div><hr /></div>
                                            </>
                                        }
                                        {commentOnly &&
                                            <>
                                                {/* <div><hr /></div> */}
                                                <div className='toggle-card-heading mt-10px ml-10 mb-10 mt-10px'>{constantVals.timeline}</div>
                                                <div className='d-flex row'>
                                                    <div className="col flex-basis-50">
                                                        <div className="row">
                                                            <div className="">
                                                                <div className="text14_bold">{constantVals.PayAmount}</div>
                                                                <div className="text14_light mt-1"> {clearanceDetails?.["request"]?.[0]?.pay_amount ?? "--"}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col flex-basis-50">
                                                        <div className="row">
                                                            <div className="">
                                                                <div className="text14_bold">{constantVals.RecAmount}</div>
                                                                <div className="text14_light mt-1"> {clearanceDetails?.["request"]?.[0]?.recovery_amount ?? "--"}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex row'>
                                                    <div className="col flex-basis-50">
                                                        <div className="row">
                                                            <div className=''>
                                                                <div className="text14_bold">{constantVals.Remark}</div>
                                                                <div className="text14_light mt-1"> {clearanceDetails?.["request"]?.[0]?.remark ?? "--"}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div><hr /></div>
                                                <div className='mb-10'>
                                                    <div className='toggle-card-heading ml-3 mt-10px mb-10'>{constantVals.Comments}</div>
                                                    {clearanceDetails?.["request"]?.[0]?.comment.length > 0 ?
                                                        clearanceDetails["request"][0].comment.map((item, index) => {
                                                            return (

                                                                <div className='d-flex row'>
                                                                    <div className='col flex-basis-50'>
                                                                        <div className="row">
                                                                            <div className=''>
                                                                                <div className="text14_bold">Comment {index + 1}</div>
                                                                                <div className="text14_light mt-1 word-wrap w-150px">{item.remark ?? "--"}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col flex-basis-50'>
                                                                        <div className="row">
                                                                            <div className=''>
                                                                                <div className="text14_bold">Created by </div>
                                                                                <div className="text14_light mt-1 word-wrap w-150px">{item.created_by_name + ` ( ${item.created_by} )` ?? "--"}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col flex-basis-50'>
                                                                        <div className="row">
                                                                            <div className=''>
                                                                                <div className="text14_bold">Created on</div>
                                                                                <div className="text14_light mt-1 word-wrap w-150px">{moment(item.created_datetime).format("DD MMM YY HH:mm") ?? "--"}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            )
                                                        }) :
                                                        <div className='mt-5 h-90-percent'>
                                                            <DataNotFound />
                                                        </div>
                                                    }
                                                </div>
                                                <div><hr /></div>
                                            </>
                                        }
                                        {
                                            selectedEmployee['release_status_text'] !== 'Completed' &&
                                            <>
                                            <div className='w-20 ml-4'>
                                            <label className='p-sub-heading mb-1 ml-2'>Select Status:</label>
                                            <DynamicDropdown
                                                data={selectedEmployee['release_status_text'] === 'Hold' || agencyName !== "F & F Settlement" ? [{'label':'Completed','title':'Completed'}] : status}
                                                render="title"
                                                value={selectedStatus?.title}
                                                selectedValue={(data)=>handleStatusChange(data)}
                                                arrow={"true"}
                                                height={"NotificationHeight"}
                                            />
                                        </div>
                                        </>
                                        }
                                        {
                                            !commentOnly && 
                                            <>
                                                {
                                                    selectedStatus?.title !== 'Hold' &&
                                                    <div className='mt-2 mt-10px'>
                                                    <div className='d-flex row justify-content-between px-3 w-60'>
                                                        <div className='mt-2'>
                                                            <div className='p-sub-heading ml-3 required'>{constantVals.PayAmount}</div>
                                                            <div className='esep-placeholder-editable'>
                                                                <input
                                                                    className="form-control esep-placeholder-editable px-3"
                                                                    type="text"
                                                                    onChange={(e) => updatePayAmount(e.target.value)}
                                                                    value={paymentAmount != null ? paymentAmount : " "}
                                                                    placeholder={'Enter amount'}
                                                                    onWheel={(e) => e.target.blur()}
                                                                    min={0}
                                                                    inputmode="decimal"
                                                                    pattern="\d*"
                                                                />
                                                            </div>
                                                            <div className='d-flex justify-content-between align-item-center mb-10px mt-10px'>
                                                                <label className='noteTextMsg ml-10px'>
                                                                    <span className='noteTextBold'>{'Note:'}</span> {constantVals.NoDuesMessage}
                                                                </label>
                                                            </div>
                                                            {payAmountError && <span className='p-sub-heading ml-3 errorBlock'>{payAmountError}</span>}
                                                        </div>
                                                        <div className='mt-2'>
                                                            <div className='p-sub-heading ml-3 required'>{constantVals.RecAmount}</div>
                                                            <div className='esep-placeholder-editable'>
                                                                <input
                                                                    className="form-control esep-placeholder-editable px-3"
                                                                    type="text"
                                                                    onChange={(e) => updateRecAmount(e.target.value)}
                                                                    value={recoveryAmount != null ? recoveryAmount : " "}
                                                                    placeholder={'Enter amount'}
                                                                    onWheel={(e) => e.target.blur()}
                                                                    min={0}
                                                                    inputmode="decimal"
                                                                    pattern="\d*"
                                                                />
                                                            </div>
                                                            <div className='d-flex justify-content-between align-item-center mb-10px mt-10px'>
                                                                <label className='noteTextMsg ml-10px'>
                                                                    <span className='noteTextBold'>{'Note:'}</span> {constantVals.NoDuesMessage}
                                                                </label>
                                                            </div>
                                                            {recAmountError && <span className='p-sub-heading ml-3 errorBlock'>{recAmountError}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                                <div className='mt-2 px-3'>
                                                    <div className='mt-4'>
                                                        <div className='p-sub-heading ml-3 required'>{constantVals.Remark}</div>
                                                        <div className='p-placeholder-text'>
                                                            <textarea
                                                                className="form-control p-placeholder px-3"
                                                                type="text"
                                                                placeholder={"Enter the remarks..."}
                                                                maxLength={500}
                                                                onChange={(e) => updateRemark(e.target.value)}
                                                                value={remark}
                                                            >
                                                            </textarea>
                                                        </div>
                                                        <CharacterCounter
                                                            totalCount="500"
                                                            currentCount={remark?.length ?? "0"}
                                                        />
                                                        {remarkError && <span className='p-sub-heading ml-3 errorBlock'>{remarkError}</span>}
                                                    </div>
                                                    {
                                                        clearanceDetails?.["request"]?.[0]?.["agency_is_document_applicable"] == true &&
                                                        <div className='mt-2'>
                                                            <div className='p-sub-heading ml-3'>{constantVals.UploadAttachment}</div>
                                                            <AttachmentInput
                                                                attachment={attachments}
                                                                onChange={(attachment) => { addAttachment(attachment) }}
                                                                isMandatory={false}
                                                                length={5}
                                                                maxSize={2}
                                                                uploadNote={uploadNote}
                                                                onDelete={(attachment) => { deleteAttachment(attachment) }}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            </>
                                        }
                                        {!viewOnly && commentOnly && clearanceDetails?.["request"]?.[0]?.comment.length < 3 &&
                                            <div className=''>
                                                <div className='toggle-card-heading ml-3 mt-10px mb-10'>{constantVals.AddNewComment}</div>
                                                <div className='p-placeholder-text px-3'>
                                                    <textarea
                                                        className="form-control p-placeholder px-1"
                                                        type="text"
                                                        placeholder={"Enter the comment..."}
                                                        maxLength={500}
                                                        onChange={(e) => setComment(e.target.value)}
                                                        value={comment}
                                                    >
                                                    </textarea>
                                                </div>
                                                <div className='px-2'>
                                                    <CharacterCounter
                                                        totalCount="500"
                                                        currentCount={comment?.length ?? "0"}
                                                    />
                                                </div>
                                                {commentError && <span className='p-sub-heading ml-3 errorBlock'>{commentError}</span>}
                                            </div>
                                        }
                                    </>
                                }
                            </div> :
                            <div className='mt-5 h-90-percent'>
                                <DataNotFound />
                            </div>
                    }

                    <div className="popup-button-background justify-content-end align-item-center pr-3 p-10px">
                        <input type='button' value={screenType == 'ClearanceInitiate' ? 'CLOSE' : 'BACK'} className='cancelBtn' onClick={handleBack} />
                        {
                            !viewOnly && (screenType == 'ClearanceInitiate' || clearanceDetails?.["request"]?.[0]?.comment.length < 3) &&
                            <input
                                type='button'
                                value={screenType == 'ClearanceInitiate' ? 'START' : 'UPDATE'}
                                className='ml-2 validateBtn'
                                onClick={handleUpdate}
                            />
                        }
                    </div>
                </div>
            </div>
            {
                confirmPopUp &&
                <ConfirmationPopUp
                    title={"Confirmation"}
                    loading={loading}
                    onDismiss={() => { setConfirmPopUp(false) }}
                    confirmBtnLbl={"CONFIRM"}
                    cancelBtnLbl={"CANCEL"}
                    onConfirmAction={handleConfirmation}
                    message={
                        <div className='d-flex flex-direction-column'>
                            <div className='align-self-center'>
                                <img
                                    alt="Success_icon"
                                    src={Success_icon}
                                    className="mb-1"
                                ></img>
                            </div>
                            <div className="popup_text text-center p-3">
                                {`${commentOnly ? "Are you sure that you want to add a comment?" : "Are you sure that you want to update clearance status?"}`}
                            </div>
                        </div>
                    }
                />
            }
        </div>
    )
}

export default ClearanceDetailsPopup