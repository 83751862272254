import React, { Component, createRef } from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri';
import './DropdownStyles.css';
import './../../../../src/assets/styles/basic.css';
import down from '../../../assets/images/applyLeave/downArrow.png';

class DynamicDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            highlightedIndex: -1, // Track the highlighted item index
        };
        this.dropdownRef = createRef(); // Create ref for the dropdown container
    }

    setValue = (list, item) => {
        this.props.selectedValue(list, item); // Call the parent method to handle selection
        this.setState({ highlightedIndex: -1 }); // Reset highlighting after selection
    }

    dropDownBtnCSS = (bg) => {
        switch (bg) {
            case "whiteBG":
                return "btn d-flex justify-content-between common-white-dropdown-text";
            case "invalid":
                return "invalidDropdown btn d-flex justify-content-between";
            default:
                return "btn common-dropdown-text d-flex justify-content-between";
        }
    }

    dropDownCSS = (height, width) => {
        let heightClass = "";
        let widthClass = "";
        switch (height) {
            case "height":
                heightClass = "dropdown-menu scroller menu-height w-100";
                break;
            case "NotificationHeight":
                heightClass = "Notification-menu-height admin-scroller dropdown-menu";
                break;
            case "NotificationHeight-L":
                heightClass = "Notification-menu-height-Large admin-scroller dropdown-menu";
                break;
            case "dependOnContent":
                heightClass = "max-dropdown-height contentWise-scroll dropdown-menu ";
                break;
            case "LAContent":
                heightClass = "dropdown-menu dropdown-hnew";
                break;
            case "widthwith":
                heightClass = "dropdown-menu dropdown-menu-for-reasoncode";
                break;
            case "innerCard":
                heightClass = "max-dropdown-height contentWise-scroll-min dropdown-menu";
                break;
            default:
                heightClass = "dropdown-menu w-25";
        }
        switch (width) {
            case "width":
                widthClass = "w-100";
                break;
            case "w-8rem":
                widthClass = "w-8rem";
                break;
            case "w-35rem":
                widthClass = "w-25rem";
                break;
            case "w-14rem":
                widthClass = "w-14rem";
                break;
            case "w-16rem":
                widthClass = "w-16rem";
                break;
            case "w-12rem":
                widthClass = "w-12rem";
                break;
            case "w-15rem":
                widthClass = "w-15rem ";
                break;
            case "w-20rem":
                widthClass = "w-20rem";
                break;
            case "w-auto":
                widthClass = "w-auto";
                break;
            default:
                widthClass = "";
        }
        return heightClass + " " + widthClass;
    }

    handleKeyDown = (e) => {
        const { data } = this.props;
        const { highlightedIndex } = this.state;

        if (e.key === 'ArrowDown') {
            // Move down the list
            this.setState({
                highlightedIndex: Math.min(highlightedIndex + 1, data.length - 1),
            }, () => {
                // Scroll to the highlighted item
                this.scrollToHighlighted();
            });
            e.preventDefault(); // Prevent default scrolling behavior
        } else if (e.key === 'ArrowUp') {
            // Move up the list
            this.setState({
                highlightedIndex: Math.max(highlightedIndex - 1, -1),
            }, () => {
                // Scroll to the highlighted item
                this.scrollToHighlighted();
            });
            e.preventDefault(); // Prevent default scrolling behavior
        } else if (e.key === 'Enter' && highlightedIndex >= 0) {
            // Select the highlighted item
            const selectedItem = data[highlightedIndex];
            this.setValue(selectedItem, highlightedIndex); // Set the value and reset highlighting
        }
    }

    scrollToHighlighted = () => {
        // Find the currently highlighted item
        const highlightedItem = this.dropdownRef.current?.querySelector('.highlighted');
        if (highlightedItem) {
            // Scroll the highlighted item into view
            highlightedItem.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        }
    }

    handleMouseEnter = (index) => {
        this.setState({ highlightedIndex: index }); // Highlight item on hover
    }

    handleMouseLeave = () => {
        this.setState({ highlightedIndex: -1 }); // Remove highlight when mouse leaves
    }

    render() {
        const { data, value, render, arrow, height, disabled, width, textBoxWidth, boxWidth, editable, bg, placeholder = "Select Value", refs = null } = this.props;
        const { highlightedIndex } = this.state;
        let bgClass = this.dropDownBtnCSS(bg);
        let dropdownClass = this.dropDownCSS(height, width);

        return (
            <div className={`dropdown ${textBoxWidth}`}>
                <button
                    className={`${bgClass}`}
                    type='button'
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    disabled={disabled}
                    style={{ width: boxWidth }}
                    ref={refs}
                    onKeyDown={this.handleKeyDown} // Handle keyboard events
                >
                    {value ?? placeholder} {/* Display selected value or placeholder */}
                    {arrow === 'true' ? (
                        <img src={down} alt='' className='mt-2 ml-2 dropdownIcon' />
                    ) : (
                        <RiArrowDropDownLine size={20} color={'rgb(185 188 197)'} />
                    )}
                </button>
                <div className={dropdownClass} aria-labelledby="dropdownMenuButton" ref={this.dropdownRef}>
                    {data?.length > 0 ? data.map((listValue, i) => {
                        const isHighlighted = highlightedIndex === i; // Check if item is highlighted
                        return (
                            <label
                                key={i}
                                className={`dropdown-item ${isHighlighted ? 'highlighted' : ''}`} // Apply highlighted class
                                onClick={() => this.setValue(listValue, i)}
                                onMouseEnter={() => this.handleMouseEnter(i)} // Highlight on hover
                                onMouseLeave={this.handleMouseLeave} // Remove highlight on mouse leave
                            >
                                {render ? listValue?.[render] : listValue}
                            </label>
                        );
                    }) : (
                        <div className='dropdown-item'>{"No data found"}</div>
                    )}
                </div>
            </div>
        );
    }
}

export default DynamicDropdown;