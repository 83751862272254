import React, { Component } from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri';
import './DropdownStyles.css';
import Select from "react-select";
import { components } from 'react-select';

const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "#F2F4FB" }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isDisabled
                ? null
                : isSelected
                ? "#EDEDED" // Background for selected option
                : isFocused
                ? "#cce1ff" // Background on hover/focus
                : "#F2F4FB", // Default background
            color: isDisabled ? "#ccc" : isSelected ? "#333" : "#64748B", // Text color for different states
            cursor: isDisabled ? "not-allowed" : "pointer",
            border: "none",
            ":active": {
                ...styles[":active"],
                backgroundColor: !isDisabled && (isSelected ? "#EDEDED" : "#b3d1ff") // Background on click
            }
        };
    }
};

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

class LargeDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedValue: ''
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ selectedValue: nextProps.value ? nextProps.value : '' });
    }

    setValue = (list) => {
        this.setState({ selectedValue: list.value }, () =>
            this.props.selectedValue(list));
    };

    render() {
        const { data, value, render, arrow, height, readOnly, disabled, noOptionFound, isMulti = false, closeMenuOnSelect = true, customStyles = {}, hideSelectedOptions = false, isOptionDisabled = false } = this.props;
        const { selectedValue } = this.state;

        return (
            <div className=''>
                <div className=''>
                    <Select
                        styles={{ ...colourStyles, ...customStyles }}
                        onChange={(listValue) => this.setValue(listValue)}
                        placeholder={value}
                        options={data}
                        isDisabled={disabled}
                        value={value}
                        menuPosition="fixed"
                        isMulti={isMulti}
                        closeMenuOnSelect={closeMenuOnSelect}
                        maxMenuHeight={150}
                        noOptionsMessage={() => noOptionFound ?? "No Options"}
                        hideSelectedOptions={hideSelectedOptions}
                        components={isMulti ? { Option } : null}
                        isOptionDisabled={(option) => option.isDisabled === true}
                    />
                </div>
            </div>
        );
    }
}

export default LargeDropdown;