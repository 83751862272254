import React, { useState, useRef, useEffect } from 'react';
// import moment from 'moment';
// import { DatePicker } from 'antd';
// import 'antd/dist/antd.css';

import approveIcon from '../../../assets/drishti_images/manager/landing/approve.png';
import rejectIcon from '../../../assets/drishti_images/manager/landing/reject.svg';
import reject_button from '../../../assets/drishti_images/manager/approval/reject_button.svg'
import approve_button from '../../../assets/drishti_images/manager/approval/approve_button.svg'
import book_btn from '../../../assets/drishti_images/supervisor/book/book_btn.svg'
import edit_button from '../../../assets/drishti_images/supervisor/book/edit_button.svg'
import EditPopup from '../../ScreenType/employeeScreens/overTime/Popup/EditPopup';
import ValidateBookPopup from '../../ScreenType/employeeScreens/overTime/Popup/ValidateBookPopup';
import Regularize from '../../ScreenType/employeeScreens/overTime/Popup/Regularize';
import DynamicDropdown from '../../shared/components/DynamicDropdown';
import Nodata from '../../../assets/drishti_images/nodatafound/Nodatafound.svg';
import * as Config from '../../../constants/Config';
import { fetchApiCallWithService, sessionOut } from '../../../constants/Service';
import './Table.css';
import OtPopup from '../../ScreenType/employeeScreens/overTime/Popup/OtPopup';
import { toast } from 'react-toastify';
import RegularizeDetail from '../../ScreenType/employeeScreens/overTime/Popup/RegularizeDetail';
import { useSelector } from 'react-redux';
import { commonHeadersAcceptJson, toaster } from '../../../utils/Utils';
import { Pagination } from 'react-bootstrap';
import Dropdown from '../../shared/components/Dropdown';
import LargeDropdown from '../components/LargeDropdown';
import exportBtn from '../../../assets/images/icons8-excel-50.png';
import AddNewOT from '../../../assets/images/AddNewOT.png';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import RegularizeHolidayWeeklyOff from '../../ScreenType/employeeScreens/overTime/Popup/RegularizeHolidayWeeklyOff';

const Table = ({ headers, data, user, onOTChange, onUpdateData, onupdateBC, setApiBody, setApiManagerBody, activeTab, setActiveStatus, getOTDetails, getOtRequestManager, isEmployee, setIsEmployee, setCurrentPage, currentPage, totalPages, costCenterDropDownSup, orgUnitDropDownSup, costCenterDropDownMag, orgUnitDropDownMag } = () => { }) => {
  const adminLoginState = useSelector(state => state.adminLoginState);
  const loginState = useSelector(state => state.loginState);
  const [selectedRows, setSelectedRows] = useState([]);
  const [otType, setOTType] = useState(activeTab === 'OTBooking' || activeTab === 'OTApproveReject' ? 'Normal' : activeTab === 'RegularizeOT' || activeTab === 'RegularizeOTBooking' || activeTab === 'RegularizeOTApprove' ? "Regularize" : activeTab === 'HolidayBooking' || activeTab === 'HolidayApprove' ? "Booking" : activeTab === 'RegularizeHolidayBooking' || activeTab === 'RegularizeHolidayApprove' ? "Regularize_Booking" : "Normal")
  const [isReject, setIsReject] = useState(false);
  const [isApprove, setIsApprove] = useState(false);
  const [isBook, setIsBook] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isRegularize, setIsRegularize] = useState({ isOpen: false, activeTab: "" });
  const [isRegularizeDetail, setIsRegularizeDetail] = useState(false);
  const [fromDate, setFromDate] = useState(new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]);
  const [toDate, setToDate] = useState(new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]);
  // const [fromDate, setFromDate] = useState(moment().subtract(2, 'days'));
  // const [toDate, setToDate] = useState(moment().subtract(1, 'days'));
  const [emp_id, setemp_id] = useState('')
  const [supHrs, setSupHrs] = useState('')
  const [costCenter, setCostCenter] = useState({
    "cost_center": "All", "cost_center_text": "All", "value": "All"
  })
  const [orgUnit, setOrgUnit] = useState({
    "org_unit": "All", "org_unit_text": "All", "value": "All"
  })
  const [status, setStatus] = useState(user === 'manager' ? ['Booked & Pending for Approval', 'Booked & Pending for Approval'] : user === 'supervisor' ? ['Pending', 'Pending'] : user === "manager supervisor" && (activeTab === "OTBooking" || activeTab === "RegularizeOTBooking" || activeTab === "HolidayBooking" || activeTab === "RegularizeHolidayBooking") ? ['Pending', 'Pending'] : user === "manager supervisor" && (activeTab === "OTApproveReject" || activeTab === "RegularizeOTApprove" || activeTab === "HolidayApprove" || activeTab === "RegularizeHolidayApprove") ? ['Booked & Pending for Approval', 'Booked & Pending for Approval'] : ['Pending', 'Pending'])
  const [ot, setOt] = useState(['OT Approve/Reject', ''])
  const [bookOTBody, setbookOTBody] = useState({});
  const [Hrs, setHrs] = useState('')
  const [Min, setMin] = useState('')
  const [approveOTBody, setApproveOTBody] = useState({});
  const [validatedData, setValidatedData] = useState([])
  const [validateOTData, setValidatedOTData] = useState([])
  const [validationTitle, setvalidationTitle] = useState([])
  const [isDayType, setIsDayType] = useState(activeTab === 'HolidayBooking'|| activeTab === 'RegularizeHolidayBooking'  || activeTab === 'HolidayApprove' || activeTab === 'RegularizeHolidayApprove' ? { value: "Weekly off", key: 'O' } : {})

  const [isValidateData, setIsValidateData] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isValidBook, setIsValidBook] = useState();
  const [isProcess, setIsProcess] = useState(false);
  const [loading, setLoading] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);



  useEffect(() => {
    const handleDropdownToggle = (event) => {
      const dropdown = event.target.closest('.dropdown').querySelector('.dropdown-menu');
      if (!dropdown) return;

      const dropdownRect = dropdown.getBoundingClientRect();
      const viewportHeight = window.innerHeight;

      if (dropdownRect.bottom > viewportHeight) {
        dropdown.classList.add('show-above');
      } else {
        dropdown.classList.remove('show-above');
      }
    };

    document.addEventListener('show.bs.dropdown', handleDropdownToggle);

    return () => {
      document.removeEventListener('show.bs.dropdown', handleDropdownToggle);
    };
  }, []);

  let tableClass = 'table mt-20 w-100';


  if (status[0] === "Pending") {
    tableClass += ' special-sticky';
    tableClass += ' pending';
  }
  else if (status[0] === "Booked & Pending for Approval" && (activeTab === 'RegularizeOTApprove' || activeTab === "HolidayApprove" || activeTab === 'RegularizeHolidayApprove')) {

    tableClass += ' special-sticky width';
    tableClass += ' pending';
  } else if (status[0] === "Booked & Pending for Approval" && (activeTab === 'OTApproveReject')) {

    tableClass += ' special-sticky conditional';
    tableClass += ' pending';
  }


  const props = {
    adminLoginState: adminLoginState,
    loginState: loginState
  }
  const selectedStatus = [
    { value: "Booked & Pending for Approval", code: "2", title: 'Booked & Pending for Approval' },
    { value: "Approved & Pending for Update", code: "3", title: "Approved & Pending for Update" },
    { value: "Rejected", code: "4", title: "Rejected" },
    { value: "Approved & Updated", code: "5", title: "Approved & Updated" },
    { value: "Error in Posting", code: "6", title: "Error in Posting" },
    { value: "Approved & Paid", code: "7", title: "Approved & Paid" },
  ];

  const selectedStatusSup = [
    { value: "Pending", code: "1", title: 'Pending' },
    { value: "Booked & Pending for Approval", code: "2", title: 'Booked & Pending for Approval' },
    { value: "Approved & Pending for Update", code: "3", title: "Approved & Pending for Update" },
    { value: "Rejected", code: "4", title: "Rejected" },
    { value: "Approved & Updated", code: "5", title: "Approved & Updated" },
    { value: "Error in Posting", code: "6", title: "Error in Posting" },
    { value: "Approved & Paid", code: "7", title: "Approved & Paid" },
  ];

  const costCenterSelect = [
    { value: '', code: '', title: 'Cost Center' },
    { value: "Cost Center 1", code: "1", title: 'Cost Center 1' },
    { value: "Cost Center 2", code: "2", title: 'Cost Center 2' },
  ];

  const dayTypeSelect = [
    { value: "Weekly off", key: 'O' },
    { value: "Holiday", key: 'H' },
  ];

  // Array of hours from 00 to 24
  const hours = Array.from({ length: 25 }, (_, i) => ('0' + i).slice(-2));


  // Array of minutes from 00 to 59
  const minutes = Array.from({ length: 60 }, (_, i) => ('0' + i).slice(-2));

  const tabMapping = {
    OTBooking: 'OT Booking',
    RegularizeOTBooking: 'Regularize Booking',
    RegularizeOT: 'Regularize Booking',
    HolidayBooking: 'Holiday/Weekly off Booking',
    OTApproveReject: 'OT Approve Reject',
    RegularizeOTApprove: 'Regularize OT Approve',
    HolidayApprove: 'Holiday/Weekly off Approve'
  };

  const displayName = tabMapping[activeTab];


  var validated_emp_id = /^\d{6,8}$/.test(emp_id) ? emp_id : null;



  useEffect(() => {
    // setemp_id('');
    setActiveStatus(status[0])

  }, [status[0]])

  useEffect(() => {
    setSelectedRows([])

  }, [status[0], fromDate, toDate, validated_emp_id, currentPage, otType, costCenter, orgUnit, isDayType])


  useEffect(() => {
    if (user === "manager supervisor" && (activeTab === "OTBooking" || activeTab === 'RegularizeOT' || activeTab === 'RegularizeOTBooking' || activeTab === 'HolidayBooking' || activeTab === 'RegularizeHolidayBooking')) {
      setApiBody(
        {
          status: status[0],
          supervisor_emp_id: loginState.userData.Perno,
          // supervisor_emp_id: "00600015",
          from_date: fromDate,
          to_date: toDate,
          ot_type: otType,
          emp_id: validated_emp_id,
          page_number: currentPage,
          cost_center: costCenter.cost_center,
          org_unit: orgUnit.org_unit,
          day_type: isDayType.key
        }
      )
    }
    else if (user === "manager supervisor" && (activeTab === "OTApproveReject" || activeTab === 'RegularizeOTApprove' || activeTab === 'HolidayApprove' || activeTab === 'RegularizeHolidayApprove')) {

      setApiManagerBody(
        {
          status: status[0],
          manager_emp_id: loginState.userData.Perno || null,
          from_date: fromDate,
          to_date: toDate,
          ot_type: otType,
          emp_id: validated_emp_id,
          page_number: currentPage,
          cost_center: costCenter.cost_center,
          org_unit: orgUnit.org_unit,
          day_type: isDayType.key
        }
      )
    }
    else if (user === "supervisor") {
      setApiBody(
        {
          status: status[0],
          supervisor_emp_id: loginState.userData.Perno,
          // supervisor_emp_id: "00600015",
          from_date: fromDate,
          to_date: toDate,
          ot_type: otType,
          emp_id: validated_emp_id,
          page_number: currentPage,
          cost_center: costCenter.cost_center,
          org_unit: orgUnit.org_unit,
          day_type: isDayType.key
        }
      )
    }
    else if (user === "manager") {
      setApiManagerBody(
        {
          status: status[0],
          manager_emp_id: loginState.userData.Perno,
          from_date: fromDate,
          to_date: toDate,
          ot_type: otType,
          emp_id: validated_emp_id,
          page_number: currentPage,
          cost_center: costCenter.cost_center,
          org_unit: orgUnit.org_unit,
          day_type: isDayType.key
        }
      )
    }


  }, [status[0], fromDate, toDate, validated_emp_id, currentPage, otType, costCenter, orgUnit, isDayType])

  const handleCheckboxChange = (index) => {
    if (index === 'header') {
      if (selectedRows.length === data.length) {
        setSelectedRows([]);
      } else {
        setSelectedRows([...Array(data.length).keys()]);
      }
    } else {
      const selectedIndex = selectedRows.indexOf(index);
      if (selectedIndex === -1) {
        setSelectedRows([...selectedRows, index]);
      } else {
        setSelectedRows(selectedRows.filter((rowIndex) => rowIndex !== index));
      }
    }
  };

  const handleUpdate = (newHrs, newMin) => {
    onUpdateData(newHrs, newMin, selectedRows)
    setIsEdit(false);
    setIsValid(false)
  };

  const handleCancel = () => {
    setIsEdit(false);
    setIsRegularize({ isOpen: false, activeTab: activeTab });
  };

  const handelRegularizeDetails = () => {
    setIsEdit(false);
    setIsValid(false)
    setIsRegularize({ isOpen: true, activeTab: activeTab });
    setIsRegularizeDetail(false)
  }

  const toggleAction = (action) => {
    if (action === "approve" && (user === "manager" || user === "manager supervisor")) {
      const selectedItems = selectedRows.map(rowIndex => data[rowIndex]);
      const approveOTBody = selectedItems.map(item => ({
        manager_ot_hrs: item.manager_ot_hrs,
        id: item.id,
        emp_id: item.emp_id,
        manager_emp_id: loginState.userData.Perno,
        action: "Approved",
        ot_date: item.ot_date,
        day_type: isDayType.key
      }));
      setApproveOTBody(approveOTBody);
      // setIsApprove(true)
      const headers = {
        "Content-Type": "application/json",
      };
      fetchApiCallWithService(Config.validateApproveOt, approveOTBody, headers, false, "accessToken", "POST", "login", "")
        .then((res) => {

          sessionOut(props, res);
          if (res.status == "200" || res.status == 200) {
            // approveOT(approveOTBody);
            setIsApprove(true)
            setSelectedRows([]);
          }
          else if ((res.status == "500" || res.status == 500) && res.data?.data?.invalid_data.length > 0) {
            setvalidationTitle('Approve')
            setValidatedOTData(res.data?.data?.invalid_data)
            setIsValidateData(true)
          }
          else {
            toaster("warning", res.data.message)
          }
        })
        .catch((err) => {
          toaster("error", "Something went wrong")
        });

    } else if (action === "reject" && (user === "manager" || user === "manager supervisor")) {
      const selectedItems = selectedRows.map(rowIndex => data[rowIndex]);
      const approveOTBody = selectedItems.map(item => ({
        manager_ot_hrs: item.manager_ot_hrs,
        id: item.id,
        emp_id: item.emp_id,
        manager_emp_id: loginState.userData.Perno,
        action: "Rejected",
        ot_date: item.ot_date
      }));

      setApproveOTBody(approveOTBody);
      setIsReject(true)
      // approveOT(approveOTBody);
    }
    else if (action === "book" && user === "supervisor") {
      setIsBook(true)
    }
  };

  const handlePageCount = (action) => {
    if (action === "decrement" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (action === "increment" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);

    }
  };


  const renderActionButtonsBoth = (item) => (

    <>
      {activeTab === 'OTApproveReject' || activeTab === "RegularizeOTApprove" || activeTab === "HolidayApprove"  || activeTab === "RegularizeHolidayApprove"? (<div className="d-flex">

        <div onClick={() => handleApprove(item)}>
          <img alt="approve" src={approveIcon} style={{ width: '25px' }} />
        </div>
        <div onClick={() => handleReject(item)}>
          <img alt="reject" src={rejectIcon} style={{ width: '25px', marginLeft: '5px' }} />
        </div>
      </div>) : (
        <div className="d-flex">
          <div onClick={() => handleBook(item)}>
            <img alt="book" src={approveIcon} style={{ width: '25px', marginLeft: '5px' }} />
          </div>
        </div>
      )
      }
    </>


  );

  const DynamicTooltip = value => (
    <Tooltip {...value}>
      <div className="tool-tip-font">{value}</div>
    </Tooltip>
  );

  const handleApprove = (item) => {
    // setIsApprove(true)
    const approveOTBody = [{
      manager_ot_hrs: item.manager_ot_hrs,
      id: item.id,
      emp_id: item.emp_id,
      manager_emp_id: loginState.userData.Perno,
      action: "Approved",
      ot_date: item.ot_date
    }];
    setApproveOTBody(approveOTBody);

    const headers = {
      "Content-Type": "application/json",
    };
    fetchApiCallWithService(Config.validateApproveOt, approveOTBody, headers, false, "accessToken", "POST", "login", "")
      .then((res) => {

        sessionOut(props, res);
        if (res.status == "200" || res.status == 200) {
          // approveOT(approveOTBody);
          setIsApprove(true)
          setSelectedRows([]);
        }
        else if ((res.status == "500" || res.status == 500) && res.data?.data?.invalid_data.length > 0) {
          setvalidationTitle('Approve')
          setValidatedOTData(res.data?.data?.invalid_data)
          setIsValidateData(true)
        }
        else {
          toaster("warning", res.data.message)
        }
      })
      .catch((err) => {
        toaster("error", "Something went wrong")
      });


  };

  const addNewOT = (activeTab) => {
    setIsRegularize({ isOpen: true, activeTab: activeTab })
  }

  const handleReject = (item) => {
    const approveOTBody = [{
      manager_ot_hrs: item.manager_ot_hrs,
      id: item.id,
      emp_id: item.emp_id,
      manager_emp_id: loginState.userData.Perno,
      action: "Rejected",
      ot_date: item.ot_date
    }];
    setApproveOTBody(approveOTBody);
    setIsReject(true)
  };

  const handleEditSup = (item) => {
    setSupHrs(item.supervisorHrs)
    setIsEdit(true)
  };
  const handleEmp = () => {
    setIsEmployee(true)
    if (!isValid) {
      setemp_id("")
    }
  };
  const handleEmpClose = () => {
    setIsEmployee(true)
    if (isValid) {
      setIsRegularizeDetail(true)
    }
  }
  const handleValidateClose = () => {
    setIsValidateData(false)
  }
  const handleBookValid = () => {
    const updatedBody = bookOTBody.map(book => ({
      ...book,
      is_process: true
    }))
    setbookOTBody(updatedBody);
    setIsValidBook(false)
    bookOT(updatedBody);
  }

  const handleBook = (item) => {
    // setIsBook(true);
    if (selectedRows.length > 1) {

      const selectedItems = selectedRows.map(rowIndex => data[rowIndex]);

      const bookOTBody = selectedItems.map(item => ({
        supervisor_emp_id: loginState.userData.Perno,
        supervisor_hr: item.supervisor_ot_hrs,
        assigned_cost_center: item.booked_cost_center,
        id: item.id,
        emp_id: item.emp_id,
        manager_emp_id: loginState.userData.Reporting,
        ot_date: item.ot_date,
        is_process: isProcess
      }));

      setbookOTBody(bookOTBody)

      const headers = {
        "Content-Type": "application/json",
      };

      fetchApiCallWithService(Config.validateBookOt, bookOTBody, headers, false, "accessToken", "POST", "login", "")
        .then((res) => {

          sessionOut(props, res);
          if (res.status == "200" || res.status == 200) {
            // bookOT(bookOTBody);
            setIsBook(true);

            setSelectedRows([]);
          }
          else if ((res.status == "500" || res.status == 500) && res.data?.data?.invalid_data.length > 0) {
            setvalidationTitle('Booking')
            setValidatedOTData(res.data?.data?.invalid_data)
            setIsValidateData(true)
          }
          else {
            toaster("warning", res.data.message)
          }
        })
        .catch((err) => {
          toaster("error", "Something went wrong")
        });

    } else {



      const bookOTBody = [{
        supervisor_emp_id: loginState.userData.Perno,
        supervisor_hr: item.supervisor_ot_hrs,
        assigned_cost_center: item.booked_cost_center,
        id: item.id,
        emp_id: item.emp_id,
        manager_emp_id: loginState.userData.Reporting,
        ot_date: item.ot_date,
        is_process: isProcess
      }]
      setbookOTBody(bookOTBody)
      const headers = {
        "Content-Type": "application/json",
      };
      fetchApiCallWithService(Config.validateBookOt, bookOTBody, headers, false, "accessToken", "POST", "login", "")
        .then((res) => {

          sessionOut(props, res);
          if (res.status == "200" || res.status == 200) {
            if (bookOTBody) {
              // bookOT(bookOTBody);
              setIsBook(true);
            }
            setSelectedRows([]);
          }
          else if ((res.status == "500" || res.status == 500) && res.data?.data?.invalid_data.length > 0) {
            setvalidationTitle('Booking')
            setValidatedOTData(res.data?.data?.invalid_data)
            setIsValidateData(true)
          }
          else {
            toaster("warning", res.data.message)
          }
        })
        .catch((err) => {
          toaster("error", "Something went wrong")
        });


    }

  };



  const bookOT = (updatedBody = undefined, bookOTBody1) => {
    const headers = {
      "Content-Type": "application/json",
    };

    fetchApiCallWithService(Config.bookOt, updatedBody || bookOTBody1 ? bookOTBody1 : bookOTBody, headers, false, "accessToken", "POST", "login", "")
      .then((res) => {
        sessionOut(props, res);
        if (res.status == "200" || res.status == 200) {
          setIsBook(false);
          if (res.data?.data && res.data?.data?.ot_data && res.data?.data?.ot_data[0].is_exceeding_quota_limit) {
            setIsBook(false);
            setIsValidBook(res.data?.data?.ot_data[0].is_exceeding_quota_limit)
          }
          else {
            toaster("success", res.data.message)
            setIsBook(false);

          }
          setSelectedRows([]);
          setApiBody(prevState => ({
            ...prevState,
            export: false
          }));
          // getOTDetails();
          setIsBook(false);
        }
        else {
          toaster("warning", res.data.message)
          setIsBook(false);
        }
      })
      .catch((err) => {
        setIsBook(false);
        toaster("error", "Something went wrong")

      });


  }

  const approveOT = (approveOTBody1) => {
    const headers = {
      "Content-Type": "application/json",
    };


    fetchApiCallWithService(Config.getOTApproveReject, approveOTBody1 ? approveOTBody1 : approveOTBody, headers, false, "accessToken", "POST", "login", "")
      .then((res) => {

        sessionOut(props, res);
        if (res.status == "200" || res.status == 200) {
          setIsReject(false)
          setIsApprove(false)
          toaster("success", res.data.message)
          setSelectedRows([]);
          setApiManagerBody(prevState => ({
            ...prevState,
            export: false
          }));
          // getOtRequestManager();
        }
        else {
          setIsReject(false)
          setIsApprove(false)
          toaster("warning", res.data.message)

        }
      })
      .catch((err) => {
        setIsReject(false)
        setIsApprove(false)
        toaster("error", "Something went wrong")

      });

  }


  const validateEmpIdAndOTDate = (employeeId, cc, otDate, dayType, quotaType, otHrs) => {
    setLoading(true)
    const body = {
      "emp_id": employeeId,
      "cost_center": cc,
      "ot_date": otDate,
      "supervisor_emp_id": loginState.userData.Perno,
      "quota_type": quotaType,
      "day_type": dayType,
      "ot_hrs": otHrs
    }

    fetchApiCallWithService(Config.validateEmpIdAndOtDate, body, commonHeadersAcceptJson, false, "accessToken", "POST", "login", "")
      .then((res) => {
        setLoading(false)
        sessionOut(props, res);
        if (res.status == "200" || res.status == 200) {
          handleCancel()
          setValidatedData(res.data.data)

          if (res.data.data.is_employee_tagged === false) {
            handleCancel()
            setIsEmployee(false)
            setIsValid(true)
          }
          else {
            setLoading(false)
            setIsRegularizeDetail(true)

          }
        }
        else {
          setLoading(false)
          toaster("warning", res.data.message)
        }
      })
  }

  const insertRegularizeOt = (data) => {

    fetchApiCallWithService(Config.insertRegularizeOt, data, commonHeadersAcceptJson, false, "accessToken", "POST", "login", "")
      .then((res) => {
        sessionOut(props, res);

        if (res.status == "201" || res.status == 201) {
          toaster("success", res.data.message)
          setIsEdit(false);
          setIsValid(false)
          setIsRegularize({ isOpen: false, activeTab: '' });
          setIsRegularizeDetail(false)
        }
        else {
          toaster("warning", res.data.message)
          setIsValid(false)
        }
      })
      .catch((err) => {
        toaster("error", "Something went wrong")
        setIsValid(false)
      });
  }

  const handleBtnClick = () => {
    if (user === "manager supervisor" && (activeTab === "OTBooking" || activeTab === 'RegularizeOT' || activeTab === 'RegularizeOTBooking' || activeTab === 'HolidayBooking' || activeTab === 'RegularizeHolidayBooking')) {
      setApiBody(
        {
          status: status[0],
          supervisor_emp_id: loginState.userData.Perno,
          from_date: fromDate,
          to_date: toDate,
          ot_type: otType,
          emp_id: validated_emp_id,
          page_number: currentPage,
          export: true,
          cost_center: costCenter.cost_center,
          org_unit: orgUnit.org_unit,
          day_type: isDayType.key
        }
      )
    }
    else if (user === "manager supervisor" && (activeTab === "OTApproveReject" || activeTab === 'RegularizeOTApprove' || activeTab === 'HolidayApprove' ||activeTab === 'RegularizeHolidayApprove')) {

      setApiManagerBody(
        {
          status: status[0],
          manager_emp_id: loginState.userData.Perno || null,
          from_date: fromDate,
          to_date: toDate,
          ot_type: otType,
          emp_id: validated_emp_id,
          page_number: currentPage,
          export: true,
          cost_center: costCenter.cost_center,
          org_unit: orgUnit.org_unit,
          day_type: isDayType.key
        }
      )
    }
    else if (user === "supervisor") {
      setApiBody(
        {
          status: status[0],
          supervisor_emp_id: loginState.userData.Perno,
          // supervisor_emp_id: "00295658",
          from_date: fromDate,
          to_date: toDate,
          ot_type: otType,
          emp_id: validated_emp_id,
          page_number: currentPage,
          export: true,
          cost_center: costCenter.cost_center,
          org_unit: orgUnit.org_unit,
          day_type: isDayType.key
        }
      )
    }
    else if (user === "manager") {
      setApiManagerBody(
        {
          status: status[0],
          manager_emp_id: loginState.userData.Perno || null,
          from_date: fromDate,
          to_date: toDate,
          ot_type: otType,
          emp_id: validated_emp_id,
          page_number: currentPage,
          export: true,
          cost_center: costCenter.cost_center,
          org_unit: orgUnit.org_unit,
          day_type: isDayType.key
        }
      )
    }

  }
  function parseTime(timeString) {
    if (!timeString) return null;

    const [hours, minutes] = timeString.split(":");
    return new Date(0, 0, 0, hours, minutes);
  }
  const handleEmpChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,8}$/.test(value)) {
      setemp_id(value);
    }
  }
  const isNumeric = (value) => {
    return /^\d*$/.test(value); // Regex to check if the value is a number (including empty string)
  };
  const isValidHour = (value) => isNumeric(value) && (value === '' || (parseInt(value, 10) >= 0 && parseInt(value, 10) <= 23));
  const isValidMinute = (value) => isNumeric(value) && (value === '' || (parseInt(value, 10) >= 0 && parseInt(value, 10) <= 59));

  // const disabledDate = (current) => {
  //   // Disable dates before fromDate and today
  //   return current && current < moment().subtract(2, 'days').endOf('day');
  // };

  // const handleFromDateChange = (date) => {
  //   setFromDate(date);
  // };

  // const handleToDateChange = (date) => {
  //   setToDate(date);
  // };
  // const disabledToDate = (current) => {
  //   return current && (current < moment(fromDate).add(1, 'days') || current > moment().subtract(1, 'days'));
  // };

  return (
    <div className='h-70vh m-10px'>
      <div className='d-flex row justify-content-between align-item-center'>
        {/* <div className='p-heading mt-2 ml-2'>{displayName} Table</div> */}
        <div className='d-flex row justify-content-between '>
          <div className='row align-items-flex-end'>
            <div className='ml-3'>
              <div className='row align-item-center'>
                <div className='p-placeholder-text mb-1'>
                  <div className='p-sub-heading mb-1'>From Date</div>
                  <input
                    className="form-control p-placeholder px-2 p-placeholder-admin"
                    type="date"
                    placeholder='Select the date'
                    onChange={(e) => setFromDate(e.target.value)}
                    value={fromDate}
                    onKeyDown={(e) => e.preventDefault()}
                    // min={"2024-07-01"}
                    min={loginState?.OTData?.date_config}
                    max={new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]}

                  />
                </div>
                {/* <div className="p-placeholder-text mb-1">
                  <DatePicker
                    className="form-control p-placeholder px-2 p-placeholder-admin"
                    placeholder="Select the date"
                    onChange={handleFromDateChange}
                    value={fromDate}
                    disabledDate={disabledDate}
                    format="YYYY-MM-DD"
                    style={{ width: '100%' }}
                  />
                </div> */}
              </div>
            </div>
            <div className='ml-2'>
              <div className='row align-item-center'>
                <div className='p-placeholder-text mb-1'>
                  <div className='p-sub-heading mb-1'>To Date</div>
                  <input
                    className="form-control p-placeholder px-2 p-placeholder-admin"
                    type="date"
                    placeholder='Select the date'
                    onChange={(e) => setToDate(e.target.value)}
                    value={toDate}
                    onKeyDown={(e) => e.preventDefault()}
                    max={new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]}
                    min={new Date(new Date(fromDate).setDate(new Date(fromDate).getDate())).toISOString().split('T')[0]}
                  />
                </div>
                {/* <div className='p-placeholder-text mb-1'>
                  <DatePicker
                    className="form-control p-placeholder px-2 p-placeholder-admin"
                    placeholder="Select the date"
                    onChange={handleToDateChange}
                    value={toDate}
                    disabledDate={disabledToDate}
                    format="YYYY-MM-DD"
                    // style={{ width: '100%' }}
                  />
                </div> */}
              </div>
            </div>
            <div className='ml-2'>
              <div className='column align-item-center'>
                <div className='p-placeholder-text mb-1'>
                  <div className='p-sub-heading mb-1'>Search Emp Id</div>
                  <input
                    className="form-control p-placeholder px-2 p-placeholder-admin"
                    type="text"
                    placeholder='Search Emp Id'
                    onChange={handleEmpChange}
                    value={emp_id}
                  />
                </div>
              </div>
            </div>
            {(user === 'supervisor' || user === "manager supervisor" && (activeTab === "OTBooking" || activeTab === "RegularizeOTBooking" || activeTab === "HolidayBooking" || activeTab === 'RegularizeHolidayBooking')) ?
              <>
                {costCenterDropDownSup != undefined && costCenterDropDownSup.length > 0 &&
                  < div className='ml-2'>
                    <div className='column align-item-center'>
                      <div className='w-11rem p-placeholder-text mb-1'>
                        {/* <Dropdown
                        data={costCenterDropDown}
                        selectedValue={(selectedItem) => { setCostCenter(selectedItem) }}
                        placeholder="Cost Center"
                        value={costCenter.value}
                        render={'value'}
                        arrow={'true'}
                        height={'NotificationHeight'}
                        width={"w-30rem"}
                      /> */}
                        <div className='p-sub-heading mb-1'>Cost Center</div>
                        <LargeDropdown
                          data={costCenterDropDownSup}
                          value={costCenter.value}
                          selectedValue={(selectedItem) => setCostCenter(selectedItem)}
                          // disabled={this.state.isPrimaryDataValidated == true || this.state.selectedTravelType == "Intracity"}
                          noOptionFound={"No Data Found"}
                        />
                      </div>
                    </div>
                  </div>
                }
                {orgUnitDropDownSup != undefined && orgUnitDropDownSup.length > 0 &&
                  <div className='ml-2'>
                    <div className='column align-item-center'>
                      <div className='w-11rem p-placeholder-text mb-1'>
                        {/* <Dropdown
                        data={orgUnitDropDown}
                        selectedValue={(selectedItem) => { setOrgUnit(selectedItem) }}
                        placeholder="Org Unit"
                        value={orgUnit.value}
                        render={'value'}
                        arrow={'true'}
                        height={'NotificationHeight'}
                        width={"w-30rem"}
                      /> */}
                        <div className='p-sub-heading mb-1'>Org Unit</div>
                        <LargeDropdown
                          data={orgUnitDropDownSup}
                          value={orgUnit.value}
                          selectedValue={(selectedItem) => { setOrgUnit(selectedItem) }}
                          // disabled={this.state.isPrimaryDataValidated == true || this.state.selectedTravelType == "Intracity"}
                          noOptionFound={"No Data Found"}
                        />
                      </div>
                    </div>
                  </div>
                }
              </> : <>
                {costCenterDropDownMag != undefined && costCenterDropDownMag.length > 0 &&
                  < div className='ml-2'>
                    <div className='column align-item-center'>
                      <div className='w-11rem p-placeholder-text mb-1'>
                        {/* <Dropdown
                        data={costCenterDropDown}
                        selectedValue={(selectedItem) => { setCostCenter(selectedItem) }}
                        placeholder="Cost Center"
                        value={costCenter.value}
                        render={'value'}
                        arrow={'true'}
                        height={'NotificationHeight'}
                        width={"w-30rem"}
                      /> */}
                        <div className='p-sub-heading mb-1'>Cost Center</div>
                        <LargeDropdown
                          data={costCenterDropDownMag}
                          value={costCenter.value}
                          selectedValue={(selectedItem) => setCostCenter(selectedItem)}
                          // disabled={this.state.isPrimaryDataValidated == true || this.state.selectedTravelType == "Intracity"}
                          noOptionFound={"No Data Found"}
                        />
                      </div>
                    </div>
                  </div>
                }
                {orgUnitDropDownMag != undefined && orgUnitDropDownMag.length > 0 &&
                  <div className='ml-2'>
                    <div className='column align-item-center'>
                      <div className='w-11rem p-placeholder-text mb-1'>
                        {/* <Dropdown
                        data={orgUnitDropDown}
                        selectedValue={(selectedItem) => { setOrgUnit(selectedItem) }}
                        placeholder="Org Unit"
                        value={orgUnit.value}
                        render={'value'}
                        arrow={'true'}
                        height={'NotificationHeight'}
                        width={"w-30rem"}
                      /> */}
                        <div className='p-sub-heading mb-1'>Org Unit</div>
                        <LargeDropdown
                          data={orgUnitDropDownMag}
                          value={orgUnit.value}
                          selectedValue={(selectedItem) => { setOrgUnit(selectedItem) }}
                          // disabled={this.state.isPrimaryDataValidated == true || this.state.selectedTravelType == "Intracity"}
                          noOptionFound={"No Data Found"}
                        />
                      </div>
                    </div>
                  </div>
                }
              </>
            }
            {(user === 'supervisor' && (activeTab === "HolidayBooking" || activeTab === "RegularizeHolidayBooking") || user === 'manager' && (activeTab === "HolidayApprove" || activeTab === "RegularizeHolidayApprove") || user === "manager supervisor" && (activeTab == "HolidayBooking" || activeTab === "HolidayApprove" || activeTab === "RegularizeHolidayBooking"|| activeTab === "RegularizeHolidayApprove")) &&
              <div className='ml-2'>
                <div className='column mb-1'>
                  <div className='p-sub-heading mb-1'>Day Type</div>
                  <DynamicDropdown
                    data={dayTypeSelect}
                    selectedValue={(selectedItem) => { setIsDayType(selectedItem) }}
                    placeholder="Day Type"
                    value={isDayType.value}
                    render={'value'}
                    arrow={'true'}
                    height={'NotificationHeight'}
                  // width={"w-14rem"}
                  />
                </div>
              </div>
            }

            {(user === 'supervisor' || user === "manager supervisor" && (activeTab === "OTBooking" || activeTab === "RegularizeOTBooking" || activeTab === "HolidayBooking" || activeTab === "RegularizeHolidayBooking")) ? (
              <div className='ml-2'>
                <div className='column mb-1'>
                  <div className='p-sub-heading mb-1 w-15rem'>Status</div>
                  <DynamicDropdown
                    data={selectedStatusSup}
                    selectedValue={(selectedItem) => { setStatus([selectedItem.value, selectedItem.title]) }}
                    placeholder="Status"
                    value={status[1]}
                    render={'title'}
                    arrow={'true'}
                    height={'NotificationHeight'}
                  // width={"w-14rem"}
                  />
                </div>
              </div>
            ) : (user === 'manager' || (user === "manager supervisor" && (activeTab === "OTApproveReject" || activeTab === "RegularizeOTApprove" || activeTab === "HolidayApprove" || activeTab === "RegularizeHolidayApprove"))) ? (
              <div className='ml-2'>
                <div className='column mb-1 '>
                  <div className='p-sub-heading mb-1 w-15rem'>Status</div>
                  <DynamicDropdown
                    data={selectedStatus}
                    selectedValue={(selectedItem) => { setStatus([selectedItem.value, selectedItem.title]) }}
                    placeholder="Status"
                    value={status[1]}
                    render={'title'}
                    arrow={'true'}
                    height={'NotificationHeight'}
                  // width={"w-14rem"}
                  />
                </div>
              </div>
            ) : null}
            {/* {(activeTab === "HolidayBooking" || activeTab === "RegularizeOT" || activeTab === "RegularizeOTBooking") && (user === "supervisor" || user === "manager supervisor") ? <input type='button' value={activeTab === "HolidayBooking" ? "ADD NEW HOLIDAY & WEEKLY OFF" : 'ADD NEW OT'} className='ml-2 addBtn mb-1' style={{ height: "36px" }} onClick={() => addNewOT(activeTab)} /> : null} */}
            {/* <input type='button' value='EXPORT OT DETAILS' className='ml-2 addBtn mb-1' onClick={handleBtnClick} style={{ height: "36px" }} /> */}
            {(activeTab === "RegularizeHolidayBooking" || activeTab === "RegularizeOT" || activeTab === "RegularizeOTBooking") && (user === "supervisor" || user === "manager supervisor") &&
              <OverlayTrigger placement="top" overlay={DynamicTooltip(activeTab === "RegularizeHolidayBooking" ? "Add New Holiday & Weekly Off" : 'Add New OT')}>
                <div onClick={() => addNewOT(activeTab)} className='pointer'>
                  <img src={AddNewOT} style={{ height: '28px', width: '28px', marginLeft: 16, marginBottom: 10 }} />
                </div>
              </OverlayTrigger>
            }
            <OverlayTrigger placement="top" overlay={DynamicTooltip('Export Details')}>
              <div onClick={handleBtnClick} className='pointer'>
                <img src={exportBtn} style={{ height: '25px', width: '25px', marginLeft: 16, marginBottom: 10 }} />
              </div>
            </OverlayTrigger>
          </div>

        </div>
      </div>
      {
        data.length > 0 ?
          <>
            <div className='table-scroll-sticky'>
              <table id="reusable-table" className={tableClass}>
                <thead>
                  <tr>
                    {headers.map(({ label, width }, index) => (
                      <th
                        key={index}
                        className="table-header"
                        style={{ whiteSpace: 'nowrap' }}
                        scope="col"
                      >
                        <div className="thead">
                          {label === 'Checkbox' && (status[0] === 'Pending' || (status[0] === 'Booked & Pending for Approval' && (activeTab === 'OTApproveReject' || activeTab === 'RegularizeOTApprove' || activeTab === "HolidayApprove" || activeTab === "RegularizeHolidayApprove"))) && (
                            <input type="checkbox" checked={selectedRows.length === data.length} onChange={() => handleCheckboxChange('header')} />
                          )}
                          {(status[0] === 'Approved & Pending for Update' || status[0] === 'Rejected' || status[0] === 'Approved & Updated' || status[0] === 'Error in Posting' || status[0] === 'Approved & Paid' || status[1] === 'Approved and updated' || ((status[0] === 'Booked & Pending for Approval' && (activeTab === 'OTBooking' || activeTab === 'RegularizeHolidayBooking' || activeTab === 'HolidayBooking' || activeTab === 'RegularizeOT' || activeTab === "RegularizeOTBooking")))) && (label === 'Checkbox' || label === 'Action') ? null : label === 'Checkbox' ? null : label}

                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody >
                  {data.map((item, rowIndex) => {
                    return (
                      <tr key={rowIndex} style={{ whiteSpace: 'nowrap' }}>
                        {headers.map(({ label, valueExtractor }, headerIndex) => (
                          <td key={headerIndex}>
                            <>
                              {label === 'Checkbox' && (status[0] === 'Pending' || (status[0] === 'Booked & Pending for Approval' && (activeTab === 'OTApproveReject' || activeTab === 'RegularizeOTApprove' || activeTab === "HolidayApprove" ||activeTab === 'RegularizeHolidayApprove'))) && (
                                <input
                                  type="checkbox"
                                  checked={selectedRows.includes(rowIndex)}
                                  onChange={() => handleCheckboxChange(rowIndex)}
                                />
                              )}
                              {label === 'Action' && selectedRows.length > 1 && (status[0] === 'Pending' || ((status[0] === 'Booked & Pending for Approval' && (activeTab === 'OTApproveReject' || activeTab === 'RegularizeOTApprove' || activeTab === "HolidayApprove" ||activeTab === 'RegularizeHolidayApprove')))) ? (
                                <div className="td table-data-font">
                                  --
                                </div>
                              ) : label === 'Action' && (status[0] === 'Pending' || ((status[0] === 'Booked & Pending for Approval' && (activeTab === 'OTApproveReject' || activeTab === 'RegularizeOTApprove' || activeTab === "HolidayApprove" ||activeTab === 'RegularizeHolidayApprove')))) ? (

                                <div className="td table-data-font">
                                  {renderActionButtonsBoth(item)}
                                </div>
                              ) : (status[1] === "Pending" || ((status[0] === 'Booked & Pending for Approval' && (activeTab === 'OTApproveReject' || activeTab === 'RegularizeOTApprove' || activeTab === "HolidayApprove" ||activeTab === 'RegularizeHolidayApprove')))) && (label === 'Booked Hrs (HH:MM)' || label === 'Approve OT Hrs') && label !== 'Action' ? (
                                <div id='Hrs' className='table-row' style={{ position: 'relative' }}>
                                  <div className='d-flex align-items-center'>
                                    <div style={{ position: 'relative' }}>
                                      <input
                                        type="text"
                                        value={valueExtractor(item) && valueExtractor(item).split(':')[0]}
                                        onChange={({ target: { value } }) => {
                                          if (isValidHour(value)) {
                                            setHrs(value);
                                            onUpdateData(value, valueExtractor(item).split(':')[1], rowIndex);
                                          }
                                        }}
                                        maxLength="2"
                                        className={'editTime'}
                                        placeholder="HH"
                                      />

                                    </div>
                                    <span className='mx-1'>:</span>
                                    <div style={{ position: 'relative' }}>
                                      <input
                                        type="text"
                                        value={valueExtractor(item) && valueExtractor(item).split(':')[1]}
                                        onChange={({ target: { value } }) => {
                                          if (isValidMinute(value)) {
                                            setMin(value);
                                            onUpdateData(valueExtractor(item).split(':')[0], value, rowIndex);
                                          }
                                        }}
                                        maxLength="2"
                                        className={'editTime'}
                                        placeholder="MM"
                                      />

                                    </div>
                                  </div>

                                  {(label === 'Booked Hrs (HH:MM)' && (parseTime(item.supervisor_ot_hrs) > parseTime(item.proposed_ot_hrs_max) || item.supervisor_ot_hrs === "")) ||
                                    (label === 'Approve OT Hrs' && (parseTime(item.manager_ot_hrs) > parseTime(item.proposed_ot_hrs_max) || item.manager_ot_hrs === "")) ? (
                                    <span className='error-txt input-error-txt'>Invalid Input</span>
                                  ) : null}
                                </div>
                                //  <div className='d-flex align-items-center'>
                                //  <div ref={dropdownRefs[0]} style={{ position: 'relative' }}>
                                //      <DynamicDropdown
                                //          data={hours}
                                //          selectedValue={(selectedItem) => {
                                //              setHrs(selectedItem);
                                //              onUpdateData(selectedItem, Min || valueExtractor(item).split(':')[1], rowIndex);
                                //          }}
                                //          value={valueExtractor(item).split(':')[0]}
                                //          height={'NotificationHeight'}
                                //          width={"w-30rem"}
                                //      />
                                //  </div>
                                //  <span className='mx-1'>:</span>
                                //  <div ref={dropdownRefs[1]} style={{ position: 'relative' }}>
                                //      <DynamicDropdown
                                //          data={minutes}
                                //          selectedValue={(selectedItem) => {
                                //              setMin(selectedItem);
                                //              onUpdateData(Hrs || valueExtractor(item).split(':')[0], selectedItem, rowIndex);
                                //          }}
                                //          value={valueExtractor(item).split(':')[1]}
                                //          height={'NotificationHeight'}
                                //          width={"w-30rem"}
                                //      />
                                //  </div>
                                //  </div>

                              ) : (status[1] === "Pending" && label === 'Assigned BC' && label !== 'Action') ? (
                                <>
                                  <input
                                    type="text"
                                    value={valueExtractor(item)}
                                    onChange={({ target: { value } }) => {
                                      if (isNumeric(value)) {
                                        onupdateBC((value), rowIndex);
                                      }
                                    }}
                                    maxLength={10}
                                    className={'editDrp'}
                                  />
                                  {/* {item.booked_cost_center.length < 10 && (
                                    <span className='error-txt input-error-txt'>Invalid Input</span>
                                  )} */}
                                </>
                              ) :
                                label !== 'Action' && typeof valueExtractor === 'function' ? (
                                  <div className="td table-data-font" style={{ color: label === 'Booked Hrs (HH:MM)' || label === 'Assigned BC' || label === 'Approve OT Hrs' ? getTextColor(item.status) : '#000000' }}>
                                    {valueExtractor(item, rowIndex)}
                                  </div>
                                ) : null}
                            </>
                          </td>
                        ))}
                      </tr>

                    );
                  })}

                </tbody>
              </table>
            </div>
          </> : <div className='d-flex justify-content-center flex-column' style={{ height: '33vh' }}>
            <div className='align-self-center'>
              <img src={Nodata} alt="Nodata" />
            </div>
            <div className='align-self-center'>
              <label className='card-content-title grey-color'>No Data found!</label>
            </div>
          </div>
      }
      {
        data.length >= 1 && selectedRows.length <= 1 && totalPages > 1 ? (<div className="col-md-12 w-100 d-flex justify-content-center">
          <Pagination>
            <Pagination.Prev
              onClick={() => { handlePageCount("decrement") }} />
            {currentPage != 1 &&
              <Pagination.Item disabled onClick={() => setCurrentPage(1)}>{1}</Pagination.Item>
            }
            <Pagination.Ellipsis disabled />
            <Pagination.Item active>{currentPage}</Pagination.Item>
            <Pagination.Ellipsis disabled />
            {currentPage != totalPages &&
              <Pagination.Item disabled onClick={() => setCurrentPage(totalPages)} >{totalPages}</Pagination.Item>
            }
            <Pagination.Next
              onClick={() => { handlePageCount("increment") }} />
          </Pagination>
        </div>) : (
          null
        )
      }

      {

        <div className='col-md-12 w-100 row justify-content-between ' style={{ alignItems: 'center' }} >
          {selectedRows.length > 1 && (user === 'manager' || (user === "manager supervisor" && (activeTab === "OTApproveReject" || activeTab === "RegularizeOTApprove" || activeTab === "HolidayApprove" ||activeTab === 'RegularizeHolidayApprove'))) && (<div>
            <span>
              {selectedRows.length} of {data.length} Selected
            </span>
          </div>)
          }
          {selectedRows.length > 1 && (user === 'manager' || (user === "manager supervisor" && (activeTab === "OTApproveReject" || activeTab === "RegularizeOTApprove" || activeTab === "HolidayApprove" ||activeTab === 'RegularizeHolidayApprove'))) && (
            <div className="col-md-4 w-100 d-flex justify-content-center" style={{ alignItems: 'center' }}>
              <Pagination>
                <Pagination.Prev
                  onClick={() => { handlePageCount("decrement") }} />
                {currentPage != 1 &&
                  <Pagination.Item disabled onClick={() => setCurrentPage(1)}>{1}</Pagination.Item>
                }
                <Pagination.Ellipsis disabled />
                <Pagination.Item active>{currentPage}</Pagination.Item>
                <Pagination.Ellipsis disabled />
                {currentPage != totalPages &&
                  <Pagination.Item disabled onClick={() => setCurrentPage(totalPages)} >{totalPages}</Pagination.Item>
                }
                <Pagination.Next
                  onClick={() => { handlePageCount("increment") }} />
              </Pagination>
            </div>
          )
          }
          {selectedRows.length > 1 && (user === 'manager' || (user === "manager supervisor" && (activeTab === "OTApproveReject" || activeTab === "RegularizeOTApprove" || activeTab === "HolidayApprove" || activeTab === "RegularizeHolidayApprove"))) && (<div className='row'>
            {activeTab == 'HolidayApprove' || activeTab === "RegularizeHolidayApprove" && isDayType.value == "Weekly off" ? <></> :
              <div className='pointer' onClick={handleEditSup}>
                <img alt="edit" src={edit_button} style={{ height: "38px" }} />
              </div>
            }
            <div className='pointer' onClick={() => toggleAction('approve')}>
              <img alt="approve" src={approve_button} />
            </div>
            <div className='mx-2 pointer' onClick={() => toggleAction('reject')}>
              <img alt="reject" src={reject_button} />
            </div>
          </div>
          )
          }
        </div>

      }

      <div className='col-md-12 w-100 row justify-content-between ' style={{ alignItems: 'center' }} >
        {
          selectedRows.length > 1 && (user === 'supervisor' || user === "manager supervisor" && (activeTab === "OTBooking" || activeTab === "RegularizeOTBooking" || activeTab === "HolidayBooking" || activeTab === 'RegularizeHolidayBooking')) && (
            <div>
              <span>
                {selectedRows.length} of {data.length} Selected
              </span>
            </div>
          )}
        {selectedRows.length > 1 && (user === 'supervisor' || (user === "manager supervisor" && (activeTab === "OTBooking" || activeTab === "RegularizeOTBooking" || activeTab === "HolidayBooking" || activeTab === 'RegularizeHolidayBooking'))) && (
          <div className="col-md-4 w-100 d-flex justify-content-center" style={{ alignItems: 'center' }}>
            <Pagination>
              <Pagination.Prev
                onClick={() => { handlePageCount("decrement") }} />
              {currentPage != 1 &&
                <Pagination.Item disabled onClick={() => setCurrentPage(1)}>{1}</Pagination.Item>
              }
              <Pagination.Ellipsis disabled />
              <Pagination.Item active>{currentPage}</Pagination.Item>
              <Pagination.Ellipsis disabled />
              {currentPage != totalPages &&
                <Pagination.Item disabled onClick={() => setCurrentPage(totalPages)} >{totalPages}</Pagination.Item>
              }
              <Pagination.Next
                onClick={() => { handlePageCount("increment") }} />
            </Pagination>
          </div>
        )}
        {
          selectedRows.length > 1 && (user === 'supervisor' || user === "manager supervisor" && (activeTab === "OTBooking" || activeTab === "RegularizeOTBooking" || activeTab === "HolidayBooking" || activeTab === 'RegularizeHolidayBooking')) && (<div className='row'>
            {activeTab == 'HolidayBooking' || activeTab === 'RegularizeHolidayBooking' && isDayType.value == "Weekly off" ? <></> :
              <div className='pointer ' onClick={handleEditSup}>
                <img alt="edit" src={edit_button} style={{ height: "38px" }} />
              </div>
            }
            <div className='mx-2 pointer' onClick={handleBook}>
              <img alt="book" src={book_btn} style={{ height: "32px" }} />
            </div>
          </div>
          )}
      </div>

      {
        isReject &&
        <OtPopup
          title={'Reject'}
          action={"reject"}
          content={'Kindly confirm to proceed with the reject'}
          onDismiss={() => setIsReject(false)}
          updateData={approveOT}
        />
      }
      {
        isApprove &&
        <OtPopup
          title={'Approve'}
          action={"approve"}
          content={"Kindly confirm to proceed with the approval"}
          onDismiss={() => setIsApprove(false)}
          updateData={approveOT}
        />
      }
      {
        isBook &&

        <OtPopup
          title={'Confirmation'}
          action={"confirm"}
          content={"Kindly confirm the booking "}
          onDismiss={() => setIsBook(false)}
          updateData={bookOT}
        />
      }
      {
        !isEmployee &&

        <OtPopup
          title={'Confirmation'}
          action={"confirm Employee"}
          content={'This employee is not tagged to you, please confirm to proceed'}
          onDismiss={handleEmp}
          updateData={handleEmpClose}
        />
      }
      {
        isValidBook &&

        <OtPopup
          title={'Confirmation'}
          action={"confirm Booking"}
          content={'OT hours quarterly quota limit exceeded for this emp id , do you want to continue?'}
          onDismiss={() => setIsValidBook(false)}
          updateData={handleBookValid}
        />
      }
      {
        isValidateData &&
        <ValidateBookPopup
          validationTitle={validationTitle}
          validatedOTData={validateOTData}
          onDismiss={handleValidateClose}


        />
      }
      {
        isRegularize.isOpen && (isRegularize.activeTab == "RegularizeOT" || isRegularize.activeTab == "RegularizeOTBooking") &&
        <Regularize onUpdate={handleUpdate} onCancel={handleCancel} setIsRegularizeDetail={setIsRegularizeDetail} validateEmpIdAndOTDate={validateEmpIdAndOTDate} loading={loading} regularizeTab={isRegularize} />
      }

      {
        isRegularize.isOpen && isRegularize.activeTab == "RegularizeHolidayBooking" &&
        <RegularizeHolidayWeeklyOff onUpdate={handleUpdate} onCancel={handleCancel} setIsRegularizeDetail={setIsRegularizeDetail} validateEmpIdAndOTDate={validateEmpIdAndOTDate} loading={loading} regularizeTab={isRegularize} />
      }
      {
        isRegularizeDetail &&
        <RegularizeDetail onUpdate={handleUpdate} onCancel={handelRegularizeDetails} validatedData={validatedData} insertRegularizeOt={insertRegularizeOt} supId={loginState.userData.Perno} mangId={loginState.userData.Reporting} regularizeTab={isRegularize} />
      }
      {
        isEdit &&
        <EditPopup onUpdate={handleUpdate} onCancel={handleCancel} supervisorHrs={supHrs} />
      }

    </div >
  )
};


const getTextColor = (status) => {
  if (status === 'Approved and updated') {
    return '#53A500';
  } else if (status === 'Pending for approval') {
    return '#E3B203';
  }
  return '#000000';
}

export default Table;

