export const masterScreenList = [
   { key: 'SchemeList', value: 'Special Scheme', endPoint: '' },
   { key: 'Reopen', value: 'Re-Open', endPoint: '' },
]
 
export const suggestionMasterScreen = {
    Sr: 'Sr No.',
    SchemeName: 'Scheme Name',
    StartDate: 'Start Date',
    EndDate: 'End Date',
    // keys
    SchemeList: 'SchemeList'
}

export const minImageHeight = 160;
export const maxImageHeight = 250;
export const minImageWidth = 360;
export const maxImageWidth = 450;

export const sendToReopen = [
    {id: 0, title: 'Send to Evaluator', value:'evaluator'},
    {id: 1, title: 'Send to Division Co-ordinator', value:'division'},
    {id: 2, title: 'Send to Suggestion office', value:'suggestionOffice'}
]
