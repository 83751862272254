import React, { useEffect, useState, useRef } from 'react'
import rightarrowbg from '../../../../../assets/drishti_images/left-arrow.svg';
import DynamicDropdown from '../../../../shared/components/DynamicDropdown';
import { SuggestionMemberDetails, SuggestionDocument } from '../../suggestionScheme/constants';
import ConfirmationPopup from './ConfirmationPopup';
import SuggesterPreviewCompoment from './SuggesterPreviewCompoment';
import SuggestionDashboard from './SuggestionDashboard';
import ViewTable from './ViewTable';
import { deleteDocuments, deleteS3Documents, EditSuggestions, getSuggestionLogs, uploadDocument, validateIdeaBankReference } from '../../../../../constants/api.service';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { toaster } from '../../../../../utils/Utils';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import LargeDropdown from '../../../../shared/components/LargeDropdown';
import ServiceUnavailable from '../../../../shared/ServiceUnavailable';
const EditSuggestion = ({setselectedCardStatus, handleBack, editData }) => {
    const DocumentType = [
        { id: 1, title: 'Before' },
        { id: 2, title: 'After' },
        { id: 3, title: 'Other' },
    ];
    const [viewSchemeFilter, setViewSchemeFilter] = useState(editData.scheme_type_name)
    const [typeScheme, setSchemeType] = useState([])
    const [category, setCategory] = useState([])
    const [agencyData, setAgencyData] = useState([])
    const [divisionData, setDivisionData] = useState([])
    const [loading, setloading] = useState(false)
    const [viewDocumentTypeFilter, setViewDocumentTypeFilter] = useState('')
    const [employeeId, setEmployeeId] = useState('');
    const [memberData, setMemberData] = useState([]);
    const [presentStatus, setpresentStatusCounts] = useState(editData.present_status);
    const [proposedChangesCounts, setproposedChangesCounts] = useState(editData.proposed_changes);
    const [otherAreasCounts, setotherAreas] = useState(editData.applicable_areas);
    const [BenefitsCounts, setBenefitsCounts] = useState(editData.benefits);
    const [attachmentName, setAttachmentName] = useState([]);
    const [documentData, setdocumentData] = useState([]);
    const [openConfirmPopUp, setopenConfirmPopUp] = useState(false);
    const [title, setTitle] = useState(editData.title);
    const [implementDate, setimplementDate] = useState(editData.implemented_date);
    const [errors, setErrors] = useState({});
    const [showDashBoard, setShowDashBoard] = useState(false);
    const [availableDocumentTypes, setAvailableDocumentTypes] = useState(DocumentType);
    const [annualSaving, setAnnualSaving] = useState(editData.annual_saving);
    const [earlierStatus, setearlierStatus] = useState(editData.earlier_status);
    const [implementStatus, setimplementStatus] = useState(editData.changes_implemented);
    const [ideaBankRef, setIdeaBankRef] = useState(editData.idea_bank_reference);
    const [documentLink, setdocumentLink] = useState(null);
    const [userHistory, setuserHistory] = useState([]);
    const loginData = useSelector(state => state.loginState);
    const [documentDetails, setDocumentDetails] = useState([]);
    const [isIdeaBankRefValid, setIsIdeaBankRefValid] = useState(false);
    const [validateBtn, setvalidateBtn] = useState(false);
    const fileInputRef = useRef(null);
    const isUploadButtonDisabled = !viewDocumentTypeFilter.title || !attachmentName.documentName;
    const [is_service_Unavailable, setIs_Service_Unavailable] = useState(false);
    const [landing_Service_reason, setLanding_Service_reason] = useState(null);
    const validate = (name, value) => {
        let error = '';
        if (name === 'attachmentName') {
            if (!value) error = 'Attachment is required';
        } else if (name === 'presentStatus' && (viewSchemeFilter.toLowerCase() === 'normal' || viewSchemeFilter.toLowerCase() === 'special')) {
            if (!value) error = 'Present Status is required';
        } else if (name === 'proposedChangesCounts' && (viewSchemeFilter.toLowerCase() === 'normal' || viewSchemeFilter.toLowerCase() === 'special')) {
            if (!value) error = 'Proposed Changes are required';
        } else if (name === 'earlierStatus' && !(viewSchemeFilter.toLowerCase() === 'normal' || viewSchemeFilter.toLowerCase() === 'special')) {
            if (!value) error = 'Earlier Status is required';
        } else if (name === 'implementStatus' && !(viewSchemeFilter.toLowerCase() === 'normal' || viewSchemeFilter.toLowerCase() === 'special')) {
            if (!value) error = 'Implement Status is required';
        } else if (name === 'BenefitsCounts') {
            if (!value) error = 'Benefits are required';
        } else if (name === 'viewDocumentTypeFilter') {
            if (!value) error = 'Document Type is required';
        }
        setErrors(prevErrors => ({ ...prevErrors, [name]: error }));
        return error;
    };

    const checkValidity = () => {
        if (viewSchemeFilter.toLowerCase() === 'normal') {
            if (presentStatus && proposedChangesCounts && BenefitsCounts) {
                return false;
            }
        } else if (viewSchemeFilter.toLowerCase() === 'special') {
            if (presentStatus && proposedChangesCounts && BenefitsCounts) {
                return false;
            }
        } else if (viewSchemeFilter.toLowerCase() === 'implemented') {
            if (earlierStatus && implementStatus && BenefitsCounts) {
                return false;
            }
        } else if (viewSchemeFilter.toLowerCase() === 'special implemented') {
            if (earlierStatus && implementStatus && BenefitsCounts) {
                return false;
            }
        }
        return true;
    };
    useEffect(() => {
        checkValidity();
        setvalidateBtn(checkValidity());
    }, [presentStatus, proposedChangesCounts, BenefitsCounts, earlierStatus, implementStatus]);

    const handleDocumentFilter = (data) => {
        setViewDocumentTypeFilter(data)
        validate('viewDocumentTypeFilter', data)
    }
    // -------------Delete the Document From S3 API--------------//
    const deleteS3Document = async (pathdoc, document, index) => {
        setloading(true)
        let params = {
            emp_id: loginData?.userData?.Perno,
            path: pathdoc
        }
        await deleteS3Documents(params)
            .then((res) => {
                if (res?.status === 200 || res?.status === 201) {
                    document.splice(index, 1);
                    setdocumentData(document);
                    toaster("success", res?.data?.message ? res?.data?.message : "")
                }
                else { res?.status != 404 && toaster("warning", res?.data?.message ? res?.data?.message : "") }
            })
            .catch((error) => {
                console.log("error in S3 delete documents-->", error)
                error?.status != 404 && toaster("warning", error?.data?.message ? error?.data?.message : "")
            })
            .finally(() => { setloading(false) })
    }

    const handleDelete = async (pathdoc, document, index) => {
        setloading(true)
        let params = {
            emp_id: loginData?.userData?.Perno,
            path: pathdoc
        }
        await deleteS3Documents(params)
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    if (document){
                        document.splice(index, 1);
                        setdocumentData(document);
                    }
                }
                else { res?.status != 404 && toaster("warning", res?.message ? res?.message : "") }
            })
            .catch((error) => {
                console.log("error in S3 delete documents-->", error)
                error?.status != 404 && toaster("warning", error?.message ? error.message : "")
            })
            .finally(() => { setloading(false) })
    }
    // -------------Delete the Document From DB API--------------//
    const deleteDocument = async (id, documents, index) => {
        setloading(true)
        await deleteDocuments(id)
            .then((res) => {
                if (res?.status === 200 || res?.status === 201) {
                    documents.splice(index, 1);
                    setdocumentData(documents);
                    toaster("success", res?.data?.message ? res?.data?.message : "")
                }
                else { res?.status != 404 && toaster("warning", res?.data?.message ? res?.data?.message : "") }
            })
            .catch((error) => {
                console.log("error in S3 delete documents-->", error)
                error?.status != 404 && toaster("warning", error?.data?.message ? error.data.message : "")
            })
            .finally(() => { setloading(false) })
    }
    // -------------POST the Document to S3 API--------------//
    const UploadDocumentDetail = async (attachment, attachmentType, attachmentName) => {
        setloading(true)
        let body = {
            emp_id: loginData?.userData?.Perno,
            attachment: attachment,
            attachment_name: attachmentName,
            attachment_type: attachmentType,
        }
        await uploadDocument(body)
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    const document = {
                        type: viewDocumentTypeFilter?.title,
                        name: res?.data?.data?.attachment_name,
                        extension: attachmentType,
                        attachment: res?.data?.data?.attachment_presigned_url,
                        path: res?.data?.data?.attachment_path,
                        role: "Suggestor",
                        document_category: "suggestion_details"
                    };
                    setdocumentData([...documentData, document]);
                    toaster("success", res?.data?.message ? res?.data?.message : "")
                }
                else { res?.status != 404 && toaster("warning", res?.data?.message ? res?.data?.message : "") }
                if (viewDocumentTypeFilter.title != 'Other') {
                    const updatedDocumentTypes = availableDocumentTypes.filter(type => type.title !== viewDocumentTypeFilter?.title);
                    setAvailableDocumentTypes(updatedDocumentTypes);
                }
            })
            .catch((error) => {
                console.log("error in S3 delete documents-->", error)
                error?.status != 404 && toaster("warning", error?.data?.message ? error.data?.message : "")
            })
            .finally(() => { setloading(false) })
    }

    const getSuggestionDetails = async (editData) => {
        setloading(true);
        getSuggestionLogs(editData?.id)
            .then((res) => {
                if (res?.status === 200 || res?.status === 201) {
                    setuserHistory(res?.data?.data);
                    setMemberData(res?.data?.data?.member_details);
                    setdocumentData(res?.data?.data?.document_details.filter((e)=> e.document_category === 'suggestion_details'));
                } else {
                    setuserHistory([]);
                    setMemberData([]);
                    setdocumentData([]);
                    toast.warn(res?.data?.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    });
                }
            })
            .catch((error) => {
                toast.warn(error.message, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                });
            })
            .finally(() => {
                setloading(false);
            });
    };

    const fileSelectHandler = (event) => {
        if (event?.target?.files && event?.target?.files[0]) {
            let typeOfImage = event?.target?.files[0]?.type;
            let ImageType = typeOfImage?.split('/');
            setAttachmentName({
                documentName: event?.target?.files[0]?.name,
                documentType: ImageType[1] || ''
            });

            let maxAllowedSize = 2097152; // 2MB size
            if (event?.target?.files[0]?.size <= maxAllowedSize) {
                let reader = new FileReader();
                reader.readAsDataURL(event?.target?.files[0]);
                reader.onload = (e) => {
                    setdocumentLink({ documentLink: e.target.result });
                };
            } else {
                toast.warn('Please upload image less than 2MB', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                });
                setdocumentLink({ documentLink: '' });
            }
        } else {
            setAttachmentName({ documentName: '', documentType: '' });
            setdocumentLink({ documentLink: '' });
        }
    };

    // const validateSuggestionDetailsID = (id) => {
    //     setloading(true)
    //     getSuggestionLogs(id)
    //         .then((res) => {
    //             if (res?.status === 200 || res?.status === 201) {
    //                 toaster("success", "Suggestion ID is Valid")
    //                 setIsIdeaBankRefValid(true);
    //             }
    //             else {
    //                 toaster("warning", "Suggestion ID is Invalid")
    //                 setIsIdeaBankRefValid(false);
    //             }
    //         })
    //         .catch((error) => {
    //             console.log("error in S3 delete documents-->", error)
    //             error?.status != 404 && toaster("warning", error ? error : "")
    //         })
    //         .finally(() => { setloading(false) })
    // }
    const validateSuggestionDetailsID = (id) => {
        setloading(true)
        let payload = {
            idea_bank_reference: parseInt(id),
            sug_member_details: memberData
          };
          validateIdeaBankReference(payload)
            .then((res) => {
                if (res?.status === 200 || res?.status === 201) {
                    // toaster("success", "Suggestion ID is Valid")
                    // setIsIdeaBankRefValid(true);
                    toaster("success", res?.data?.message ? res?.data?.message : "") 
                }
                else {
                    // toaster("warning", "Suggestion ID is Invalid")
                    // setIsIdeaBankRefValid(false);
                    toaster("warning", res?.data?.message ? res?.data?.message : "")  
                }
            })
            .catch((error) => {
                console.log("error in S3 delete documents-->", error)
                error?.status != 404 && toaster("warning", error ? error : "")
            })
            .finally(() => { setloading(false) })
    }

    const handleUpload = () => {
        if (validate('viewDocumentTypeFilter', viewDocumentTypeFilter) || validate('attachmentName', attachmentName)) return;
        UploadDocumentDetail(documentLink?.documentLink, attachmentName?.documentType, attachmentName?.documentName);
        setViewDocumentTypeFilter('');
        setAttachmentName('');
        if (fileInputRef.current) { fileInputRef.current.value = ''; }
    };

    const handleDeleteDocument = (index, pre) => {
        if (!Array.isArray(index)) {
            index = [index];
        }
        if (pre === 'prev') {
            const updatedDocuments = [...documentData];
            const deletedDocument = updatedDocuments[index];
            if (deletedDocument?.id) {
                deleteDocument(deletedDocument?.id, updatedDocuments, index)
            } else { deleteS3Document(deletedDocument?.path, updatedDocuments, index) }
            if (deletedDocument?.type !== 'Other') {
                setAvailableDocumentTypes(prevTypes => {
                    const updatedTypes = prevTypes.filter(type => type.title !== 'Other');
                    return [...updatedTypes, { title: deletedDocument?.type }];
                });
            } else {
                const otherExists = availableDocumentTypes.some(type => type.title === 'Other');
                if (!otherExists) {
                    setAvailableDocumentTypes(prevTypes => [...prevTypes, { title: 'Other' }]);
                }
            }
        }
    }
    const handleSubmit = () => {
        // const requiredFields = ['presentStatus', 'proposedChangesCounts', 'BenefitsCounts', 'implementStatus', 'earlierStatus'];
        // let hasError = false;
        // requiredFields.forEach(field => {
        //     if (validate(field, eval(field))) {
        //         hasError = true;
        //     }
        // });
        // if (!hasError) {
            // console.log("erprpr")
            setopenConfirmPopUp(true);
        // }
    };

    const handleChange = (e, name) => {
        const { value } = e.target;
        if (name === 'presentStatus') {
            if (viewSchemeFilter.toLowerCase() === 'normal' || viewSchemeFilter.toLowerCase() === 'special') {
                setpresentStatusCounts(value);
            }
        } else if (name === 'earlierStatus') {
            if (!(viewSchemeFilter.toLowerCase() === 'normal' || viewSchemeFilter.toLowerCase() === 'special')) {
                setearlierStatus(value);
            }
        } else if (name === 'proposedChangesCounts') {
            if (viewSchemeFilter.toLowerCase() === 'normal' || viewSchemeFilter.toLowerCase() === 'special') {
                setproposedChangesCounts(value);
            }
        } else if (name === 'otherAreasCounts') {
            setotherAreas(value);
        } else if (name === 'implementStatus') {
            setimplementStatus(value);
        } else if (name === 'BenefitsCounts') {
            setBenefitsCounts(value);
        } else if (name === 'title') {
            setTitle(value);
        }
        validate(name, value);
    };
    
    useEffect(() => {
        const existingDocumentTypes = documentData.map(doc => doc.type);
        const updatedDocumentTypes = DocumentType.filter(type => !existingDocumentTypes.includes(type.title) || (type.title !== 'Before' && type.title !== 'After'));
        setAvailableDocumentTypes(updatedDocumentTypes);
    }, [documentData]);

    const lengthCalculate = (value) => {
        let length = value ? value.length : 0
        return length
    }
    useEffect(() => {
        getSuggestionDetails(editData);
    }, [editData])

    const onConfirmAction = () => {
        editSuggestion(userHistory?.suggestion_details?.id);
    }
    const editSuggestion = async (id) => {
        setloading(true)
        let payload = {
            current_role: "Suggestor",
            from_role: "Suggestor",
            to_role: "Evaluator",
            action: "Clarification_Submitted",
            to_status: 0,
            from_status: 6,
            from_agency_name: null,
            to_agency_name: userHistory?.suggestion_details?.agency_name,
            role: "Suggestor",
            comment: null,
            existing: {
                suggestion_details: {
                    id: userHistory?.suggestion_details?.id,
                    implemented_date: implementDate != '' ? implementDate : null,
                    present_status: presentStatus != '' ? presentStatus : null,
                    earlier_status: earlierStatus != '' ? earlierStatus : null,
                    proposed_changes: proposedChangesCounts != '' ? proposedChangesCounts : null,
                    changes_implemented: implementStatus != '' ? implementStatus : null,
                    benefits: BenefitsCounts != '' ? BenefitsCounts : null,
                    // idea_bank_reference: isIdeaBankRefValid ? ideaBankRef : null,
                    idea_bank_reference: ideaBankRef ? parseInt(ideaBankRef) : null,
                    applicable_areas: otherAreasCounts != '' ? otherAreasCounts : null,
                    annual_saving: annualSaving != '' ? annualSaving : null,
                    is_suggestion_implementable: false,
                },
                member_details: memberData,
                document_details: documentData,
            }
        }
        await EditSuggestions(id, payload)
            .then((res) => {
                if (res?.status === 200 || res?.status === 201) {
                    toast.warn(res?.data?.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    });
                    setopenConfirmPopUp(false)
                    setShowDashBoard(true);
                }
                else {
                    // res?.status != 404 && toaster("warning", res?.data?.message ? res?.data?.message : "")
                    toaster("warning", res?.data?.message ? res?.data?.message : "")
                    setopenConfirmPopUp(false)
                    setShowDashBoard(false);
                }
            })
            .catch((error) => {
                console.log("error in get Presize Url request API", error)
                error?.status != 404 && toaster("warning", error?.data?.message ? error.data?.message : "")
                setopenConfirmPopUp(false)
            })
            .finally(() => { setloading(false) })
    }
    return (
        <>
        {
            showDashBoard ?
                <><SuggestionDashboard setselectedCardStatus={setselectedCardStatus} /></>
                :
                <>
                    <div className='d-flex flex-direction-column'>
                        <div onClick={()=>{    
                                    handleBack(documentData);
                                    }
                            } 
                            className=' row pointer'>
                            <LoaderIndicator loading={loading} />
                            <img
                                alt="hide"
                                src={rightarrowbg}
                                className="up_arrow"
                            />
                            <div className='toggle-card-heading ml-1'>
                                {'Back'}
                            </div>
                        </div>
                        <div className='mt-2 suggestion-screen-screen-scroll'>
                            <div className='flex-direction-column '>
                                <span className='document-innercard-content-title common-text-color'>Update Suggestion Entry</span>
                                <div className='mt-1'>
                                    <div className='row ml-4 w-100'>
                                        <div className='col-12 col-md-3 mb-3'>
                                            <div className='p-sub-heading required'>Title</div>
                                            <div className='p-placeholder-text'>
                                                <input
                                                    className="form-control p-placeholder"
                                                    type="text"
                                                    style={{ height: '43px' }}
                                                    value={userHistory?.suggestion_details?.title ?? '----'}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-3 mb-3'>
                                            <div className='p-sub-heading required'>Category</div>
                                            <div className='p-placeholder-text'>
                                                <LargeDropdown
                                                    data={category}
                                                    value={userHistory?.suggestion_details?.category_name ?? '----'}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-3 mb-3'>
                                            <div className='p-sub-heading required'>Scheme Type</div>
                                            <div className='p-placeholder-text'>
                                                <DynamicDropdown
                                                    data={typeScheme}
                                                    render="title"
                                                    value={userHistory?.suggestion_details?.scheme_type_name ?? '----'}
                                                    arrow={"true"}
                                                    height={"NotificationHeight"}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                        {(viewSchemeFilter.toLowerCase() === 'special' || viewSchemeFilter.toLowerCase() === 'special implemented') && (
                                            <div className='col-12 col-md-3 mb-3'>
                                                <div className='p-sub-heading ml-2 required'>Scheme Name</div>
                                                <div className='p-placeholder-text'>
                                                    <LargeDropdown
                                                        value={userHistory?.suggestion_details?.scheme_name ?? '---'}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>)}
                                        {(viewSchemeFilter.toLowerCase() === 'implemented' || viewSchemeFilter.toLowerCase() === 'special implemented') && (
                                            <div className='col-12 col-md-3 mb-3'>
                                                <div className='p-sub-heading required'>Implemented Date</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder"
                                                        type="date"
                                                        style={{ height: '38px' }}
                                                        value={userHistory?.suggestion_details?.implemented_date ?? '----'}
                                                        onChange={(e) => handleChange(e, 'implementDate')}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>)}
                                    </div>
                                    <hr />
                                    <div className='flex-direction-column'>
                                        <span className='mt-3 mb-1'>Member Details</span>
                                    </div>
                                    {memberData.length > 0 && (
                                        <ViewTable
                                            data={memberData}
                                            showCheck={false}
                                            showEdit={false}
                                            hasAction={false}
                                            memberTable={true}
                                            columns={SuggestionMemberDetails} />)}
                                    {viewSchemeFilter != '' && (
                                        <>
                                            <hr className='mt-2' />
                                            <div className='flex-direction-column '>
                                                <span className='mt-3'>Suggestion Details</span>
                                                <div className='row mt-2'>
                                                    <div className='col-12 col-md-3 mb-3'>
                                                        <div className='p-sub-heading required'>{viewSchemeFilter.toLowerCase() === 'normal' || viewSchemeFilter.toLowerCase() === 'special' ? 'Present status' : 'Earlier status'}</div>
                                                        <div className='p-placeholder-text'>
                                                            <textarea
                                                                className="form-control p-placeholder"
                                                                type="text"
                                                                value={(viewSchemeFilter.toLowerCase() === 'normal' ||viewSchemeFilter.toLowerCase() === 'special') ? presentStatus : earlierStatus}
                                                                onChange={(e) => handleChange(e, (viewSchemeFilter.toLowerCase() === 'normal' || viewSchemeFilter.toLowerCase() === 'special') ? 'presentStatus' : 'earlierStatus')}
                                                                maxLength={350}
                                                            >
                                                            </textarea>
                                                            {(errors.presentStatus) && <span className="p-sub-heading ml-3 errorBlock">{errors.presentStatus}</span>}
                                                            {(errors.earlierStatus) && <span className="p-sub-heading ml-3 errorBlock">{errors.earlierStatus}</span>}
                                                            <div className="justify-content-end time-text">
                                                                {lengthCalculate((viewSchemeFilter.toLowerCase() === 'normal' || viewSchemeFilter.toLowerCase() === 'special') ? presentStatus : earlierStatus)}/350
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-md-3 mb-3'>
                                                        <div className='p-sub-heading required'>{(viewSchemeFilter.toLowerCase() === 'normal' || viewSchemeFilter.toLowerCase() === 'special') ? 'Proposed changes' : ' Changes implemented'}</div>
                                                        <div className='p-placeholder-text'>
                                                            <textarea
                                                                className="form-control p-placeholder"
                                                                type="text"
                                                                value={viewSchemeFilter.toLowerCase() === 'normal' || viewSchemeFilter.toLowerCase() === 'special' ? proposedChangesCounts : implementStatus}
                                                                onChange={(e) => handleChange(e, (viewSchemeFilter.toLowerCase() === 'normal' || viewSchemeFilter.toLowerCase() === 'special') ? 'proposedChangesCounts' : 'implementStatus')}
                                                                maxLength={350}
                                                            >
                                                            </textarea>
                                                            {errors.proposedChangesCounts && <span className="p-sub-heading ml-3 errorBlock">{errors.proposedChangesCounts}</span>}
                                                            {errors.implementStatus && <span className="p-sub-heading ml-3 errorBlock">{errors.implementStatus}</span>}
                                                            <div className="justify-content-end time-text">
                                                                {lengthCalculate((viewSchemeFilter.toLowerCase() === 'normal' || viewSchemeFilter.toLowerCase() === 'special') ? proposedChangesCounts : implementStatus)}/350
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-md-3 mb-3'>
                                                        <div className='p-sub-heading'>Other areas where this suggestion is applicable</div>
                                                        <div className='p-placeholder-text'>
                                                            <textarea
                                                                className="form-control p-placeholder"
                                                                type="text"
                                                                value={otherAreasCounts}
                                                                onChange={(e) => setotherAreas(e.target.value)}
                                                                maxLength={350}
                                                            >
                                                            </textarea>
                                                            <div className="justify-content-end time-text">
                                                                {lengthCalculate(otherAreasCounts)}/350
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-md-3 mb-3'>
                                                        <div className='p-sub-heading required'>Benefits</div>
                                                        <div className='p-placeholder-text'>
                                                            <textarea
                                                                className="form-control p-placeholder"
                                                                type="text"
                                                                value={BenefitsCounts}
                                                                onChange={(e) => handleChange(e, 'BenefitsCounts')}
                                                                maxLength={350}
                                                            >
                                                            </textarea>
                                                            {errors.BenefitsCounts && <span className="p-sub-heading ml-3 errorBlock">{errors.BenefitsCounts}</span>}
                                                            <div className="justify-content-end time-text">
                                                                {lengthCalculate(BenefitsCounts)}/350
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-md-3 mb-3'>
                                                        <div className='p-sub-heading'>Annual Savings in ₹</div>
                                                        <div className='p-placeholder-text'>
                                                            <input
                                                                className="form-control p-placeholder"
                                                                type="text"
                                                                value={annualSaving}
                                                                placeholder={'Enter Annual Saving in ₹...'}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    if (/^\d*\.?\d*$/.test(value)) {
                                                                        setAnnualSaving(value);
                                                                    }
                                                                }}
                                                                onKeyPress={(e) => {
                                                                    if (!/[0-9.]$/.test(e.key)) {
                                                                        e.preventDefault();
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    {(viewSchemeFilter.toLowerCase() === 'implemented' || viewSchemeFilter.toLowerCase() === 'special implemented') && (
                                                        <>
                                                            <div className='col-12 col-md-3 mb-3'>
                                                                <div className='p-sub-heading'>Idea Bank Reference</div>
                                                                <div className='p-placeholder-text flex-direction-row'>
                                                                    <input
                                                                        className="form-control p-placeholder"
                                                                        type="number"
                                                                        value={ideaBankRef}
                                                                        onChange={(e) => {
                                                                            const value = e.target.value.replace(/\D/g, '');
                                                                            if(ideaBankRef==''){
                                                                                setIdeaBankRef(null)    
                                                                            }else{
                                                                                setIdeaBankRef(parseInt(value));
                                                                            }
                                                                        }}
                                                                        placeholder={'Enter Idea Bank Reference'}
                                                                        onKeyPress={(e) => {
                                                                            if (!/[0-9]/.test(e.key)) {
                                                                                e.preventDefault();
                                                                            }
                                                                        }}
                                                                        
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className='align-content-center'>
                                                                <input
                                                                    type="button"
                                                                    value='VALIDATE'
                                                                    className={`${ideaBankRef ? 'validateBtn' : 'validateBtnDisabled'}`}
                                                                    onClick={() => validateSuggestionDetailsID(ideaBankRef)}
                                                                    disabled={!ideaBankRef}
                                                                />
                                                            </div>
                                                        </>)}
                                                </div>
                                            </div></>)}
                                    <hr />
                                    <div className='flex-direction-column '>
                                        <span className='mt-3'>Document to Upload</span>
                                        <div className='row mt-2'>
                                            <div className='col-12 col-md-3'>
                                                <div className='p-sub-heading'>Select Document Type</div>
                                                <div className='p-placeholder-text'>
                                                    <DynamicDropdown
                                                        data={availableDocumentTypes}
                                                        render="title"
                                                        value={viewDocumentTypeFilter.title}
                                                        selectedValue={(data) => handleDocumentFilter(data)}
                                                        arrow={"true"}
                                                        height={"NotificationHeight"}
                                                    />
                                                    {errors.viewDocumentTypeFilter && <span className="p-sub-heading ml-3 errorBlock">{errors.viewDocumentTypeFilter}</span>}
                                                </div>
                                            </div>
                                            <div className='col-12 col-md-3'>
                                                <div className='p-sub-heading'>Upload Document</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className='form-control p-placeholder'
                                                        type="file"
                                                        accept=".png,.jpg,.jpeg,.pdf"
                                                        ref={fileInputRef}
                                                        style={{ height: '43px' }}
                                                        onChange={(e) => fileSelectHandler(e)}

                                                    />
                                                    {errors.attachmentName && <span className="p-sub-heading ml-3 errorBlock">{errors.attachmentName}</span>}
                                                    <span className='noteTextMsg'>{'You can upload Attachment upto 2MB size only.(pdf/png/jpg/jpeg)'}</span>
                                                </div>
                                            </div>
                                            <div className='col-12 col-md-3 mb-4 d-flex align-items-center'>
                                                <input
                                                    type="button"
                                                    value='UPLOAD'
                                                    className={`${!isUploadButtonDisabled ? 'validateBtn' : 'validateBtnDisabled'}`}
                                                    onClick={handleUpload}
                                                    disabled={isUploadButtonDisabled}
                                                />
                                            </div>
                                        </div>
                                        {documentData.length > 0 && (
                                            <SuggesterPreviewCompoment
                                                download={true}
                                                data={documentData}
                                                viewDelete={true}
                                                handleDeleteDocument={handleDeleteDocument} />)}
                                    </div>
                                    <hr />
                                    <div className='row mt-3'>
                                        <div className='col-12 col-md-3 mb-3'>
                                            <div className='p-sub-heading required'>Select Division</div>
                                            <div className='p-placeholder-text'>
                                                <LargeDropdown
                                                    data={divisionData}
                                                    value={userHistory?.suggestion_details?.division_name ?? '----'}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-3 mb-3'>
                                            <div className='p-sub-heading required'>Select Agency</div>
                                            <div className='p-placeholder-text'>
                                                <LargeDropdown
                                                    data={agencyData}
                                                    value={userHistory?.suggestion_details?.agency_name ?? '----'}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row justify-content-end'>
                            <div className="col-4">
                                <input
                                    type="button"
                                    value='SUBMIT'
                                    className={`${!validateBtn ? 'validateBtn' : 'validateBtnDisabled'}`}
                                    disabled={validateBtn}
                                    onClick={handleSubmit}
                                />
                                <input type='button' onClick={()=>{
                                        handleBack(documentData);
                                    }
                            } 
                            value={'BACK'} className='btn cancelBtn mr-3' />
                            </div>
                        </div>
                        {openConfirmPopUp &&
                            <ConfirmationPopup
                                loading={loading}
                                title={"Confirmation"}
                                message={"Are you sure that you want to Submit Suggestion?"}
                                confirmBtnLbl={"SUBMIT"}
                                onDismiss={() => { setopenConfirmPopUp(false) }}
                                onConfirmAction={onConfirmAction}
                            />}
                    </div>
                </>
        }
        </>
    )
}
export default EditSuggestion